import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import "./style.scss";
import AuthContext from "context/AuthContext";
import {
  AddOutlined,
  CheckOutlined,
  ChevronLeft,
  ChevronRight,
  DeleteOutline,
  FirstPageOutlined,
  LastPageOutlined,
  PlayArrowOutlined,
  RefreshOutlined,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import { GeneratedGamesGrid } from "../GameGenerator";
import backgroundImage from "assets/images/background/step-bg.webp";
import LinearProgress from "@material-ui/core/LinearProgress";
import { convertLudoScore } from "../../components/common/GeneratedGameCard";
import { styled } from "@mui/material/styles";
import SocketContext from "../../context/SocketContext";
import usePersistedState from "../../hooks/usePersistedState";

const generateStepByStep = "generateStepByStep";
const generateIdeasFromSteps = "generateIdeasFromSteps";

const DEFAULT_ARRAY = [];

export const StepByStepWrapper = ({}) => {
  const [results, setResults] = useState([]);
  return <StepByStepGenerator results={results} setResults={setResults} />;
};

const StepByStepGenerator = ({
  onHoverSeed,
  includeTitle = true,
  standaloneResults = false,
  allowClear = true,
  showResults,
  setShowResults,
  setResultsLength,
  onReset,
  formKey = "StepByStep",
}) => {
  const { auth } = useContext(AuthContext);
  const { call, loading } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { cache } = useContext(CacheContext);
  const { generationSteps = DEFAULT_ARRAY } = cache;

  const [generationStepsLength, setGenerationStepsLength] = usePersistedState(
    formKey + ".generationStepsLength",
    0
  );
  const [results, setResults] = usePersistedState(formKey + ".results2", []);
  const [activeStep, setActiveStep] = usePersistedState(
    formKey + ".activeStep",
    0
  );
  const [choices, setChoices] = usePersistedState(formKey + ".choices3", {
    step_choices: [],
  });
  const [currentOptions, setCurrentOptions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState([]);

  let className = "w-100 step-generator";

  useEffect(() => {
    if (generationStepsLength > 0) {
      let different = generationSteps.length !== generationStepsLength;
      setGenerationStepsLength(generationSteps.length);
      if (different) {
        setCurrentOptions([]);
        setChoices({ step_choices: [] });
        onClickStep(0);
      }
    }
  }, [generationSteps.length, generationStepsLength]);

  useEffect(() => {
    track("step-by-step.moved-to-step", { step: activeStep });
  }, [activeStep]);

  useEffect(() => {
    if (setResultsLength) setResultsLength(results.length);
  }, [results]);

  useEffect(() => {
    loadSuggestions(activeStep);
  }, [activeStep]);

  function onHover(seed) {
    if (onHoverSeed) return onHoverSeed(seed);
  }

  function clearResults() {
    track("step-by-step.clear-results");
    setResults([]);
    setShowResults(false);
  }

  async function loadSuggestions(index, force = false) {
    let step = generationSteps?.[index];
    if (!step) return;
    if (currentOptions[index] && !force) return;
    let n = 9;
    if (step.allow_input) n = 8;
    if (step.fixed_options) n = 50;
    let data = {
      n,
      genre: auth.user.genres[0],
      platform: auth.user.platform || "Mobile",
      step: step.key,
      step_choices: choices.step_choices.filter((choice) => choice),
    };

    setLoadingSuggestions((prevState) => [...prevState, index]);
    let result = await call(generateStepByStep, { data });
    if (result.ok) {
      setCurrentOptions((prevState) => {
        let newState = [...prevState];
        newState[index] = result.body;
        return newState;
      });
    }
    setLoadingSuggestions((prevState) => prevState.filter((i) => i !== index));
  }

  async function onClickStep(index) {
    setGenerationStepsLength(generationSteps.length);
    setActiveStep(index);
  }

  function changeStepChoices(value, description, goToNext = true) {
    track("step-by-step.selected-option", {
      step: activeStep,
      value,
      description,
    });
    setChoices((prevState) => {
      let step_choices = [...prevState.step_choices];
      step_choices[activeStep] = {
        step: generationSteps[activeStep].key,
        description,
        value: value,
      };
      return {
        ...prevState,
        step_choices,
      };
    });
    if (goToNext) {
      if (activeStep <= generationSteps.length - 1) {
        onClickStep(activeStep + 1);
      }
    }
  }

  async function generateIdeas() {
    let data = {
      n: 3,
      genre: auth.user.genres[0],
      platform: auth.user.platform || "Mobile",
      step_choices: choices.step_choices.filter((choice) => choice),
      request_id: PerformanceUtils.generateId(),
    };
    let response = await call(generateIdeasFromSteps, { data });
    if (response.ok) {
      setResults((prevState) => [...response.body, ...prevState]);
      setShowResults(true);
    }
  }

  function onPrevious() {
    if (activeStep > 0) {
      track("step-by-step.previous", { step: activeStep - 1, choices });
      onClickStep(activeStep - 1);
    }
  }

  function onSkipStep() {
    if (activeStep <= generationSteps.length - 1) {
      track("step-by-step.skip-step", { step: activeStep + 1, choices });
      onClickStep(activeStep + 1);
    }
  }

  function onResetWrapper() {
    track("step-by-step.reset", { step: activeStep, choices });
    setCurrentOptions([]);
    setChoices({ step_choices: [] });
    onClickStep(0);
    loadSuggestions(0, true);
    if (onReset) {
      setTimeout(() => {
        onReset();
      }, 1)
    }
  }

  function onRefresh() {
    track("step-by-step.refresh-options", {
      step: activeStep,
      choices,
      currentOptions,
    });
    loadSuggestions(activeStep, true);
  }

  function onSkipEnd() {
    track("step-by-step.skip-to-end", { step: activeStep, choices });
    setActiveStep(generationSteps.length);
  }

  function onGenerateMore() {
    track("step-by-step.generate-more");
    generateIdeas();
  }

  const generateButtonDisabled = false; //choices.step_choices.filter(choice => choice).length < generationSteps.length;

  const skipDisabled =
    activeStep >= generationSteps?.length || !choices?.step_choices?.[0]?.value;

  const isLoadingTheseSuggestions = loadingSuggestions.includes(activeStep);

  return (
    <div className={className}>
      {includeTitle && (
        <PageTitle
          titleHeading="Step-by-Step Game Ideator"
          titleDescription="Generate game ideas based on your own words or other games."
        />
      )}
      <div className="generator-wrapper">
        {!(standaloneResults && showResults) && (
          <div className="steps-wrapper">
            <div
              className="bg-image"
              style={{ backgroundImage: `url(${backgroundImage})` }}
            />
            <div className="bg-overlay" />
            <div className="content-wrapper">
              {activeStep >= 0 && activeStep <= generationSteps.length - 1 && (
                <>
                  <div className="main-title">
                    Choose a{" "}
                    <span className="section-name">
                      {generationSteps[activeStep].name}
                    </span>
                  </div>
                  <div className="sub-title">
                    {generationSteps[activeStep].description}
                  </div>
                  <div className="step-content">
                    <div className="choices">
                      <Choices
                        key={isLoadingTheseSuggestions + activeStep}
                        activeStep={activeStep}
                        choices={choices}
                        currentOptions={currentOptions}
                        loading={isLoadingTheseSuggestions}
                        changeStepChoices={changeStepChoices}
                        step={generationSteps[activeStep]}
                        onRefresh={onRefresh}
                      />
                      {isLoadingTheseSuggestions && (
                        <div className="loading-wrapper">
                          <div className="text-align-center m-4">
                            <CircularProgress size={55} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Actions
                    onResetWrapper={onResetWrapper}
                    onPrevious={onPrevious}
                    activeStep={activeStep}
                    generationSteps={generationSteps}
                    onSkipStep={onSkipStep}
                    onSkipEnd={onSkipEnd}
                    skipDisabled={skipDisabled}
                    generateIdeas={generateIdeas}
                    showSteps={true}
                    choices={choices}
                  />
                </>
              )}
              {activeStep === generationSteps.length && (
                <>
                  <div className="main-title">
                    Confirm your <span className="section-name">Choices</span>
                  </div>

                  <div className="step-content">
                    <div className="steps final">
                      {generationSteps.map((step, index) => {
                        let className = "step";
                        const value = choices.step_choices[index]?.value;
                        return (
                          <div
                            className={className}
                            key={step.id}
                            onClick={() => onClickStep(index)}
                          >
                            <span className="step-number">
                              {index + 1}. {step.name}
                            </span>
                            <span className="step-choice">
                              {value || "N/A"}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Actions
                    onResetWrapper={onResetWrapper}
                    onPrevious={onPrevious}
                    activeStep={activeStep}
                    generationSteps={generationSteps}
                    onSkipStep={onSkipStep}
                    onSkipEnd={onSkipEnd}
                    skipDisabled={skipDisabled}
                    generateIdeas={generateButtonDisabled ? undefined : generateIdeas}
                    showSteps={false}
                    choices={choices}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {(!standaloneResults || showResults) && (
          <div className="main-generator-content">
            {allowClear && (
              <div className="buttons">
                {results?.length > 0 && (
                  <MyButton
                    className="primary generate-more-button"
                    color="primary"
                    onClick={onGenerateMore}
                    disabled={loading[generateIdeasFromSteps]}
                  >
                    Generate More Game Ideas
                  </MyButton>
                )}
                {results?.length > 0 && (
                  <Tooltip
                    title="Clear History"
                    arrow
                    PopperProps={{
                      className:
                        "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                    }}
                    placement="top"
                  >
                    <span>
                      <IconButton onClick={clearResults}>
                        <DeleteOutline className="pointer text-blue" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
            <div className="seeds" onMouseLeave={() => onHover()}>
              <GeneratedGamesGrid
                games={results}
                onClick={undefined}
                keyAdd={undefined}
                fullVersion={true}
                setGames={setResults}
                childrenStart={
                  !allowClear && (
                    <div className="more-ideas">
                      <IconButton
                        className="icon-button"
                        onClick={onGenerateMore}
                        disabled={loading[generateIdeasFromSteps]}
                      >
                        {loading[generateIdeasFromSteps] ? (
                          <CircularProgress size={35} />
                        ) : (
                          <Tooltip
                            title="Generate More Ideas"
                            arrow
                            PopperProps={{
                              className:
                                "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                            }}
                            placement="top"
                          >
                            <AddOutlined className="white round" />
                          </Tooltip>
                        )}
                      </IconButton>
                    </div>
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Actions = ({onResetWrapper, onPrevious, activeStep, generationSteps, onSkipStep, onSkipEnd, generateIdeas, showSteps, skipDisabled, choices}) => {

  const { loading } = useContext(APIContext);

  return (
    <div className="actions">
      <NavigationIcon
        description="Reset choices and start from the first step"
        onClick={onResetWrapper}
      >
        <FirstPageOutlined className="font-size-xxxxl text-white"/>
        <span>Reset</span>
      </NavigationIcon>
      <hr/>
      <div className="buttons">
        <NavigationIcon
          description="Previous step"
          onClick={onPrevious}
          disabled={activeStep <= 0}
        >
          <ChevronLeft className="font-size-xxxxxxl text-white"/>
          <span>Back</span>
        </NavigationIcon>
        {showSteps ? <div className="step-number">
          {activeStep + 1} / {generationSteps.length}
        </div> : <GeneratingButton
          id="step-generator.generate"
          className="text-gradient bold"
          disabled={!generateIdeas || !choices?.step_choices?.length}
          loading={loading[generateIdeasFromSteps]}
          loadProgressSecs={10}
          onClick={generateIdeas}
        >
          <WbIncandescentOutlined className="font-size-md flip-vertical mr-3" />{" "}
          Generate Game Ideas
        </GeneratingButton>}
        <NavigationIcon
          description="Skip to next step"
          onClick={onSkipStep}
          disabled={skipDisabled}
        >
          <span>Skip</span>
          <ChevronRight className="font-size-xxxxxxl text-white"/>
        </NavigationIcon>
      </div>
      <hr style={{paddingRight: "35px"}}/>
      <NavigationIcon
        description="Skip to the end"
        onClick={onSkipEnd}
        disabled={skipDisabled}
      >
        <span>End</span>
        <LastPageOutlined className="font-size-xxxxl text-white"/>
      </NavigationIcon>
    </div>
  )
}

const COLOR_GRADIENT = [
  "#FF0000", // 0% (Red)
  "#FF2000", // 10%
  "#FF4000", // 20%
  "#FF6000", // 30%
  "#FF8000", // 40%
  "#FFFF00", // 50% (Yellow)
  "#DFFF00", // 60%
  "#BFFF00", // 70%
  "#9FFF00", // 80%
  "#80FF00", // 90%
  "#00FF00", // 100% (Green)
];

const ColoredLinearProgress = styled(LinearProgress)(({theme, barcolor}) => ({
  "& .MuiLinearProgress-bar": {
    backgroundColor: barcolor,
  },
}));

const Choices = ({
                   activeStep,
                   step,
                   currentOptions,
                   choices,
                   loading,
                   changeStepChoices,
                   onRefresh,
                 }) => {
  let currentChoice = choices?.step_choices[activeStep];
  const defaultValue = !currentChoice?.description
    ? currentChoice?.value
    : undefined;

  const [showEnterOwn, setShowEnterOwn] = useState(!!defaultValue);
  const stepOptions = currentOptions[activeStep] || [];
  const [otherText, setOtherText] = useState(defaultValue);
  const [refreshKey, setRefreshKey] = useState(0);

  function onSubmitOther() {
    if (otherText) {
      changeStepChoices(otherText);
      setOtherText("");
    }
  }

  const filteredOptions = useMemo(() => {
    let number = step?.allow_input ? 8 : 9;
    const currentChoiceOption = stepOptions.find(
      (option) => option.value === currentChoice?.value
    );

    const otherOptions = stepOptions.filter(
      (option) => option.value !== currentChoice?.value
    );

    const startIndex = (refreshKey * (number - 1)) % otherOptions.length;
    const cycledOptions = [
      ...otherOptions.slice(startIndex),
      ...otherOptions.slice(0, startIndex),
    ];

    const result = currentChoiceOption
      ? [currentChoiceOption, ...cycledOptions]
      : cycledOptions;
    return result.slice(0, number);
  }, [step, stepOptions, refreshKey, currentChoice]);

  const showRefresh =
    activeStep >= 2 || (!!step?.fixed_options && filteredOptions.length >= 8);

  function onRefreshWrapper() {
    if (step?.fixed_options) {
      setRefreshKey(refreshKey + 1);
    } else {
      return onRefresh();
    }
  }

  return (
    !loading && (
      <React.Fragment key={activeStep}>
        {filteredOptions.map(({ step, value, score, description }, index) => {
          let className = "choice";
          const active = choices.step_choices[activeStep]?.value === value;
          if (active) className += " selected";

          const colorIndex = score?.score
            ? Math.min(
                Math.floor(score.score * COLOR_GRADIENT.length),
                COLOR_GRADIENT.length - 1
              )
            : undefined;
          const ludoScore = score?.score
            ? convertLudoScore(score.score)
            : undefined;
          let tooltipText = description;
          if (ludoScore) tooltipText += ` (Ludo Score: ${ludoScore})`;

          const avatar = score?.score && (
            <div className="ludo-score">
              <ColoredLinearProgress
                variant="determinate"
                value={Math.max(ludoScore, 9)}
                barcolor={COLOR_GRADIENT[colorIndex]}
              />
            </div>
          );

          return (
            <div key={value + index} className={className}>
              <Tooltip
                arrow
                placement="top"
                open={description ? undefined : false}
                title={tooltipText}
                PopperProps={{
                  disablePortal: true,
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
              >
                <div
                  className="choice-text"
                  onClick={() => changeStepChoices(value, description)}
                >
                  <span>{value}</span>
                  {avatar}
                </div>
              </Tooltip>
            </div>
          );
        })}
        {!showEnterOwn && step?.allow_input && (
          <div className="choice" onClick={() => setShowEnterOwn(true)}>
            <div className="choice-text">
              <span>Other</span>
            </div>
          </div>
        )}
        {showEnterOwn && !loading[generateStepByStep] && step?.allow_input && (
          <div className="choice">
            <div className="input-wrapper" key={activeStep}>
              <TextField
                key={activeStep}
                className="text-field"
                placeholder={`Enter ${step.name.toLowerCase()} here`}
                value={otherText}
                onChange={(e) => setOtherText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmitOther();
                  }
                }}
              />
              <div
                className={"check" + (!otherText ? " disabled" : "")}
                onClick={onSubmitOther}
              >
                <CheckOutlined />
              </div>
            </div>
          </div>
        )}
        {showRefresh && (
          <div className="choices" key={currentOptions}>
            <div
              className="choice rounded-border single"
              onClick={onRefreshWrapper}
            >
              <div className="choice-text">
                <RefreshOutlined className="font-size-xxxxl" />
                <span>Refresh Options</span>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  );
};

const NavigationIcon = ({ onClick, description, disabled, children }) => {
  return (
    <Tooltip
      title={description}
      arrow
      PopperProps={{
        className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
      }}
      placement="top"
    >
      <IconButton
        className="navigation-icon"
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default StepByStepGenerator;
