import React, {useContext, useMemo} from 'react';
import clsx from 'clsx';
import {
  Typography,
  Button,
  Tooltip,
  Slider
} from '@material-ui/core';
import {connect} from 'react-redux';
import {setHeaderDrawerToggle} from 'reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CacheContext, {DEFAULT_CACHE} from "context/CacheContext";
import MyButton from "components/Controls/MyButton";
import HelpIcon from "components/common/HelpIcon";
import AuthContext from "context/AuthContext";

const DEFAULT_OBJECT = {};

const HeaderDrawer = (props) => {
  const {cache, setCacheValue} = useContext(CacheContext);
  const {generationOptions = DEFAULT_OBJECT} = cache;
  const {headerDrawerToggle, setHeaderDrawerToggle} = props;

  const {auth} = useContext(AuthContext);
  const {subscription} = auth;

  const toogleHeaderDrawer = () => {
    setHeaderDrawerToggle(!headerDrawerToggle);
  };

  function handleChange(event) {
    let {name, value} = event.target;
    setCacheValue('generationOptions', {...generationOptions, [name]: value});
  }

  const maxGenerations = useMemo(() => {
    let result = 5;
    if (subscription && subscription.plan && subscription.plan.metadata)
      result = parseInt(subscription.plan.metadata.results) || result;
    return result;
  }, [subscription]);

  function onReset() {
    setCacheValue('generationOptions', DEFAULT_CACHE.generationOptions);
  }

  return (
    <>
      <div className="app-drawer-content">
        <Tooltip arrow title="Close drawer" placement="left">
          <Button
            size="small"
            onClick={toogleHeaderDrawer}
            className="close-drawer-btn bg-white p-0 d-40"
            id="CloseDrawerTooltip">
            <div
              className={clsx('navbar-toggler hamburger hamburger--elastic text-black', {
                'is-active': headerDrawerToggle
              })}>
              <span className="hamburger-box">
                <span className="hamburger-inner"/>
              </span>
            </div>
          </Button>
        </Tooltip>
        <div className="vh-100 shadow-overflow">
          <PerfectScrollbar>
            <div className="p-4">
              <div className="text-center">
                <div className="font-weight-bold font-size-lg mb-4 text-black">
                  Generating Options
                </div>
              </div>
              <div>
                <MySlider
                  name="numberGenerations"
                  title={
                    <span>
                      Number of Generations
                      <HelpIcon
                        text="Total of games to be generated. The higher the number, the longer it will take to finish."/>
                    </span>
                  }
                  value={generationOptions.numberGenerations}
                  onChange={handleChange}
                  defaultValue={5}
                  min={1}
                  max={maxGenerations}
                />
              </div>
            </div>
            <div className="px-4">
              <MyButton
                onClick={onReset}
              >
                Reset
              </MyButton>
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <div
        onClick={toogleHeaderDrawer}
        className={clsx('app-drawer-overlay', {
          'is-active': headerDrawerToggle
        })}
      />
    </>
  );
};

const MySlider = ({name, title, min, max, defaultValue, step, onChange, value}) => (
  <>
    <Typography id="discrete-slider-restrict" gutterBottom>
      {title}
    </Typography>
    <Slider
      name={name}
      className="slider-primary my-3 mt-4"
      track="inverted"
      value={value || defaultValue}
      step={step}
      valueLabelDisplay="on"
      min={min}
      onChange={(event, value) => {
        return onChange({target: {name, value}});
      }}
      max={max}
      marks={[{value: min, label: "" + min}, {value: max, label: "" + max}]}
    />
  </>
);

const mapStateToProps = (state) => ({
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderDrawerToggle: (enable) => dispatch(setHeaderDrawerToggle(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDrawer);
