import React from 'react';
import ShowIf from "components/common/ShowIf";
import LazyLoad from 'react-lazyload';

const LudoLazyLoad = ({enable = true, ...props}) => (
  enable ?
      <LazyLoad {...props}>
        {props.children || null}
      </LazyLoad>
  : props.children || null
)

export default LudoLazyLoad;
