import {
  IconButton, Grid,
  CircularProgress, Chip, Tooltip
} from "@material-ui/core";
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import './style.scss';
import {convertProxyUrl} from "components/common/ImageGallery";
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import GameCard, {GameIcon} from "components/common/GameCard";
import DetailsPanelContext from "context/DetailsPanelContext";
import _ from 'lodash';
import MyButton from "components/Controls/MyButton";
import {AssessmentOutlined, WbIncandescentOutlined} from "@mui/icons-material";
import {useHistory} from "react-router";
import SocketContext from "context/SocketContext";

const DEFAULT_ARRAY = [];
const getGamesInformation = 'getGamesInformation';

const GAMES_SLICE = 8;

const GameTopicsDetailsPanel = () => {

  const {call} = useContext(APIContext);
  const [games, setGames] = useState({});
  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;
  const {showTrendTopic} = useContext(DetailsPanelContext);

  const {topics = DEFAULT_ARRAY, game} = detailsPanel;

  useEffect(() => {

    let gameIds = _.uniq(_.flatten(topics.map(({games}) => (games || []).slice(0, GAMES_SLICE))));
    call(getGamesInformation, {data: {ids: gameIds}}).then(response => {
      if (response.ok) {
        let result = {};
        response.body.forEach(game => {
          result[game._id] = game;
        });
        setGames(result);
      }
    });
  }, [topics]);


  function showTopic(topic, genre) {
    showTrendTopic(topic, genre);
  }

  return (
    <div className="game-topics-details-panel">
      <div className="topic-details content p0">
        <Header game={game}/>
        <div className="info">
          <div className="info-content">
            <ShowIf condition={Object.keys(games).length > 0}>
              <span className="white-title">Trending Topics Including this Game</span>
              {topics.map((topic, topicIndex) => (
                <TopicContent
                  key={topic?._id}
                  topicIndex={topicIndex + 1}
                  topic={topic}
                  games={games}
                />
              ))}
            </ShowIf>
            <ShowIf condition={Object.keys(games).length === 0}>
              <div className="text-align-center m-4 loading">
                <CircularProgress size={55}/>
              </div>
            </ShowIf>
          </div>
        </div>
      </div>
    </div>
  )
};

const TopicContent = ({topic, games, topicIndex}) => {

  const history = useHistory();
  const {track} = useContext(SocketContext);

  function onClickCompare() {
    track('trends.compare-to-market', {topic});
    let url = '/market-analysis';
    let dataTopic = JSON.parse(JSON.stringify(topic));
    history.push(url, {
      data: {topic: dataTopic, sources: topic.sources}
    });
  }

  function onClickGenerate() {
    track('trends.generate-ideas', {topic});
    let url = '/game-ideator';
    history.push(url, {
      data: {topic, sources: topic.sources}
    });
  }

  const topicGames = useMemo(() => {
    return topic.games.slice(0, GAMES_SLICE).map(id => games[id]).filter(game => !!game);
  }, [games, topic]);

  const extraGames = topic.games.length - topicGames.length;

  return (
    <div className="topic">
      <div className="left">
        <span>#{topicIndex}</span>
        <div className="vr"/>
      </div>
      <div className="right">
        <div className="chips">
          {topic.keywords.map((keyword, keywordIndex) => <Chip key={keywordIndex} label={keyword}/>)}
        </div>
        <div className="games-wrapper">
          <Games games={topicGames} topic={topic}/>
          {extraGames > 0 ? < span> +{extraGames} < /span> : null}
        </div>
        <div className="topic-actions">
              <span onClick={onClickCompare}><AssessmentOutlined
                className="font-size-lg"/>Compare to Market</span> | <span
          onClick={onClickGenerate}><WbIncandescentOutlined className="flip-vertical font-size-lg"/> Generate Game Ideas</span>
        </div>
      </div>
    </div>
  );
}

const Header = ({game}) => {

  const {showGame} = useContext(DetailsPanelContext);

  let {header = {}, screenshots = []} = game;

  if (!header?.url) {
    header = (screenshots[0] || {});
  }

  let style = {};
  let headerClassName = "header";
  if (header?.url) {
    header.url = convertProxyUrl(header);
    headerClassName += " cover-header";
    style = {background: `linear-gradient(rgba(19,22,72,0.5) 0%, rgba(19,22,72,0.8) 100%), url(${header.url})`};
  }

  return (
    <>
      <div className={headerClassName} style={style}>
        <div className="game-wrapper text-align-center w-100">
          <GameIcon game={game} fallbackToCover={true}/>
          <span className="game-title text-align-center w-100 d-block">
          {game.title}
            </span>
          <span className="game-developer text-align-center w-100 d-block">
          {game.developers[0]}
            </span>
          <MyButton color="secondary" className="secondary align-self-center mb-2" onClick={() => showGame(game)}>View
            Game
            Details</MyButton>
        </div>
      </div>
    </>
  );
}

const Keywords = ({topic}) => (
  <div className="keywords text-align-center">
    <Grid container spacing={1}>
      {topic.keywords.map((keyword, index) =>
        <ShowIf condition={index !== 0} key={keyword + index}>
          <Grid item md={4}>
            <span>{keyword}</span>
          </Grid>
        </ShowIf>
      )}
    </Grid>
  </div>
)

const Games = ({games = DEFAULT_ARRAY, topic = {}}) => {

  const {showGame} = useContext(DetailsPanelContext);

  return (
    <div className="games">
      <ShowIf condition={games.length > 0}>
        {games.map((game, index) => (
            <Tooltip
              title={game.title}
              arrow
              PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
              placement="top"
            >
            <span>
            <GameIcon game={game} key={game._id} onClick={showGame} fallbackToCover={true}/>
            </span>
            </Tooltip>
          )
        )}
      </ShowIf>
      <ShowIf condition={games.length === 0}>
        <div className="text-align-center m-4">
          <CircularProgress size={55}/>
        </div>
      </ShowIf>
    </div>
  )
}

export default GameTopicsDetailsPanel;
