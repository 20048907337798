import _ from 'lodash';
import {v4 as uuidv4} from "uuid";
import fastCompareIsEqual from "react-fast-compare";

class PerformanceUtils {

  isEqual(foo, bar) {
    return fastCompareIsEqual(foo, bar);
  }

  async sha256(message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }

  arrayToObject(array = [], key = 'id') {
    let result = {};
    array.forEach(item => {
      result[item[key]] = item;
    });
    return result;
  }

  editOrAdd(document, collection = [], key = 'id') {
    let found = false;
    let result = collection.map(existingData => {
      if (document[key] === existingData[key]) {
        found = true;
        return document;
      }
      return existingData;
    });

    if (!found) result = [document, ...collection];

    return result;
  }

  removeFromArray(document, collection = [], key = 'id') {
    return collection.filter(d => {
      return d[key] !== document[key];
    });
  }

  generateId(prefix) {
    let id = uuidv4();
    if (prefix) {
      id = id.split('-');
      id[0] = prefix;
      id = id.join('-');
    }
    return id;
  };

  sortBy(data = [], fields = ['date'], reverse = true) {
    let sorted = _.sortBy(data, fields);
    if (reverse) sorted = _.reverse(sorted);
    return sorted;
  }

  arrayMove(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
      let k = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  };

  hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
    }
  }

}

export default new PerformanceUtils();
