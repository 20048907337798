import React, {useContext, useEffect, useMemo, useState} from 'react';
import PageTitle from "components/layout-components/PageTitle";
import APIContext from "context/APIContext";
import qs from 'qs';
import './style.scss';
import ShowIf from "components/common/ShowIf";
import {CircularProgress} from "@material-ui/core";
import {GameGridWithIds} from "pages/Favorites";
import GeneratedGameCard from "components/common/GeneratedGameCard";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import TrendTopics from "pages/Trends/TrendTopics";
import {useLocation} from "react-router";
import moment from 'moment';
import {FormikSelectField} from "formik-material-fields";
import {Formik, Form} from "formik";

const getUserDigests = 'getUserDigests';
const DEFAULT_ARRAY = [];

const DATE_FORMAT = 'DDMMYYYY';

const Digest = () => {

  const location = useLocation();
  const {call, loading} = useContext(APIContext);
  const [digest, setDigest] = useState();
  const [digests, setDigests] = useState([]);

  const search = qs.parse(location.search, {ignoreQueryPrefix: true});

  useEffect(() => {
    call(getUserDigests).then(response => {
      if (response.ok) {
        setDigests(response.body);
      }
    })
  }, []);

  useEffect(() => {
    if (digests && digests.length > 0) {
      let date = search.date || moment().format(DATE_FORMAT);
      let chosen = digests.find(digest => moment.unix(digest.date).format(DATE_FORMAT) === date);
      if (!chosen) chosen = digests[0];
      if(chosen) {
        chosen.recommendations = (chosen.recommendations || []).slice(1);
      }
      setDigest(chosen);
    }
  }, [digests, search.date])

  return (
    <div className="digest">
      <PageTitle
        titleHeading="Weekly Digest"
        titleDescription="Your personalized Ludo recommendations"
      >
      </PageTitle>
      <div className="digest-content p-4">
        <ShowIf condition={!!loading[getUserDigests]}>
          <CircularProgress size={15}/>
        </ShowIf>
        <ShowIf condition={!loading[getUserDigests] && digests.length === 0}>
          <span className="mt-2 d-block text-primary">You currently have no digests. Keep using Ludo and we'll provide you with custom recommendations soon!</span>
        </ShowIf>
        <ShowIf condition={!!digest}>
          <DateSelector digests={digests} digest={digest} setDigest={setDigest}/>
          {(digest?.recommendations || DEFAULT_ARRAY).map((recommendation, index) => <Recommendation
            data={recommendation} index={index}/>)}
        </ShowIf>
      </div>
    </div>
  );
}

const DateSelector = ({digests, digest, setDigest}) => {
  const options = useMemo(() => {
    return digests.map(digest => {
      return {
        value: digest._id,
        label: moment.unix(digest.date).format('DD/MM/YYYY')
      }
    })
  }, [digests]);

  function onChange(event) {
    let value = event.target.value;
    let digest = digests.find(({_id}) => value === _id);
    if (digest) setDigest(digest);
  }

  return (
    <Formik
      initialValues={{digest: digest._id}}
      onSubmit={onChange}
    >
      <Form>
        <FormikSelectField
          name="digest"
          options={options}
          onChange={onChange}
        />
      </Form>
    </Formik>
  )
}

const Recommendation = ({data, index}) => {

  const topics = useMemo(() => {
    return (data.topics || []).map(topic => {
      return {
        ...topic,
        games: topic.games.map(id => {
          return {
            game_id: id
          }
        })
      }
    })
  }, [data.topics]);

  return (
    <div id={`#section-${index}`} className={"recommendation i" + index}>
      <ShowIf condition={!!data.title}>
        <span className="title secondary">
          {data.title}
        </span>
      </ShowIf>
      <ShowIf condition={!!data.subtitle}>
        <span className="sub-title">
          {data.subtitle}
        </span>
      </ShowIf>
      <ShowIf condition={!!data.games && data.games.length > 0}>
        <div className="mt-5">
          <GameGridWithIds
            gameIds={data.games}
            includeStats={true}
            gameProps={{
              lazyLoad: false,
              location: data.country,
            }}
          />
        </div>
      </ShowIf>
      <ShowIf condition={!!topics && topics.length > 0}>
        <TrendTopics
          topics={topics}
          location={data.country}
          tab={0}
        />
      </ShowIf>
      <ShowIf condition={!!data.generations && data.generations.length > 0}>
        <div className="mt-4 pt-3">
          <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 600: 2, 1000: 3}}
          >
            <Masonry gutter="15px">
              {data.generations.map(game => (
                <GeneratedGameCard
                  key={game.id}
                  game={game}
                />))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </ShowIf>
    </div>
  )
}

export default Digest;
