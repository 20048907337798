import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ShowIf from "components/common/ShowIf";
import {Chip, CircularProgress, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@material-ui/core";
import PerformanceUtils from "helpers/PerformanceUtils";
import APIContext from "context/APIContext";
import GameCard from "components/common/GameCard";
import usePersistedState from "hooks/usePersistedState";
import {LockIcon} from "components/Controls/MyButton";
import {cleanGame} from "components/common/GameCard";
import {getGameScoreData} from "pages/Trends/TrendGames";
import DetailsPanelContext from "context/DetailsPanelContext";
import CacheContext from "context/CacheContext";
import LudoCarousel from "components/common/LudoCarousel";
import NoResults from "components/common/NoResults";
import {SEARCH_RESULTS_MESSAGE} from "scenes/SubscriptionPage/Plans";
import LudoLazyLoad from "components/common/LudoLazyLoad";
import {
  AssessmentOutlined,
  MoreVert,
  SubjectOutlined,
  WbIncandescentOutlined
} from "@mui/icons-material";
import {useHistory} from "react-router";
import restrictions from 'config/restrictions.json';
import SocketContext from "context/SocketContext";
import useWatchWidth from "hooks/useWatchWidth";

const NUMBER_GAMES = 50;

export const TrendTopics = ({
                              topics,
                              tab,
                              genre,
                              location,
                              carouselClassName,
                              expanded = true,
                              sources
                            }) => {

  const {cache, setCacheValue} = useContext(CacheContext);
  const {showTrendTopic} = useContext(DetailsPanelContext);
  const {trialExpired} = cache;

  let halfs = useMemo(() => {
    if (topics && topics.length > 0) {
      let half = Math.ceil(topics.length / 2);
      return [
        topics.slice(0, half),
        topics.slice(half),
      ]
    }
    return [[], []];
  }, [topics]);

  const [minScore, maxScore] = useMemo(() => {
    if ((topics || [])[0]) {
      let min = topics[0].normalized_score;
      let max = topics[0].normalized_score;
      for (let i = 0; i < (topics || []).length; i++) {
        let val = parseFloat(topics[i].normalized_score);
        if (!!val) {
          if (val < min) min = val;
          if (val > max) max = val;
        }
      }
      return [min, max];
    } else {
      return [0, 0];
    }
  }, [topics])

  const topicIds = useMemo(() => (topics || []).map(({topic_id}) => topic_id), [topics]);

  const onClickTopic = useCallback((topic, locked) => {
    if (locked) return setCacheValue('lockScreen', SEARCH_RESULTS_MESSAGE);
    let topicsToShow = trialExpired ? topics.slice(0, restrictions.TRENDS_NUMBER) : topics;
    showTrendTopic(topic, genre, topicsToShow);
  }, [genre, topics, trialExpired]);

  return (
    <div className="topics-wrapper">
      <ShowIf condition={topics === null}>
        <div className="text-align-center m-4">
          <CircularProgress size={55}/>
        </div>
      </ShowIf>
      <ShowIf condition={topics && topics.length === 0}>
        <NoResults/>
      </ShowIf>
      <ShowIf condition={topics && topics.length > 0}>
        <>
          <ShowIf condition={expanded}>
            <div className="topic-list-expanded mx-4 text-primary">
              <ExpandedGameTopics
                key={topicIds}
                tab={tab}
                topics={topics}
                onClick={onClickTopic}
                sources={sources}
                genre={genre}
                location={location}
                carouselClassName={carouselClassName}
                trialExpired={trialExpired}
              />
            </div>
          </ShowIf>
          <ShowIf condition={!expanded}>
            <div className="topic-list p-4 text-primary">
              <Grid container>
                <Grid item sm={12} md={6}>
                  {halfs[0].map((topic, index) =>
                    <TopicListItem
                      key={topic.topic_id}
                      topic={topic}
                      position={index + 1}
                      minScore={minScore}
                      maxScore={maxScore}
                      onClick={onClickTopic}
                    />
                  )}
                </Grid>
                <Grid item sm={12} md={6}>
                  {halfs[1].map((topic, index) =>
                    <TopicListItem
                      key={topic.topic_id + index}
                      topic={topic}
                      position={index + 1 + halfs[0].length}
                      minScore={minScore}
                      maxScore={maxScore}
                      onClick={onClickTopic}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </ShowIf>
        </>
      </ShowIf>
    </div>
  )
}

const ExpandedGameTopics = ({
                              topics,
                              tab,
                              onClick,
                              genre,
                              location,
                              sources,
                              carouselClassName,
                              trialExpired,
                              scrollContainer = ".app-content--inner__wrapper",
                            }) => {
  return (<div>
      {topics.map((topic, index) => (
        <LudoLazyLoad enable={true} offset={400} height={348} once key={"lazy" + topic.topic_id + index}
                      scrollContainer={scrollContainer}>
          <TopicListItemExpanded
            key={topic.topic_id + index}
            onClick={onClick}
            topic={topic}
            position={index + 1}
            genre={genre}
            sources={sources}
            location={location}
            forceDate={tab === 1}
            carouselClassName={carouselClassName}
            locked={index >= restrictions.TRENDS_NUMBER && trialExpired}
          />
        </LudoLazyLoad>
      ))}
    </div>
  );
}

const DEFAULT_ARRAY = [];
const getGamesTrends = 'getGamesTrends';

const TopicListItemExpanded = ({
                                 topic,
                                 position,
                                 onClick,
                                 genre,
                                 sources,
                                 location,
                                 forceDate,
                                 carouselClassName,
                                 locked,
                               }) => {

  const {track} = useContext(SocketContext);
  const history = useHistory();
  const {call} = useContext(APIContext);

  const [visibleIndex, setVisibleIndex] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState();

  const getGamesInformation = "getGamesInformation";

  const [gameTrends, setGameTrends] = useState(DEFAULT_ARRAY);

  const {keywords, score_text, score, title} = topic;
  const visibleKeywords = title ? [title] : keywords.slice(0, 4);

  const optionsRef = useRef();
  const optionsWidth = useWatchWidth(optionsRef);

  const [games, setGames] = usePersistedState('ExpandedGameTopics.fetchedGames' + topic.topic_id, undefined);

  const gameIds = useMemo(() => {
    return topic.games.map(({game_id}) => game_id).slice(0, NUMBER_GAMES);
  }, [topic]);


  useEffect(() => {
    if (gameIds.length > 0) {
      call(getGamesInformation, {data: {ids: gameIds}}).then(response => {
        if (response.ok) {
          setGames(PerformanceUtils.arrayToObject(response.body.map(cleanGame), '_id'));
        } else {
          setGames(null);
        }
      });
    }
  }, [gameIds]);

  useEffect(() => {
    if (!locked) {
      let ids = topic.games.map(({game_id}) => game_id);
      call(getGamesTrends, {data: {ids}, location}).then(response => {
        if (response.ok && response.body.length > 0) {
          setGameTrends(response.body)
        }
      });
    }
  }, [topic.games, genre, location, locked]);

  const filteredGames = useMemo(() => {
    let result = {
      games: [],
      infos: []
    }
    let ids = (topic.games || []).map(({game_id}) => game_id);
    let gameData = ids.map(id => (games || {})[id]).filter(g => !!g);
    result.games = gameData.slice(0, NUMBER_GAMES);
    result.infos = result.games.map(({_id}) => {
      return gameTrends.filter(trend => {
        return trend.game_id === _id;
      }).map(data => data.infos)[0];
    });
    return result;
  }, [topic.games, gameTrends, games])

  const altText = useMemo(() => {
    return filteredGames.games.map((game, index) => getGameScoreData({
      game,
      game_info: filteredGames.infos[index]
    }, undefined, forceDate));
  }, [filteredGames, forceDate]);

  function onCompare(event) {
    track('trends.compare-to-market', {topic});
    event.preventDefault();
    event.stopPropagation();
    let url = '/market-analysis';
    history.push(url, {
      data: {topic, sources}
    });
  }

  function onGenerate(event) {
    track('trends.generate-ideas', {topic});
    event.preventDefault();
    event.stopPropagation();
    let url = '/game-ideator';
    history.push(url, {
      data: {topic, sources}
    });
  }

  function onActionsClick(event) {
    track('trends.open-context', {topic});
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function closeContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);
  }

  const allowCompare = topic.location === "United States";
  const optionsWidthThreshold = 500;
  const smallOptionsWidthThreshold = 200;

  function onVisibleIndex(index) {
    setVisibleIndex(index);
  }

  return (
    <div className={"topic-expanded" + (locked ? " locked" : "")}>
      <div className="d-flex flex-row top-row" onClick={() => onClick(topic, locked)}>
          <span className="position">
            #{position}
          </span>
        <Tooltip
          title={score_text}
          arrow
          placement="top"
          PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
        >
          <div className="keywords">
            <ShowIf condition={!locked}>
              {visibleKeywords.map((keyword, index) => <Chip
                className={"bg-gradient ml-2 px-4 font-size-md " + (index === 0 ? "big" : "small")}
                key={keyword + index}
                size={index === 0 ? "medium" : "small"}
                label={keyword}
              />)}
            </ShowIf>
            <ShowIf condition={!!locked}>
              <b>&nbsp;</b><span>&nbsp;</span> <span>&nbsp;</span> <span>&nbsp;</span>
            </ShowIf>
          </div>
        </Tooltip>
        <ShowIf condition={!!score}>
          <span className="more-options" ref={optionsRef}>

            {optionsWidth > smallOptionsWidthThreshold &&
              <span className="option details" onClick={() => onClick(topic, locked)}>
              <SubjectOutlined/>&nbsp;View Topic Details
            </span>}

            <ShowIf condition={optionsWidth <= optionsWidthThreshold}>
              <div
                className="option ml-2"
                onClick={onActionsClick}
              >
                <MoreVert className="font-size-xxxl"/>
              </div>

              {menuAnchorEl && <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={!!menuAnchorEl}
                onClose={closeContextMenu}
              >
                {optionsWidth < smallOptionsWidthThreshold && <MenuItem onClick={() => onClick(topic, locked)}>
                  <ListItemIcon>
                    <SubjectOutlined/>
                  </ListItemIcon>
                  <ListItemText primary="View Topic Details"/>
                </MenuItem>}
                {allowCompare && <MenuItem onClick={onCompare}>
                  <ListItemIcon>
                    <AssessmentOutlined/>
                  </ListItemIcon>
                  <ListItemText primary="Compare to Market"/>
                </MenuItem>}
                <MenuItem onClick={onGenerate}>
                  <ListItemIcon>
                    <WbIncandescentOutlined className="flip-vertical"/>
                  </ListItemIcon>
                  <ListItemText primary="Generate Game Ideas"/>
                </MenuItem>
              </Menu>}
            </ShowIf>

            <ShowIf condition={optionsWidth > optionsWidthThreshold}>

            {allowCompare ? <span className="option compare" onClick={onCompare}>
              <AssessmentOutlined/>&nbsp;Compare to Market
            </span> : null}

              <span className="option generate" onClick={onGenerate}>
              <WbIncandescentOutlined className="flip-vertical"/>&nbsp;Generate Game Ideas
            </span>

            </ShowIf>

        </span>
        </ShowIf>
      </div>
      <div className="bottom-row">
        <ShowIf condition={filteredGames.games.length === 0}>
          <CircularProgress size={25}/>
        </ShowIf>

        <ShowIf condition={filteredGames.games.length > 0}>

          <LudoCarousel
            id="trend-topic"
            className={carouselClassName}
            onVisibleIndex={onVisibleIndex}
          >
            {filteredGames.games.map((game, index) => {
              return (
                <React.Fragment key={game._id + index}>
                  {index < visibleIndex && <GameCard
                    game={game}
                    genre={genre}
                    location={location}
                    altText={altText[index]}
                    locked={locked}
                  />}
                </React.Fragment>
              )
            })}
          </LudoCarousel>
        </ShowIf>
      </div>
    </div>
  )
}

const TopicListItem = ({topic, position, minScore = 0, maxScore = 0, onClick}) => {

  const {cache, setCache} = useContext(CacheContext);
  const {trialExpired} = cache;
  const shouldHide = trialExpired && position > restrictions.TRENDS_NUMBER;

  const {score_text, normalized_score, title} = topic;

  const percentageValue = Math.abs(normalized_score) * 100;

  let negativeClassname = "bar-wrapper negative";
  let positiveClassname = "bar-wrapper positive";
  if (normalized_score >= 0) positiveClassname += " colored";
  if (normalized_score < 0) negativeClassname += " colored";

  let positiveBarWidth = "100%";
  let negativeBarWidth = "0%";

  if (minScore < 0) {

    let total = Math.abs(minScore) + Math.abs(maxScore);

    let negativeTotal = Math.abs(minScore) / total;
    let positiveTotal = Math.abs(maxScore) / total;

    positiveBarWidth = (positiveTotal * 100) + "%";
    negativeBarWidth = (negativeTotal * 100) + "%";
  }

  function onClickWrapper() {
    if (shouldHide) {
      setCache(prevState => {
        return {
          ...prevState,
          lockScreen: SEARCH_RESULTS_MESSAGE
        }
      })
    } else {
      onClick(topic);
    }
  }

  return (
    <>
      <Tooltip
        title={shouldHide ? null : score_text}
        open={shouldHide ? false : undefined}
        arrow
        placement="top"
        PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
      >
        <div className="topic my-1" onClick={onClickWrapper}>
          <span className="position">{position}</span>
          <span className="keywords text-truncate text-capitalize">
            <ShowIf condition={shouldHide}>
              <span className="font-weight-bold mr-2">Locked</span>
              <LockIcon/>
            </ShowIf>
            <ShowIf condition={!shouldHide}>
              <b>{title}</b>
            </ShowIf>
            <span className="filler"/>
      </span>
          <div className="bar px-2">
            <div className={negativeClassname} style={{width: negativeBarWidth}}>
              <hr style={{width: percentageValue + "%"}}/>
            </div>
            <div className={positiveClassname} style={{width: positiveBarWidth}}>
              <hr style={{width: percentageValue + "%"}}/>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  )
}

export default TrendTopics;
