import React, {useContext, useState} from 'react';
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import {Grid} from "@material-ui/core";
import {Form, Formik} from "formik";
import {FormikSelectField, FormikTextField} from "formik-material-fields";
import MyButton from "components/Controls/MyButton";
import FileUpload, {toBase64} from "components/Controls/FileUpload";
import ShowIf from "components/common/ShowIf";
import FormikPersist from 'components/utils/FormikPersist';
import * as Yup from "yup";
import {Alert} from "@material-ui/lab";

const TYPES = [
  'Bug report',
  'Feature request',
  'Feedback',
  'Other'
];

const Feedback = () => {

  const EP = 'submitFeedback';
  const {call, loading} = useContext(APIContext);
  const [result, setResult] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadKey, setUploadKey] = useState(0);

  async function sendFeedback(values, {resetForm}) {
    let {text, type} = values;
    let binaryStrings = await Promise.all(files.map(file => toBase64(file, false)));
    let data = {
      text, type, images: binaryStrings
    };
    let response = await call(EP, {data});
    if (response.ok) {
      setResult(true);
      setFiles([]);
      resetForm();
      setUploadKey(Math.random());
    }
  }

  const isLoading = loading[EP];

  return (
    <div className="w-100">
      <PageTitle
        titleHeading="Feedback and Bug Reports"
        titleDescription="Let us know how we can improve Ludo."
      />
      <div className="form-wrapper">
        <Formik
          initialValues={{type: "", text: ''}}
          onSubmit={sendFeedback}
          validationSchema={ValidationSchema}
        >
          <Form>
            <FormikPersist name="Feedback"/>
            <div className="d-flex flex-column">
              <Grid container>
                <Grid item sm={12} md={6}>
                  <FormikSelectField
                    className="mt-2"
                    name="type"
                    label="Report type"
                    options={Object.values(TYPES).map(type => {
                      return {value: type, label: type}
                    })}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={12} md={6}/>
                <Grid item sm={12} md={6}>
                  <FormikTextField
                    name="text"
                    className="mt-2"
                    label="Report"
                    multiline
                    rows="4"
                  />
                </Grid>
                <Grid item sm={12} md={6}/>
                <Grid item sm={12} md={6}>
                  <FileUpload onFilesUpdated={setFiles} key={uploadKey}/>
                </Grid>
                <Grid item sm={12} md={6}/>
                <Grid item sm={12} md={6}>
                  <MyButton
                    id="feedback.send"
                    loading={isLoading}
                  >
                    Send
                  </MyButton>
                </Grid>
                <ShowIf condition={!!result}>
                  <Grid item sm={12}>
                    <Alert severity="success" className="mt-2 w-50">
                      Thank you for your feedback!
                    </Alert>
                  </Grid>
                </ShowIf>
              </Grid>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
};

export default Feedback;

const ValidationSchema = Yup.object().shape({
  type: Yup.string()
    .required('Choose one option'),
  text: Yup.string()
    .required('Type your feedback'),
});
