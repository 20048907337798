import React, {useContext, useMemo, useState, useEffect, useCallback} from "react";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import {CircularProgress, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import {cleanImage} from "components/common/ImageGallery";
import SocketContext from "context/SocketContext";
import {Favorite, FavoriteBorder, ThumbDown, ThumbDownOutlined} from "@mui/icons-material";

const addFavoriteGame = 'addFavoriteGame';
const addFavoriteGeneratedGame = 'addFavoriteGeneratedGame';
const addFavoriteImage = 'addFavoriteImage';
const removeFavorite = 'removeFavorite';
const addFavoriteText = 'addFavoriteText';

const DEFAULT_ARRAY = [];

const FavoriteButton = ({
                          data,
                          ep,
                          children,
                          payloadId,
                          payloadId2,
                          className = "",
                          afterClickedFavorite,
                          tooltipOpen,
                          onTextChanged,
                          menuItem = false,
  placement="top"
                        }) => {

  const {call} = useContext(APIContext);
  const {track} = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const {cache, removeFavoriteFromCache, addFavoriteToCache} = useContext(CacheContext);
  const {selectedProjectId, allFavorites = DEFAULT_ARRAY} = cache;

  const favoriteMatch = useMemo(() => {
    return allFavorites.find(favorite => favorite.payload_id && (favorite.payload_id === payloadId || favorite.payload_id === payloadId2))
  }, [allFavorites, payloadId, payloadId2]);

  async function removeFavoriteWrapper() {
    track('favorite.remove', data);
    let toRemove = {...favoriteMatch};
    let response = await call(removeFavorite, {id: favoriteMatch._id});
    if (response.ok) {
      removeFavoriteFromCache(toRemove);
    }
  }

  async function addFavoriteWrapper() {
    track('favorite.add', data);
    let response = await call(ep, {...data, projectId: selectedProjectId});
    if (response.ok) {
      addFavoriteToCache(response.body);
    }
    if (!!afterClickedFavorite) afterClickedFavorite();
  }

  const onClick = useCallback(async event => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    if (!!favoriteMatch) {
      await removeFavoriteWrapper();
    } else {
      await addFavoriteWrapper();
    }
    setLoading(false);
  }, [favoriteMatch]);

  let title = !!favoriteMatch ? "Remove from favorites" : "Add to favorites";

  useEffect(() => {
    if (!!onTextChanged) onTextChanged(title, onClick);
  }, [title, onClick])

  return menuItem ? (
      <MenuItem onClick={onClick}>
        <ListItemIcon>
          {loading && <CircularProgress size={20}/>}
          <ShowIf condition={!loading}>
            {children || (!!favoriteMatch ? <Favorite
                onClick={onClick}
                className="font-size-lg favorite-button"/> :
              <FavoriteBorder
                onClick={onClick}
                className="font-size-lg favorite-button"
              />)}
          </ShowIf>
        </ListItemIcon>
        <ListItemText primary={title}/>
      </MenuItem>
    ) :
    (<div className={"favorite-button-wrapper " + className}>
      <Tooltip
        key={title}
        title={title}
        PopperProps={{disablePortal: true, className: "MuiTooltip-popper secondary"}}
        open={tooltipOpen}
        placement={placement}
      >
      <span>
          <IconButton
            onClick={onClick}
            disabled={loading}
          >
              {loading && <CircularProgress size={20}/>}
            <ShowIf condition={!loading}>
            {children || (!!favoriteMatch ? <Favorite
                onClick={onClick}
                className="favorite-button"/> :
              <FavoriteBorder
                onClick={onClick}
                className="favorite-button"
              />)}
            </ShowIf>
          </IconButton>
      </span>
      </Tooltip>
    </div>);
};

export const TextFavoriteButton = ({data, type, children, className}) => (
  <FavoriteButton data={{text: data, type}} ep={addFavoriteText} payloadId={data.id} className={className}>
    {children}
  </FavoriteButton>);

export const ImageFavoriteButton = ({image, children, className, afterClickedFavorite, tooltipOpen, placement}) => {
  return (<FavoriteButton
    data={{image: cleanImage(image.originalImage || image)}}
    ep={addFavoriteImage}
    payloadId={image.url}
    payloadId2={image.id}
    className={className}
    tooltipOpen={tooltipOpen}
    placement={placement}
    afterClickedFavorite={afterClickedFavorite}>
    {children}
  </FavoriteButton>);
}

export const GameFavoriteButton = ({game, children, className, afterClickedFavorite, menuItem}) => {
  return (<FavoriteButton data={{id: game._id}} ep={addFavoriteGame} payloadId={game._id} className={className}
                          menuItem={menuItem}
                          afterClickedFavorite={afterClickedFavorite}>
    {children}
  </FavoriteButton>);
};

export const GeneratedGameFavoriteButton = ({
                                              game, children, className, afterClickedFavorite
                                            }) => {
  return (<FavoriteButton data={{game}} ep={addFavoriteGeneratedGame} payloadId={game.id} className={className}
                          afterClickedFavorite={afterClickedFavorite}>
    {children}
  </FavoriteButton>);
};


export const DislikeButton = ({
                                onClick, className = "", active = false
                              }) => {

  async function onClickWrapper(event) {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }
  return (<div className={"dislike-button-wrapper " + className}>
    <Tooltip
      key="Dislike"
      title="Dislike"
      PopperProps={{disablePortal: true, className: "MuiTooltip-popper secondary"}}
      placement="top"
    >
        <span>
          <IconButton
            disabled={active}
            onClick={onClickWrapper}
          >
            {active ? <ThumbDown className="dislike-button"/> : <ThumbDownOutlined className="dislike-button"/>}
          </IconButton>
        </span>
    </Tooltip>
  </div>);
};
