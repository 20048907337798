import React, {useContext, useEffect, useState} from 'react';
import {
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem, Grid, CircularProgress
} from "@material-ui/core";
import AuthContext from "context/AuthContext";
import APIContext from "context/APIContext";
import {Form, Formik} from "formik";
import {FormikTextField} from "formik-material-fields";
import MyButton from "components/Controls/MyButton";
import * as Yup from "yup";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import SocketContext from "context/SocketContext";
import {AddCircleOutline, CloseOutlined, DeleteOutline, Mail, MoreVert} from "@mui/icons-material";

import slackIcon from 'assets/images/icons/slack-white.svg';

const getTeams = 'getTeams';
const getTeamUsers = 'getTeamUsers';
const inviteToTeam = 'inviteToTeam';
const removeTeamUser = 'removeTeamUser';
const removeInvite = "removeInvite";
const removeSlackAuth = "removeSlackAuth";
const getSlackAuthUrl = 'getSlackAuthUrl';

const DEFAULT_ARRAY = [];
const ROLES = {
  owner: "owner",
  editor: "editor"
};

const Team = ({onClose}) => {

  const {track} = useContext(SocketContext);
  const {call, loading} = useContext(APIContext);
  const {auth} = useContext(AuthContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const {teams = DEFAULT_ARRAY} = cache;
  const [users, setUsers] = useState([]);
  const [add, setAdd] = useState(false);
  const [url, setUrl] = useState(undefined);

  const team = (teams || [])[0];
  const teamId = (team || {})._id

  useEffect(() => {
    track('team.open');
    if (teamId) {
      call(getSlackAuthUrl, {team: teamId}).then(response => {
        if (response.ok) {
          setUrl(response.body.value);
        }
      });
    }
    call(getTeams).then(response => {
      if (response.ok) {
        setCacheValue('teams', response.body);
      }
    });
  }, [teamId]);

  useEffect(() => {
    if (team) {
      call(getTeamUsers, {id: teamId}).then(response => {
        if (response.ok) {
          setUsers(response.body);
        }
      })
    }
  }, [team])

  async function onAddTeamMember(email) {
    let response = await call(inviteToTeam, {
      email,
      id: team._id
    }, {successMessage: 'User invited successfully!'});
    if (response.ok) {
      setAdd(false);
      mergeTeam(teams, response.body, setCacheValue);
    }
  }

  async function onRemoveSlack() {
    let response = await call(removeSlackAuth, {team: team._id}, {successMessage: 'Slack integration removed successfully!'});
    if (response.ok) {
      mergeTeam(teams, response.body, setCacheValue);
    }
  }

  let isOwner = team?.owner_id === auth.user._id;
  let owner = users.find(user => user.role === ROLES.owner) || {};
  let usersExceptOwner = users.filter(user => user.role !== ROLES.owner);
  let {invitations = []} = (team || {});
  const isLoading = loading[getTeams] || (!!team && users.length === 0);

  return (
    <div className="mx-5 team bg-white text-primary">
      <span className="top-right">
          <IconButton onClick={onClose}>
          <CloseOutlined
            className="font-size-xxl pointer text-secondary"
          />
          </IconButton>
        </span>
      <Container className="py-3 py-xl-5">

        <div className="text-center mb-4">
          <span className="mb-4 font-size-xxxxl">
            {(team || {}).name || "Create Your Team"}
          </span>
        </div>

        <ShowIf condition={isLoading}>
          <div className="text-align-center m-4">
            <CircularProgress size={55}/>
          </div>
        </ShowIf>
        <ShowIf condition={!isLoading}>
          <ShowIf condition={!team}>
            <AddTeamForm/>
          </ShowIf>
          <ShowIf condition={!!team}>
            <User
              key={owner.user_id}
              user={owner}
              showOptions={false}
              team={team}
            />
            <br/>
            {usersExceptOwner.map(user => (
              <User
                key={user.user_id}
                user={user}
                team={team}
                showOptions={isOwner}
              />
            ))}
            {invitations.map(invitation => (
              <User
                key={invitation.email}
                user={invitation}
                invitation={true}
                team={team}
                showOptions={isOwner}
              />
            ))}
            <ShowIf condition={add}>
              <AddMemberForm onAdd={onAddTeamMember} loading={loading[inviteToTeam]}/>
            </ShowIf>
            <ShowIf condition={!add && isOwner}>
              <span className="text-secondary clickable" onClick={() => setAdd(true)}>
                <AddCircleOutline/> Add Team Member
              </span>
            </ShowIf>
          </ShowIf>
          <ShowIf condition={!!team}>
            <div className="text-center mb-4">
            <span className="mb-2 mt-5 font-size-xxxxl d-block">
              Slack Integration
            </span>
              <ShowIf condition={!!team?.slack_token}>
              <span className="mx-5 px-4 d-block">
              Your slack integration has been successfully configured. You can set it up again or remove it by using the buttons below.
                </span>
              </ShowIf>
              <div className="flex-row">
                <a href={url}>
                  <MyButton
                    id="team.connect-to-slack"
                    size="medium"
                    color="secondary"
                  >
                    <img alt="Slack" src={slackIcon} className="font-size-lg mr-2 mt-1"/>
                    Connect to Slack
                  </MyButton>
                </a>
                <ShowIf condition={!!team?.slack_token}>
                  <MyButton
                    id="team.remove-slack"
                    size="medium"
                    className="ml-3 red"
                    onClick={onRemoveSlack}
                    loading={!!loading[removeSlackAuth]}
                    style={{width: "270px"}}
                  >
                    <DeleteOutline className="font-size-lg mr-2 mt-1"/>
                    Remove Slack Integration
                  </MyButton>
                </ShowIf>
              </div>
            </div>
          </ShowIf>
        </ShowIf>
      </Container>
    </div>
  )
};

const AddMemberForm = ({onAdd, loading}) => {

  function onSubmit(values) {
    return onAdd(values.email);
  }

  return (
    <Formik
      initialValues={{email: ""}}
      onSubmit={onSubmit}
      validationSchema={ValidationSchemaTeamMember}
    >
      <Form>
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid item xs={10} sm={4}>
            <FormikTextField
              name="email"
              disabled={loading}
              label="Email"
            />
          </Grid>
          {/*<Grid item xs={8} sm={4}>
            <FormikSelectField
              name="interval"
              label="Subscription Interval"
              options={SUB_INTERVALS.map(({value, label}) => {
                return {value, label}
              })}
              fullWidth
            />
          </Grid>*/}
          <Grid item xs={4} sm={4}>
            <MyButton id="team.add-member" className="add-team-member" color="secondary" size="small" loading={loading}>Send
              Invite</MyButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

const User = ({user, team, showOptions = true, invitation = false}) => {
  const {call} = useContext(APIContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  function onClickActions(event) {
    cancelEvent(event);
    setMenuAnchorEl(event.currentTarget);
  }

  function onCloseActions(event) {
    cancelEvent(event);
    setMenuAnchorEl(undefined);
  }

  function cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async function callInviteEndpoint(event, endpoint, data, successMessage) {
    onCloseActions(event)
    setLoading(true);
    let response = await call(endpoint, data, {successMessage})
    setLoading(false);
    if (response.ok) {
      mergeTeam(cache.teams, response.body, setCacheValue);
    }
  }

  async function resendInvitation(event) {
    return callInviteEndpoint(event, inviteToTeam, {
      id: team._id,
      email: user.email,
      interval: user.subscription_interval
    }, 'Invitation sent successfully');
  }

  async function cancelInvitation(event) {
    return callInviteEndpoint(event, removeInvite, {id: team._id, email: user.email});
  }

  async function removeUser(event) {
    return callInviteEndpoint(event, removeTeamUser, {
      id: team._id,
      userId: user.user_id
    }, 'User removed from team successfully!')
  }

  return (
    <Grid container className="user">
      <Grid xs={12} item sm={6}>
        <span>{user.email}</span>
      </Grid>
      <Grid item xs={6} sm={6}>
        <span className="disabled right text-capitalize">{user.user_id ? user.role : "Invited"}</span>
        <ShowIf condition={showOptions}>

          <IconButton
            className="options d-inline-flex"
            onClick={loading ? undefined : onClickActions}>
            <ShowIf condition={loading}>
              <CircularProgress size={15}/>
            </ShowIf>
            <ShowIf condition={!loading}>
              <MoreVert className="font-size-md text-secondary"/>
            </ShowIf>
          </IconButton>
          <ShowIf condition={!!menuAnchorEl}>
            <Menu
              anchorEl={menuAnchorEl}
              keepMounted
              open={!!menuAnchorEl}
              onClose={onCloseActions}
            >
              <ShowIf condition={invitation}>
                <MenuItem onClick={resendInvitation}>
                  <ListItemIcon>
                    <Mail/>
                  </ListItemIcon>
                  <ListItemText primary="Re-send Invitation"/>
                </MenuItem>
                <MenuItem onClick={cancelInvitation}>
                  <ListItemIcon>
                    <CloseOutlined
                      className="font-size-lg"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Cancel Invitation"/>
                </MenuItem>
              </ShowIf>
              <ShowIf condition={!invitation}>
                <MenuItem onClick={removeUser}>
                  <ListItemIcon>
                    <CloseOutlined
                      className="font-size-lg"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Remove User"/>
                </MenuItem>
              </ShowIf>
            </Menu>
          </ShowIf>
        </ShowIf>
      </Grid>
    </Grid>
  )
}

const addTeam = 'addTeam';
const refreshToken = 'refreshToken';

const AddTeamForm = ({}) => {

  const {call, loading} = useContext(APIContext);
  const {setAuth} = useContext(AuthContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const {teams = DEFAULT_ARRAY} = cache;

  async function submitForm(values) {
    let {name} = values;
    let response = await call(addTeam, {team: {name}});
    if (response.ok) {
      setCacheValue('teams', [...teams, response.body]);
      response = await call(refreshToken);
      if (response.ok) {
        setAuth(response.body, false, true);
      }
    }
  }

  const isLoading = loading[addTeam];

  return (
    <Formik
      initialValues={{name: ""}}
      onSubmit={submitForm}
      validationSchema={ValidationSchemaTeam}
    >
      <Form>
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid item sm={6}>
            <FormikTextField
              name="name"
              disabled={isLoading}
              label="Team Name"
            />
          </Grid>
          <Grid item sm={6}>
            <MyButton id="team.add-team" loading={isLoading}>
              Submit
            </MyButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default Team;

export function mergeTeam(teams, team, setCacheValue) {
  let newTeams = teams.map(t => {
    if (t._id !== team._id) return t;
    return team;
  })
  setCacheValue('teams', newTeams);
}

const ValidationSchemaTeam = Yup.object().shape({
  name: Yup.string().required('No name provided'),
});

const ValidationSchemaTeamMember = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
});
