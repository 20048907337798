import React, {useEffect, useRef, useState} from "react";
import {
  Checkbox,
  Chip,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
  FormControl, Typography
} from "@material-ui/core";
import {Field} from "formik";
import ShowIf from "components/common/ShowIf";

const ITEM_HEIGHT = 44;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5,
      width: 200
    }
  },
  variant: "menu",
  getContentAnchorEl: null
};

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

const FormikChipSelect = ({
                            name,
                            title,
                            values = DEFAULT_ARRAY,
                            labels = DEFAULT_OBJECT,
                            options = DEFAULT_ARRAY,
                            chipClassName = "",
                            className,
                            style,
                            menuClassName,
                            itemClassName = "",
                            itemStyle,
                            checkboxStyle,
                            showAvatar = undefined,
                            onChange
                          }) => {

  let selectedOptions = options;
  const chipsRef = useRef();
  const [maxSize, setMaxSize] = useState(1);

  useEffect(() => {
    if (chipsRef.current) {
      handleResize();
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [chipsRef.current])

  function handleResize() {
    let width = chipsRef.current ? chipsRef.current.offsetWidth : 0;
    setMaxSize(Math.max(Math.floor(width / 160), 1));
  }

  if (values.length > 0) {
    selectedOptions = values.map(str => {
      return {
        label: labels[str] || str,
        value: str
      }
    });
  }

  return (
    <div className="chip-select">
      <Field name={name}>
        {({
            field, form: {touched, errors, setFieldValue}
          }) => (
          <FormControl error={touched[name] && errors[name]}>
            <InputLabel className="chip-select-label">{title}</InputLabel>
            <Select
              name={name}
              labelId="chip-select-label"
              className={className}
              multiple
              value={field.value || []}
              onChange={event => {
                field.onChange(event);
                setTimeout(() => {
                  handleResize();
                }, 100);
                if (onChange) onChange(event.target.value);
              }}
              input={<Input defaultValue={field.value}/>}
              style={style}
              renderValue={(selected = []) => {
                let toRender = selected.slice(0, maxSize);
                let difference = selected.length - toRender.length;
                return (<div ref={chipsRef} style={{minWidth: "100%"}}>
                  {toRender.map((value) => {
                    return (<Chip
                      style={{zIndex: "1"}}
                      className={"mr-2 "+chipClassName}
                      icon={!!showAvatar ? showAvatar(value) : null}
                      key={value}
                      label={labels[value] || value}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={() => {
                        let newValues = field.value.filter(genre => genre !== value);
                        setFieldValue(field.name, newValues);
                        if (onChange) onChange(newValues);
                      }}
                    />);
                  })}
                  <ShowIf condition={difference > 0}>
                    <Typography className="extra-elements text-white d-inline-flex font-weight-bold ml-1">
                      + {difference}
                    </Typography>
                  </ShowIf>
                </div>);
              }}
              MenuProps={{
                ...MenuProps,
                className: menuClassName
              }}>
              {selectedOptions.map((item) => {
                let {label, value} = item;
                let style = !!itemStyle ? itemStyle(item) : {};
                let checkStyle = !!checkboxStyle ? checkboxStyle(item) : {};
                return (<MenuItem className={"mx-2 px-2 " + itemClassName} key={value} value={value} style={style}>
                  <Checkbox
                    checked={(field.value || []).indexOf(value) > -1}
                    className="p-0 mr-2"
                    style={checkStyle}
                  />
                  <ListItemText primary={label}/>
                </MenuItem>);
              })}
            </Select>
            <ShowIf condition={touched[name] && !!errors[name]}>
              <FormHelperText>{errors[name]}</FormHelperText>
            </ShowIf>
          </FormControl>
        )}
      </Field>
    </div>
  )
};

export default FormikChipSelect;
