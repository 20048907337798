import { useContext, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import CacheContext from "context/CacheContext";

export default function useWatchWidth(ref) {
  const { cache } = useContext(CacheContext);
  const { detailsPanel } = cache;
  const sidePanelOpen = !!detailsPanel.mode;

  const [width, setWidth] = useState(0);

  const handleResize = useCallback(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  // Use useLayoutEffect for the initial measurement
  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    window.addEventListener("resize", handleResize);
    
    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, handleResize]);

  useEffect(() => {
    handleResize();
  }, [sidePanelOpen, handleResize]);

  return width;
}
