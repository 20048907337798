import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import APIContext from "context/APIContext";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import { Form, Formik } from "formik";
import PageTitle from "components/layout-components/PageTitle";
import MyButton, { GeneratingButton } from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import usePersistedState from "hooks/usePersistedState";
import FormikPersist from "components/utils/FormikPersist";
import * as Yup from "yup";
import ShowIf from "components/common/ShowIf";
import PerformanceUtils from "helpers/PerformanceUtils";
import _ from 'lodash';
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
} from "components/Controls/UniversalInput";
import {
  convertFormColors,
  FilterPanel,
  FiltersButton,
  showColorAvatar,
} from "pages/Search";
import "./style.scss";
import ImageGallery, {
  convertProxyUrl,
  MyImage,
} from "components/common/ImageGallery";
import { FormikSelectField } from "formik-material-fields";
import AuthContext from "context/AuthContext";
import FileUpload, {
  ThumbnailPreview,
  toBase64,
} from "components/Controls/FileUpload";
import { GDDModal } from "pages/GDD3/Helpers";
import { FAVORITE_TYPES, filterFavorites } from "pages/Favorites";
import { useHistory, useLocation } from "react-router";
import { Hint } from "scenes/Headquarters";
import {
  AutoAwesomeOutlined,
  CloseOutlined, CropFreeOutlined,
  CropLandscapeOutlined,
  CropOutlined,
  CropPortraitOutlined,
  CropSquare,
  DeleteOutline,
} from "@mui/icons-material";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import SocketContext from "../../context/SocketContext";
import { Examples } from "../GameGenerator";
import ImageEditor from "./ImageEditor";
const generateImages = "generateImages";
const generateImagesInpainting = "generateImagesInpainting";
const cancelGeneration = "cancelGeneration";
const uploadImage = "uploadImage";
const getImagePrompts = "getImagePrompts";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

let cancelBatches = [];

export const IMAGE_STRENGTH = {
  min: 0.0,
  max: 100.0,
  default: 50.0,
  increment: 5,
};

export const IMAGE_TYPES = {
  art: { label: <span>🎨 Art</span>, value: "art" },
  screenshot: { label: <span>🎮 Gameplay</span>, value: "screenshot" },
  icon: { label: <span>🖼️ Icons</span>, value: "icon" },
  asset: { label: <span>📁 Asset</span>, value: "asset" },
};

export const ASPECT_RATIOS = {
  default: {
    label: (
      <span>
        <AutoAwesomeOutlined /> Auto
      </span>
    ),
    value: "default",
    labelCollapsed: "Auto Aspect Ratio",
  },
  square: {
    label: (
      <span>
        <CropSquare /> Square
      </span>
    ),
    value: "square",
    labelCollapsed: "Square",
  },
  landscape: {
    label: (
      <span>
        <CropLandscapeOutlined /> Landscape
      </span>
    ),
    value: "landscape",
    labelCollapsed: "Landscape",
  },
  portrait: {
    label: (
      <span>
        <CropPortraitOutlined /> Portrait
      </span>
    ),
    value: "portrait",
    labelCollapsed: "Portrait",
  },
};

const ImageGenerator = () => {
  return (
    <div className="w-100 image-generator">
      <PageTitle
        titleHeading="Image Generator"
        titleDescription="Generate game screenshots or icons based on your own words or other games."
      ></PageTitle>
      <ImageGeneratorResults />
    </div>
  );
};

export const ImageGeneratorResults = ({
  fullVersion = true,
  imageProps = DEFAULT_OBJECT,
  onHover,
  artStyle,
  initialValues,
  clearInitialImage,
}) => {
  const { track } = useContext(SocketContext);
  const { cache } = useContext(CacheContext);
  const { call, loading } = useContext(APIContext);
  const history = useHistory();

  const scrollToRef = useRef();

  const [currentBatchId, setCurrentBatchId] = useState(undefined);

  let resultsKey = "ImageGenerator.results" + (!fullVersion ? ".small" : "");
  let filesKey = "ImageGenerator.files" + (!fullVersion ? ".small" : "");
  let keyCurrent = "ImageGenerator.current" + (!fullVersion ? ".small" : "");

  const [results, setResults] = usePersistedState(resultsKey, []);
  const [currentFormValues, setCurrentFormValues] = usePersistedState(
    keyCurrent,
    DEFAULT_OBJECT
  );
  const [files, setFiles] = usePersistedState(filesKey, []);
  const [editor, setEditor] = useState(false);

  useEffect(() => {
    if (clearInitialImage) {
      setFiles([]);
    }
  }, [clearInitialImage]);

  const loadingGenerateImages = loading[generateImages] || loading[generateImagesInpainting];
  const { projects, selectedProjectId } = cache;
  const project = useMemo(() => {
    return (
      (projects || []).find((project) => project._id === selectedProjectId) ||
      {}
    );
  }, [projects, selectedProjectId]);

  function cancelGenerationWrapper() {
    if (currentBatchId) {
      cancelBatches.push(currentBatchId);
      call(
        cancelGeneration,
        { generationId: currentBatchId },
        { hideErrorMessage: true }
      );
    }
    setCurrentBatchId(undefined);
  }

  function generateSimilar(data) {
    history.replace({ ...history.location, state: { data } });
  }

  function onLoadMoreImages() {
    track("image-generator.generate_more", { ...currentFormValues });
    generate(currentFormValues);
  }

  const generate = async (values, chosenFiles = files) => {
    cancelGenerationWrapper();
    setCurrentFormValues(values);
    let { search, genres, platform } = values;

    let imageData = chosenFiles[0];

    let data = {
      request_id: PerformanceUtils.generateId(),
      filters: {
        genres,
        colors: values.selectedColors,
        creative_mode: values.creative_mode,
        platform,
      },
      image_type: values.image_type,
      art_style: values.art_style,
      perspective: values.perspective,
      aspect_ratio: values.aspect_ratio,
      ...convertUniversalInput(search),
    };

    let inpainting = !!imageData?.mask

    if (imageData) {
      delete data.filters.creative_mode;
      data.strength = values.strength !== undefined ? values.strength / 100.0 : undefined;
      data.preserve_color = values.preserve_color;
      data.preserve_composition = values.preserve_composition;
      data.preserve_details = values.preserve_details;
      data.mask_image = imageData.mask;
      if (imageData.image) data[imageData.submit_field] = imageData.image;
      if (imageData.cropped) data[imageData.submit_field] = {...data[imageData.submit_field], ...imageData.cropped};
      else data[imageData.submit_field] = imageData.image || imageData;
    }

    setCurrentBatchId(data.request_id);
    const ep = inpainting ? generateImagesInpainting : generateImages;
    let response = await call(ep, { data });
    if (response.ok) {
      setResults((prevState) => [
        ...response.body.map(prepareGeneratedImage),
        ...prevState,
      ]);

      setTimeout(() => {
        if (scrollToRef.current) {
          scrollToRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 500);
    }
    setCurrentBatchId(undefined);
  };

  return (
    <div className="generator-wrapper">
      <div className="form-wrapper pb-3">
        <ImageGeneratorForm
          files={files}
          setFiles={setFiles}
          generate={generate}
          project={project}
          loading={loadingGenerateImages}
          onCancel={cancelGenerationWrapper}
          fullVersion={fullVersion}
          artStyle={artStyle}
          initialValues={initialValues}
          setEditor={setEditor}
          editor={editor}
          setResults={setResults}
        />
      </div>
      <LoadingTip
        style={{ marginLeft: "60px", marginTop: "30px", marginBottom: "30px" }}
        section={LOADING_TIPS_SECTIONS.imageGenerator}
        visible={loadingGenerateImages || results?.length === 0}
        key={loadingGenerateImages}
      />
      <div className="main-generator-content">
        <div className="buttons">
          {!loadingGenerateImages && results?.length > 0 && (
            <MyButton
              className="primary generate-more-button"
              color="primary"
              onClick={() => onLoadMoreImages()}
            >
              Generate More Images
            </MyButton>
          )}
          {results?.length > 0 && (
            <Tooltip
              title="Clear History"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <span>
                <IconButton onClick={() => setResults([])}>
                  <DeleteOutline className="pointer text-blue" />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
        <ShowIf condition={results?.length > 0}>
          <div className="d-flex w-100 justify-content-center p-4">
            <Grid container spacing={2} justifyContent="center">
              {results.map((result) => (
                <Grid item key={result.id}>
                  <MyImage
                    key={result.id || result.url}
                    image={result}
                    {...imageProps}
                    onHover={onHover}
                    contextMenuOverrides={{
                      generateSimilar,
                      onGenerateSimilarResults: results => {
                        setResults(prevState => (_.uniqBy([...results.map(prepareGeneratedImage), ...prevState], 'id')));
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <span ref={scrollToRef} className="scrollTo" />
          </div>
        </ShowIf>
      </div>
    </div>
  );
};

export function prepareGeneratedImage(image) {
  if (!image) return;
  const isEmbedded = !!image.image;
  let url = image.url || `data:image/png;base64, ${image.image}`;
  image = { ...image, url, embedded: isEmbedded };
  return {
    ...image,
    src: url,
    originalImage: image,
  };
}

async function fileFromImage(image, submit_field, rawData) {
  const urlToObject = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  };

  let proxyUrl = convertProxyUrl(image, true);
  let file = await urlToObject(proxyUrl);
  file.preview = URL.createObjectURL(file);
  file.image = image;
  file.mask = rawData?.mask;
  file.cropped = rawData?.cropped;
  const isGameImage = image?.game_id;
  file.submit_field =
    submit_field ||
    (isGameImage ? "initial_game_image" : "initial_generated_image");
  return file;
}

const ImageGeneratorForm = ({
  generate,
  project = DEFAULT_OBJECT,
  loading,
  onCancel,
  files,
  setFiles,
  fullVersion,
  artStyle,
  initialValues,
  setEditor,
  editor,
  setResults
}) => {
  const location = useLocation();
  const { cache } = useContext(CacheContext);
  const { auth } = useContext(AuthContext);
  const { call } = useContext(APIContext);
  const {
    genres = DEFAULT_ARRAY,
    colors = DEFAULT_ARRAY,
    generationStyles = DEFAULT_ARRAY,
    perspectives = DEFAULT_ARRAY,
    allFavorites = DEFAULT_ARRAY,
    platforms = DEFAULT_ARRAY,
  } = cache;
  const [persistedData, setPersistedData] = useState();
  const [formValues, setFormValues] = useState();
  const [favoritesModal, setFavoritesModal] = useState(false);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [examples, setExamples] = useState(DEFAULT_ARRAY);
  const [examplesKey, setExamplesKey] = useState(1);

  const favorites = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.image),
    [allFavorites]
  );

  const finalInitialValues = useMemo(() => {
    return {
      search: [],
      genres: initialValues?.genres || auth.user.genres || [],
      selectedColors: [],
      creative_mode: false,
      image_type: validateImageType(
        initialValues?.image_type || IMAGE_TYPES.art.value
      ),
      art_style: artStyle || generationStyles[0],
      perspective: initialValues?.perspective || perspectives[0],
      platform: initialValues?.platform || auth.user.platform,
      strength: 50,
      preserve_color: false,
      preserve_composition: true,
      preserve_details: true,
      aspect_ratio: ASPECT_RATIOS.default.value,
    };
  }, []);

  const formKey =
    "ImageGenerator10" +
    project._id +
    (!fullVersion ? "-small-" : "") +
    JSON.stringify(finalInitialValues || {});
  const [collapsed, setCollapsed] = usePersistedState(
    formKey + ".collapsed",
    true,
    true
  );

  function validateImageType(type) {
    const allowedImageTypes = Object.values(IMAGE_TYPES).map(
      (imageType) => imageType.value
    );
    if (allowedImageTypes.indexOf(type) === -1) {
      type = IMAGE_TYPES.art.value;
    }
    return type;
  }

  const chipSize = {
    xs: 12,
    md: fullVersion ? 6 : 12,
    sm: fullVersion ? 3 : 12,
  };

  const convertedColors = useMemo(() => convertFormColors(colors), [colors]);

  const [eArtStyle, ePerspective, eType, ePlatform, eGenres] = useMemo(() => {
    let data = formValues || persistedData?.values || finalInitialValues;
    return [
      data?.art_style,
      data?.perspective,
      data?.image_type,
      data?.platform,
      data?.genres,
    ];
  }, [formValues, persistedData?.values, finalInitialValues]);

  useEffect(() => {
    if (persistedData) {
      getExamplesWrapper(eArtStyle, ePerspective, eType, ePlatform, eGenres);
    }
  }, [
    eArtStyle,
    ePerspective,
    eType,
    ePlatform,
    eGenres,
    examplesKey,
    persistedData,
  ]);

  async function getExamplesWrapper(
    art_style,
    perspective,
    image_type,
    platform,
    genres
  ) {
    let data = {
      image_type,
      art_style,
      perspective,
      filters: {
        genres: (genres || []).filter((genre) => !!genre),
        platform,
        n: 1,
      },
    };
    let response = await call(getImagePrompts, { data });
    if (response.ok) {
      setExamples(response.body);
    }
  }

  useEffect(() => {
    if (files.length > 0) {
      Promise.all(
        files.map(async (file) => {
          return fileFromImage(file.image || file, undefined, file);
        })
      ).then((newFiles) => {
        setProcessedFiles(newFiles);
      });
    } else {
      setProcessedFiles([]);
    }
  }, [files]);

  async function receiveGenerateSimilar(data, formik) {
    let values = { ...formik.values };

    if (data.currentPath !== location.pathname)
      values = { ...finalInitialValues };
    else {
      values = { ...values, search: [] };
    }

    let newFiles = files;
    if (data.image) {
      newFiles = [await fileFromImage(data.image)];
      setFiles(newFiles);
      setTimeout(() => {
        //loading current value from cache, when generating
        //similar from outside page, will override this. so we
        //wait to make sure we get the correct image in the preview
        setFiles(newFiles);
      }, 150);
    }

    let image_type = data.image_type || data.image?.image_type;
    if (image_type) values.image_type = image_type;
    formik.setValues(values);
    //generate(values, newFiles);
  }

  async function onClickedFavoriteImage(image, formik) {
    formik.setFieldValue("strength", 50);
    setFiles([await fileFromImage(image)]);
    setFavoritesModal(false);
  }

  function openFavorites(event) {
    event.stopPropagation();
    event.preventDefault();
    setFavoritesModal(true);
  }

  async function onFilesUpdated(files, formik) {
    if (files.length > 0) {
      setFavoritesModal(false);
      formik.setFieldValue("strength", 50);
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file, false))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        let images = response.body;
        images = images.map((image) => {
          return { ...image, submit_field: "initial_image_file" };
        });
        setFiles(images);
      }
    }
  }

  function onRefresh() {
    setExamplesKey(examplesKey + 1);
  }

  async function onModifiedImage(crop, mask, formik) {
    if (files?.[0]) {
      const file = files[0];

      if (crop) {
        const image = file.image || file;

        const img = new Image();

        let fetchedUrl = await fetch(image.url)
          .then((response) => response.blob())
          .then(async (blob) => {
            if (!blob.type) blob = new Blob([blob], { type: "image/jpeg" });
            return URL.createObjectURL(blob);
          });

        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const [left, top, right, bottom] = crop;
          const cropWidth = img.width - left - right;
          const cropHeight = img.height - top - bottom;

          canvas.width = cropWidth;
          canvas.height = cropHeight;

          ctx.drawImage(
            img,
            left,
            top,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight
          );

          const binaryString = canvas.toDataURL("image/jpeg");
          let data = { files: [binaryString] };
          let response = await call(uploadImage, { data });
          if (response.ok) {
            setFiles([{ ...file, mask, crop, cropped: response.body[0] }]);
          }
          setEditor(false);
        };
        img.src = fetchedUrl;
      } else {
        setFiles((prevState) => {
          const file = prevState[0];
          return [{ ...file, mask, crop: undefined, cropped: undefined }];
        });
        setEditor(false);
      }
      if (mask) {
        formik.setFieldValue('strength', 0);
      }
    }
  }

  return (
    <Formik
      key={formKey}
      initialValues={finalInitialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={BySentenceValidationSchema}
      onSubmit={(values) => generate(values, files)}
    >
      {(formik) => (
        <>
          {editor && (
            <ImageEditor
              url={files?.[0]?.url || files?.[0]?.image?.url}
              initialImage={files?.[0]?.image || files?.[0]}
              submitField={files?.[0]?.submit_field}
              onClose={() => setEditor(false)}
              formValues={formik.values}
              onResults={results => {
                setResults(prevState => (_.uniqBy([...results.map(prepareGeneratedImage),...prevState], 'id')));
                setEditor(false);
              }}
            />
          )}
          <FormikPersist
            name={formKey}
            onLoad={(data) => setPersistedData(data)}
            onChangeValues={(values) => setFormValues(values)}
          />
          {!!persistedData && (
            <ChangeDataOnLocation
              initialValues={finalInitialValues}
              fields={["search", "genres", "image_type"]}
              shouldOverride={() => true}
              override={receiveGenerateSimilar}
            />
          )}
          <Form>
            {!!persistedData && (
              <div className="d-flex flex-column">
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sm={12}
                      md={12}
                      xs={12}
                      className="input-fields-wrapper"
                    >
                      <div className="d-flex input-fields">
                        <FormikSelectField
                          className="mode-field"
                          name="image_type"
                          label="Image Type"
                          options={Object.keys(IMAGE_TYPES).map((key) => {
                            let { label, value } = IMAGE_TYPES[key];
                            return {
                              value,
                              label: (
                                <div className="d-flex flex-column">
                                  <span className="font-weight-bold">
                                    {label}
                                  </span>
                                </div>
                              ),
                            };
                          })}
                          fullWidth
                        />
                        <UniversalInput
                          name="search"
                          formik={formik}
                          onSetData={(data) => {
                            formik.setFieldValue("search", data);
                          }}
                          value={formik.values.search}
                          allowed={[
                            "text",
                            "game",
                            "generated_game",
                            "gdd",
                            "topic",
                          ]}
                        />
                      </div>
                      <Examples
                        examples={examples}
                        formik={formik}
                        number={1}
                        mainIcon={false}
                        onRefresh={onRefresh}
                      />
                    </Grid>
                    <div className="image-options-wrapper">
                      <Grid
                        item
                        xs={fullVersion ? 4 : "auto"}
                        sm="auto"
                        md="auto"
                        className="file-wrapper-grid"
                      >
                        <div className="file-wrapper">
                          {files.length > 0 && (
                            <ThumbnailPreview
                              files={processedFiles}
                              removeFile={() => setFiles([])}
                            />
                          )}
                          {files.length === 0 && (
                            <div className="row">
                              <MyButton
                                onClick={openFavorites}
                                className="initial-image-button"
                              >
                                Choose initial image (optional)
                              </MyButton>
                              <Hint
                                iconClassName="mt-4"
                                hint="Make variations or edit an input image. You should also describe what you want to see in the prompt."
                              />
                            </div>
                          )}
                        </div>
                      </Grid>
                      <ShowIf condition={files.length > 0}>
                        <Grid
                          item
                          xs={fullVersion ? 12 : "auto"}
                          sm="auto"
                          md="auto"
                        >
                          <div className="slider-wrapper">
                            <MyButton
                              className="text-gradient"
                              onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setEditor(true)
                            }}>
                              <CropFreeOutlined className="mr-2"/>
                              Open In Editor
                            </MyButton>
                            <div className="slider">
                              <Typography
                                id="discrete-slider-restrict"
                                className="title"
                                gutterBottom
                              >
                                <span className="title-span">
                                  Image Strength
                                </span>
                                <Hint
                                  iconClassName="pt-0"
                                  hint="How much the model should stick to the initial image"
                                />
                              </Typography>
                              <Slider
                                name="Image Strength"
                                className="slider-primary"
                                track="inverted"
                                value={
                                  formik.values.strength === undefined
                                    ? finalInitialValues.strength
                                    : formik.values.strength
                                }
                                step={IMAGE_STRENGTH.increment}
                                valueLabelDisplay="on"
                                min={IMAGE_STRENGTH.min}
                                onChange={(event, value) => {
                                  formik.setFieldValue("strength", value);
                                }}
                                max={IMAGE_STRENGTH.max}
                                marks={[
                                  {
                                    value: IMAGE_STRENGTH.min,
                                    label: "" + IMAGE_STRENGTH.min,
                                  },
                                  {
                                    value: IMAGE_STRENGTH.max,
                                    label: "" + IMAGE_STRENGTH.max,
                                  },
                                ]}
                              />
                            </div>
                            {/*<div className="retain-wrapper">
                            <div className="title">
                              <span className="title-span">Retain</span>
                              <Hint
                                iconClassName="pt-0"
                                hint="What information to retain from the initial image"
                              />
                            </div>
                            <div className="checkboxes">
                              <FormikCheckboxField
                                name="preserve_details"
                                className={
                                  "p-0 mr-0 checkbox " +
                                  (formik.values.preserve_details
                                    ? "checked "
                                    : "")
                                }
                                trueValue={true}
                                falseValue={false}
                                color="secondary"
                                label={<span className="mr-3">Details</span>}
                              />
                              <FormikCheckboxField
                                name="preserve_composition"
                                className={
                                  "p-0 mr-0 checkbox " +
                                  (formik.values.preserve_composition
                                    ? "checked "
                                    : "")
                                }
                                trueValue={true}
                                falseValue={false}
                                color="secondary"
                                label={
                                  <span className="mr-3">Composition</span>
                                }
                              />
                              <FormikCheckboxField
                                name="preserve_color"
                                className={
                                  "p-0 mr-0 checkbox " +
                                  (formik.values.preserve_color
                                    ? "checked "
                                    : "")
                                }
                                trueValue={true}
                                falseValue={false}
                                color="secondary"
                                label={<span>Color</span>}
                              />
                            </div>
                          </div>*/}
                          </div>
                        </Grid>
                      </ShowIf>
                    </div>
                    <Grid item xs={12} sm={12} md={12}>
                      <FiltersButton
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                        page="image-generator"
                      />
                    </Grid>
                    <ShowIf condition={!collapsed}>
                      <div className="filters-form">
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikSelectField
                            className="mt-4"
                            name="platform"
                            label="Platform"
                            style={{ top: "2px", position: "relative" }}
                            options={[undefined, ...platforms].map(
                              (platform) => {
                                return {
                                  value: platform,
                                  label: platform || "No Platform",
                                };
                              }
                            )}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikChipSelect
                            name="genres"
                            title="Genres"
                            values={genres}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikSelectField
                            name="art_style"
                            label="Art Style"
                            options={generationStyles.map((value) => {
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                      {value}
                                    </span>
                                  </div>
                                ),
                              };
                            })}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikSelectField
                            name="perspective"
                            label="Perspective"
                            options={perspectives.map((value) => {
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                      {value}
                                    </span>
                                  </div>
                                ),
                              };
                            })}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikSelectField
                            name="aspect_ratio"
                            label="Aspect Ratio"
                            options={Object.keys(ASPECT_RATIOS).map((key) => {
                              let { label, value } = ASPECT_RATIOS[key];
                              return {
                                value,
                                label: (
                                  <div className="d-flex flex-column">
                                    <span className="font-weight-bold">
                                      {label}
                                    </span>
                                  </div>
                                ),
                              };
                            })}
                            fullWidth
                          />
                        </Grid>
                        <Grid
                          item
                          xs={chipSize.xs}
                          sm={chipSize.sm}
                          md={chipSize.md}
                          alignContent="flex-end"
                        >
                          <FormikChipSelect
                            name="selectedColors"
                            title="Colors"
                            className="color-picker"
                            showAvatar={(entry) =>
                              showColorAvatar(entry, convertedColors)
                            }
                            menuClassName="color-picker"
                            itemClassName="text-capitalize no-hover-change color-picker-entry"
                            itemStyle={(entry) => {
                              let { rgb } = entry;
                              return {
                                fill: `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`,
                              };
                            }}
                            checkboxStyle={(entry) => {
                              let { rgb, label } = entry;
                              let rgbColor = `rgb(${rgb[0]}, ${rgb[1]},${rgb[2]})`;
                              let border =
                                label === "white" || label === "orange"
                                  ? "1px solid lightgrey"
                                  : "none";
                              return {
                                fill: rgbColor,
                                background: rgbColor,
                                color: rgbColor,
                                border,
                              };
                            }}
                            options={convertedColors}
                          />
                        </Grid>
                        {/*files.length === 0 && (
                          <Grid
                            item
                            xs={chipSize.md}
                            sm={chipSize.md}
                            md={chipSize.md}
                            alignContent="flex-end"
                          >
                            <FormikCheckboxField
                              name="creative_mode"
                              className={
                                "p-0 mr-0 checkbox " +
                                (formik.values.creative_mode ? "checked " : "")
                              }
                              trueValue={true}
                              falseValue={false}
                              color="secondary"
                              label={
                                <span>
                                  Creative Mode{" "}
                                  <Hint hint="Ludo.ai will spice up your prompt for extra creativity" />
                                </span>
                              }
                            />
                          </Grid>
                        }*/}
                      </div>
                    </ShowIf>
                    <ShowIf condition={collapsed}>
                      <Grid item sm={12} md={12}>
                        <FilterPanel
                          ignore={files.length > 0 ? ["creative_mode"] : []}
                          convertedColors={convertedColors}
                          onExpand={() => setCollapsed(false)}
                        />
                      </Grid>
                    </ShowIf>
                  </Grid>
                  <div className="d-flex">
                    <GeneratingButton
                      id="image-generator.generate"
                      label="Start New Generation"
                      className="gradient"
                      loading={loading}
                      onCancel={onCancel}
                      style={{ margin: 0, marginLeft: "15px" }}
                      trackOptions={{
                        ...formik.values,
                        search: convertUniversalInput(formik.values.search),
                      }}
                      loadProgressSecs={30}
                    />
                  </div>
                </Grid>
                <GDDModal
                  open={favoritesModal}
                  onClose={() => setFavoritesModal(false)}
                  className="image-generator-modal"
                >
                  <span className="top-right">
                    <IconButton onClick={() => setFavoritesModal(false)}>
                      <CloseOutlined className="font-size-xxl pointer text-secondary" />
                    </IconButton>
                  </span>
                  <div className="px-4 m-auto modal-content">
                    <center>
                      <span className="font-weight-bold text-secondary">
                        Upload an Image
                      </span>

                      <FileUpload
                        accept="image/*"
                        title={null}
                        onFilesUpdated={files => onFilesUpdated(files, formik)}
                        maxFiles={1}
                      />

                      <span className="font-weight-bold text-secondary py-3 d-block">
                        Or Select From Your Favorites
                      </span>
                    </center>

                    <ImageGallery
                      images={favorites}
                      minImages={2}
                      enforceSize={false}
                      onImageClick={true}
                      onImageClickFunc={image => onClickedFavoriteImage(image, formik)}
                    />
                  </div>
                </GDDModal>
              </div>
            )}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ImageGenerator;

const BySentenceValidationSchema = Yup.object().shape({
  search: Yup.array(),
});
