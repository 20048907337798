import {Chip, Tooltip} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import React from "react";
import _ from 'lodash';
import moment from "moment";
import {SlopeIcon} from "components/common/GameDetailsPanel";

const LOCATION_MAP = {
  'brazil': ['Brazil'],
  'europe': ['United Kingdom', 'Spain', 'France', 'Germany'],
  'india': ['India'],
  'mexico': ['Mexico'],
  'russia': ['Russia'],
  'turkey': ['Turkey'],
  'us': ['United States']
};

const MOBILE_CHART_GENRES = [
  "All",
  "Action",
  "Adventure",
  "Board",
  "Card",
  "Casino",
  "Casual",
  "Music",
  "Puzzle",
  "Racing",
  "Role Playing",
  "Simulation",
  "Sports",
  "Strategy",
  "Trivia",
  "Word"
];

export function chooseTrend(trends = [], genre, country) {
  if (country && LOCATION_MAP[country]) {
    country = LOCATION_MAP[country][0];
  }
  let source = (trends[0] || {}).source
  if (source === "steam" || source === "itch") {
    return trends[0] || {};
  }

  let resultsByCountry = trends.filter(trend => {
    let isSelected = true;
    if (country) isSelected = isSelected && trend.country.toLowerCase() === country.toLowerCase();
    return isSelected;
  });

  let result = resultsByCountry.find(trend => {
    let isSelected = true;
    if (genre && MOBILE_CHART_GENRES.includes(genre)) isSelected = isSelected && trend.genre === genre;
    return isSelected;
  });

  return result || resultsByCountry[0] || trends[0] || {};
}

export function getRankAndSlope(trend, selectedChart) {
  let slope = null, rank = null, chartName = null;
  if (trend && trend.chart_history && Array.isArray(trend.chart_history)) {
    let chosenChart = trend.chart_history.find(({chart}) => chart === (selectedChart || trend.current_rank_chart));
    if (chosenChart) {
      chartName = chosenChart.chart;
      slope = chosenChart.rank_slope;
      if (slope !== null) {
        slope = slope || 0.0;
        let isSlopeZero = Math.abs(slope).toFixed(1) === "0.0";
        if (!slope || isSlopeZero) {
          slope = 0;
        }
        slope = slope.toFixed(1);
      }
      rank = chosenChart.rank[chosenChart.rank.length - 1];
    }
  }
  return {slope, rank, chartName};
}

function getMultipleScoreData(gameInfo, onlySlope, forceDate, showGenre) {

  let displayGameInfo = [...gameInfo];

  let all = displayGameInfo.find(info => info.genre === "All");
  if (all) {
    displayGameInfo = [all, ...displayGameInfo.filter(info => info.genre !== "All")];
  }

  displayGameInfo = _.uniqBy(displayGameInfo, 'genre');

  return (
    <ul>
      {displayGameInfo.map((game_info, index) => (
        <li key={index}>
          {getGameScoreData({...gameInfo, game_info}, onlySlope, forceDate, showGenre, true)}
        </li>
      ))}
    </ul>
  )
}

export function getGameScoreData(gameInfo, onlySlope = false, forceDate, showGenre = false) {

  let info = gameInfo.game_info;

  if (!forceDate && Array.isArray(info) && info.length > 0) {

    let filteredInfo = info.filter(genreChart => genreChart.current_rank > 0).slice(0, 3);

    if (filteredInfo.length > 0)
      return getMultipleScoreData(filteredInfo, onlySlope, forceDate, true);
  }

  let {slope, rank} = getRankAndSlope(info || {});
  let hasRank = !!rank

  let showDate = slope === undefined || !hasRank || forceDate || gameInfo.forceDate;
  let scoreText;
  let altText = undefined, dateAltText = undefined;

  if (showDate) {
    let releaseDate = (gameInfo.game || {}).release_date;
    let isFuture = moment().unix() < releaseDate;
    dateAltText = <span className="mr-2">
      <ShowIf condition={isFuture}>
      <Chip
        className="mr-2 font-size-xs pre-release"
        size="small"
        label="Pre-Release"
      />
        </ShowIf>
      {releaseDate ? moment.unix((gameInfo.game || {}).release_date).format('MMM Do YYYY') : "Coming Soon"}
    </span>;
    if (forceDate || gameInfo.forceDate) return dateAltText;
  }

  if (slope !== undefined && slope !== null) {
    scoreText = `at an average of ${Math.abs(slope).toFixed(1)} position per day`;
    let isSlopeZero = Math.abs(slope).toFixed(1) === "0.0";
    if (isSlopeZero) scoreText = "Stable"
    else if (slope > 0) scoreText = "Rising " + scoreText;
    else if (slope < 0) scoreText = "Falling " + scoreText;
  }

  if (hasRank) {
    if (!forceDate && !gameInfo.forceDate)
      dateAltText = undefined;
    rank = rank > 0 ? ("#" + rank) : undefined;
    altText = <Tooltip
      title={scoreText}
      arrow
      placement="top"
      PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
    >
      <div>
        <ShowIf condition={!onlySlope}>
          <span title={rank} className="d-inline-flex">{rank}</span>
        </ShowIf>
        <ShowIf condition={slope !== undefined}>
          <SlopeIcon slope={slope} className="ml-2 mr-1"/>
          {slope}
        </ShowIf>
        <ShowIf condition={showGenre}>
          <span title={info.genre} className="d-inline-flex ml-2">{info.genre}</span>
        </ShowIf>
      </div>
    </Tooltip>
  }

  if (!!altText && !!dateAltText) {
    altText = <>
      {dateAltText}
      {altText}
    </>
  }

  return altText || dateAltText;
}
