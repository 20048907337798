import React from 'react';

const CacheContext = React.createContext(null);
export default CacheContext;
export const DEFAULT_CACHE = {
  ludoError: false,
  detailsPanel: {
    visible: false,
  },
  teams: [],
  gddOpen: false,
  gddComments: {
    archived: false,
    unarchived: true,
    section: undefined
  },
  allFavorites: [],
  selectedProjectId: false,
  projectsOpen: false,
  genres: [],
  perspectives: [],
  platforms: [],
  generationOptions: {
    numberGenerations: 3
  },
  credits: {},
  lockScreen: undefined,
  achievementsOpen: false,
  whatsNew: [],
  videos: [],
  generationSteps: [],
};
