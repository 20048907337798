import React, {useContext, useState} from 'react';
import APIContext from 'context/APIContext';
import {Formik, Form} from "formik";
import {Grid, InputAdornment, Typography} from '@material-ui/core';
import {FormikTextField} from 'formik-material-fields';
import {useParams} from "react-router";
import {NavLink} from "react-router-dom";
import MyButton from "components/Controls/MyButton";
import ShowIf from "components/common/ShowIf";
import {Alert} from "@material-ui/lab";
import * as Yup from "yup";
import MailOutlineTwoToneIcon from "@mui/icons-material/MailOutlineTwoTone";
import LockTwoToneIcon from "@mui/icons-material/LockOutlined";
import logoLarge from "assets/images/logo-white.png";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const EP = "resetPassword";

const SUCCESS_MESSAGE = "Your password has been changed! Please login using your new credentials.";

const ResetPassword = props => {

  const {call, loading} = useContext(APIContext);
  const [afterSubmitMessage, setAfterSubmitMessage] = useState();
  const params = useParams();

  const defaultEmail = params.email;
  const code = params.code;

  async function onSubmit(values) {
    let data = {
      code,
      email: values.email,
      password: values.password
    };
    let response = await call(EP, {data});
    if (response.ok) {
      setAfterSubmitMessage(SUCCESS_MESSAGE)
    }
  }

  const isLoading = loading[EP];

  return (
    <div className="app-wrapper bg-gradient min-vh-100 reset-password">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-5 px-2">
                <div className="auth-form-wrapper">
                  <div className="w-100 pr-0 pr-lg-5">
                    <div className=" mt-3">
                      <span className="text-center">
                          <h1 className="display-4 mb-4 font-weight-bold font-size-xxxxl">
                          <img
                            width="450"
                            alt="Ludo"
                            className="d-block m-auto logo"
                            src={logoLarge}
                          />
                          Password Reset
                        </h1>
                      </span>
                      <MyForm
                        onSubmit={onSubmit}
                        loading={isLoading}
                        afterSubmitMessage={afterSubmitMessage}
                        email={defaultEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyForm = ({email, onSubmit, loading, afterSubmitMessage}) => {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{email, password: "", password2: ""}}
      onSubmit={onSubmit}
      validationSchema={ValidationSchema}
    >
      {({isValid, dirty}) => (
        <Form>
          <div className="mb-3">
            <label className="font-weight-bold mb-2">
              Email address
            </label>
            <FormikTextField
              name="email"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="yourname@yourmail.com"
              type="email"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineTwoToneIcon/>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between position-relative">
              <label className="font-weight-bold mb-2">
                Enter your new password
              </label>
              <div
                className="d-flex"
                style={{position: "absolute", right: "5px", top: 0, color: "white", cursor: "pointer"}}
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="mr-3">
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </div>
                <span style={{top: "1px", position: "relative", width: "35px", textAlign: "right"}}>
                  {showPassword ? "Hide" : "Show"}
                </span>
              </div>
            </div>
            <FormikTextField
              name="password"
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              disabled={loading || !!afterSubmitMessage}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockTwoToneIcon/>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <ShowIf condition={!afterSubmitMessage}>
            <MyButton
              className="blue"
              style={{width: "100%"}}
              loading={loading}
            >
              Reset Password
            </MyButton>
          </ShowIf>
          <ShowIf condition={!!afterSubmitMessage}>
            <Alert severity="success">
              {afterSubmitMessage}
            </Alert>
            <Typography className="text-align-center mt-2">
              <NavLink to="/login" className="text-white font-weight-bold">
                Back to Login page
              </NavLink>
            </Typography>
          </ShowIf>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  password: Yup.string()
    .required('No password provided')
    .min(6, 'Password is too short - should be 6 characters minimum'),
});
