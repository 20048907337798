import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Editable } from "pages/GDD3/Helpers";
import { useBus, useListener } from "react-bus";
import SocketContext from "context/SocketContext";
import { Chip, Tooltip } from "@material-ui/core";
import {
  DESCRIPTION_MENUS,
  MENUS,
  TITLE_MENUS,
} from "pages/GDD3/GDDSideMenu/GameSummaryMenu";
import GDDContext from "context/GDDContext";
import ShowIf from "components/common/ShowIf";
import { Sync } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MaterialMenu,
} from "@material-ui/core";
import WbIncandescentOutlinedIcon from "@mui/icons-material/WbIncandescentOutlined";
import FavoriteBorderOutlined from "@mui/icons-material/FavoriteBorderOutlined";
import CacheContext from "context/CacheContext";

const FIELDS = {
  title: "title",
  description: "description",
};

const GDDGameSummary = ({
  active,
  scrollTo,
  changeGdd,
  section,
  value,
  component,
}) => {
  const { track } = useContext(SocketContext);
  const { menu, openMenu } = useContext(GDDContext);
  const bus = useBus();
  const ref = useRef();
  const titleRef = useRef();
  const textRef = useRef();
  const [activeField, setActiveField] = useState();

  useEffect(() => {
    if (active && scrollTo && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);
    }
  }, [active, scrollTo]);

  const [hoverText, setHoverText] = useState();

  const onHover = useCallback(
    (data) => {
      setHoverText(data);
      setTimeout(() => {
        if (ref.current)
          ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 50);
    },
    [ref]
  );

  const onClick = useCallback(
    (data) => {
      if (data) {
        setHoverText();
        track("gdd.summary.click-idea", data);
        let newValue = {
          ...(value || {}),
          title: data.title || value?.title,
          text: (value?.text || "") + (data.text || ""),
        };
        changeGdd(section, newValue, true);
      }
    },
    [section, value, changeGdd]
  );

  const onReplace = useCallback(
    (data) => {
      if (data) {
        track("gdd.summary.replace-idea", data);
        let newValue = {
          ...(value || {}),
          title: data.title,
          text: data.text,
        };
        let otherData = data.genres ? { genres: data.genres } : undefined;
        changeGdd(section, newValue, true, otherData);
      }
    },
    [section, value, changeGdd]
  );

  useListener(`${component.section}.hover`, onHover);
  useListener(`${component.section}.click`, onClick);
  useListener(`${component.section}.replace`, onReplace);

  let className = "section game-summary";
  if (active) className += " active";
  if (!value?.text && !value?.title) className += " empty";

  function onTyped(key, text) {
    let newValue = {
      ...(value || {}),
      [key]: text,
    };
    changeGdd(section, newValue, true);
    bus.emit(`${component.section}.typed`);
  }

  function onClickedOption(option) {
    openMenu({ option, component });
  }

  let descriptionMenuWrapper = "editable menu-wrapper";
  let titleMenuWrapper = "editable menu-wrapper large";

  const descriptionIds = [
    DESCRIPTION_MENUS.suggestions.id,
    DESCRIPTION_MENUS.suggestionsExpand.id,
  ];

  if (descriptionIds.includes(menu?.option)) descriptionMenuWrapper += " open";
  if (menu?.option === TITLE_MENUS.suggestions.id) titleMenuWrapper += " open";

  if (activeField === FIELDS.description) descriptionMenuWrapper += " selected";
  if (activeField === FIELDS.title) titleMenuWrapper += " selected";

  return (
    <>
      <div className={className} ref={ref}>
        <div className={titleMenuWrapper} ref={titleRef}>
          <Menu id="title" options={TITLE_MENUS} onClick={onClickedOption} />
          <Editable
            className="text-align-left w-100"
            value={!hoverText?.title ? value?.title || "" : ""}
            setValue={(value = "") => onTyped("title", value)}
            placeholder="Enter a game title"
            addOnText={hoverText?.title}
            onFocus={() => setActiveField(FIELDS.title)}
          />
        </div>
        <div className={descriptionMenuWrapper} ref={textRef}>
          <Menu
            id="summary"
            options={{
              descriptionSuggestions: value?.text
                ? DESCRIPTION_MENUS.suggestionsExpand
                : DESCRIPTION_MENUS.suggestions,
              chat: DESCRIPTION_MENUS.chat,
            }}
            onClick={onClickedOption}
          />
          <Editable
            className="text-align-left w-100"
            value={hoverText?.isNew ? "" : value?.text || ""}
            setValue={(value = "") => onTyped("text", value)}
            addOnText={hoverText?.text}
            placeholder="Enter a game description"
            onFocus={() => setActiveField(FIELDS.description)}
          />
        </div>
      </div>
    </>
  );
};

export const GDDSummaryActions = ({ component, section }) => {
  const { track } = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { openMenu } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  if (component?.section !== gddComponents.summary.section) return null;

  function onActionsClick(event) {
    track("gdd.section.clicked-context", { section });
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onClickedOption(option) {
    openMenu({ option, component });
    setMenuAnchorEl();
  }

  return (
    <>
      <div className="action hide-preview">
        <Sync className="font-size-xl hvr-grow" onClick={onActionsClick} />
      </div>
      {!!menuAnchorEl && (
        <MaterialMenu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <span
            className="p-4"
            style={{ color: "#9E9EB3", fontWeight: "bold" }}
          >
            Replace section content with:
          </span>
          <MenuItem onClick={() => onClickedOption(MENUS.ideator.id)}>
            <ListItemIcon>
              <WbIncandescentOutlinedIcon className="flip-vertical" />
            </ListItemIcon>
            <ListItemText primary={MENUS.ideator.label} />
          </MenuItem>
          <MenuItem onClick={() => onClickedOption(MENUS.favorites.id)}>
            <ListItemIcon>
              <FavoriteBorderOutlined />
            </ListItemIcon>
            <ListItemText primary={MENUS.favorites.label} />
          </MenuItem>
        </MaterialMenu>
      )}
    </>
  );
};

export const Menu = ({ id, options = {}, onClick }) => {
  const { track } = useContext(SocketContext);

  function onClickedOption(data) {
    track("clicked-button", { id: `gdd.${id}.${data}` });
    onClick(data);
  }

  return (
    <div className="hover-options hide-preview">
      <div className="white-shadow" />
      {Object.keys(options)
        .filter((key) => !!options[key])
        .map((key) => {
          return (
            <React.Fragment key={key}>
              {!!options[key]?.label && (
                <Tooltip
                  title={options[key]?.label}
                  arrow
                  PopperProps={{
                    disablePortal: false,
                    className: "MuiTooltip-popper MuiTooltip-popperArrow",
                  }}
                  placement="top"
                >
                  <Chip
                    label={options[key]?.buttonLabel || options[key]?.label}
                    onClick={() => onClickedOption(options[key].id)}
                  />
                </Tooltip>
              )}
              {!options[key]?.label && (
                <Chip
                  label={options[key]?.buttonLabel || options[key]?.label}
                  onClick={() => onClickedOption(options[key].id)}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default GDDGameSummary;
