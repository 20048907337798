import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import "./style.scss";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import logo from "assets/images/logo-square.png";
import {
  Avatar,
  Chip,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  AddBox,
  AddBoxOutlined,
  AddCircleOutline,
  AddCommentOutlined,
  AddOutlined,
  ArrowForwardOutlined,
  AutoAwesomeOutlined,
  ChatBubble,
  ChatBubbleOutline,
  CloseOutlined,
  DeleteOutline,
  Download,
  EmojiEventsOutlined,
  FileCopyOutlined,
  HistoryOutlined,
  ImageOutlined, InsightsOutlined,
  Lightbulb,
  QuestionMarkOutlined,
  RefreshOutlined,
  SearchOutlined,
  SyncOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import _ from "lodash";
import PerformanceUtils from "helpers/PerformanceUtils";
import SocketContext from "context/SocketContext";
import ContentEditable from "react-contenteditable";
import striptags from "striptags";
import ReactMarkdown from "react-markdown";
import { capitalize } from "@mui/material";
import MyButton, { GeneratingButton } from "../../Controls/MyButton";
import { useHistory } from "react-router";
import AuthContext from "../../../context/AuthContext";
import DetailsPanelContext from "../../../context/DetailsPanelContext";
import { DocumentationModal } from "../../utils/HelpIcon";
import { getGameIconUrl } from "../GameCard";
import PageTitle from "../../layout-components/PageTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GeneratedImage } from "../GeneratedGameCard";
import { END_OF_TEXT } from "../Chat";
import FileUpload, { toBase64 } from "../../Controls/FileUpload";
import ImageGallery from "../ImageGallery";
import { GDDModal } from "../../../pages/GDD3/Helpers";
import { FAVORITE_TYPES, filterFavorites } from "../../../pages/Favorites";

const getChatAssistantLogs = "getChatAssistantLogs";
const getChatAssistantArchives = "getChatAssistantArchives";
const removeChatAssistantArchive = "removeChatAssistantArchive";
const cancelGeneration = "cancelGeneration";
const chatAssistant = "chatAssistant";
const uploadImage = "uploadImage";
const GENERATING_IMAGE = "<|image|>";
var requestId;

export const AssistantChatPage = () => {
  return (
    <div className="assistant-chat-page">
      <PageTitle
        titleHeading="Chat Assistant"
        titleDescription="Chat with your game design companion."
      />
      <AssistantChat showArchives={true} />
    </div>
  );
};

const AssistantChat = ({ showArchives = false }) => {
  const { call } = useContext(APIContext);
  const { auth } = useContext(AuthContext);
  const { track } = useContext(SocketContext);
  const { cache, setCacheValue } = useContext(CacheContext);
  const [messages, setMessages] = useState(DEFAULT_ARRAY);
  const [followUps, setFollowUps] = useState(DEFAULT_ARRAY);
  const [value, setValue] = useState("");
  const [waiting, setWaiting] = useState(false);
  const {
    selectedProjectId,
    assistantStreamingMessage,
    allFavorites = DEFAULT_ARRAY,
  } = cache;

  const [refreshing, setRefreshing] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [conversationId, setConversationId] = useState(
    PerformanceUtils.generateId()
  );
  const [chosenPlaceholder] = useState(
    "Write your question or request here..."
  );
  const [logsLoaded, setLogsLoaded] = useState(false);
  const [archives, setArchives] = useState(DEFAULT_ARRAY);
  const [chatName, setChatName] = useState("New Chat");
  const [archivesOpened, setArchivesOpened] = useState(false);
  const [images, setImages] = useState([]);
  const [favoritesModal, setFavoritesModal] = useState(false);

  const inputRef = useRef(null);
  const chatRef = useRef(null);

  const platform = auth.user.platform;
  const genre = auth.user.genres[0];

  const messagesLength = messages.length;

  const favorites = useMemo(
    () => filterFavorites(allFavorites, FAVORITE_TYPES.image),
    [allFavorites]
  );

  useEffect(() => {
    call(getChatAssistantLogs).then((response) => {
      if (response.ok) {
        let { message_history = DEFAULT_ARRAY, _id } = response?.body || {};
        if (_id) {
          setConversationId(_id);
          setName(response?.body);
        }
        message_history = message_history.filter(({ text }) => !!text);
        let shouldGetFollowUps = true;
        if (message_history.length > 0) {
          setMessages(message_history);
          let followUps =
            message_history[message_history.length - 1].followups || [];
          if (followUps.length > 0) {
            setFollowUps(followUps);
            shouldGetFollowUps = false;
          }
        }

        if (shouldGetFollowUps) {
          const userMessage = {
            role: "user",
            text: "",
          };
          sendMessages(message_history, userMessage).then(() => {
            setLogsLoaded(true);
          });
        } else {
          setLogsLoaded(true);
        }
      }
    });
    getArchives();
  }, []);

  useEffect(() => {
    if (shouldSubmit) {
      setShouldSubmit(false);
      sendChatMessage(value);
    }
  }, [shouldSubmit, value, messages]);

  useEffect(() => {
    if (!waiting) {
      inputRef.current?.focus();
    }
  }, [waiting]);

  useEffect(() => {
    if (chatRef.current) {
      const { scrollHeight, clientHeight } = chatRef.current;
      const maxScrollTop = scrollHeight - clientHeight;
      chatRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [messagesLength, followUps.length]);

  async function onCancel() {
    if (requestId) {
      call(
        cancelGeneration,
        { generationId: requestId },
        { hideErrorMessage: true }
      );
      setWaiting(false);
      requestId = undefined;
      let oldValue = messages[messages.length - 1]?.text;
      let currentMessages = [...messages];
      currentMessages.pop();

      setMessages(currentMessages);
      sendChatMessage("", true, currentMessages);
      setValue(oldValue);
    }
  }

  async function getArchives() {
    if (showArchives) {
      call(getChatAssistantArchives).then((response) => {
        if (response.ok) {
          let matchingArchive = (response?.body || DEFAULT_ARRAY).find(
            (archive) => archive._id === conversationId
          );
          if (matchingArchive) setName(matchingArchive);
          setArchives(response?.body || DEFAULT_ARRAY);
        }
      });
    }
  }

  async function onRegenerate() {
    let oldMessages = [...messages];
    oldMessages.splice(-1);
    let userMessage = oldMessages.splice(-1)[0];
    return sendMessages(oldMessages, userMessage, true);
  }

  async function sendChatMessage(
    text = value,
    force = false,
    currentMessages = messages,
    clearValue = true
  ) {
    if (!force && (!text || waiting)) return;

    text = text.split("<br>").join("\n");
    text = striptags(text).split("&nbsp;").join(" ");

    const userMessage = {
      role: "user",
      text,
    };

    const oldMessages = [...currentMessages];
    return sendMessages(oldMessages, userMessage, undefined, clearValue);
  }

  async function sendMessages(
    oldMessages,
    currentMessage,
    regenerate = false,
    clearValue = true,
    id = conversationId
  ) {
    if (clearValue) setValue("");

    const isEmpty = !currentMessage?.text;

    let newMessages = currentMessage?.text
      ? [...oldMessages, {...currentMessage, images: images?.length > 0? images : undefined  }]
      : [...oldMessages];

    track("assistant.chat.send-message", {
      ...currentMessage,
      history_size: oldMessages.length,
      regenerate: regenerate || undefined,
    });

    setMessages(newMessages);
    setCacheValue("assistantStreamingMessage", []);
    setWaiting(true);

    requestId = PerformanceUtils.generateId(
      regenerate || isEmpty ? "00000000" : undefined
    );

    if (!isEmpty) setFollowUps([]);

    let data = {
      id: selectedProjectId,
      data: {
        conversation_id: id,
        request_id: requestId,
        message: currentMessage.text,
        images,
        message_history: oldMessages,
        is_regeneration: !!regenerate,
        platform,
        genre,
      },
    };

    let workingImages = [...images];
    setImages([]);

    let response = await call(chatAssistant, data);
    if (response.ok && data.data.request_id === requestId) {
      let message = undefined;
      if (response.body?.text && response.body?.role) {
        message = { ...response.body };
      }

      setFollowUps(response.body?.followups || []);

      if (message) {
        delete message.followups;
        setMessages([...newMessages, message]);
      }

      setCacheValue("assistantStreamingMessage", []);
      setWaiting(false);
    } else if (!response.ok) {
      setWaiting(false);
      setImages(workingImages);
    }

    if (currentMessage.text) await getArchives();
  }

  function onClickFollowUp(text) {
    sendChatMessage(text);
  }

  const cleanedStreamingMessage = useMemo(() => {
    return _.sortBy(
      _.uniqBy(
        (assistantStreamingMessage || []).filter(
          (data) => data.requestId === requestId
        ),
        "index"
      ),
      ["index"]
    );
  }, [assistantStreamingMessage]);

  function setNewValue(value = "") {
    setValue(value);
  }

  async function onRefreshFollowups() {
    setRefreshing(true);
    await sendChatMessage("", true, undefined, false);
    setRefreshing(false);
  }

  async function onCopy(text) {
    await navigator.clipboard.writeText(text);
  }

  async function startNewChat() {
    setMessages([]);
    setFollowUps([]);
    setWaiting(false);
    const userMessage = {
      role: "user",
      text: "",
    };
    let newId = PerformanceUtils.generateId();
    setConversationId(newId);
    sendMessages([], userMessage, false, false, newId);
    setChatName("New Chat");
    track("assistant.chat.new-chat");
  }

  const showMessages = messages.length > 0;
  const showLargeFollowUps = !!followUps[0]?.type && !showMessages;

  const streamingMessage = useMemo(() => {
    return cleanedStreamingMessage
      ?.map(({ tokens }) => tokens)
      .join("")
      .replace(END_OF_TEXT, "");
  }, [cleanedStreamingMessage]);

  function setName(archive) {
    setChatName(archive.title || archive.message_history[0].text || "New Chat");
  }

  function onSelectArchive(archive) {
    track("assistant.chat.select-archive", {
      archive_id: archive._id,
    });
    setMessages(archive.message_history);
    setValue("");
    setConversationId(archive._id);
    let lastMessage =
      archive.message_history[archive.message_history.length - 1];
    let followUps = lastMessage?.followups || [];
    setFollowUps(followUps);
    setName(archive);
  }

  function openArchives() {
    setArchivesOpened(true);
    track("assistant.chat.show-archives");
  }

  function openFavorites(event) {
    event.stopPropagation();
    event.preventDefault();
    setFavoritesModal(true);
  }

  async function onFilesUpdated(files) {
    if (files.length > 0) {
      setFavoritesModal(false);
      let binaryStrings = await Promise.all(
        files.map((file) => toBase64(file, false))
      );
      let data = { files: binaryStrings };
      let response = await call(uploadImage, { data });
      if (response.ok) {
        let images = response.body;
        setImages(prevState => {
          let newResult = [...prevState];
          images.forEach((image) => {
            newResult = PerformanceUtils.editOrAdd(image, prevState, 'url')
          })
          return newResult;
        });
      }
    }
  }

  async function onClickedFavoriteImage(image) {
    setImages(prevState => PerformanceUtils.editOrAdd(image, prevState, 'url'));
    setFavoritesModal(false);
  }

  return (
    <div className="assistant-chat">
      <GDDModal
        open={favoritesModal}
        onClose={() => setFavoritesModal(false)}
        className="image-generator-modal"
      >
        <span className="top-right">
          <IconButton onClick={() => setFavoritesModal(false)}>
            <CloseOutlined className="font-size-xxl pointer text-secondary" />
          </IconButton>
        </span>
        <div className="px-4 m-auto modal-content">
          <center>
            <span className="font-weight-bold text-secondary">
              Upload an Image
            </span>

            <FileUpload
              accept="image/*"
              title={null}
              onFilesUpdated={(files) => onFilesUpdated(files)}
              maxFiles={1}
            />

            <span className="font-weight-bold text-secondary py-3 d-block">
              Or Select From Your Favorites
            </span>
          </center>

          <ImageGallery
            images={favorites}
            minImages={2}
            enforceSize={false}
            onImageClick={true}
            onImageClickFunc={(image) => onClickedFavoriteImage(image)}
          />
        </div>
      </GDDModal>
      <div className="content-wrapper">
        {showArchives && (
          <div className="top-bar">
            <span className="gradient-text soft">{chatName}</span>
            <div className="buttons">
              {!archivesOpened && (
                <Tooltip
                  title="Chat History"
                  arrow
                  PopperProps={{
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                  placement="top"
                >
                  <IconButton onClick={openArchives}>
                    <HistoryOutlined className="pointer font-size-xxxxxl text-blue" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title="Start New Chat"
                arrow
                PopperProps={{
                  className:
                    "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                }}
                placement="top"
              >
                <IconButton
                  onClick={startNewChat}
                  disabled={messages.length === 0}
                >
                  <AddBox className="pointer font-size-xxxxxl text-blue" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        <div className="chat-content">
          {messages.length === 0 && (
            <div className="top-banner">
              <div className="logo-wrapper">
                <img width="80" alt="Ludo" className="logo" src={logo} />
              </div>
              <div className="top-text">
                How can I help you with your{" "}
                <span className="purple">game design</span> today?
              </div>
            </div>
          )}

          {!logsLoaded && (
            <div className="text-align-center m-4">
              <CircularProgress size={55} />
            </div>
          )}

          {followUps.length > 0 && showLargeFollowUps && (
            <>
              <FollowUps
                followUps={followUps}
                onClick={onClickFollowUp}
                onRefresh={onRefreshFollowups}
                large={true}
                refreshing={refreshing}
              />
              <div className="disclaimer">
                <div className="disclaimer-content">
                  Ludo can make mistakes. Please check results for accuracy.
                </div>
              </div>
            </>
          )}

          {showMessages && (
            <div className="messages" ref={chatRef}>
              {messages.map((message, index) => {
                let className = "message";
                if (message.role === "user") className += " own";

                const isAssistant = message.role === "assistant";
                const showRegenerate =
                  index === messages.length - 1 && !waiting && isAssistant;

                return (
                  <div key={index} className={className}>
                    <span className="message-author">{message.role}</span>
                    <div className="body-wrapper">
                      <MessageBody message={message} />
                      <div className="actions">
                        {isAssistant && (
                          <Tooltip
                            title="Copy response"
                            arrow
                            PopperProps={{
                              className:
                                "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                            }}
                            placement="top"
                          >
                            <IconButton
                              onClick={() => onCopy(message.text)}
                              className="copy"
                            >
                              <FileCopyOutlined className="font-size-xxl pointer text-tertiary" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {showRegenerate && (
                          <Tooltip
                            title="Regenerate response"
                            arrow
                            PopperProps={{
                              className:
                                "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                            }}
                            placement="top"
                          >
                            <IconButton
                              onClick={onRegenerate}
                              className="regenerate"
                            >
                              <SyncOutlined className="font-size-xxl pointer text-tertiary" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {waiting && messages[messages.length - 1]?.role === "user" && (
                <div className="message waiting">
                  <span className="message-author">assistant</span>
                  <div className="body-wrapper">
                    <MessageBody
                      loading={!streamingMessage}
                      message={{ text: streamingMessage }}
                    />
                    <div className="regenerate" />
                  </div>
                </div>
              )}
              {!waiting && followUps.length > 0 && !showLargeFollowUps && (
                <FollowUps
                  followUps={followUps}
                  onClick={onClickFollowUp}
                  onRefresh={onRefreshFollowups}
                  large={false}
                  refreshing={refreshing}
                />
              )}
            </div>
          )}
        </div>
        {logsLoaded && (
          <div className="input-container">
            <Tooltip
              title="Start New Chat"
              arrow
              PopperProps={{
                className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
              }}
              placement="top"
            >
              <IconButton
                onClick={startNewChat}
                className="clear-history"
                disabled={messages.length === 0}
              >
                <AddCommentOutlined className="font-size-xxl pointer text-white" />
              </IconButton>
            </Tooltip>
            <div className="input-box-wrapper">
              <div className="horizontal">
                <ContentEditable
                  key="123"
                  innerRef={inputRef}
                  className="input-box"
                  disabled={waiting}
                  placeholder={chosenPlaceholder}
                  html={value || ""}
                  onPaste={(event) => {
                    event.preventDefault();
                    const text = event.clipboardData.getData("text/plain");
                    const selection = window.getSelection();
                    if (!selection.rangeCount) return false;
                    selection.deleteFromDocument();
                    const range = selection.getRangeAt(0);
                    const textNode = document.createTextNode(text);
                    range.insertNode(textNode);
                    range.setStartAfter(textNode);
                    range.setEndAfter(textNode);
                    selection.removeAllRanges();
                    selection.addRange(range);
                    setValue(event.currentTarget.innerHTML);
                  }}
                  onChange={(event) =>
                    waiting ? undefined : setNewValue(event.target.value)
                  }
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.code === "Enter") {
                      if (!event.shiftKey) {
                        setShouldSubmit(true);
                        event.preventDefault();
                        event.stopPropagation();
                      }
                    }
                  }}
                />
                {!waiting && (
                  <Tooltip
                    title="Select attachment"
                    arrow
                    PopperProps={{
                      className:
                        "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                    }}
                    placement="top"
                  >
                    <IconButton
                      onClick={openFavorites}
                      className="add-attachment"
                    >
                      <AddCircleOutline className="font-size-xxxxl pointer text-tertiary" />
                    </IconButton>
                  </Tooltip>
                )}
                {!waiting && (
                  <Tooltip
                    title="Send message"
                    arrow
                    PopperProps={{
                      className:
                        "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                    }}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => sendChatMessage(value)}
                      className="send-message"
                      disabled={!value?.trim()}
                    >
                      <ArrowForwardOutlined className="font-size-xxl pointer text-tertiary" />
                    </IconButton>
                  </Tooltip>
                )}
                {waiting && (
                  <Tooltip
                    title="Cancel request"
                    arrow
                    PopperProps={{
                      className:
                        "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                    }}
                    placement="top"
                  >
                    <IconButton onClick={onCancel} className="cancel">
                      <CloseOutlined className="font-size-xxl pointer text-tertiary" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {images?.length > 0 && <div className="images">
                {images.map((image) => (
                  <div className="attachment">
                    <CloseOutlined
                      className="close"
                      onClick={() =>
                        setImages((prevState) =>
                          PerformanceUtils.removeFromArray(
                            image,
                            prevState,
                            "url"
                          )
                        )
                      }
                    />
                    <img key={image.url} className="attachment" src={image.url} />
                  </div>
                ))}
              </div>}
            </div>
          </div>
        )}
      </div>
      {archivesOpened && (
        <Archives
          archives={archives}
          setArchives={setArchives}
          onClick={onSelectArchive}
          activeId={conversationId}
          onClose={() => setArchivesOpened(false)}
        />
      )}
    </div>
  );
};

const Archives = ({ archives, setArchives, onClick, onClose, activeId }) => (
  <div className="archives">
    <div className="archive-header">
      <span className="gradient-text soft">Your Chat History</span>
      <IconButton onClick={onClose}>
        <CloseOutlined className="font-size-xxl pointer text-white" />
      </IconButton>
    </div>
    {archives.map((archive) => (
      <Archive
        archive={archive}
        key={archive._id}
        active={archive._id === activeId}
        setArchives={setArchives}
        onClick={onClick}
      />
    ))}
  </div>
);

const Archive = ({ archive, onClick, active, setArchives }) => {
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const [anchor, setAnchor] = useState(null);

  function onActionsClicked(event) {
    setAnchor(event.currentTarget);
  }

  async function deleteArchive(archive) {
    if (archive) {
      track("assistant.chat.delete-archive", {
        archive_id: archive._id,
      });
      let response = await call(removeChatAssistantArchive, {
        id: archive._id,
      });
      if (response.ok) {
        setArchives(response.body);
      }
    }
    setAnchor(null);
  }
  return (
    <div className={"archive" + (active ? " active" : "")} key={archive._id}>
      <span className="description" onClick={() => onClick(archive)}>
        <ChatBubbleOutline className="mr-2" />{" "}
        {archive.title || archive.message_history?.[0]?.text}
      </span>
      <div className="options">
        <MoreVertIcon style={{ height: "22px" }} onClick={onActionsClicked} />
        {anchor && (
          <Menu
            anchorEl={anchor}
            keepMounted
            open={!!anchor}
            onClose={() => setAnchor(null)}
          >
            <MenuItem onClick={() => deleteArchive(archive)}>
              <ListItemIcon>
                <DeleteOutline />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          </Menu>
        )}
      </div>
    </div>
  );
};

const DEFAULT_ARRAY = [];
const MAX_REFS = 3;

export const MessageBody = ({ message, loading }) => {
  const history = useHistory();
  const {
    text,
    images = DEFAULT_ARRAY,
    links = DEFAULT_ARRAY,
    references = DEFAULT_ARRAY,
  } = message;
  const [showAll, setShowAll] = useState(false);

  const textSplits = useMemo(() => {
    let splits = [];
    const regex = /!\[.*?\]\(.*?\)\n___.*?___/g;
    let textNew = text.split(END_OF_TEXT).join("");
    let imageDescriptions = textNew.match(regex) || [];

    if (imageDescriptions.length > 0) {
      let splitText = textNew.split(regex);
      for (let i = 0; i < splitText.length; i++) {
        if (splitText[i]) {
          splits.push({ type: "text", content: splitText[i] });
        }
        if (images[i]) {
          splits.push({ type: "image", content: images[i] });
        }
        if (imageDescriptions[i]) {
          splits.push({
            type: "caption",
            content: imageDescriptions[i].split("___")[1],
          });
        }
      }
    } else {
      splits.push({ type: "text", content: textNew });
      if(images.length > 0) {
        images.forEach((image) => {
          splits.push({ type: "image", content: image });
        });
      }
    }

    splits = splits.map((split) => {
      if (split.type === "text") {
        if (split.content.includes(GENERATING_IMAGE)) {
          let newSplits = split.content.split(GENERATING_IMAGE);
          let newContent = [];
          for (let i = 0; i < newSplits.length; i++) {
            newContent.push({ type: "text", content: newSplits[i] });
            if (i < newSplits.length - 1)
              newContent.push({ type: "generating_image" });
          }
          return newContent;
        }
      }
      return split;
    });

    return _.flatten(splits);
  }, [text]);

  const newReferences = useMemo(() => {
    return references.filter((reference) => !!reference.new);
  }, [references]);

  const filteredReferences = useMemo(() => {
    let result = newReferences;
    if (!showAll) {
      result = result.slice(0, MAX_REFS);
    }
    return result;
  }, [references, showAll]);

  function onClickLink(link) {
    history.push(link.page);
  }

  const showingAll = newReferences.length === filteredReferences.length;

  let className = "message-body";
  if (loading) className += " loading";

  return (
    <pre className={className}>
      {loading && <CircularProgress size={15} />}
      {textSplits.map(({ type, content }, index) => (
        <React.Fragment key={index}>
          {type === "generating_image" && (
            <div className="generating-image">
              <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                height="512"
                width="512"
              />
              <div className="button-wrapper">
                <GeneratingButton
                  label=""
                  disabled={true}
                  loading={true}
                  loadProgressSecs={10}
                />
              </div>
            </div>
          )}
          {type === "text" && <ReactMarkdown children={content} />}
          {type === "caption" && (
            <span className="image-description">{content}</span>
          )}
          {type === "image" && (
            <GeneratedImage
              image={content}
              imageProps={{ hideActions: false }}
            />
          )}
        </React.Fragment>
      ))}
      {links.length > 0 && (
        <div className="links">
          {links.map((link) => (
            <MyButton
              className="text-gradient"
              style={{ width: "fit-content" }}
              key={link.page}
              onClick={() => onClickLink(link)}
            >
              {link.text}
            </MyButton>
          ))}
        </div>
      )}
      {filteredReferences.length > 0 && (
        <div className="references">
          <span className="description">
            This answer was based on the following references:&nbsp;
          </span>
          {filteredReferences.map((reference, index) => (
            <Reference reference={reference} />
          ))}
          {!showingAll && (
            <>
              <span className="show-all" onClick={() => setShowAll(!showAll)}>
                Show more
              </span>
            </>
          )}
          {/*filteredReferences.map(reference => (
          <Tooltip
            arrow
            open={false}
            key={reference.id}
            placement="top"
            title={reference.content}
            PopperProps={{disablePortal: true, className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
          >
            <span>{reference.title}</span>
          </Tooltip>
        ))*/}
        </div>
      )}
    </pre>
  );
};

const Reference = ({ reference }) => {
  const { title, display_title, type, type_title } = reference;

  const { track } = useContext(SocketContext);
  const history = useHistory();
  const [game, setGame] = useState(null);
  const { call } = useContext(APIContext);
  const [helpModal, setHelpModal] = useState(false);
  const [image, setImage] = useState(null);

  const { showGame } = useContext(DetailsPanelContext);

  function getAvatar() {
    if (image) {
      return <Avatar className="mr-0 topic-icon" src={image} />;
    }

    if (type === "charts") {
      return (
        <div className="topic-icon">
          <EmojiEventsOutlined className="font-size-xl trending" />
        </div>
      );
    } else if (type === "help") {
      return (
        <div className="topic-icon">
          <QuestionMarkOutlined className="font-size-xl trending" />
        </div>
      );
    } else if (type === "trends") {
      return (
        <div className="topic-icon">
          <InsightsOutlined className="font-size-xl trending" />
        </div>
      );
    }
  }

  const gameId = reference.game?.game_id;

  useEffect(() => {
    if (gameId) {
      call("getGameInformation", { id: gameId }).then((response) => {
        if (response.ok) {
          setGame(response.body);
          let url = getGameIconUrl(response.body, true);
          if (url) setImage(url);
        }
      });
    }
  }, [gameId]);

  function onClicked() {
    track("assistant.chat.clicked-reference", reference);
    if (game) return showGame(game);
    if (type === "help") {
      return setHelpModal(true);
    } else if (type === "trends") {
      let url = "/trends";
      let data = {
        sources: reference.trends?.source,
        location: reference.trends?.country,
        sorting: reference.trends?.sorting,
        genre: reference.trends?.genre,
        category: reference.trends?.category,
        title: reference.title,
        display_title: reference.display_title,
        type: "charts",
      };
      history.push(url, { data });
    } else if (type === "new") {
      let url = "/trends";
      let data = {
        sources: reference.trends?.source,
        location: reference.trends?.country,
        sorting: reference.trends?.sorting,
        genre: reference.trends?.genre,
        category: reference.trends?.category,
        title: reference.title,
        display_title: reference.display_title,
        type: "latest",
      };
      history.push(url, { data });
    } else if (type === "top" || type === "charts") {
      let url = "/top-charts";
      history.push(url, {
        data: { ...(reference.top_chart || {}) },
      });
    }
  }

  const defaultDoc = useMemo(() => {
    let result = reference?.help?.flotiq_index;
    if (result) {
      return result.replace("doc_", "");
    }
  }, [reference.help]);

  return (
    <>
      {helpModal && (
        <DocumentationModal
          modal={helpModal}
          key={helpModal}
          setModal={setHelpModal}
          defaultDoc={defaultDoc}
          defaultIndex={reference?.help?.doc_index}
          onClose={() => setHelpModal(false)}
        />
      )}
      <Tooltip
        arrow
        placement="top"
        title={type_title}
        PopperProps={{
          disablePortal: true,
          className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
        }}
      >
        <Chip
          className="reference"
          onClick={onClicked}
          label={display_title || title}
          avatar={getAvatar()}
        />
      </Tooltip>
    </>
  );
};

export const FollowUps = ({
  followUps,
  onClick,
  onRefresh,
  large = false,
  refreshing,
  trackPrefix = "assistant.chat"
}) => {
  const { track } = useContext(SocketContext);

  let className = "follow-ups";
  if (large) className += " large";

  function getIcon(type) {
    if (type === "help") return <QuestionMarkOutlined />;
    if (type === "ideation") return <Lightbulb className="lightbulb" />;
    if (type === "images" || type === "image") return <ImageOutlined />;
    if (type === "research") return <SearchOutlined />;
    return <AutoAwesomeOutlined/>;
  }

  function onClickWrapper(followuUp) {
    track(trackPrefix+".clicked-follow-up", followuUp);
    onClick(followuUp.text);
  }

  function onRefreshWrapper() {
    track(trackPrefix+".clicked-refresh-follow-ups", { followUps });
    onRefresh();
  }

  return (
    <>
      <div className={className}>
        {followUps.map((followUp, index) => {
          if (large) {
            return (
              <div
                key={index}
                className="follow-up-large"
                onClick={() => onClickWrapper(followUp)}
              >
                <span>{followUp.text}</span>
                <Tooltip
                  arrow
                  placement="top"
                  title={capitalize(followUp.type)}
                  PopperProps={{
                    disablePortal: true,
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                >
                  <div className="icon">{getIcon(followUp.type)}</div>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <span
                key={index}
                className="follow-up d-flex align-items-center"
                style={{gap: "10px"}}
                onClick={() => onClickWrapper(followUp)}
              >
                <div className="icon">{getIcon(followUp.type)}</div>
                {" "}
                {followUp.text}
              </span>
            );
          }
        })}
      </div>
      {large && (
        <div className="refresh-follow-ups">
          <hr />
          {refreshing ? (
            <CircularProgress size={25} />
          ) : (
            <span onClick={onRefreshWrapper}>
              <RefreshOutlined /> Refresh
            </span>
          )}
          <hr />
        </div>
      )}
    </>
  );
};

export default AssistantChat;
