import React, { useContext, useMemo } from "react";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import SocketContext from "context/SocketContext";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import _ from "lodash";
import ShowIf from "components/common/ShowIf";
import { CloseOutlined } from "@mui/icons-material";

const updateUserInfo = "updateUserInfo";

const DismissableMessage = ({
  id,
  message,
  displayUntil,
  style,
  className = "",
}) => {
  const { track } = useContext(SocketContext);
  const { call } = useContext(APIContext);
  const { auth, setAuth } = useContext(AuthContext);

  async function onDismiss() {
    track("dismissable-message.dismissed", {
      id,
      message: getNodeText(message),
    });
    let tutorials_closed = auth.user.tutorials_closed || [];
    tutorials_closed.push(id);
    tutorials_closed = _.uniq(tutorials_closed);
    setAuth({ user: { ...auth.user, tutorials_closed } }, true, false);
    await call(updateUserInfo, { data: { tutorials_closed } });
  }

  const shouldDisplay = useMemo(() => {
    let { tutorials_closed = [] } = auth.user;
    let isClosed = !!tutorials_closed.find((item) => item === id);
    if (isClosed) return false;
    if (displayUntil) {
      let now = moment();
      let until = moment(displayUntil, "DDMMYYYY");
      return now.isBefore(until);
    }
    return true;
  }, [id, displayUntil, auth.user.tutorials_closed]);

  return (
    shouldDisplay && (
      <div
        className={
          "dismissable-message animate__animated animate__fadeIn animate__slow " +
          className
        }
        style={style}
      >
        <IconButton className="dismiss" onClick={onDismiss}>
          <CloseOutlined />
        </IconButton>
        <span className="message">{message}</span>
      </div>
    )
  );
};

function getNodeText(node) {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
}

export default DismissableMessage;
