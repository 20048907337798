import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import {CircularProgress} from "@material-ui/core";
import CacheContext from "context/CacheContext";
import ImageGallery from "components/common/ImageGallery";
import GeneratedGameCard from "components/common/GeneratedGameCard";
import {useHistory, useLocation, useParams} from "react-router";
import {filterFavorites} from "pages/Favorites";
import GameGrid from "components/common/GameGrid";
import PageTitle from "components/layout-components/PageTitle";
import _ from 'lodash';
import qs from "qs";
import './style.scss';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

const getSharedItems = 'getSharedItems'
const getGamesInformation = 'getGamesInformation';

export const FAVORITE_TYPES = {
  image: 'image',
  game: 'game',
  generated_game: 'generated_game',
  mechanic: 'mechanic',
  feature: 'feature'
};

const ProjectShared = () => {

  const params = useParams();
  const location = useLocation();
  const gamesRef = useRef();
  const generatedRef = useRef();
  const imagesRef = useRef();
  const history = useHistory();

  const {call, loading} = useContext(APIContext);
  const {setCacheValue} = useContext(CacheContext);
  const [shared, setShared] = useState(undefined);
  const [games, setGames] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const {teamId} = params;

  const images = useMemo(() => _.uniqBy(filterFavorites(shared, FAVORITE_TYPES.image), 'url'), [shared]);
  const generatedGames = useMemo(() => _.uniqBy(filterFavorites(shared, FAVORITE_TYPES.generated_game), 'id'), [shared]);

  const scrollTo = qs.parse(location.search, {ignoreQueryPrefix: true}).scrollTo;

  useEffect(() => {
    if (!!shared && !games) {
      let ids = _.uniq(filterFavorites(shared, FAVORITE_TYPES.game, 'game_id'));
      call(getGamesInformation, {data: {ids}}).then(response => {
        if (response.ok) {
          setGames(response.body)
        }
        setIsLoading(false);
      });
    } else if (!!shared && !!games) {
      setIsLoading(false);
    }
  }, [shared, games]);

  useEffect(() => {
    if (teamId) {
      setIsLoading(true);
      setCacheValue('gddOpen', false);
      call(getSharedItems, {id: teamId}).then(response => {
        if (response.ok) {
          setShared(response.body)
        }
      })
    } else {
      setIsLoading(false);
    }
  }, [teamId]);

  const showLoading = isLoading || loading[getSharedItems] || loading[getGamesInformation];

  useEffect(() => {
    if (!isLoading) {
      let ref;
      switch (scrollTo) {
        case 'shared-generated':
          ref = generatedRef;
          break;
        case 'shared-games':
          ref = gamesRef;
          break;
        case 'shared-images':
          ref = imagesRef;
          break;
        default:
          break;
      }
      if (ref && ref.current) {
        setTimeout(() => {
          ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }, 0);
      }
    }
  }, [isLoading, scrollTo]);

  function goToSection(scrollValue) {
    history.push(`/shared/${teamId}?scrollTo=${scrollValue}`)
  }

  return (
    <div className="project-shared w-100">
      <PageTitle
        titleHeading="Shared Items"
        titleDescription="Content that has been shared in your team"
      />
      <div className="top-menu sticky-top">
        <div className="content d-flex flex-row ">
        <span
          onClick={() => goToSection('shared-generated')}
          className={`${scrollTo === "shared-generated" ? "active" : ""}`}
        >
          Generated Games
        </span>
          <span
            onClick={() => goToSection('shared-games')}
            className={`${scrollTo === "shared-games" ? "active" : ""}`}
          >
          Games
        </span>
          <span
            onClick={() => goToSection('shared-images')}
            className={`${scrollTo === "shared-images" ? "active" : ""}`}
          >
          Images
        </span>
        </div>
      </div>
      <div className="shared-content">
        <ShowIf condition={showLoading}>
          <div className="text-align-center m-4">
            <CircularProgress size={55}/>
          </div>
        </ShowIf>
        <ShowIf condition={!showLoading}>
          <div className="mt-4">
            <ShowIf condition={generatedGames.length > 0}>
              <span ref={generatedRef} className="scrollTo"/>
              <span className="text-secondary section-title font-size-xxxxxl">Generated Games</span>
              <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 600: 2, 1000: 3}}
              >
                <Masonry gutter="15px">
                  {generatedGames.map(game => (
                    <GeneratedGameCard
                      key={game.id}
                      game={game}
                    />))}
                </Masonry>
              </ResponsiveMasonry>
            </ShowIf>
            <ShowIf condition={(games || []).length > 0}>
              <span ref={gamesRef} className="scrollTo"/>
              <span className="text-secondary section-title font-size-xxxxxl">Games</span>
              <GameGrid
                games={games}
                gameProps={{lazyLoad: false}}
              />
            </ShowIf>
            <ShowIf condition={images.length > 0}>
              <span ref={imagesRef} className="scrollTo"/>
              <span className="text-secondary mb-2 d-block section-title font-size-xxxxxl">Images</span>
              <ImageGallery
                onImageClick={true}
                images={images}
                minImages={4}
              />
            </ShowIf>
          </div>
        </ShowIf>
      </div>
    </div>
  )
};

export default ProjectShared;
