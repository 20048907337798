import React from "react";

export const LABELS = {
  mechanic: 'mechanic',
  theme: 'theme',
  feature: 'feature',
  junk: 'junk',
  "": ""
};

export const AnnotatedDescription = ({game}) => {
  let {description_annotations = []} = game;
  if (description_annotations.length === 0) return game.description_clean || game.description;
  let prev = "";
  return description_annotations.map((annotation, index) => {
    let {text = "", label = ""} = annotation;

    let prevEndsWithNewline = prev.length > 0 && prev.charAt(prev.length - 1) === "\n";
    let currentStartsWithNewline = text.length > 0 && text.charAt(0) === "\n";

    if (prev.length > 0 && !prevEndsWithNewline && !currentStartsWithNewline)
      text = " " + text;

    prev = text;
    return (
      <span
        className={"highlight " + LABELS[label]}
        key={index+text}
      >
        {text}
      </span>
    )
  })
};
