import React, {useContext, useEffect, useState} from 'react';
import {Collapse, Dialog} from "@material-ui/core";
import ExpandIcon from "components/common/ExpandIcon";
import {
  Grid,
} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import './style.scss';
import SocketContext from "context/SocketContext";
import {ChevronLeft, ChevronRight, CloseOutlined, HelpOutline, PlayCircle} from "@mui/icons-material";

const DEFAULT_ARRAY = [];

const Tutorial = ({steps = DEFAULT_ARRAY, defaultExpanded = false, onChangeExpanded, className = ""}) => {

  const {track} = useContext(SocketContext);

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [step, setStep] = useState(undefined);

  useEffect(() => {
    if (onChangeExpanded)
      onChangeExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    if(step !== undefined) track('tutorial.clicked', steps[step])
  },[step, steps]);

  return (
    <div className={"tutorial text-white w-100 pb-3 " + className}>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container spacing={6}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={Math.ceil(12 / steps.length * 2)} lg={Math.floor(12 / steps.length)} key={step.text}>
              <TutorialItem step={step} index={index} setStep={setStep}/>
            </Grid>
          ))}
        </Grid>
      </Collapse>
      <hr className="hr-blue z-over"/>
      <div className="collapse-control w-100 clickable d-flex" onClick={() => setExpanded(!expanded)}>
        <span className="flex-grow-1">
          <HelpOutline className="font-size-xl mr-2"/>
          <span>{expanded ? "Hide" : "Show"} Tutorial</span>
      </span>
        <ExpandIcon style={{color: "white"}} expanded={expanded} setExpanded={setExpanded}/>
      </div>
      <ShowIf condition={step !== undefined}>
        <TutorialModal
          steps={steps}
          defaultStep={step}
          onClose={() => setStep(undefined)}
        />
      </ShowIf>
    </div>
  )
};

export const TutorialItem = ({step, index, setStep}) => {
  return (
    <div className="tutorial-item d-flex flex-column h-100 m-auto" onClick={() => setStep(index)}>
      <ShowIf condition={!!step.video}>
        <div className="video-container">
          <div className="cover"/>
          <iframe
            src={step.video}
            className="video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <span className="font-weight-bold text-primary">{step.text}</span>
      </ShowIf>
      <ShowIf condition={!step.video}>
        <div className="img-wrapper">
          <PlayCircle className="play"/>
          <img
            alt={step.text}
            src={step.cover}
            className="cover"
            width="640"
            height="480"
          />
        </div>
        <span className="mt-4">{step.text}</span>
      </ShowIf>
    </div>
  )
}

export const TutorialModal = ({steps, defaultStep, onClose}) => {
  const [step, setStep] = useState(defaultStep);

  return (
    <Dialog
      maxWidth="xl"
      open={true}
      onClose={onClose}
      classes={{
        paper: 'modal-content rounded border-0 bg-white p-3 p-xl-0'
      }}
      style={{minWidth: "90%"}}
    >
      <div
        className="bg-white tutorial-modal px-5 py-5 mt-5 mb-3 d-block text-align-center d-flex flex-row position-relative"
        key={step}>
        <div className="close-icon" onClick={onClose}>
          <CloseOutlined
            className="font-size-xxxxl clickable"
          />
        </div>
        <div className="left">
          <ShowIf condition={step > 0}>
            <div onClick={() => setStep(step - 1)} className="icon-wrapper">
              <ChevronLeft className="font-size-xxxxxl clickable text-secondary"/>
            </div>
          </ShowIf>
        </div>
        <div className="d-flex flex-column item">
          <ShowIf condition={!!steps[step].image}>
            <div className="img-wrapper">
              <img
                alt={`Tutorial Step #${step + 1}`}
                src={steps[step].image}
                className="animated"
                width="640"
                height="480"
              />
            </div>
          </ShowIf>
          <ShowIf condition={!!steps[step].video}>
            <div className="video-stretch">
              <iframe
                src={steps[step].video}
                frameBorder="0"
                width="1120" height="630"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          </ShowIf>
          <span className="text-secondary mt-5 font-weight-bold">{steps[step].text}</span>
        </div>
        <div className="right">
          <ShowIf condition={step < (steps.length - 1)}>
            <div onClick={() => setStep(step + 1)} className="icon-wrapper">
              <ChevronRight className="font-size-xxxxxl clickable text-secondary"/>
            </div>
          </ShowIf>
        </div>
      </div>
    </Dialog>
  );
};

export default Tutorial;
