import React, {useState, useContext, useEffect} from "react";
import AuthContext from "context/AuthContext";
import logoLarge from 'assets/images/logo-white.png';
import SocketContext from "context/SocketContext";
import moment from 'moment';

const HubspotWelcomeForm = ({onClose}) => {

  const {auth} = useContext(AuthContext);
  const {track} = useContext(SocketContext);

  const [done, setDone] = useState(false);

  const email = auth.user.email;

  function onComplete() {
    setDone(true);
    track('welcome-form.submit');
    setTimeout(() => {
      onClose();
    }, 2800)
  }

  useEffect(() => {
    if (email) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        if (email && window.hbspt) {
          window.hbspt.forms.create({
            portalId: '7594866',
            formId: '967970e2-c18c-423f-aae4-1d1a3e3373d8',
            target: '#hubspotForm',
            onFormReady: (form) => {
              let emailInput = form.querySelector('input[name="email"]');
              emailInput.value = email;
              let event = new Event('input', {
                bubbles: true,
                cancelable: true,
              });
              emailInput.dispatchEvent(event);
              track('welcome-form.show');
            },
            onFormSubmit: (form) => {
              onComplete();
            },
          })
        }
      });
    }
  }, [email]);

  if (!email) return null;

  return (
    <div className="welcome-form-wrapper">
      <img
        width="225"
        alt="Ludo"
        className="d-block mx-auto logo"
        src={logoLarge}
      />
      {!done && <>
        <h1 className="display-4 font-weight-bold">
          Customize Your Ludo Experience!
        </h1>
        <h2>
          Quick questions to personalize your game development journey
        </h2>
      </>}
      <div id="hubspotForm"></div>
    </div>
  );

}

export default HubspotWelcomeForm;
