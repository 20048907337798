import Swagger from 'swagger-client';

var swaggerSpec;

export default function swagger(opts = {}) {
  return new Swagger({
    ...opts,
    spec: swaggerSpec,
  }).then(
    client => {
      if (!swaggerSpec) swaggerSpec = client.spec;
      if (opts.host !== undefined) client.spec.host = opts.host;
      if (opts.protocol !== undefined) client.spec.schemes = [opts.protocol];
      return client;
    },
    err => {
      console.log(err);
    }
  );
}
