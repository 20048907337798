import React, {useMemo, useState} from 'react';
import PageTitle from "components/layout-components/PageTitle";
import './style.scss';
import {YOUTUBE_VIDEOS} from "components/Tutorials/SimpleTutorials";
import {Grid} from "@material-ui/core";
import {TutorialItem, TutorialModal} from "components/Tutorials/Tutorial";
import ShowIf from "components/common/ShowIf";

const TutorialsPage = () => {

  const [step, setStep] = useState(undefined);
  const steps = useMemo(() => Object.values(YOUTUBE_VIDEOS), []);

  return (
    <div className="tutorials-page">
      <PageTitle
        titleHeading="Tutorial Videos"
        titleDescription="Learn how to use Ludo to its full potential."
      />
      <ShowIf condition={step !== undefined}>
        <TutorialModal
          steps={steps}
          defaultStep={step}
          onClose={() => setStep(undefined)}
        />
      </ShowIf>
      <div className="content tutorial">
        <Grid container spacing={3}>
          {steps.map((step, index) => (
            <Grid item xl={3} lg={4} md={4} sm={6} key={step.video}>
              <TutorialItem step={step} index={index} setStep={setStep}/>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
};

export default TutorialsPage;
