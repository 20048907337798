import React, {useContext, useEffect, useState, useRef} from 'react';
import AuthContext from 'context/AuthContext';
import Plans, {isFreePlan} from "scenes/SubscriptionPage/Plans";
import SocketContext from "context/SocketContext";
import PageTitle from "components/layout-components/PageTitle";
import './style.scss';
import ShowIf from "components/common/ShowIf";
import {CircularProgress, Chip, Grid, IconButton, List, ListItem} from "@material-ui/core";
import MyButton from "components/Controls/MyButton";
import APIContext from "context/APIContext";
import CreditCards from "scenes/SubscriptionPage/CreditCards";
import moment from 'moment';
import {
  ArrowBackIosNewOutlined,
  ArrowLeft,
  CancelPresentationOutlined,
  CloseOutlined,
  CreditCard,
  ReceiptLongOutlined
} from "@mui/icons-material";

const unsubscribe = 'unsubscribe';
const getInvoices = 'getInvoices';
const goToBillingPortal = 'goToBillingPortal';

export const SUBSCRIPTION_STATUS = {
  active: 'active',
  trialing: 'trialing',
  unsubscribed: 'unsubscribed',
  past_due: 'past_due'
};

const PANEL_STATES = {
  card: 'Manage Credit Cards',
  invoices: 'Invoices'
}

export const SubscriptionPage = ({onSuccess, onClose}) => {

  const {track} = useContext(SocketContext);
  const {auth, setAuth} = useContext(AuthContext);
  const {call} = useContext(APIContext);
  const [panel, setPanel] = useState();
  const [titleKey, setTitleKey] = useState();
  const scrollRef = useRef();

  const {subscription} = auth;

  const subscriptionStatus = (subscription || {}).status || SUBSCRIPTION_STATUS.unsubscribed;
  const allowUnsubscribe = (subscriptionStatus === SUBSCRIPTION_STATUS.active || subscriptionStatus === SUBSCRIPTION_STATUS.past_due || subscriptionStatus === SUBSCRIPTION_STATUS.trialing) && !subscription.cancel_at && !subscription.from_team && !isFreePlan(subscription.plan);

  let confirmUnsubscribeText = "Are you sure you want to unsubscribe?";

  useEffect(() => {
    track('subscription.open');
    setTitleKey(1);
  }, []);

  async function clickedUnsubscribe() {
    let response = await call(unsubscribe, undefined, {successMessage: 'Your subscription has been scheduled for unsubscription at the end of the current billing period.'});
    if (response.ok) {
      let subscription = response.body;
      setAuth({subscription}, true, false);
    }
  }

  function goToBillingPortalWrapper() {
    call(goToBillingPortal).then(response => {
      if (response.ok) {
        window.location = response.body.value;
      }
    });
  }

  return (
    <div className="subscription-modal-content">
      <div className="subscriptions-panel-wrapper">
        <div className="subscriptions-main-content scrollable" ref={scrollRef}>
          <PageTitle
            key={titleKey}
            titleHeading="Subscriptions & Billing"
            scrollRef={scrollRef}
          />
          <div className="top-bar">
            {onClose && <div className="back clickable hvr-backward" onClick={onClose}>
              <ArrowBackIosNewOutlined className="font-size-lg"/>
              <span className="ml-2">To Ludo</span>
            </div>}
            <div className="options">
              <ShowIf condition={allowUnsubscribe}>
                <MyButton
                  id="subscription.unsubscribe"
                  className="d-inline-flex no-background"
                  onClick={clickedUnsubscribe}
                  confirmText={confirmUnsubscribeText}
                  variant="text"
                  style={{width: "150px"}}
                >
                  <CancelPresentationOutlined/>&nbsp;&nbsp;Unsubscribe
                </MyButton>
              </ShowIf>
              <ShowIf condition={panel !== PANEL_STATES.invoices}>
                <MyButton
                  id="subscription.invoices"
                  className="d-inline-flex no-background"
                  onClick={goToBillingPortalWrapper}
                  variant="text"
                  style={{width: "120px"}}
                >
                  <ReceiptLongOutlined/>&nbsp;&nbsp;Invoices
                </MyButton>
              </ShowIf>
              <ShowIf condition={panel !== PANEL_STATES.card}>
                <MyButton
                  id="subscription.manage-credit-cards"
                  className="d-inline-flex no-background"
                  onClick={goToBillingPortalWrapper}
                  variant="text"
                  style={{width: "230px"}}
                >
                  <CreditCard/>&nbsp;&nbsp;Manage Credit Cards
                </MyButton>
              </ShowIf>
            </div>
          </div>
          <Plans
            onSuccess={onSuccess}
            sizes={{
              xl: 4, lg: !!panel ? 6 : 4, md: !!panel ? 12 : 6, sm: 12
            }}
          />
        </div>
        <SidePanel panel={panel} onClose={() => setPanel()}/>
      </div>
    </div>
  )
};

const SidePanel = ({panel, onClose}) => {

  return (
    <ShowIf condition={!!panel}>
      <div className="side-panel">
        <div className="top-bar">
          <span className="title">
            {panel}
          </span>
          <IconButton onClick={onClose}>
            <CloseOutlined
              className="font-size-xxl pointer text-white"
            />
          </IconButton>
        </div>
        <div className="panel-content">
          <ShowIf condition={panel === PANEL_STATES.card}>
            <CreditCards whiteForm={true}/>
          </ShowIf>
          <ShowIf condition={panel === PANEL_STATES.invoices}>
            <InvoiceList/>
          </ShowIf>
        </div>
      </div>
    </ShowIf>
  )
}

const InvoiceList = ({}) => {

  const {call} = useContext(APIContext);
  const [loading, setLoading] = useState(true);

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    call(getInvoices).then(response => {
      if (response.ok) {
        setInvoices(response.body);
        setLoading(false);
      }
    })
  }, []);

  return (
    <div className="invoice-list">
      <ShowIf condition={loading}>
        <div className="m-4 text-align-center">
          <CircularProgress size={55} className="text-white"/>
        </div>
      </ShowIf>
      <ShowIf condition={!loading}>
        <ShowIf condition={invoices.length === 0}>
          <span className="text-white ml-4 pl-2">No invoices available</span>
        </ShowIf>
        <List>
          {invoices.map(invoice => {
            return (
              <a href={invoice.hosted_invoice_url} target="_blank" key={invoice.id}>
                <ListItem
                  button
                  className="invoice"
                >
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      {moment.unix(invoice.date).format('DD/MM/YYYY')}
                    </Grid>
                    <Grid item xs={4}>
                      <span className="price">
                        {'$' + invoice.amount_due / 100.0}
                      </span>
                    </Grid>
                    <Grid item xs={4}>
                      <Chip
                        className={"paid-status paid-" + invoice.paid}
                        label={invoice.paid ? "Paid" : "Unpaid"}
                        style={{width: "100%"}}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </a>
            );
          })}
        </List>
      </ShowIf>
    </div>
  )
}

export default SubscriptionPage;
