import React from 'react';
import PageTitle from "components/layout-components/PageTitle";

const Documentation = () => {

  return (
    <div className="w-100">
      <PageTitle
        titleHeading="Documentation"
        titleDescription="Learn how to use Ludo"
      />
    </div>
  )
};

export default Documentation;
