import React, {useContext, useEffect, useMemo, useState} from 'react';
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import {
  DialogTitle, DialogContent, DialogContentText, DialogActions,
  Grid, IconButton, Dialog, ListItemIcon, ListItemText, ListItem, List
} from "@material-ui/core";
import MyButton from "components/Controls/MyButton";
import {Form, Formik} from "formik";
import {FormikSelectField, FormikTextField} from "formik-material-fields";
import PerformanceUtils from "helpers/PerformanceUtils";
import CacheContext from "context/CacheContext";
import * as Yup from "yup";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import './style.scss';
import AuthContext from "context/AuthContext";
import SocketContext from "context/SocketContext";
import {isTeamsPlan} from "scenes/SubscriptionPage/Plans";
import {Add, CheckCircleOutline, CloseOutlined, Delete} from "@mui/icons-material";

const addProject = 'addProject';
const archiveProject = 'archiveProject';
const updateUserInfo = 'updateUserInfo';
const getProjects = 'getProjects';

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

export const ActiveProjectName = ({className = ""}) => {
  const {cache} = useContext(CacheContext);
  const {projects = DEFAULT_ARRAY, selectedProjectId} = cache;

  const project = useMemo(() => {
    return (projects || []).find(project => project._id === selectedProjectId) || {};
  }, [selectedProjectId, projects]);

  return <span className={"active-project-name " + className}>{project.name}</span>
};

const Projects = ({onClose}) => {
  const {call, loading} = useContext(APIContext);
  const {auth} = useContext(AuthContext);
  const {track} = useContext(SocketContext);
  const {cache, setCache, setCacheValue} = useContext(CacheContext);
  const {projects, selectedProjectId} = cache;
  const [openDelete, setOpenDelete] = useState();
  const [showNewProject, setShowNewProject] = useState(false);
  const [projectId, setProjectId] = useState(selectedProjectId);

  useEffect(() => {
    if (auth && auth.user && (auth.user.teams || [].length > 0)) {
      call(getProjects).then(response => {
        if (response.ok) {
          setCacheValue('projects', response.body);
        }
      })
    }
  }, [auth])

  const detailsProject = useMemo(() => {
    return (projects || []).find(project => project._id === projectId) || {};
  }, [projectId, projects]);

  async function onEditProject(values) {
    let data = {_id: projectId, ...values};
    if (showNewProject) delete data._id;
    if (data.team_id === "none") delete data.team_id;
    let response = await call(addProject, {project: data});
    if (response.ok) {
      setCache(prevState => {
        return {
          ...prevState,
          projects: PerformanceUtils.editOrAdd(response.body, prevState.projects, '_id'),
        }
      });
      setDefaultProject(response.body);
      selectExistingProject(response.body);
    }
  }

  function selectNewProject() {
    setShowNewProject(true)
  }

  function selectExistingProject(project) {
    setProjectId(project._id);
    setShowNewProject(false);
  }

  async function onDeleteProject(project) {
    track('projects.delete-project', {id: project._id});
    let projectId = project._id;
    let response = await call(archiveProject, {projectId});
    setOpenDelete(false);
    if (response.ok) {
      let projects = PerformanceUtils.removeFromArray(response.body, cache.projects, '_id');
      let selectedId = projectId === cache.selectedProjectId ? (projects[0] || {})._id : cache.selectedProjectId;
      setProjectId(selectedId);
      setCache(prevState => {
        return {
          ...prevState,
          projects,
          selectedProjectId: selectedId
        }
      });
    }
  }

  function setDefaultProject(project) {
    track('projects.select', {id: project._id});
    setCacheValue('selectedProjectId', project._id);
    call(updateUserInfo, {data: {selected_project: project._id}});
  }

  return (
    <div className="projects-new">
      <div className="back clickable" onClick={onClose}>
        <CloseOutlined
          className="font-size-xxl pointer text-secondary"
        />
      </div>
      <div className="main-content">
        <div className="project-list">
          <List component="nav">
            <ProjectBadge
              className="blue"
              onClick={selectNewProject}
              selected={showNewProject}
              icon={<Add/>}
              text="New Concept"
            />
            {projects.map(project => <ProjectBadge
              project={project}
              onClick={selectExistingProject}
              selected={project._id === projectId && !showNewProject}
              icon={project._id === selectedProjectId ? <CheckCircleOutline/> : undefined}
              onDelete={() => setOpenDelete(project)}
            />)}
          </List>
        </div>
        <ProjectDetails
          key={detailsProject?._id + showNewProject}
          project={showNewProject ? {} : detailsProject}
          onEdit={onEditProject}
          loading={loading[addProject]}
          setDefaultProject={setDefaultProject}
        />
      </div>
      <MyDialog
        open={!!openDelete}
        title="Delete this project?"
        loading={loading[archiveProject]}
        onCancel={() => setOpenDelete(false)}
        content={
          <DialogContentText id="alert-dialog-description">
            Once a project has been deleted, it cannot be recovered. Are you sure you want to delete it?
          </DialogContentText>
        }
        onConfirm={() => onDeleteProject(openDelete)}
      />
    </div>
  );
};

const ProjectBadge = ({text, project = DEFAULT_OBJECT, onClick, selected, className = "", icon, onDelete}) => {

  let teamDescription = "Private Concept";
  if (project.team_id) teamDescription = "Shared Concept";
  if (!project._id) teamDescription = undefined;
  return (
    <ListItem
      className={"project-badge " + className}
      selected={selected}
      onClick={() => onClick(project)}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text || project.name} secondary={teamDescription}/>
      <ShowIf condition={!!onDelete}>
        <IconButton component="span" className="delete-icon" onClick={onDelete}>
          <Delete className="font-size-lg"/>
        </IconButton>
      </ShowIf>
    </ListItem>
  )
}

const ProjectDetails = ({project, onEdit, loading, setDefaultProject}) => {

  const {auth} = useContext(AuthContext);
  const {cache} = useContext(CacheContext);
  const {platforms, genres, teams, selectedProjectId} = cache;

  const canUseTeam = isTeamsPlan(auth.subscription);

  const TEAM_OPTIONS = [
    {value: "none", label: "None"},
    ...(teams || []).map(team => {
      return {value: team._id, label: team.name};
    })
  ];

  return (
    <div className="project-details">
      <span className="title font-size-xl text-primary font-weight-bold mb-5 d-block">Concept Details</span>
      <Formik
        initialValues={{
          name: project.name,
          platform: auth.user.platform || "Mobile",
          genres: auth.user.genres || [],
          team_id: project.team_id
        }}
        onSubmit={onEdit}
        validationSchema={ValidationSchema}
      >
        {formik => (
          <Form className="strong-colors-form">
            <FormikTextField
              autoFocus
              margin="dense"
              name="name"
              label="Concept Title"
              fullWidth
            />
            <ShowIf condition={(teams || []).length > 0}>
              <FormikSelectField
                className="mb-3"
                name="team_id"
                label="Team"
                options={TEAM_OPTIONS}
                disabled={!canUseTeam}
                fullWidth
              />
            </ShowIf>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormikSelectField
                  className="mt-4"
                  name="platform"
                  label="Platform"
                  options={platforms.map(platform => {
                    return {value: platform, label: platform}
                  })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikChipSelect
                  name="genres"
                  title="Genres"
                  values={genres}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <MyButton
                  id="project.change"
                  color="secondary"
                  variant="contained"
                  disabled={!formik.dirty}
                  loading={loading}
                >
                  {project._id ? "Save Changes" : "Add Concept"}
                </MyButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ShowIf condition={!!project._id}>
                  <MyButton
                    id="project.select"
                    color="primary"
                    variant="contained"
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDefaultProject(project);
                    }}
                    disabled={selectedProjectId === project._id}
                    loading={loading}
                  >
                    Select Project
                  </MyButton>
                </ShowIf>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const MyDialog = ({title, content, open, onConfirm, onCancel, loading, clickDisabled, hideActions = false}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className="ludo-modal"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <ShowIf condition={!hideActions}>
        <DialogActions>
          <MyButton onClick={onCancel} variant="outlined" color="secondary" style={{maxWidth: "40vw"}}>
            Cancel
          </MyButton>
          <MyButton
            color="primary"
            onClick={onConfirm}
            variant="contained"
            style={{maxWidth: "40vw"}}
            autoFocus
            loading={loading}
            disabled={clickDisabled}
          >
            Confirm
          </MyButton>
        </DialogActions>
      </ShowIf>
    </Dialog>
  )
};

export const ProjectsModal = ({open = false, onClose}) => {
  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={onClose}
      className="ludo-modal projects-modal"
    >
      <Projects onClose={onClose}/>
    </Dialog>
  )
};

export default Projects;

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No name provided'),
  platform: Yup.string()
    .required('No platform provided'),
  genres: Yup.array()
});
