import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import { CircularProgress, Grid } from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import "./style.scss";
import { FormTab, FormTabs } from "components/common/FormTabs";
import { Form, Formik, useFormikContext } from "formik";
import FormikPersist from "components/utils/FormikPersist";
import { FormikSelectField } from "formik-material-fields";
import PerformanceUtils from "helpers/PerformanceUtils";
import TrendTopics from "pages/Trends/TrendTopics";
import { useParams } from "react-router";
import MyButton from "components/Controls/MyButton";
import CacheContext from "context/CacheContext";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
} from "components/Controls/UniversalInput";
import { FilterPanel, FiltersButton } from "pages/Search";
import usePersistedState from "hooks/usePersistedState";
import _ from "lodash";
import SocketContext from "context/SocketContext";
import { SEARCH_RESULTS_MESSAGE } from "scenes/SubscriptionPage/Plans";
import GameGrid from "components/common/GameGrid";
import {
  EmojiEventsOutlined,
  FormatListBulletedOutlined,
  NewReleasesOutlined,
  Sort,
  ViewModuleOutlined,
} from "@mui/icons-material";
import restrictions from "config/restrictions.json";
import LoadingTip, { LOADING_TIPS_SECTIONS } from "components/utils/LoadingTip";
import AuthContext from "../../context/AuthContext";

const TABS = ["charts", "latest"];

const getTrendGames = "getTrendGames";
const getTrendTopics = "getTrendTopics";
const getTrendOptions = "getTrendOptions";

const DEFAULT_OBJECT = {};

const Trends = ({
  fullVersion = true,
  gameProps,
  defaultLocation,
  gameWidth,
  spacing,
}) => {
  const params = useParams();

  let defaultTab = TABS.indexOf(params.tab);
  let chosenTabNumber = !fullVersion ? 0 : defaultTab >= 0 ? defaultTab : 0;

  const { track } = useContext(SocketContext);
  const { call, loading } = useContext(APIContext);

  const [tab, setTab] = useState(chosenTabNumber);
  const [loadedOptions, setLoadedOptions] = useState(false);
  const [data, setData] = useState([{}, {}, {}]);
  const [options, setOptions] = useState([{}, {}, {}]);
  const [loadingState, setLoading] = useState();
  const [first, setFirst] = useState([true, true, true]);
  const [localTab, setLocalTab] = useState(fullVersion ? 0 : 1);
  const [formValues, setFormValues] = useState(DEFAULT_OBJECT);
  const [executedOptions, setExecutedOptions] = useState(DEFAULT_OBJECT);
  const [sorting, setSorting] = useState([
    Object.keys(SORTING_LABELS[0])[0],
    Object.keys(SORTING_LABELS[1])[0],
  ]);

  const [initialSorting, setInitialSorting] = useState([undefined, undefined]);

  const loadingCall = loading[getTrendGames] || loading[getTrendTopics];

  const [selectedOptions, setSelectedOptions] = useState([
    {
      games: { n: 100 },
      trends: { n: 30 },
    },
    {
      games: { n: 100 },
      trends: { n: 30 },
    },
    {
      games: { n: 100 },
    },
  ]);

  useEffect(() => {
    if (fullVersion && chosenTabNumber >= 0) {
      window.history.replaceState(null, null, `/trending/${TABS[tab]}`);
    }
  }, [tab, fullVersion]);

  async function getData(tab, selectedOptions, topics = false, games = false) {
    let type = TABS[tab];
    setLoading(tab);

    if (["steam", "itch"].includes(selectedOptions.games.sources)) {
      selectedOptions.games.location = "All";
    }
    if (["steam", "itch"].includes(selectedOptions.trends.sources)) {
      selectedOptions.trends.location = "All";
    }

    track("trends.load", {
      options: selectedOptions.trends || selectedOptions.games,
      tab: TABS[tab],
    });

    if (tab === 1) {
      selectedOptions.games.location = undefined;
    }

    let [response1, response2] = await Promise.all([
      games
        ? call(getTrendGames, { type, data: selectedOptions.games })
        : undefined,
      topics
        ? call(getTrendTopics, { type, data: selectedOptions.trends })
        : undefined,
    ]);

    let gamesData;
    let topicsData;
    let defaultLocalTab;

    if (response2?.ok) {
      topicsData = response2.body;
      defaultLocalTab = 0;
    }
    if (response1?.ok) {
      gamesData = response1.body;
      defaultLocalTab = 1;
    }

    setExecutedOptions(selectedOptions);

    setData((prevState) => {
      let data = prevState[tab] || {};
      if (gamesData) data.games = gamesData;
      if (topicsData) data.topics = topicsData;
      data.defaultLocalTab = defaultLocalTab;
      prevState[tab] = data;
      return [...prevState];
    });
    setLoading();
  }

  async function getDefaultOptions() {
    let data = await Promise.all(
      TABS.map(async (type) => {
        let response = await call(getTrendOptions, { type });
        if (response.ok) return response.body;
      })
    );
    setOptions(data);
    setLoadedOptions(true);
  }

  useEffect(() => {
    getDefaultOptions();
  }, []);

  const setupDataRequest = useCallback(
    async (tab, reqPayload, onChange, localTab, doRequest = true) => {
      let oldOptions = selectedOptions[tab];
      setFormValues(JSON.parse(JSON.stringify(reqPayload)));

      reqPayload = JSON.parse(JSON.stringify(reqPayload));

      if (!!reqPayload.search) {
        reqPayload.search = convertUniversalInput(reqPayload.search);
      }

      if (tab === 0 && !reqPayload.sorting)
        reqPayload.sorting =
          sorting[tab] || Object.keys(SORTING_LABELS[tab])[0];

      let reqOptions = JSON.parse(JSON.stringify(oldOptions));

      if (localTab === 1)
        reqOptions.games = { ...reqOptions.games, ...reqPayload };

      if (localTab === 0)
        reqOptions.trends = { ...reqOptions.trends, ...reqPayload };

      let isNewReq = !PerformanceUtils.isEqual(reqOptions, executedOptions);
      let dataEmpty = ((data[tab] || {}).topics || []).length === 0;

      const hasSearch =
        _.flatten(Object.values(reqPayload.search || {})).length > 0;
      const isFirst = first[tab];

      let stop = onChange && hasSearch && !isFirst && !dataEmpty;

      if (!stop && (isNewReq || dataEmpty)) {
        if (first[tab]) {
          let newFirst = [...first];
          newFirst[tab] = false;
          setFirst(newFirst);
        }

        let newSelectedOptions = JSON.parse(JSON.stringify(selectedOptions));
        newSelectedOptions[tab] = reqOptions;
        setSelectedOptions(newSelectedOptions);

        if (!hasSearch) doRequest = true;

        if (doRequest)
          return getData(tab, reqOptions, localTab === 0, localTab === 1);
      }
    },
    [selectedOptions, sorting, first, data]
  );

  const onSortChange = useCallback(
    async (tab, values) => {
      if (values?.initialSorting) {
        setInitialSorting((prevState) => {
          let val = [...prevState];
          val[tab] = values.initialSorting;
          return val;
        });
      }
      if (values && values.sorting && sorting[tab] !== values.sorting) {
        let newSorting = [...sorting];
        newSorting[tab] = values.sorting;
        setSorting(newSorting);
        return setupDataRequest(
          tab,
          { ...formValues, sorting: values.sorting },
          undefined,
          localTab
        );
      }
    },
    [formValues, sorting, first, data, localTab]
  );

  const showSorting = tab > 1;

  const transformedOptions = useMemo(() => {
    let sources = (((selectedOptions || {})[tab] || {}).games || {}).sources;
    if (["itch", "steam"].includes(sources)) {
      return options.map((option) => {
        return {
          ...option,
          locations: undefined,
        };
      });
    } else {
      return options;
    }
  }, [options, selectedOptions, tab]);

  function changeLocalTab(localTab) {
    setLocalTab(localTab);
    return setupDataRequest(tab, formValues, undefined, localTab);
  }

  return (
    <div className="trending">
      <ShowIf condition={fullVersion}>
        <PageTitle
          titleHeading="Market Trends"
          titleDescription="Stay on top the trends Ludo discovers in the market"
        >
          {/*<TrendsTutorial/>*/}
        </PageTitle>
      </ShowIf>
      <div className="form-wrapper mb-0 py-3">
        <ShowIf condition={!loadedOptions}>
          <div className="text-align-center m-4">
            <CircularProgress size={55} />
          </div>
        </ShowIf>
        <ShowIf condition={fullVersion && !!loadedOptions}>
          <div>
            <FormTabs value={tab} className="mt-4">
              <FormTab
                label={
                  <span>
                    <EmojiEventsOutlined /> Charts
                  </span>
                }
                onClick={() => setTab(0)}
              />
              <FormTab
                label={
                  <span>
                    <NewReleasesOutlined /> New Releases
                  </span>
                }
                onClick={() => setTab(1)}
              />
            </FormTabs>
          </div>
        </ShowIf>
      </div>
      <ShowIf condition={!!loadedOptions}>
        <div className="form-wrapper d-flex py-0 m-0">
          <ChartsForm
            key={tab + (defaultLocation || "")}
            fullVersion={fullVersion}
            tab={tab}
            setTab={setTab}
            options={transformedOptions[tab]}
            makeRequest={(values, onChange) => {
              setupDataRequest(tab, values, onChange, localTab);
            }}
            showSorting={showSorting}
            loading={loadingState === tab}
            defaultLocation={defaultLocation}
            makeChanges={(values) => {
              setupDataRequest(tab, values, undefined, localTab, false);
            }}
            onSortChange={(values) => onSortChange(tab, values)}
          />
        </div>
      </ShowIf>
      <div className="trending-content">
        <GameTopicCharts
          data={data[tab]}
          options={options[tab]}
          onSortChange={(values) => onSortChange(tab, values)}
          tab={tab}
          selectedOptions={selectedOptions[tab]}
          loading={loadingCall || loadingState === tab}
          fullVersion={fullVersion}
          gameProps={gameProps}
          gameWidth={gameWidth}
          spacing={spacing}
          localTab={localTab}
          setLocalTab={changeLocalTab}
          executedOptions={executedOptions}
          initialSorting={initialSorting[tab]}
        />
      </div>
    </div>
  );
};

export const GameTopicCharts = ({
  data,
  options,
  extraOptions,
  onSortChange,
  tab,
  loading,
  fullVersion,
  gameProps,
  gameWidth,
  spacing,
  selectedOptions = { games: {}, trends: {} },
  executedOptions,
  localTab,
  setLocalTab,
  initialSorting,
}) => {
  const { games, topics } = data;
  const hasSearch =
    _.flatten(Object.values(executedOptions?.trends?.search || {})).length > 0;
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (data.defaultLocalTab !== undefined && fullVersion)
      setLocalTab(data.defaultLocalTab);
  }, [data.defaultLocalTab, fullVersion]);

  const showSortingBox = !hasSearch && localTab === 0;

  const onlyGames = useMemo(() => {
    return (games || []).map(({ game }) => game).filter((g) => !!g);
  }, [games]);

  return (
    <div className="trending-content-games">
      <div className="options-bar">
        {extraOptions}

        <ShowIf condition={fullVersion && setLocalTab}>
          <div className="toggle-wrapper">
            <span>Show Trending:</span>
            <div className="toggle">
              <span
                onClick={() => setLocalTab(0)}
                className={`left ${localTab === 0 && "active"}`}
              >
                Topics
              </span>
              <span
                onClick={() => setLocalTab(1)}
                className={`right ${localTab === 1 && "active"}`}
              >
                Games
              </span>
            </div>
          </div>
        </ShowIf>

        <ShowIf
          condition={
            showSortingBox &&
            !!options &&
            !!options?.sorting &&
            (!!topics || !!games)
          }
        >
          <SortingForm
            options={(options?.sorting || []).map((sort) => {
              return {
                value: sort,
                label: SORTING_LABELS[tab][sort]?.label || sort,
                description: SORTING_LABELS[tab][sort]?.description,
              };
            })}
            onChange={onSortChange}
            initialValue={initialSorting}
            key={initialSorting}
            formId={"Trending.Sort.Form3" + tab}
          />
        </ShowIf>

        <ShowIf condition={localTab === 0 && topics?.length > 0}>
          <div className="mode-icon" onClick={() => setExpanded(!expanded)}>
            <ShowIf condition={!expanded}>
              <ViewModuleOutlined className="font-size-lg mr-2" />
              <span>Switch to Grid View</span>
            </ShowIf>
            <ShowIf condition={expanded}>
              <FormatListBulletedOutlined className="font-size-lg mr-2" />
              <span>Switch to List View</span>
            </ShowIf>
          </div>
        </ShowIf>
      </div>

      <LoadingTip
        style={{ marginLeft: "30px", marginTop: "30px", marginBottom: "30px" }}
        section={LOADING_TIPS_SECTIONS.dailyTrends}
        visible={loading}
        key={loading}
      />
      {!loading && (
        <>
          {localTab === 0 && (
            <TrendTopics
              topics={topics}
              tab={tab}
              genre={selectedOptions.trends.genre}
              location={selectedOptions.trends.location}
              sources={selectedOptions.trends.sources}
              expanded={expanded}
            />
          )}
          {localTab === 1 && (
            <GameGrid
              key={localTab + onlyGames.length}
              games={onlyGames}
              gameProps={{
                genre: selectedOptions.games.genre,
                location: selectedOptions.games.location,
                ...gameProps,
              }}
              spacing={spacing || (!fullVersion ? 2 : undefined)}
              gameWidth={gameWidth || (!fullVersion ? 170 : undefined)}
              allGenres={true}
              lockAfterIndex={restrictions.GAME_SEARCH_NUMBER}
            />
          )}
        </>
      )}
    </div>
  );
};

export const SOURCE_LABELS = {
  "appstore+playstore": "AppStore & PlayStore",
  appstore: "AppStore",
  playstore: "PlayStore",
  itch: "Itch",
  steam: "Steam",
};

const ChartsForm = ({
  options = DEFAULT_OBJECT,
  makeRequest,
  tab,
  setTab,
  showSorting = true,
  loading,
  fullVersion,
  defaultLocation,
  makeChanges,
  onSortChange,
}) => {
  const { auth } = useContext(AuthContext);

  const defaultSources =
    auth.user.platform === "Mobile"
      ? "appstore+playstore"
      : auth.user.platform === "Desktop"
      ? "steam"
      : undefined;
  const defaultGenre = (auth.user.genres || [])[0];

  const formKey =
    "Trending.Form4" +
    tab +
    (defaultLocation || "") +
    (defaultSources || "") +
    (defaultGenre || "");

  const [loaded, setLoaded] = useState(false);
  const [collapsed, setCollapsed] = usePersistedState(
    "Trending.FormCollapsed",
    true,
    true
  );
  const { cache, setCache } = useContext(CacheContext);
  const { trialExpired } = cache;

  const matchingLocation = useMemo(() => {
    if (defaultLocation) {
      let toMatch = defaultLocation.toLowerCase();
      return (options.locations || []).find(
        (loc) => loc === toMatch.toLowerCase()
      );
    }
  }, [options.locations, defaultLocation]);

  let genre = defaultGenre || (options.genres || [])[0];
  let sorting = (options.sorting || [])[0];
  let sources = defaultSources || (options.sources || [])[0];
  let location = matchingLocation || (options.locations || [])[0];

  if (!showSorting) sorting = undefined;

  const total = [genre, location, sorting, sources].filter(
    (val) => !!val
  ).length;
  const md = !fullVersion ? 12 : Math.min(12 / total, 4);

  function isLockedSource(source) {
    return false;
    //return trialExpired && source.toLowerCase() !== "appstore+playstore";
  }

  function isLockedLocation(location) {
    if (!location) return false;
    return trialExpired && location.toLowerCase() !== "united states";
  }

  function isLockedGenre(genre) {
    return false;
    //return trialExpired && !ALLOWED_FREE_GENRES.includes(genre);
  }

  function makeRequestWrapper(values = {}, formik) {
    values = { ...values, ...(formik?.values || {}) };
    if (
      !isLockedLocation(values.location) &&
      !isLockedGenre(values.genre) &&
      !isLockedSource(values.sources)
    ) {
      return makeRequest(values);
    } else {
      setCache((prevState) => {
        return {
          ...prevState,
          lockScreen: SEARCH_RESULTS_MESSAGE,
        };
      });
      if (isLockedLocation(values.location))
        formik.setFieldValue("location", (options.locations || [])[0]);
      if (isLockedGenre(values.genre, true))
        formik.setFieldValue("genre", (options.genres || [])[0]);
      if (isLockedSource(values.sources))
        formik.setFieldValue("source", (options.sources || [])[0]);
    }
  }

  const hideSearch = tab === 2;

  return (
    <div key={formKey} className="w-100">
      <Formik
        key={formKey}
        initialValues={{ genre, location, sorting, sources }}
        onSubmit={(values, formik) => makeRequestWrapper(values, formik)}
      >
        {(formik) => (
          <div className="w-100">
            {loaded && (
              <OnFormikChange
                onChange={(values) => {
                  makeChanges(values);
                }}
              />
            )}
            <FormikPersist
              name={formKey}
              onLoad={(state, formik) => {
                if (
                  state?.values?.sources &&
                  !options.sources.includes(state.values.sources)
                ) {
                  formik.setFieldValue("sources", (options.sources || [])[0]);
                }
                setLoaded(true);
                makeRequestWrapper(state.values, formik);
              }}
            />
            {loaded && (
              <ChangeDataOnLocation
                fields={["genre", "search", "sources", "sorting"]}
                onAction={(values, data) => {
                  if (values.sorting) {
                    onSortChange({ ...values, initialSorting: values.sorting });
                  }
                  if (data.tab) {
                    if (data.tab === "charts") setTab(0);
                    else setTab(1);
                  }
                }}
              />
            )}
            <Form>
              <div className="d-flex flex-column pt-3 pb-1">
                <Grid container>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    className="mb-2"
                  >
                    <ShowIf condition={!hideSearch}>
                      <Grid item sm={12} md={12} className="search-row">
                        <UniversalInput
                          name="search"
                          formik={formik}
                          onSetData={(data) => {
                            formik.setFieldValue("search", data);
                          }}
                          value={formik.values.search}
                          uploadMedia={true}
                          allowed={[
                            "text",
                            "game",
                            "generated_game",
                            "gdd",
                            "topic",
                          ]}
                        />
                        <div className="button-wrapper">
                          <MyButton
                            id="trends.search"
                            trackOptions={{
                              ...formik.values,
                              search: convertUniversalInput(
                                formik.values.search
                              ),
                              tab,
                            }}
                            size="medium"
                            loading={loading}
                            onClick={() => makeRequestWrapper(formik.values)}
                          >
                            Search
                          </MyButton>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FiltersButton
                          collapsed={collapsed}
                          setCollapsed={setCollapsed}
                          page="trends"
                        />
                      </Grid>

                      <ShowIf condition={collapsed}>
                        <Grid item sm={12} md={12}>
                          <FilterPanel
                            onExpand={() => setCollapsed(false)}
                            ignore={["location", "sorting"]}
                          />
                        </Grid>
                      </ShowIf>
                    </ShowIf>
                    <ShowIf condition={!collapsed && !!sources}>
                      <Grid item xs={12} sm={12} md={md}>
                        <FormikSelectField
                          className="mt-2"
                          name="sources"
                          label="Source"
                          options={(options.sources || []).map((source) => {
                            let locked = isLockedSource(source);
                            let className = locked ? "locked" : "";
                            return {
                              value: source,
                              label: (
                                <div className="d-flex">
                                  <span className={className}>
                                    {SOURCE_LABELS[source] || source}
                                  </span>
                                </div>
                              ),
                            };
                          })}
                          fullWidth
                        />
                      </Grid>
                    </ShowIf>
                    <ShowIf condition={!collapsed || hideSearch}>
                      <ShowIf condition={!!genre}>
                        <Grid item xs={12} sm={12} md={md}>
                          <FormikSelectField
                            className="mt-2"
                            name="genre"
                            label="Genre"
                            fullWidth
                            options={options.genres.map((genre) => {
                              let locked = isLockedGenre(genre);
                              let className = locked ? "locked" : "";
                              return {
                                value: genre,
                                label: (
                                  <div className="d-flex">
                                    <span className={className}>{genre}</span>
                                  </div>
                                ),
                              };
                            })}
                          />
                        </Grid>
                      </ShowIf>
                      <ShowIf
                        condition={
                          !!location &&
                          (formik.values.location || "").includes("store")
                        }
                      >
                        <Grid item xs={12} sm={12} md={md}>
                          <FormikSelectField
                            className="mt-2"
                            name="location"
                            label="Location"
                            options={(options.locations || []).map(
                              (location) => {
                                let locked = isLockedLocation(location);
                                let className =
                                  location.length <= 3
                                    ? "text-uppercase"
                                    : "text-capitalize";
                                if (locked) className += " locked";
                                return {
                                  value: location,
                                  label: (
                                    <div className="d-flex">
                                      <span className={"mr-2 " + className}>
                                        {location}
                                      </span>
                                    </div>
                                  ),
                                };
                              }
                            )}
                            fullWidth
                          />
                        </Grid>
                      </ShowIf>
                      {showSorting && !!sorting ? (
                        <Grid item xs={12} sm={12} md={md}>
                          <FormikSelectField
                            className="mt-2"
                            name="sorting"
                            label="Sorting"
                            options={(options.sorting || []).map((sort) => {
                              return {
                                value: sort,
                                label: SORTING_LABELS[tab][sort].label || sort,
                              };
                            })}
                            fullWidth
                          />
                        </Grid>
                      ) : null}
                    </ShowIf>
                  </Grid>
                </Grid>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export const SortingForm = ({ options, onChange, formId, initialValue }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="sorting-form-wrapper">
      <Formik
        key={formId}
        initialValues={{
          sorting: initialValue || options[0].value,
        }}
      >
        {(formik) => (
          <div className="d-flex flex-row" style={{ alignItems: "center" }}>
            <Sort className="font-size-xxl sort-icon mr-2" />
            {loaded && <OnFormikChange onChange={onChange} />}
            <FormikPersist
              name={formId}
              onLoad={(state, formik) => {
                setLoaded(true);
                if (initialValue) {
                  formik.setFieldValue("sorting", initialValue);
                }
              }}
            />
            <Form>
              <div className="d-flex">
                <FormikSelectField
                  className="mt-2"
                  name="sorting"
                  fullWidth
                  options={options.map(({ value, label, description }) => {
                    let locked = false;
                    let className = locked ? "locked" : "";
                    return {
                      value,
                      label: (
                        <div className="d-flex flex-column">
                          <span className={className + " font-weight-bold"}>
                            {label}
                          </span>
                          <span
                            className="description"
                            style={{ opacity: 0.8 }}
                          >
                            {description}
                          </span>
                        </div>
                      ),
                    };
                  })}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export const OnFormikChange = ({ onChange, loaded }) => {
  const formik = useFormikContext();
  useEffect(() => {
    if (loaded === undefined || loaded === true) onChange(formik.values);
  }, [formik.values, loaded]);
  return null;
};

export const SORTING_LABELS = [
  {
    growth_rate: {
      label: "Exponential growth",
      description: "Rapidly increasing the number of new players day by day",
    },
    "charts-original": {
      label: "Uniqueness",
      description:
        "Game topics that are different from most other games in the top charts",
    },
    absolute_growth: {
      label: "Chart toppers",
      description:
        "Highest number of new players accumulated over the past 7 days",
    },
    overall_chart: {
      label: "Most top-chart games",
      description: "Largest number of games in the Top-200 Overall",
    },
  },
  {
    "new-original": {
      label: "Uniqueness",
      description:
        "Game topics that are different from most other recently released games",
    },
    mom: {
      label: "Growing number of releases",
      description:
        "Highest increase in number of games released, month-over-month",
    },
    "past-2-weeks": {
      label: "Very recent releases",
      description: "Highest number of new game releases over the past 14 days",
    },
    "release-date": {
      label: "New game topics",
      description:
        "Game topics that have appeared the most recently in new releases",
    },
  },
];

export default Trends;
