import {
  IconButton,
  Grid,
  Divider, ListItemIcon, CircularProgress, Chip, Tooltip
} from "@material-ui/core";
import {Formik, Form} from "formik";
import {Rating} from '@material-ui/lab';
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import moment from 'moment';
import _ from 'lodash';
import './style.scss';
import {GameFavoriteButton} from "components/common/FavoriteButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImageGallery, {convertProxyUrl} from "components/common/ImageGallery";
import {Link} from "react-router-dom";
import {GameIcon, SourceIcon} from "components/common/GameCard";
import {AnnotatedDescription} from "components/utils/Annotations";
import CacheContext from "context/CacheContext";
import {ShareIcon} from "components/Sharing";
import {FormTab, WhiteFormTabs} from "components/common/FormTabs";
import APIContext from "context/APIContext";
import Plot from 'react-plotly.js';
import ErrorBoundary from "components/utils/ErrorBoundary";
import DetailsPanelContext from "context/DetailsPanelContext";
import {FormikSelectField} from "formik-material-fields";
import SocketContext from "context/SocketContext";
import usePersistedState from "hooks/usePersistedState";
import {chooseTrend, getRankAndSlope} from "pages/Trends/TrendGames";
import {
  ArrowDownward,
  ArrowUpward,
  ChevronLeft,
  ChevronRight,
  Remove,
  SearchOutlined,
  WbIncandescentOutlined
} from "@mui/icons-material";
import ReactPlayer from "react-player";

const getGameTrends = 'getGameTrends';

const GRAPH_COLORS = {
  'Top Free': '#214B9B',
  'Top Paid': '#E99211',
  'Top Grossing': '#C35317',
  'Popular': '#214B9B',
  'Top Rated': '#E99211',
  'Top Sellers': '#C35317',
};
const DEFAULT_ARRAY = [];
const DEFAULT_OBJECT = {};

export const GAME_DETAILS_PANEL_TABS = ["Overview", "Trends"];

const GameDetailsPanel = () => {

  const {track} = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [gameTrends, setGameTrends] = useState(undefined);
  const [prevGame, setPrevGame] = useState(undefined);
  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;

  const {call} = useContext(APIContext);

  let {game, genre, location = "United States", chart} = detailsPanel;

  function setTabWrapper(tab) {
    track('detailsPanel-game.change-tab', {game_id: game?._id, tab: GAME_DETAILS_PANEL_TABS[tab]});
    setTab(tab);
  }

  useEffect(() => {
    if (game && game._id) {
      setLoading(!!genre || !!location);
      setPrevGame(game);
      let data = {id: game._id};
      call(getGameTrends, data).then(response => {
        if (response.ok && response.body.length > 0) {
          setGameTrends(response.body)
        }
        setLoading(false);
      });
    }
  }, [game, genre, location]);

  return (
    <ShowIf condition={!!prevGame}>
      <GameContent
        key={prevGame?._id}
        game={prevGame}
        onNext={undefined}
        onPrevious={undefined}
        gameTrends={gameTrends}
        tab={tab}
        setTab={setTabWrapper}
        loading={loading}
        chart={chart}
        genre={genre}
        location={location}
      />
    </ShowIf>
  )
};

export const SlopeIcon = ({slope, className, style}) => (
  slope > 0 ?
    <ArrowUpward
      className={className}
      style={style}
    /> : (slope < 0 ?
      <ArrowDownward
        className={className}
        style={style}
      /> : <Remove
        className={className}
        style={style}
      />)
)

const GameContent = ({game, gameTrends, onNext, onPrevious, tab, setTab, loading, chart, genre, location}) => {

  const trend = useMemo(() => {
    return chooseTrend(gameTrends, genre, location, chart);
  }, [gameTrends, genre, location, chart]);

  const slopeElement = useMemo(() => {
    const {slope, rank} = getRankAndSlope(trend, chart);
    return (
      <ShowIf condition={!!rank}>
        (#{rank}
        <ShowIf condition={slope !== null}>
          <>
            &nbsp;<SlopeIcon slope={slope} style={{top: "-2px", position: "relative"}}
                             className="font-size-md"/>&nbsp;{slope}
          </>
        </ShowIf>)
      </ShowIf>
    );
  }, [trend, chart]);

  return (
    <>
      <div className="top-source-icon">
        <SourceIcon game={game}/>
      </div>
      <div className="game-details content p0">
        <Header
          game={game}
          onNext={onNext}
          onPrevious={onPrevious}
        />
        <div className="body">
          <Divider className="w-100 mt-0"/>
          <Genres game={game}/>
          <Info game={game}/>
          <Actions game={game}/>
          <Divider className="w-100"/>
          <div className="mx-5 content-wrapper">
            <ShowIf condition={!loading}>
              <ShowIf condition={!gameTrends}>
                <WhiteFormTabs value={tab} className="mb-2 tabs one" key="no-trends">
                  <FormTab
                    label={GAME_DETAILS_PANEL_TABS[0]}
                    onClick={() => setTab(0)}
                  />
                </WhiteFormTabs>
              </ShowIf>
              <ShowIf condition={!!gameTrends}>
                <WhiteFormTabs value={tab} className="mb-2 tabs two" key="has-trends">
                  <FormTab
                    label={GAME_DETAILS_PANEL_TABS[0]}
                    onClick={() => setTab(0)}
                  />
                  <FormTab
                    label={
                      <span>
                        <span className="mr-2">{GAME_DETAILS_PANEL_TABS[1]}</span>
                        {slopeElement}
                      </span>
                    }
                    onClick={() => setTab(1)}
                  />
                </WhiteFormTabs>
              </ShowIf>
              <ShowIf condition={tab === 0}>
                <Description game={game}/>
                <div className="gallery">
                  <VideoGallery
                    videos={game.videos}
                  />
                  <ImageGallery
                    images={game.screenshots}
                    onImageClick={true}
                  />
                </div>
              </ShowIf>
              <ShowIf condition={tab === 1 && !!gameTrends}>
                <TrendInfo
                  key={genre + location}
                  game={game}
                  gameTrends={gameTrends}
                  chart={chart}
                  genre={genre}
                  location={location}
                />
              </ShowIf>
            </ShowIf>
            <ShowIf condition={loading}>
              <div className="text-align-center m-4">
                <CircularProgress size={55}/>
              </div>
            </ShowIf>
          </div>
        </div>
      </div>
    </>
  )
};

const VideoGallery = ({videos = DEFAULT_ARRAY}) => {

  const filteredVideos = useMemo(() => {
    return videos.filter(({url}) => url && !url.includes('.gif'));
  }, [videos]);

  return (
    <>
      {filteredVideos.length > 0 && (
        <div className="video-gallery">
          {filteredVideos.map((video) => (
            <ReactPlayer
              key={video?.url}
              url={video?.url}
              onError={console.log}
              width={360}
              height={210}
              controls={true}
            />
          ))}
        </div>
      )}

    </>
  );
}

const selectorOptions = {
  buttons: [
    {
      step: 'month',
      stepmode: 'backward',
      count: 1,
      label: '1m'
    }, {
      step: 'month',
      stepmode: 'backward',
      count: 6,
      label: '6m'
    }, {
      step: 'year',
      stepmode: 'backward',
      count: 1,
      label: '1y'
    }, {
      step: 'all',
    }
  ],
};

const TrendInfo = React.memo(({gameTrends = DEFAULT_ARRAY, chart, genre, location, game}) => {

  const {track} = useContext(SocketContext);
  const [selectedGenre, setSelectedGenre] = usePersistedState(`detailsTrendGenre-${game._id}${genre + location + chart}`, genre);
  const [selectedLocation, setSelectedLocation] = usePersistedState(`detailsTrendLocation-${game._id}${genre + location + chart}`, location);

  const trend = useMemo(() => {
    return chooseTrend(gameTrends, selectedGenre, selectedLocation);
  }, [gameTrends, selectedLocation, selectedGenre]);

  useEffect(() => {
    if (trend) {
      setSelectedLocation(trend.country);
      setSelectedGenre(trend.genre);
    }
  }, [trend]);

  const locationMap = useMemo(() => {
    let result = {};
    gameTrends.forEach(trend => {
      const {country} = trend;
      result[country] = result[country] || [];
      result[country].push(trend);
    });
    return result;
  }, [gameTrends]);

  const {
    peak_rank,
    peak_chart,
    chart_days,
    chart_history,
  } = trend;

  let charts = useMemo(() => {
    if (chart_history && chart_history.length > 0) {
      return chart_history.map(({date, rank, chart}, index) => {
        let x = date.map(d => new Date(moment.unix(d).toDate()));
        let y = date.map((d, index) => rank[index]);
        return {
          x,
          y,
          name: chart,
          hovertemplate: `%{x|%Y/%m/%d} - %{y}<extra></extra>`,
          type: 'scatter',
          mode: 'lines+markers',
          marker: {color: GRAPH_COLORS[chart] || '#214B9B'},
        };
      });
    }
  }, [chart_history]);

  const {slope, rank, chartName} = useMemo(() => getRankAndSlope(trend, chart), [trend, chart]);

  const range = useMemo(() => {
    let oneMonth = moment().add(-1, 'month');
    let firstRangeDate = moment(charts[0].x[0]).unix() < oneMonth.unix() ? oneMonth.toDate() : moment(charts[0].x[0]).toDate();
    return [firstRangeDate, new Date()];
  }, [charts]);

  const allRange = useMemo(() => {
    return [charts[0].x[0], new Date()];
  }, [charts]);

  const locationOptions = useMemo(() => {
    return (Object.keys(locationMap) || []).map(location => {
      let currentRank = locationMap[location][0].current_rank;
      let rank = currentRank ? `#${currentRank}` : "N/A";
      return {value: location, label: `${location} (${rank})`}
    });
  }, [locationMap]);

  const genreOptions = useMemo(() => {
    return (locationMap[selectedLocation] || []).map(trend => {
      let rank = trend.current_rank ? `#${trend.current_rank}` : "N/A";
      let label = `${trend.genre} (${rank})`
      return {value: trend.genre, label}
    })
  }, [locationMap, selectedLocation]);

  useEffect(() => {
    if (!selectedGenre && genreOptions.length > 0)
      setSelectedGenre((genreOptions[0].value));
  }, [selectedGenre, genreOptions]);

  function onChangeLocation(formik, event) {
    let location = event.target.value;
    setSelectedLocation(location);
    let genre = locationMap[location][0].genre;
    formik.setFieldValue("genre", genre);
    track('detailsPanel-game.trend-change', {game_id: trend.game_id, location, genre});
  }

  function onChangeGenre(formik, event) {
    let genre = event.target.value;
    setSelectedGenre(genre);
    track('detailsPanel-game.trend-change', {game_id: trend.game_id, location: selectedLocation, genre});
  }

  let displayGenre = selectedGenre === "All" ? "Overall" : selectedGenre;

  return (
    <ErrorBoundary fallback="An error has occurred">
      <div className="trend-info mt-2 d-flex flex-column">
        <div className="info">
          <Formik
            key={selectedGenre + selectedLocation}
            initialValues={{genre: selectedGenre, location: selectedLocation}}
          >
            {(formik) => (
              <Form>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item md={6}>
                    <div className="info-element">
                      <span className="info-title">Country</span>
                      <span className="info-content">
                        <FormikSelectField
                          className="white"
                          name="location"
                          options={locationOptions}
                          onChange={(event) => onChangeLocation(formik, event)}
                          fullWidth
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="info-element">
                      <span className="info-title">Genre</span>
                      <span className="info-content">
                        <span className="info-content">
                        <FormikSelectField
                          className="white"
                          name="genre"
                          options={genreOptions}
                          onChange={(event) => onChangeGenre(formik, event)}
                          fullWidth
                        />
                      </span>
                      </span>
                    </div>
                  </Grid>
                  <ShowIf condition={!!peak_rank}>
                    <Grid item md={6}>
                      <div className="info-element">
                        <span className="info-title">Peak Rank</span>
                        <div className="info-content d-flex flex-column">
                          <span>{peak_rank}</span>
                          <span className="info-small">{displayGenre}, {peak_chart}</span>
                        </div>
                      </div>
                    </Grid>
                  </ShowIf>
                  <Grid item md={6}>
                    <div className="info-element">
                      <span className="info-title">Current Rank</span>
                      <div className="info-content d-flex flex-column">
                        <span>{rank || "None"}</span>
                        <ShowIf condition={!!rank}>
                          <span className="info-small">{displayGenre}, {chartName}</span>
                        </ShowIf>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="info-element">
                      <span className="info-title">Chart Days</span>
                      <span className="info-content">{chart_days}</span>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="info-element">
                      <span className="info-title">Position Change/Day</span>
                      <div className="info-content d-flex flex-column">
                        <ShowIf condition={slope !== null}>
                          <span className="d-flex">
                            <SlopeIcon slope={slope} className="mr-2 font-size-xxxxxl"/>
                            {slope}
                          </span>
                          <span className="info-small">{displayGenre}, {chartName}</span>
                        </ShowIf>
                        <ShowIf condition={slope === null}>
                          N/A
                        </ShowIf>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <ShowIf condition={!!charts}>
            <Grid container justifyContent="center" spacing={1}>
              <Plot
                key={charts}
                data={charts}
                layout={{
                  font: {
                    size: 16,
                    family: 'Bison',
                    color: '#214B9B',
                  },
                  displayModeBar: false,
                  width: 350,
                  height: 400,
                  margin: {
                    l: 30, r: 30, b: 20, t: 40, pad: 0
                  },
                  xaxis: {
                    rangeselector: selectorOptions,
                    rangeslider: {
                      allRange
                    },
                    range
                  },
                  yaxis: {
                    autorange: 'reversed',
                    fixedrange: true,
                  },
                  showlegend: true,
                  legend: {orientation: "h"},
                  plot_bgcolor: '#B4C9E8',
                  paper_bgcolor: '#B4C9E8',
                }}
              />
            </Grid>
          </ShowIf>
        </div>
      </div>
    </ErrorBoundary>
  )
});

const Description = ({game}) => {

  const [expanded, setExpanded] = useState(false);
  let {description, description_clean, summary} = game;
  let chosenDescription = description_clean || description;

  const maxHeight = expanded ? "2000px" : "115px";

  return (
    <ShowIf condition={!!chosenDescription}>
      <div className="description" style={{maxHeight}}>
        <ShowIf condition={expanded}>
          <AnnotatedDescription game={game}/>
        </ShowIf>
        <ShowIf condition={!expanded}>
          {summary || description || chosenDescription}
        </ShowIf>
      </div>
      <span className="expand" onClick={() => setExpanded(!expanded)}>
        <ShowIf condition={!expanded}>
            Show full description <ExpandMoreIcon/>
        </ShowIf>
        <ShowIf condition={expanded}>
            Show summary <ExpandLessIcon/>
        </ShowIf>
        </span>
    </ShowIf>
  );
};

const Header = ({game, onNext, onPrevious}) => {

    const {showImage} = useContext(DetailsPanelContext);

    let {
      user_rating,
      user_rating_count,
      header = DEFAULT_OBJECT,
      icon = DEFAULT_OBJECT,
      screenshots = DEFAULT_ARRAY
    } = game;

    let ratingsText = `${Number(user_rating_count || 0).toLocaleString()} ratings`;

    if (!header?.url) {
      header = (screenshots[0] || {});
    }

    let style = {};
    let headerClassName = "header";
    if (header?.url) {
      header.url = convertProxyUrl(header);
      headerClassName += " cover-header";
      style = {background: `linear-gradient(rgba(19,22,72,0.5) 0%, rgba(19,22,72,0.8) 100%), url(${header.url})`};
    }

    function onIconClick() {
      let icon = (game.icons || [])[0] || game.icon;
      showImage({...icon, icon: true, game_id: game._id});
    }

    return (
      <div className={headerClassName} style={style}>
        <div className="image-wrapper">
          <ShowIf condition={!!onPrevious}>
            <div className="previous-game">
              <IconButton onClick={onPrevious}>
                <ChevronLeft
                  className="font-size-xxxxxxl text-white"
                />
              </IconButton>
            </div>
          </ShowIf>
          <ShowIf condition={!!onNext}>
            <div className="next-game">
              <IconButton onClick={onNext}>
                <ChevronRight
                  className="font-size-xxxxxxl text-white"
                />
              </IconButton>
            </div>
          </ShowIf>
          <ShowIf condition={!!icon?.url}>
            <GameIcon game={game} onClick={onIconClick}/>
          </ShowIf>
        </div>
        <div className="d-flex flex-column text-align-center rating">
          <ShowIf condition={user_rating > 0 || user_rating_count > 0}>
            <span>{ratingsText}</span>
            <Rating
              value={user_rating}
              size="small"
              precision={0.1}
              readOnly={true}
            />
          </ShowIf>
        </div>
        <div className="text-align-center title">
          {game.title}
        </div>
        <ShowIf condition={!!game.short_description}>
          <div className="text-align-center subtitle px-3">
            {game.short_description}
          </div>
        </ShowIf>
      </div>
    )
  }
;

const Actions = ({game}) => {

  const {track} = useContext(SocketContext);

  function trackWrapper(event, action) {
    track(action, {game});
  }

  return (
    <div className="actions">
      <Grid container justifyContent="space-evenly" spacing={0}>

        <Grid item className="text-align-center" md={2}>
          <span className="actions-content">
              <GameFavoriteButton game={game}/>
              <span className="small">Favorites</span>
          </span>
        </Grid>
        <Grid item className="text-align-center" md={2}>
          <span className="actions-content">
            <Tooltip
              title="Generate based on this game"
              arrow
              PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
              placement="top"
            >
            <Link
              to={{
                pathname: "/game-ideator",
                state: {data: {game}}
              }}
              onClick={event => trackWrapper(event, 'details-panel.game.generate')}
            >
              <ListItemIcon>
                <IconButton component="span">
                <WbIncandescentOutlined className="flip-vertical font-size-lg pointer"/>
                </IconButton>
              </ListItemIcon>
            </Link>
            </Tooltip>
            <span className="small">Generate</span>
          </span>
        </Grid>
        <Grid item className="text-align-center" md={3}>
          <span className="actions-content">
            <Tooltip
              title="Search similar games"
              arrow
              PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
              placement="top"
            >
            <Link
              to={{
                pathname: "/search",
                state: {data: {game}}
              }}
              onClick={event => trackWrapper(event, 'details-panel.game.search-similar', true)}
            >
              <ListItemIcon>
                <IconButton component="span">
                <SearchOutlined/>
                </IconButton>
              </ListItemIcon>
            </Link>
            </Tooltip>
            <span className="small">
              Search similar
            </span>
          </span>
        </Grid>
        <Grid item className="text-align-center" md={2}>
          <ShareIcon data={{game}}/>
        </Grid>
      </Grid>
    </div>
  )
};

const REPLACE_MAP = {
  'Casual Play': 'Casual',
}

const Genres = ({game}) => {
  let {augmented_genres, genres} = game;
  let chosenGenres = useMemo(() => {
    return _.uniq((augmented_genres || genres || DEFAULT_ARRAY)
      .map(genre => REPLACE_MAP[genre] || genre));
  }, [augmented_genres, genres]);
  return (
    <ShowIf condition={chosenGenres.length > 0}>
      <div className="genres text-align-center">{chosenGenres.map(genre => <Chip
        className="text-white mr-2 font-size-md"
        key={genre}
        size="small"
        label={genre}
      />)}</div>
    </ShowIf>
  )
};

const Info = ({game}) => {

  const {showDeveloper} = useContext(DetailsPanelContext);

  let {release_date, update_date, developers = []} = game;
  let releaseDateText = moment.unix(release_date).fromNow();
  let updateDateText = moment.unix(update_date).fromNow();

  function onClickDeveloper() {
    showDeveloper(game.developers[0], game.source);
  }

  return (
    <>
      <div className="info-stats text-align-center">
        <Grid container justifyContent="space-evenly">
          <Grid item md={4}>
            <span className="stats-title font-weight-bold">Developer</span>
            <span className="info-content developer link" onClick={onClickDeveloper}>{(developers || [])[0]}</span>
          </Grid>
          <ShowIf condition={!!release_date}>
            <Grid item md={4}>
              <span className="stats-title font-weight-bold">Released</span>
              <span className="info-content">{releaseDateText}</span>
            </Grid>
          </ShowIf>
          <ShowIf condition={!!update_date}>
            <Grid item md={4}>
              <span className="stats-title font-weight-bold">Updated</span>
              <span className="info-content">{updateDateText}</span>
            </Grid>
          </ShowIf>
        </Grid>
      </div>
      <Divider className="w-100"/>
    </>
  );
};

export default GameDetailsPanel;
