import React, {useContext} from "react";
import CacheContext from "context/CacheContext";
import {IconButton, ListItemIcon, ListItemText, Tooltip} from "@material-ui/core";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import {GDDModal} from "pages/GDD3/Helpers";
import {ShareToSlackButton} from "components/Sharing/ShareToSlack";
import SocketContext from "context/SocketContext";

export const ShareIcon = ({data}) => {
  const {cache, setCacheValue} = useContext(CacheContext);
  const {selectedProjectId} = cache;

  async function onClick() {
    setCacheValue('shareOpen', {data, project: selectedProjectId});
  }

  return (
    <span className="actions-content" onClick={onClick}>
      <Tooltip
        title="Share"
        arrow
        PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
        placement="top"
      >
        <div>
          <ListItemIcon>
            <IconButton component="span">
              <ShareOutlinedIcon/>
            </IconButton>
          </ListItemIcon>
          </div>
      </Tooltip>
      <span className="small text-white">
        Share
      </span>
  </span>
  )
}

export const ShareContextMenu = ({data, trackId, trackOptions}) => {
  const {track} = useContext(SocketContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const {selectedProjectId} = cache;

  async function onClick() {
    if (trackId) track(trackId, trackOptions)
    setCacheValue('shareOpen', {data, project: selectedProjectId});
  }

  return (
    <div onClick={onClick} style={{display: "contents"}}>
      <ListItemIcon>
        <ShareOutlinedIcon/>
      </ListItemIcon>
      <ListItemText primary="Share"/>
    </div>
  )
}

export const ShareBox = () => {

  const {cache, setCacheValue} = useContext(CacheContext);
  const {shareOpen} = cache;

  function onClose() {
    setCacheValue('shareOpen', null);
  }

  return (
    <GDDModal title="Share this content" open={!!shareOpen} onClose={onClose}>
      <div className="px-4 m-auto">
        <ShareToSlackButton data={shareOpen} onClose={onClose}/>
      </div>
    </GDDModal>
  );
}
