import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import App from "./App";
import 'assets/base.scss';
import {Provider} from 'react-redux';
import configureStore from './configureStore';
import {SnackbarProvider} from "notistack";

const store = configureStore();

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <App/>
        </SnackbarProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default AppWrapper;
