import React, { useState, useContext, useEffect, useRef } from "react";
import APIContext from "../../context/APIContext";
import "./game-guide.scss";
import { IconPlaceholder } from "./GDD4Menu";
import {
  ArrowForwardOutlined,
  ChevronRightOutlined,
  ExpandMoreOutlined,
  InfoOutlined,
  PrecisionManufacturingOutlined,
  QuestionAnswerOutlined,
  SchoolOutlined,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { CircularProgress } from "@material-ui/core";
import GDDContext from "../../context/GDDContext";
import Chat from "../../components/common/Chat";
import SocketContext from "../../context/SocketContext";

const DEFAULT_PROMPT =
  "Guide me through the complete development process of game, from initial setup to final optimization";

const SCREENS = {
  LOADING: "LOADING",
  TOPICS: "TOPICS",
  SUBTOPIC: "SUBTOPIC",
  GUIDES: "GUIDES",
  SELECTOR: "SELECTOR",
};

const ENGINES = [
  { id: "unity", name: "Unity" },
  { id: "cocos2d", name: "Cocos2d-x" },
  { id: "buildbox", name: "Buildbox" },
  { id: "solar2d", name: "Solar2D" },
  { id: "godot", name: "Godot" },
  { id: "unreal", name: "Unreal" },
  { id: "roblox", name: "Roblox" },
];

const LEVELS = [
  {
    id: "novice",
    title: "Beginner",
    icon: <SchoolOutlined />,
    description:
      "New to game development. Learn core concepts and basic implementations.",
    features: [
      "Detailed explanations of basic concepts",
      "Step-by-step guidance",
      "More code comments and examples",
      "Focus on fundamental patterns",
    ],
  },
  {
    id: "expert",
    title: "Advanced",
    icon: <PrecisionManufacturingOutlined />,
    description:
      "Experienced developer seeking advanced techniques and optimizations.",
    features: [
      "Advanced optimization strategies",
      "Complex system architectures",
      "Edge cases and performance tuning",
      "Platform-specific considerations",
    ],
  },
];

const GameGuide = ({ projectId }) => {
  const { track } = useContext(SocketContext);
  const { call, loading } = useContext(APIContext);
  const [content, setContent] = useState(null);
  const [values, setValues] = useState(undefined);
  const [expandedTopic, setExpandedTopic] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [allGuides, setAllGuides] = useState([]);

  const [screen, setScreen] = useState(SCREENS.LOADING);

  useEffect(() => {
    trackWrapper("change-screen", { screen });
  }, [screen]);

  function trackWrapper(action, payload) {
    track(`guide.${action}`, payload);
  }

  async function fetchContent(
    action,
    context = {},
    currentValues = values,
    regenerate = false
  ) {
    let data = {
      game_concept_id: projectId,
      action,
      skill_level: currentValues.skillLevel,
      engine: currentValues.engine,
      prompt: currentValues.prompt,
      ...context,
    };
    trackWrapper(action, data);
    const response = await call("getGuideContent", {
      regenerate,
      data,
    });

    if (response?.ok) {
      setContent(response.body);
      return response.body;
    }
  }

  async function onSelectValues(values) {
    setValues(values);
    setContent(null);
    setSelectedStep(null);
    setExpandedTopic(null);
    setScreen(SCREENS.TOPICS);
    let guide = await fetchContent("VIEW_TOPICS", undefined, values, true);
    if (guide) {
      let guides = await getGuideList(projectId);
      if (guides?.length > 0) {
        setAllGuides(guides);
      }
    }
  }

  async function getGuideList(projectId) {
    let response = await call("getProjectGuideContent", { projectId });
    if (response?.ok) {
      return response.body;
    }
  }

  useEffect(() => {
    getGuideList(projectId).then((guides) => {
      setAllGuides(guides);
      if (guides?.length > 0) {
        onSelectGuide(guides[0]);
      } else {
        setScreen(SCREENS.SELECTOR);
      }
    });
  }, [projectId]);

  function onSelectGuide(guide) {
    if (guide) {
      const values = {
        skillLevel: guide.skill_level,
        engine: guide.engine,
        prompt: guide.prompt,
      };
      setExpandedTopic(null);
      setSelectedStep(null);
      setValues(values);
      setContent(guide);
      setScreen(SCREENS.TOPICS);
      fetchContent("VIEW_TOPICS", undefined, values).catch(console.log);
    } else {
      setScreen(SCREENS.SELECTOR);
    }
  }

  const renderTopicsList = () => {
    const categories = JSON.parse(content.content_json)?.categories;
    if (!Array.isArray(categories)) return null;
    return (
      <div className="topics-view">
        {categories?.map((category, i) => (
          <div key={i} className="category">
            <h2>{category.title}</h2>
            <p>{category.description}</p>
            <div className="topics">
              {category.topics.map((topic, j) => (
                <div key={j} className="topic-container">
                  <input
                    type="checkbox"
                    id={`topic-${topic.id}`}
                    checked={expandedTopic === topic.id}
                    onChange={() =>
                      setExpandedTopic(
                        expandedTopic === topic.id ? null : topic.id
                      )
                    }
                  />
                  <label className="topic-header" htmlFor={`topic-${topic.id}`}>
                    <div className="topic-info">
                      <div className="topic-meta">
                        <h3>{topic.title}</h3>
                        <p>{topic.description}</p>
                      </div>
                    </div>
                    <div className="meta">
                      <span className="topics-count">
                        {topic.steps.length} steps
                      </span>
                    </div>
                    <div className="expand-icon">
                      <ExpandMoreOutlined />
                    </div>
                  </label>

                  {expandedTopic === topic.id && (
                    <div className="topic-steps">
                      <div className="steps">
                        {topic.steps.map((step, i) => (
                          <div
                            key={i}
                            className="step"
                            onClick={() => {
                              setSelectedStep(step.id);
                              fetchContent("VIEW_SUBTOPIC", {
                                current_topic_id: topic.id,
                                current_subtopic_id: step.id,
                              });
                            }}
                          >
                            <div className="step-number">{i + 1}</div>
                            <div className="step-content">
                              <h4>{step.title}</h4>
                              <p>{step.summary}</p>
                            </div>
                            <ArrowForwardOutlined />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderSubtopicContent = () => {
    const section = JSON.parse(content.content_json);
    return (
      <div className="subtopic-detail">
        <div className="navigation">
          <button
            onClick={() => {
              setSelectedStep(null);
              fetchContent("VIEW_TOPICS");
            }}
          >
            ↑ Back to Topics
          </button>

          <div className="nav-buttons">
            {content.previous_step && (
              <button
                onClick={() => {
                  setSelectedStep(content.previous_step.id);
                  fetchContent("VIEW_SUBTOPIC", {
                    current_topic_id: content.topic_id,
                    current_subtopic_id: content.previous_step.id,
                  });
                }}
              >
                ← Previous: {content.previous_step.title}
              </button>
            )}

            {content.next_step && (
              <button
                onClick={() => {
                  setSelectedStep(content.next_step.id);
                  fetchContent("VIEW_SUBTOPIC", {
                    current_topic_id: content.topic_id,
                    current_subtopic_id: content.next_step.id,
                  });
                }}
              >
                Next: {content.next_step.title} →
              </button>
            )}
          </div>
        </div>

        <div className="content">
          <div className="content-header">
            <h1>{section.title}</h1>
            <div className="topic-context">
              Part of: <span>{content.topic_title}</span>
              <div className="step-progress">
                Step {content.current_step} of {content.total_steps}
              </div>
            </div>
          </div>

          <div className="main-content">
            <ReactMarkdown
              components={{ code: CodeBlock }}
              children={section.content}
            />
          </div>

          <ChatPrompt section={section} />
        </div>
      </div>
    );
  };

  if (screen === SCREENS.LOADING) {
    return (
      <div className="text-align-center m-4 opacity-5">
        <CircularProgress size={150} />
      </div>
    );
  }

  if (screen === SCREENS.GUIDES) {
    return <AllGuides guides={allGuides} onSelectGuide={onSelectGuide} />;
  }

  if (screen === SCREENS.SELECTOR) {
    return (
      <SkillLevelSelector
        onSelect={onSelectValues}
        onCancel={() => setScreen(SCREENS.TOPICS)}
        defaultValues={values}
      />
    );
  }

  return (
    <div className="main-wrapper responsive-styles">
      {values && (
        <div className="top-bar">
          <div className="skill-level-indicator">
            <div className="current-level">
              <span className="label">Experience Level: </span>
              <span className="text-capitalize bold">{values.skillLevel}</span>
              <span className="label">Engine: </span>
              <span className="text-capitalize bold">
                {ENGINES.find(({ id }) => id === values.engine)?.name}
              </span>
              <div className="prompt-indicator">
                <InfoOutlined className="info-icon" />
                <div className="prompt-tooltip">
                  <div className="tooltip-title">Current Prompt</div>
                  <div className="tooltip-content">
                    {values.prompt || DEFAULT_PROMPT}
                  </div>
                </div>
              </div>
            </div>
            <button
              className="reset-level blue"
              onClick={() => setScreen(SCREENS.SELECTOR)}
            >
              Start New Guide
            </button>
            {allGuides.length > 1 && (
              <button
                className="reset-level"
                onClick={() => setScreen(SCREENS.GUIDES)}
              >
                View All Guides
              </button>
            )}
          </div>
        </div>
      )}
      {loading.getGuideContent ? (
        <LoadingState type={selectedStep ? "subtopic" : "topics"} />
      ) : !content ? null : selectedStep ? (
        renderSubtopicContent()
      ) : (
        renderTopicsList()
      )}
    </div>
  );
};

const ChatPrompt = ({ section }) => {
  const [resetKey, setResetKey] = useState(0);
  return (
    <div className="ask-question">
      <div className="question-prompt">
        <div className="prompt-header">
          <div className="icon">
            <QuestionAnswerOutlined />
          </div>
          <div className="text">
            <h3>
              Have questions about <i>{section.title}</i>?
            </h3>
            <p>
              Chat with Ludo Assistant for help with implementation,
              troubleshooting, or best practices.
            </p>
          </div>
        </div>
        <Chat
          key={resetKey}
          onStartNewChat={() => setResetKey(resetKey + 1)}
          component={{ section: "chat" }}
          section="chat"
          includeFollowUps={false}
          guide_page={section?.content}
        />
      </div>
    </div>
  );
};

const CodeBlock = ({ node, children }) => {
  let className = node?.properties?.className?.[0] || "";
  className = className.replace("language-", "");
  return <code className={className}>{children}</code>;
};

const AllGuides = ({ guides, onSelectGuide }) => {
  return (
    <div className="all-guides">
      <div className="guides-header">
        <h2>Your Development Guides</h2>
        <div className="header-actions">
          <button
            className="create-guide-button"
            onClick={() => onSelectGuide(null)}
          >
            Start New Guide
          </button>
        </div>
      </div>

      <div className="guides-list">
        {guides.map((guide) => (
          <div
            key={guide._id}
            className="guide-item"
            onClick={() => onSelectGuide(guide)}
          >
            <div className="guide-main-info">
              <div className="guide-icon">
                {guide.skill_level === "novice" ? (
                  <SchoolOutlined />
                ) : (
                  <PrecisionManufacturingOutlined />
                )}
              </div>
              <div className="guide-details">
                <div className="guide-meta">
                  <span className="skill-level text-capitalize">
                    {guide.skill_level}
                  </span>
                  <span className="engine">
                    {ENGINES.find((e) => e.id === guide.engine)?.name}
                  </span>
                </div>
                <div className="guide-prompt">
                  {guide.prompt || DEFAULT_PROMPT}
                </div>
                <div className="guide-dates">
                  <span>
                    Created: {new Date(guide.date * 1000).toLocaleDateString()}
                  </span>
                  <span>
                    Last viewed:{" "}
                    {new Date(guide.date_last_view * 1000).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
            <ChevronRightOutlined />
          </div>
        ))}
      </div>
    </div>
  );
};

const SkillLevelSelector = ({ onSelect, defaultValues, onCancel }) => {
  const [values, setValues] = useState(
    defaultValues || { skillLevel: "novice", engine: "unity", prompt: "" }
  );

  const handleSubmit = () => {
    onSelect(values);
  };

  return (
    <div className="skill-level-selector">
      <h2>Customize Your Guide</h2>

      <div className="setup-section">
        <h3>1. Choose Your Experience Level</h3>
        <p className="subtitle">
          Select the difficulty level that best matches your game development
          experience
        </p>
        <div className="levels">
          {LEVELS.map((level) => (
            <div
              key={level.id}
              className={`level-card ${
                values.skillLevel === level.id ? "selected" : ""
              }`}
              onClick={() =>
                setValues((prevState) => ({
                  ...prevState,
                  skillLevel: level.id,
                }))
              }
            >
              <div className="level-header">
                <div className="icon">{level.icon}</div>
                <h3>{level.title}</h3>
              </div>
              <p className="description">{level.description}</p>
              <ul className="features">
                {level.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="setup-section">
        <h3>2. Select Game Engine</h3>
        <div className="engine-selector">
          {ENGINES.map((engine) => (
            <button
              key={engine.id}
              className={`engine-button ${
                values.engine === engine.id ? "selected" : ""
              }`}
              onClick={() =>
                setValues((prevState) => ({
                  ...prevState,
                  engine: engine.id,
                }))
              }
            >
              {engine.name}
            </button>
          ))}
        </div>
      </div>

      <div className="setup-section">
        <h3>3. Customize Your Guide (Optional)</h3>
        <div className="prompt-input">
          <textarea
            value={values.prompt}
            onChange={(e) =>
              setValues((prevState) => ({
                ...prevState,
                prompt: e.target.value,
              }))
            }
            placeholder={DEFAULT_PROMPT}
            rows={4}
          />
          <p className="hint">
            Describe what specific aspects of game development you'd like to
            focus on. Leave empty for a complete development guide.
          </p>
        </div>
      </div>

      <div className="button-group">
        {defaultValues && (
          <button className="cancel-button" onClick={onCancel}>
            Go Back
          </button>
        )}
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={!values.skillLevel || !values.engine}
        >
          Generate New Development Guide
        </button>
      </div>
    </div>
  );
};

const LoadingState = ({ type = "topics" }) => {
  if (type === "topics") {
    return (
      <div className="topics-view loading">
        {[1, 2].map((category) => (
          <div key={category} className="category loading">
            <div className="skeleton title" />
            <div className="skeleton description" />

            <div className="topics">
              {[1, 2, 3].map((topic) => (
                <div key={topic} className="topic-container loading">
                  <div className="topic-header">
                    <div className="topic-info">
                      <div className="skeleton title" />
                      <div className="skeleton description" />
                      <div className="skeleton meta" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (type === "subtopic") {
    return (
      <div className="subtopic-detail loading">
        <div className="navigation">
          <div className="skeleton back-button" />
          <div className="nav-buttons">
            <div className="skeleton nav-button" />
            <div className="skeleton nav-button" />
          </div>
        </div>

        <div className="content">
          <div className="content-header">
            <div className="skeleton title" />
            <div className="skeleton topic-context" />
          </div>

          <div className="main-content">
            {[1, 2, 3].map((line) => (
              <div key={line} className="skeleton text-line" />
            ))}
          </div>

          <div className="learning-points">
            <div className="key-points loading">
              <div className="skeleton title" />
              {[1, 2, 3].map((point) => (
                <div key={point} className="skeleton list-item" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const GameGuideHeader = ({ gdd }) => {
  const projectName = gdd.sections[0]?.value?.title;
  const gameGenre = gdd.sections[0]?.value?.genre;

  return (
    <div className="game-guide-header main-header">
      <div className="icon">
        <IconPlaceholder icon={gdd.icon} />
      </div>
      <div className="description gradient-text2">
        Interactive Development Guide for{" "}
        <i>
          <b>{projectName}</b>
        </i>
        <div className="subtitle">
          Step-by-step guidance to build your {gameGenre} game, from basic setup
          to advanced features
        </div>
      </div>
    </div>
  );
};

export default GameGuide;
