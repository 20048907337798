import React from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  expand: {
    padding: 0,
    display: "inline-block",
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    height: "25px",
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const ExpandIcon = ({expanded, setExpanded, style}) => {
  const classes = useStyles();
  return (
    <IconButton
      style={style}
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      aria-expanded={expanded}
      aria-label="show more"
      onClick={() => setExpanded(!expanded)}
    >
      <ExpandMoreIcon/>
    </IconButton>
  )
};


export default ExpandIcon;
