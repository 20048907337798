import React from 'react';
import ludoConfused from "assets/images/ludo-confused.png";

const NoResults = () => {
  return (
    <div className="no-results d-flex flex-column">
      <div className="p-5 ml-5 d-flex flex-column">
        <span className="font-weight-bold font-size-xl text-primary">Oops! Ludo's dig came up empty...</span>
        <span className="font-weight-bold font-size-lg text-secondary mt-3">Please, modify your criteria and try again.</span>
      </div>
      <img
        width="150"
        alt="Ludo"
        className="d-block"
        src={ludoConfused}
      />
    </div>
  )
};


export default NoResults;
