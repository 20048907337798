import React, { useContext, useMemo, useEffect } from "react";
import ShowIf from "components/common/ShowIf";
import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import "./style.scss";
import SocketContext from "context/SocketContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import {
  ArrowForwardIosOutlined,
  AutoAwesomeOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import GameSummaryMenu from "pages/GDD3/GDDSideMenu/GameSummaryMenu";
import GamesMenu from "pages/GDD3/GDDSideMenu/GamesMenu";
import GameElementMenu from "pages/GDD3/GDDSideMenu/GameElementsMenu";
import MoodboardMenu from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import GDDContext from "context/GDDContext";
import HeaderMenu from "pages/GDD3/GDDSideMenu/HeaderMenu";
import Chat from "components/common/Chat";
import CacheContext from "context/CacheContext";
import { Hint } from "scenes/Headquarters";

const GDDSideMenu = ({ addNewComponent, gdd }) => {
  const { track } = useContext(SocketContext);
  const { menu } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  function onClickedComponent(component) {
    track("gdd.menu.clicked", { section: component?.section });
    if (component.multiple_allowed)
      component = { ...component, id: PerformanceUtils.generateId() };
    addNewComponent(component, menu?.section);
  }

  const component = menu?.component;
  const option = menu?.option;
  let className = "gdd-side-menu-3";
  if (option) className += ` option-${option}`;

  const section = useMemo(() => {
    return gdd.sections.find(({ id, name }) => id === menu.section || name === menu.section);
  }, [gdd, menu]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  },[menu]);

  return (
    <ShowIf condition={!!component}>
      <div className={className}>
        <ShowIf condition={component?.section === "index"}>
          <IndexSection
            component={component}
            setComponent={onClickedComponent}
            gdd={gdd}
          />
        </ShowIf>
        <ShowIf condition={component?.section === "chat" || component?.noMenu}>
          <div className="menu-section">
            <MenuTopBar />
            <div className="menu-section-content">
              <Chat component={component} section={menu?.section} element={undefined} sectionValue={section} ludo_score={menu?.ludo_score} ludo_score_metric={menu?.ludo_score_metric} ludo_score_label={menu?.ludo_score_label} />
            </div>
          </div>
        </ShowIf>
        <ShowIf
          condition={component?.section === gddComponents?.summary?.section}
        >
          <GameSummaryMenu component={component} gdd={gdd} />
        </ShowIf>
        <ShowIf
          condition={
            component?.section === gddComponents?.moodboard?.section ||
            component?.section === gddComponents?.images?.section
          }
        >
          <MoodboardMenu component={component} gdd={gdd} />
        </ShowIf>

        <ShowIf condition={component?.section === gddComponents?.header?.section}>
          <HeaderMenu component={component} gdd={gdd} />
        </ShowIf>

        <ShowIf
          condition={
            component?.section === gddComponents?.games?.section ||
            component?.section === gddComponents?.trending?.section
          }
        >
          <GamesMenu component={component} gdd={gdd} />
        </ShowIf>
        <ShowIf condition={!!component?.gameElements}>
          <GameElementMenu
            key={component?.section + menu?.option}
            component={component}
            gdd={gdd}
          />
        </ShowIf>
      </div>
    </ShowIf>
  );
};

export const MenuTopBar = ({ overrideTitle }) => {
  const { menu, closeMenu } = useContext(GDDContext);
  const title =
    overrideTitle ||
    `${
      menu?.subSection ||
      menu?.component?.label ||
      ((menu?.option || "").toLowerCase().includes("chat")
        ? "Ludo AI Chat Assistant"
        : undefined)
    }`;
  return (
    <div className="menu-top-bar">
      <span className="title text-capitalize gradient-text">{title}</span>
      <div className="handle" />
      <IconButton onClick={closeMenu} className="close-menu-button">
        <CloseOutlined className="font-size-xxxxxl pointer" />
      </IconButton>
    </div>
  );
};

function isDisabled(component, gdd) {
  let disabled = false;
  let { multiple_allowed, gameElements } = component;
  if (!multiple_allowed || !!gameElements) {
    return (gdd?.sections || []).find(({ name }) => name === component.section);
  }
  return disabled;
}

const IndexSection = ({ setComponent, component, gdd }) => {
  const { closeMenu, suggestions } = useContext(GDDContext);
  const { cache } = useContext(CacheContext);
  const { componentGroups, gddComponents } = cache;

  const groups = useMemo(() => {
    return componentGroups
      .map((group) => {
        return {
          ...group,
          components: group.components.filter((component) => !component.hide),
        };
      })
      .filter((group) => group.components.length > 0);
  }, [componentGroups]);

  return (
    <div className="menu-section index">
      <MenuTopBar onClose={closeMenu} title={component.label} />
      <div className="menu-section-content">
        <span className="secondary-title">
          <AutoAwesomeOutlined className="font-size-lg mr-2" /> Suggestions{" "}
          <Hint
            hint="Ludo's suggestions of the most relevant sections for your game concept"
            iconClassName="help"
          />
        </span>
        {!suggestions?.length && (
          <span className="suggestions-loading">
            <CircularProgress />
          </span>
        )}
        {suggestions?.length > 0 && (
          <>
            <Grid container>
              {suggestions.map((component) => (
                <Grid item md={4} key={component.section}>
                  <Tooltip
                    title={component.description}
                    placement="top"
                    PopperProps={{
                      disablePortal: true,
                      className:
                        "MuiTooltip-popper MuiTooltip-popperArrow secondary max-width",
                    }}
                  >
                    <div>
                      <SectionIcon
                        label={component.label}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setComponent(component);
                        }}
                        disabled={isDisabled(component, gdd)}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <hr className="light-hr" />
        <span className="secondary-title">Add Standard Sections</span>
        <Grid container>
          {Object.values(gddComponents)
            .filter((component) => !component.hide && !component.gameElements)
            .map((component) => (
              <Grid item md={4}>
                <SectionIcon
                  label={component.label}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setComponent(component);
                  }}
                  disabled={isDisabled(component, gdd)}
                />
              </Grid>
            ))}
        </Grid>
        <hr className="light-hr" />
        {groups.map((group) => {
          return (
            <React.Fragment key={group.name}>
              <span className="secondary-title">
                {group.title}{" "}
                <Hint hint={group.description} iconClassName="help" />
              </span>
              <Grid container>
                {group.components.map((component) => (
                  <Grid item md={4} key={component.section}>
                    <Tooltip
                      title={component.description}
                      placement="top"
                      PopperProps={{
                        disablePortal: true,
                        className:
                          "MuiTooltip-popper MuiTooltip-popperArrow secondary max-width",
                      }}
                    >
                      <div>
                        <SectionIcon
                          label={component.label}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setComponent(component);
                          }}
                          disabled={isDisabled(component, gdd)}
                        />
                      </div>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const SectionIcon = ({ label, onClick, active, disabled }) => (
  <div
    className={
      "section-icon" + (active ? " active" : "") + (disabled ? " disabled" : "")
    }
    onClick={disabled ? undefined : onClick}
  >
    <span>{label}</span>
  </div>
);

export const MenuOptions = ({ section, onClick, options, visible }) => {
  const { track } = useContext(SocketContext);

  function onClickWrapper(value) {
    track("gdd.menu.clicked-option", { section, option: value });
    onClick(value);
  }

  return (
    <ShowIf condition={visible}>
      {Object.values(options).map((value) => (
        <MenuOption title={value} onClick={() => onClickWrapper(value)} />
      ))}
    </ShowIf>
  );
};

export const MenuOption = ({ onClick, title }) => (
  <div className="menu-option-title px-5">
    <div className="menu-option-title-content clickable" onClick={onClick}>
      <span>{title}</span>
      <ArrowForwardIosOutlined className="font-size-xxxl" />
    </div>
  </div>
);

export default GDDSideMenu;
