import React, {useContext, useMemo, useState} from "react";
import _ from "lodash";
import ShowIf from "components/common/ShowIf";
import moment from "moment";
import {Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import ExpandIcon from "components/common/ExpandIcon";
import {Form, Formik} from "formik";
import {FormikTextField} from "formik-material-fields";
import MyIconButton from "components/common/MyIconButton";
import AuthContext from "context/AuthContext";
import SocketContext from "context/SocketContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import CacheContext from "context/CacheContext";
import {FEATURE_UNAVAILABLE_MESSAGE} from "scenes/SubscriptionPage/Plans";
import {CommentOutlined, Delete, MoreVert, Send} from "@mui/icons-material";

const CommentsSection = ({component, gdd, changeGdd, section, value}) => {

  const {setCacheValue} = useContext(CacheContext);
  const {track} = useContext(SocketContext);
  const {auth} = useContext(AuthContext);

  const {subscription} = auth;

  let canUseComments = false;
  if (subscription && subscription.plan && (subscription.plan.metadata || {}).team === "true")
    canUseComments = true;

  const comments = useMemo(() => {
    return _.reverse(_.sortBy(value.comments || [], 'date'));
  }, [value.comments]);

  function sendComment(text, id) {
    if (!canUseComments) return showLockScreen();
    track('gdd.add-comment');
    let comment = {
      id: PerformanceUtils.generateId(),
      date: moment().unix(),
      text,
      author_email: auth.user.email
    }

    let comments = (value.comments || []);
    if (id) {
      comments = comments.map(mainComment => {
        if (mainComment.id === id) {
          mainComment.replies = mainComment.replies || [];
          mainComment.replies.push(comment);
        }
        return mainComment;
      })
    } else {
      comments = [...comments, comment];
    }

    let newValue = {
      ...(value || {}),
      comments
    }
    changeGdd(section, newValue, true);
  }

  function removeComment(commentId) {
    if (!canUseComments) return showLockScreen();
    track('gdd.remove-comment');
    let newValue = {
      ...value,
      comments: value?.comments?.filter(({id}) => id !== commentId)
    }
    changeGdd(section, newValue, true);
  }

  function showLockScreen() {
    setCacheValue('lockScreen', FEATURE_UNAVAILABLE_MESSAGE);
  }

  return (
    <div className="menu-section-content comments">
      <div className="pl-4 py-2">
        <div className="main-comment">
          <CommentTextField
            onSubmit={sendComment} placeholder="Submit comment" label="New Comment"/>
        </div>
        <ShowIf condition={comments.length > 0}>
          <div className="comments-list">
            {comments.map(comment => {
              return <Comment
                key={comment.id}
                comment={comment}
                reply={(text) => sendComment(text, comment.id)}
                remove={() => removeComment(comment.id)}
                gdd={gdd}
              />
            })}
          </div>
        </ShowIf>
      </div>
    </div>
  );
}

const Comment = ({comment, gdd, reply, remove, allowReply = true}) => {

  const [expanded, setExpanded] = useState(false);

  let date = moment.unix(comment.date).fromNow();
  let {author_email, replies = []} = comment;

  const showMore = replies.length > 0;
  let numberReplies = replies.length

  let className = "main-comment animate__animated animate__fadeIn";
  if (expanded) className += " expanded";
  if (showMore) className += " clickable";
  if (!allowReply) {
    className = "comment animate__animated animate__fadeIn";
  }

  function showReply() {
    setExpanded(true);
  }

  let expandClassName = "expand text-secondary";

  return (
    <div className={className} onClick={() => {
      if (!expanded && showMore) setExpanded(true);
    }}>
      <span className="author text-truncate">{author_email}</span>
      <span className="date">{date}</span>
      <span className="text pre">{comment.text}</span>
      <ShowIf condition={allowReply}>
        <CommentMenu
          comment={comment}
          deleteComment={remove}
          showReply={showReply}
        />
        <ShowIf condition={showMore}>
          <span className={expandClassName} onClick={() => setExpanded(!expanded)}>
          <ExpandIcon expanded={expanded} setExpanded={setExpanded}/>
            {!expanded ? `Show replies (${numberReplies})` : "Hide replies"}
          </span>
        </ShowIf>
        <ShowIf condition={expanded}>
          {replies.map(reply => {
            return (
              <div key={reply.id} style={{display: "contents"}}>
                <Divider className="w-100 mt-1"/>
                <Comment comment={reply} gdd={gdd} allowReply={false}/>
              </div>
            )
          })}
          <CommentTextField onSubmit={reply}/>
        </ShowIf>
      </ShowIf>
    </div>
  );
}

const CommentMenu = ({comment, deleteComment, showReply}) => {

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const {auth} = useContext(AuthContext);
  const {user} = auth;
  const isOwner = user.email === comment.author_email;

  function cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function openMenu(event) {
    cancelEvent(event);
    setMenuAnchorEl(event.currentTarget)
  }

  return (
    <div className="comment-menu text-primary" key={comment.id}>

      <div className="comment-actions">
        <ShowIf condition={isOwner}>
          <IconButton onClick={openMenu} component="span" className="flex">
            <MoreVert className="font-size-lg text-secondary"/>
          </IconButton>
        </ShowIf>
      </div>
      <ShowIf condition={!!menuAnchorEl}>
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={(event) => {
            cancelEvent(event);
            setMenuAnchorEl(null)
          }}
        >
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            showReply();
          }}>
            <ListItemIcon>
              <CommentOutlined/>
            </ListItemIcon>
            <ListItemText primary="Add reply"/>
          </MenuItem>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            deleteComment();
          }}>
            <ListItemIcon>
              <Delete/>
            </ListItemIcon>
            <ListItemText primary="Remove"/>
          </MenuItem>
        </Menu>
      </ShowIf>
    </div>
  )
}

const CommentTextField = ({onSubmit, placeholder = "Reply", label}) => {

  function onFormikSubmit(values, {resetForm}) {
    let {text} = values;
    if (text.length > 0) {
      onSubmit(text);
      resetForm();
    }
  }

  function onKeyDown(event, formik) {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  }

  return (
    <Formik
      initialValues={{text: ''}}
      onSubmit={onFormikSubmit}
    >
      {formik => (
        <Form>
          <div className="d-flex reply-wrapper">
            <FormikTextField
              label={label}
              name="text"
              size="small"
              InputLabelProps={{shrink: true}}
              fullWidth
              className="mt-2"
              multiline={true}
              placeholder={placeholder}
            />
            <button type="submit" name="go" onKeyDown={event => onKeyDown(event, formik)}>
              <MyIconButton
                iconElement={<Send className="ml2 text-secondary font-size-md"/>}
                title={placeholder}
              />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CommentsSection;
