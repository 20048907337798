import React, { useEffect } from "react";
import { debounce } from "lodash";
import { useFormikContext } from "formik";

const FormikPersist = ({
  debounceTime = 300,
  isSessionStorage = false,
  name,
  onLoad,
  onChangeValues,
}) => {
  const formik = useFormikContext();

  useEffect(() => {
    saveForm(formik);
  }, [formik]);

  useEffect(() => {
    if (onChangeValues) onChangeValues(formik.values);
  },[formik.values])

  useEffect(() => {
    let maybeState = isSessionStorage
      ? window.sessionStorage.getItem(name)
      : window.localStorage.getItem(name);
    if (!!maybeState) {
      maybeState = JSON.parse(maybeState);
      formik.setFormikState(maybeState);
    }
    if (!!onLoad) onLoad(maybeState || {}, formik);
  }, [name, isSessionStorage]);

  const saveForm = debounce((data) => {
    if (isSessionStorage) {
      window.sessionStorage.setItem(name, JSON.stringify(data));
    } else {
      window.localStorage.setItem(name, JSON.stringify(data));
    }
  }, debounceTime);

  return null;
};

export default FormikPersist;
