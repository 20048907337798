import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GameGridWithIds } from "pages/Favorites";
import ShowIf from "components/common/ShowIf";
import { useListener } from "react-bus";
import { Editable, FAIcon } from "pages/GDD3/Helpers";
import _ from "lodash";
import APIContext from "context/APIContext";
import { Formik, Form } from "formik";
import { InputLabel, MenuItem, Popper, Select } from "@material-ui/core";
import UniversalInput from "components/Controls/UniversalInput";
import SocketContext from "context/SocketContext";
import {
  AddOutlined,
  CloseOutlined,
  DeleteOutline,
  RoomOutlined,
} from "@mui/icons-material";
import { Menu } from "pages/GDD3/GDDGameSummary";
import { MENUS } from "pages/GDD3/GDDSideMenu/GamesMenu";
import GDDContext from "context/GDDContext";
import CacheContext from "context/CacheContext";

const getTopChartsCountries = "getTopChartsCountries";

const GDDGames = ({
  active,
  changeGdd,
  section,
  value,
  includeStats = true,
  component,
}) => {
  const { preview } = useContext(GDDContext);
  const { track } = useContext(SocketContext);
  const [selected, setSelected] = useState();

  const onClick = useCallback(
    (game) => addGame(game._id),
    [section, value, changeGdd]
  );
  useListener(`${section}.click`, onClick);

  useEffect(() => {
    if (value?.title === undefined) {
      changeText("Similar Games", "title");
    }
  }, [value]);

  function addGame(id) {
    if (id) {
      track("gdd.games.add-game", { id });
      let newValue = {
        ...(value || []),
        games: _.uniq([...(value?.games || []), id]),
      };
      changeGdd(section, newValue, true);
    }
  }

  function removeGame(id) {
    if (id) {
      let newValue = {
        ...(value || {}),
        games: (value?.games || []).filter((currentId) => currentId !== id),
      };
      changeGdd(section, newValue, true);
    }
  }

  function changeText(text, field = "text") {
    let newValue = {
      ...(value || {}),
      [field]: text,
    };
    changeGdd(section, newValue, true);
  }

  let className = "section game-inspiration";
  if (active) className += " active";
  if ((value?.games || []).length === 0) className += " empty";
  else className += " keep-together";

  const country = value?.country || "United States";

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";
  let textClassName = "description editable";
  if (selected === "text") textClassName += " selected";

  return (
    <div className={className}>
      <div className="left-content">
        {preview ? (
          <span className="section-subtitle">{value?.title}</span>
        ) : (
          <div className={titleClassName}>
            <Editable
              className="w-100"
              value={value?.title}
              setValue={(value = "") => changeText(value, "title")}
              placeholder="Section title"
              onFocus={() => setSelected("title")}
              onBlur={() => setSelected()}
            />
          </div>
        )}
        <div className={textClassName}>
          <Editable
            className="w-100"
            value={value?.text}
            setValue={(value = "") => changeText(value)}
            placeholder="Enter text (optional)"
            onFocus={() => setSelected("text")}
            onBlur={() => setSelected()}
          />
        </div>
      </div>
      <div className="right-content">
        <ShowIf condition={!value?.games?.length}>
          <AddGameElement
            active={active}
            component={component}
            onAddGame={addGame}
            menus={MENUS}
            section={section}
            shouldSelect={active}
          />
        </ShowIf>
        <ShowIf condition={value?.games?.length > 0}>
          <GameGridWithIds
            gameIds={value?.games || []}
            gameProps={{
              lazyLoad: false,
              useGif: false,
              location: country,
              actions: (game) => (
                <FAIcon
                  tooltip="Remove Image"
                  icon={<DeleteOutline className="font-size-xxxxxl" />}
                  className="remove-icon top-right clickable text-white z-over"
                  style={{ color: "white" }}
                  onClick={() => removeGame(game._id)}
                />
              ),
            }}
            includeStats={includeStats}
            allGenres={true}
          >
            <AddGameElement
              active={active}
              menus={MENUS}
              onAddGame={addGame}
              component={component}
              section={section}
            />
          </GameGridWithIds>
        </ShowIf>
      </div>
    </div>
  );
};

export const AddGameElement = ({
  active,
  onAddGame,
  component,
  menus,
  section,
  shouldSelect,
  allowClose = true,
}) => {
  const ref = useRef();
  const [selected, setSelected] = useState(false);
  const [formikKey, setFormikKey] = useState(0);
  const { openMenu } = useContext(GDDContext);

  useEffect(() => {
    if (shouldSelect) {
      setSelected(true);
    }
  }, [shouldSelect]);

  useEffect(() => {
    if (!active) setSelected(false);
  }, [active]);

  function onSubmit(values) {}

  function onSelectedGame(game) {
    setFormikKey(formikKey + 1);
    onAddGame(game._id);
  }

  function onClickedOption(option) {
    openMenu({ option, component, section });
  }

  let className = "game-element new d-flex flex-column menu-wrapper";
  if (selected) className += " selected";

  return (
    <div className="add-game" onClick={() => setSelected(true)}>
      <div className={className} ref={ref}>
        <Menu id="games" options={menus} onClick={onClickedOption} />
        <ShowIf condition={!selected}>
          <AddOutlined className="plus" />
        </ShowIf>
        <ShowIf condition={!!selected}>
          {allowClose && <div
            className="close"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelected(false);
            }}
          >
            <CloseOutlined className="font-size-lg text-primary" />
          </div>}
          <Formik
            key={formikKey}
            initialValues={{ value: "" }}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <UniversalInput
                  autoFocus={true}
                  label="Enter game title"
                  formik={formik}
                  allowed={["game"]}
                  PopperComponent={FullWidthPopper}
                  onSetData={(data) => {
                    formik.setFieldValue("search", data);
                    if (data.length > 0) onSelectedGame(data[0].value);
                  }}
                  value={formik.values.search}
                />
              </Form>
            )}
          </Formik>
        </ShowIf>
      </div>
    </div>
  );
};

const CountrySelector = ({ changeGdd, section, value }) => {
  const { call } = useContext(APIContext);
  const [countries, setCountries] = useState([]);

  const country = value?.country || "United States";

  useEffect(() => {
    call(getTopChartsCountries).then((response) => {
      if (response.ok) setCountries(response.body);
    });
  }, []);

  function changeCountry(country) {
    let newValue = {
      ...(value || {}),
      country,
    };
    changeGdd(section, newValue, true);
  }

  return (
    <div className="action hide-preview ml-2" onClick={() => {}}>
      <ShowIf
        condition={(value?.games || []).length > 0 && countries.length > 0}
      >
        <div className="select-field-wrapper">
          <RoomOutlined className="font-size-xxl mr-2" />
          <InputLabel id="chip-select-label">Country</InputLabel>
          <Select
            key={country}
            name="country"
            label="Country"
            value={country}
            className="w-100"
            defaultValue={country}
            onChange={(event) => {
              changeCountry(event.target.value);
            }}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                <span>{country}</span>
              </MenuItem>
            ))}
          </Select>
        </div>
      </ShowIf>
    </div>
  );
};

export const GDDGamesActionCountry = ({
  component,
  section,
  changeGdd,
  value,
}) => {
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  if (
    component?.section !== gddComponents.games.section &&
    component?.section !== gddComponents.trending.section
  )
    return null;

  return (
    <CountrySelector section={section} changeGdd={changeGdd} value={value} />
  );
};

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const FullWidthPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export default GDDGames;
