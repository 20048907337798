import * as React from 'react';

const PDFTemplate = props => {
  return (
    <div
      className="pdf-footer"
      style={{
        background: "white",
        position: "absolute",
        bottom: "10px",
        padding: "0 10px",
        width: "90%"
      }}
    >
        <span style={{
          display: "inline-block"
        }}>
          Created using
          <img
            height="20"
            alt="Ludo"
            className="d-inline-block ml-2 mr-2"
            src="/images/logo-small.png"
            style={{position: "relative", top: "-3px"}}
          />
          More info at ludo.ai
        </span>
      <span style={{
        display: "inline-block",
        float: "right"
      }}>Page {props.pageNum} of {props.totalPages}</span>
    </div>
  );
}

export default PDFTemplate;
