import React from "react";
import {IconButton, Tooltip} from "@material-ui/core";

const MyIconButton = ({
                        onClick,
                        iconElement,
                        title,
                        tooltipClassName = "secondary",
                        disabled
                      }) => {

  return (
    <Tooltip
      title={title}
      arrow
      PopperProps={{disablePortal: false, className: "MuiTooltip-popper MuiTooltip-popperArrow " + tooltipClassName}}
      placement="top"
    >
      <IconButton onClick={onClick} className="clickable" component="span" disabled={disabled}>
        {iconElement}
      </IconButton>
    </Tooltip>
  );
};

export default MyIconButton;
