import React, {useContext} from 'react';
import clsx from 'clsx';
import {connect} from 'react-redux';
import {Chip} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import ScrollContext from "context/ScrollContext";

const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    children,
    icon,
    showLabel
  } = props;

  const scrollContext = useContext(ScrollContext);
  let scrollRef = props.scrollRef || scrollContext?.scrollRef;

  return (
    <div
      className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
        'app-page-title--shadow': pageTitleShadow
      })}>
      <div className="top-bg-content w-100">
        {pageTitleIconBox && (
          <div className="w-100 text-white">
            <div className="title-first-line app-page-title--first">
              <ShowIf condition={!!icon}>
                <div className="mr-4">
                  {icon}
                </div>
              </ShowIf>
              <div className="title-wrapper">
                <h1>{titleHeading}</h1>
                <ShowIf condition={!!showLabel}>
                  <Chip
                    style={{height: "20px"}}
                    className="mr-2 ml-2 font-size-lg"
                    label={showLabel}
                  />
                </ShowIf>
                {pageTitleDescription && (
                  <span className="app-page-title--description app-page-title--second">
                    {titleDescription}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex align-self-start z-over w-100">
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
