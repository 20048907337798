import React, { useContext, useEffect, useMemo, useState } from "react";
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { ShowVisibleIf } from "components/common/ShowIf";
import "./style.scss";
import { Form, Formik } from "formik";
import FormikPersist from "components/utils/FormikPersist";
import { FormikSelectField } from "formik-material-fields";
import PerformanceUtils from "helpers/PerformanceUtils";
import { useHistory, useLocation, useParams } from "react-router";
import { GeneratingButton } from "components/Controls/MyButton";
import usePersistedState from "hooks/usePersistedState";
import _ from "lodash";
import SocketContext from "context/SocketContext";
import {
  ArrowBackIosNewOutlined,
  ChevronRightOutlined,
} from "@mui/icons-material";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Hint } from "../../scenes/Headquarters";
import DetailsPanelContext from "../../context/DetailsPanelContext";
import { getGameIconUrl } from "../../components/common/GameCard";
import { GameTopicCharts, SORTING_LABELS, SOURCE_LABELS } from "../Trends";
import { convertProxyUrl } from "../../components/common/ImageGallery";

const INITIAL_TABLE_ROWS = 15;

const getTrendTopics = "getTrendTopics";
const getGamesLandscape = "getGamesLandscape";
const getTopicsLandscape = "getTopicsLandscape";
const getTrendOptions = "getTrendOptions";
const getGameInformation = "getGameInformation";
const getGamesInformation = "getGamesInformation";

const SOURCES_WITHOUT_COUNTRY = ["steam", "itch"];

const Trends2 = ({
  fullVersion = true,
  gameProps,
  defaultLocation,
  gameWidth,
  spacing,
}) => {
  const locationHook = useLocation();
  const history = useHistory();
  const { call } = useContext(APIContext);
  const { track } = useContext(SocketContext);
  const { showTrendTopic, showGame } = useContext(DetailsPanelContext);

  const [data, setData] = useState([]);
  const [singleRequestData, setSingleRequestData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [sources, setSources] = useState([]);
  const [genres, setGenres] = useState([]);
  const [formValues, setFormValues] = useState(undefined);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [selectedRequest, setSelectedRequest] = usePersistedState(
    "Trends2.selectedRequest",
    undefined
  );

  useEffect(() => {
    if (!!locationHook?.state?.data) {
      const { display_title, title, type, genre, location, sorting, sources } =
        locationHook.state?.data;
      let request = {
        ep: getTrendTopics,
        title: display_title,
        info: title,
        index: 99,
        subIndex: 0,
        data: {
          type,
          data: {
            genre,
            sorting,
            //category,
            sources,
            location,
            n: 20,
          },
        },
      };
      onClickedMore(request);
      history.replace({ ...history.location, state: {} });
    }
  }, [locationHook]);

  useEffect(() => {
    call(getTrendOptions, { type: "charts" }).then((response) => {
      if (response.ok) {
        setLocations(response.body?.locations);
        setSources(response.body?.sources);
        setGenres(response.body?.genres);
      }
      setLoadingOptions(false);
    });
  }, []);

  async function handleRequest(request, formValues, saveData = setData) {
    let filteredFormValues = { ...formValues };

    if (SOURCES_WITHOUT_COUNTRY.includes(filteredFormValues.sources))
      delete filteredFormValues.location;

    let data = {
      ...request.data,
      data: { ...request.data.data, ...filteredFormValues },
    };

    let response = await call(request.ep, data);
    if (response.ok) {
      let key = `${request.index}-${request.subIndex}`;
      saveData((prevState) => {
        return _.uniqBy(
          _.orderBy(
            [
              ...(prevState || []).filter((item) => item.key !== key),
              {
                ...request,
                data: response.body,
                key,
              },
            ],
            "subIndex"
          ),
          "key"
        );
      });
    }
  }

  const hasSelectedRequest = !!selectedRequest;

  useEffect(() => {
    if (formValues && !hasSelectedRequest) {
      setData([]);
      Promise.all(
        requests.map((request) => handleRequest(request, formValues))
      );
    }
  }, [formValues, hasSelectedRequest]);

  useEffect(() => {
    if (selectedRequest) {
      repeatRequest(selectedRequest, { n: 20 }, setSingleRequestData);
    }
  }, [selectedRequest]);

  function trackWrapper(action, payload) {
    track(`trends.${action}`, payload);
  }

  function onClickedMore(request) {
    trackWrapper("clicked-more", { request });
    let newSelectedRequest = JSON.parse(JSON.stringify(request));
    setSelectedRequest(newSelectedRequest);
    setSingleRequestData();
  }

  function onClickedBack() {
    trackWrapper("clicked-back", { selectedRequest });
    setSelectedRequest();
  }

  function onClickedTopic(topic) {
    trackWrapper("topic", { topic });
    showTrendTopic(topic);
  }

  async function onClickedGame(game) {
    trackWrapper("open-game", { existing_game: game._id });
    let genre, location;
    showGame(game, genre, location);
  }

  const initialValues = {
    sources: sources[0],
    genre: genres[0],
    location: defaultLocation || locations[0],
  };

  async function repeatRequest(request, overrides = {}, saveData) {
    let newReq = { ...request };
    Object.keys(overrides).forEach((key) => {
      newReq.data.data[key] = overrides[key];
    });
    return handleRequest(newReq, formValues, saveData);
  }

  function onChangeForm(name, value) {
    trackWrapper("change-form", { name, value });
    if (formValues[name] !== value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  }

  let tab = 0;
  let optionsSorting = Object.keys(SORTING_LABELS[0]);
  if (
    selectedRequest &&
    !optionsSorting.includes(selectedRequest?.data?.data?.sorting)
  ) {
    optionsSorting = Object.keys(SORTING_LABELS[1]);
    tab = 1;
  }

  return (
    <div className="trends-page-content">
      {fullVersion && (
        <PageTitle
          titleHeading="Market Trends"
          titleDescription="Stay on top the trends Ludo discovers in the market"
        />
      )}
      {!selectedRequest && (
        <div className="filters">
          {loadingOptions ? (
            <CircularProgress size={53} />
          ) : (
            <Formik
              initialValues={initialValues}
              key={sources.length + genres.length + locations.length}
            >
              {(formik) => (
                <Form>
                  <FormikPersist
                    name="Trends2"
                    onLoad={(data) => {
                      if (!Object.keys(data?.values || {}).length) {
                        formik.setValues(initialValues);
                        setFormValues(initialValues);
                      } else {
                        setFormValues(data.values);
                      }
                    }}
                  />
                  <FormikSelectField
                    onChange={(event) => {
                      onChangeForm(event.target.name, event.target.value);
                    }}
                    name="sources"
                    label="Source"
                    options={sources.map((source) => {
                      return {
                        value: source,
                        label: SOURCE_LABELS[source] || source,
                      };
                    })}
                    fullWidth
                  />
                  <FormikSelectField
                    name="genre"
                    label="Genre"
                    fullWidth
                    onChange={(event) => {
                      onChangeForm(event.target.name, event.target.value);
                    }}
                    options={genres.map((genre) => {
                      return {
                        value: genre,
                        label: (
                          <div className="d-flex">
                            <span>{genre}</span>
                          </div>
                        ),
                      };
                    })}
                  />
                  <ShowVisibleIf
                    condition={
                      !SOURCES_WITHOUT_COUNTRY.includes(formik.values.sources)
                    }
                    className="show-visible-if"
                  >
                    <FormikSelectField
                      onChange={(event) => {
                        onChangeForm(event.target.name, event.target.value);
                      }}
                      name="location"
                      label="Country"
                      options={locations.map((country) => {
                        return { value: country, label: country };
                      })}
                      fullWidth
                    />
                  </ShowVisibleIf>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
      <div className="trends-content">
        {selectedRequest ? (
          <div className="single-request-results">
            <div className="single-title">
              <span className="gradient-text3">{selectedRequest?.title}</span>
              {selectedRequest?.info && <Hint hint={selectedRequest?.info} />}
            </div>
            {!singleRequestData && (
              <CircularProgress size={60} className="mt-4" />
            )}
            <GameTopicCharts
              data={{ topics: singleRequestData?.[0]?.data }}
              tab={tab}
              localTab={0}
              fullVersion={fullVersion}
              gameProps={gameProps}
              gameWidth={gameWidth || (!fullVersion ? 170 : undefined)}
              spacing={spacing}
              options={{ sorting: optionsSorting }}
              initialSorting={selectedRequest.data?.data?.sorting}
              onSortChange={(value) => {
                repeatRequest(
                  selectedRequest,
                  { n: 20, sorting: value.sorting },
                  setSingleRequestData
                );
              }}
              extraOptions={
                <>
                  <div className="mode-icon" onClick={onClickedBack}>
                    <ArrowBackIosNewOutlined className="font-size-lg mr-2" />
                    <span>Back</span>
                  </div>
                </>
              }
            />
          </div>
        ) : (
          <>
            <div className="trends-container">
              <TrendsBox title="Overall Topic Trends" hr={true} className="overall">
                {requests
                  ?.filter((d) => d.index === 0)
                  .map((request, index) => (
                    <TrendsListing
                      key={index}
                      title={request.title}
                      info={request.info}
                      onClickedMore={() => onClickedMore(request)}
                      onClickedTopic={onClickedTopic}
                      borders={index === 1 ? 2 : 0}
                      data={
                        data?.find((d) => d.index === 0 && d.subIndex === index)
                          ?.data
                      }
                    />
                  ))}
              </TrendsBox>
              <TrendsBox
                title={requests?.find((d) => d.index === 1)?.title}
                info={requests?.find((d) => d.index === 1)?.info}
                hr={false}
                className="landscape"
              >
                {data?.find((d) => d.index === 1) ? (
                  data
                    ?.filter((d) => d.index === 1)
                    .map((item, index) => (
                      <MarketLandscape
                        key={index}
                        onClickedMore={() => onClickedMore()}
                        onClickedGame={onClickedGame}
                        data={item.data}
                      />
                    ))
                ) : (
                  <div className="w-100 text-align-center">
                    <CircularProgress size={60} />
                  </div>
                )}
              </TrendsBox>
              <TrendsBox
                title={requests?.find((d) => d.index === 2)?.title}
                info={requests?.find((d) => d.index === 2)?.info}
                hr={false}
                className="table"
              >
                <TrendsTable
                  data={data?.find((d) => d.index === 2)?.data}
                  onLoadMore={async (n) =>
                    repeatRequest(
                      requests.find(({ index }) => index === 2),
                      { n }
                    )
                  }
                />
              </TrendsBox>
              <TrendsBox title="Specific Trends" className="specific" hr={true}>
                {requests
                  ?.filter((d) => d.index === 3)
                  .map((request, index) => (
                    <TrendsListing
                      key={index}
                      title={request.title}
                      info={request.info}
                      bars={true}
                      onClickedMore={() => onClickedMore(request)}
                      onClickedTopic={onClickedTopic}
                      borders={index === 1 ? 2 : index === 3 ? 1 : 0}
                      data={
                        data?.find((d) => d.index === 3 && d.subIndex === index)
                          ?.data
                      }
                    />
                  ))}
              </TrendsBox>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const TrendsTable = ({ data, onLoadMore }) => {
  const { columns = [], topics = [] } = data || {};
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);

  const [n, setN] = useState(INITIAL_TABLE_ROWS);

  const { showTrendTopic } = useContext(DetailsPanelContext);

  async function loadMore() {
    setLoading(true);
    let newValue = n + INITIAL_TABLE_ROWS;
    setN(newValue);
    await onLoadMore(newValue);
    setLoading(false);
  }

  const sortedData = useMemo(() => {
    if (!data) return { columns, topics };
    let sortedIndices = [...topics.keys()];

    if (sortColumn !== null) {
      sortedIndices.sort((a, b) => {
        const aValue = columns[sortColumn].values_raw[a];
        const bValue = columns[sortColumn].values_raw[b];

        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    } else {
      sortedIndices.sort((a, b) => {
        const aTitle = topics[a].title.toLowerCase();
        const bTitle = topics[b].title.toLowerCase();

        if (aTitle < bTitle) return sortDirection === "asc" ? -1 : 1;
        if (aTitle > bTitle) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    const sortedTopics = sortedIndices.map((index) => topics[index]);

    const sortedColumns = columns.map((column) => ({
      ...column,
      values: sortedIndices.map((index) => column.values[index]),
      values_raw: sortedIndices.map((index) => column.values_raw[index]),
      values_description: sortedIndices.map(
        (index) => column.values_description[index]
      ),
    }));

    return { columns: sortedColumns, topics: sortedTopics };
  }, [data, sortColumn, sortDirection, columns, topics]);

  const handleSort = (columnIndex) => {
    if (sortColumn === columnIndex) {
      setSortDirection(sortDirection === "desc" ? "asc" : "desc");
    } else {
      setSortColumn(columnIndex);
      setSortDirection("desc");
    }
  };

  const handleTopicSort = () => {
    if (sortColumn === null) {
      setSortDirection(sortDirection === "desc" ? "asc" : "desc");
    } else {
      setSortColumn(null);
      setSortDirection("desc");
    }
  };

  return data ? (
    <div className="d-flex flex-column w-100">
      <div className="table-container">
        <table className="trends-table">
          <thead>
            <tr>
              <th onClick={handleTopicSort} style={{ cursor: "pointer" }}>
                <span>Topic{" "}
                  {sortColumn === null && (sortDirection === "asc" ? "▲" : "▼")}</span>
              </th>
              {columns.map((column, index) => (
                <th
                  key={column.name}
                  onClick={() => handleSort(index)}
                  style={{ cursor: "pointer" }}
                >
                  <TableTooltip title={column.description}>
                    <span>
                      {column.name}{" "}
                      {sortColumn === index &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </span>
                  </TableTooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body">
            {sortedData.topics.map((topic, lineIndex) => (
              <tr
                key={topic.id || lineIndex}
                className="table-row"
                onClick={() => showTrendTopic(topic)}
              >
                <td className="table-cell">
                  <TableTooltip title={topic.summary}>
                    <span>{topic.title}</span>
                  </TableTooltip>
                </td>
                {sortedData.columns.map((column) => (
                  <td key={topic.id + column.name} className="table-cell">
                    <TableTooltip title={column.values_description[lineIndex]}>
                      <span>{column.values[lineIndex]}</span>
                    </TableTooltip>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <GeneratingButton
        id="trends.table.loadMore"
        className="mx-auto mt-3"
        color="secondary"
        onClick={loadMore}
        loading={loading}
        loadingText="Loading topics..."
      >
        Load More
      </GeneratingButton>
    </div>
  ) : (
    <div className="w-100 text-align-center">
      <CircularProgress size={60} />
    </div>
  );
};

const TableTooltip = ({ title, children }) =>
  title ? (
    <Tooltip
      arrow
      placement="top"
      title={title}
      PopperProps={{
        disablePortal: true,
        className:
          "MuiTooltip-popper MuiTooltip-popperArrow secondary text-align-left",
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

const MarketLandscape = ({ onClickedGame, data }) => {
  const { call } = useContext(APIContext);
  const [games, setGames] = useState({});

  useEffect(() => {
    const ids = data.games.map(({ game_id }) => game_id);
    call(getGamesInformation, { data: { ids } }).then((response) => {
      if (response.ok) {
        setGames(PerformanceUtils.arrayToObject(response.body, "_id"));
      }
    });
  }, [data.games]);

  const axisConfig = useMemo(() => {
    const xValues = data.games.map((game) => game.trend_score * 100);
    const yValues = data.games.map((game) => game.position_score * 100);

    const xMin = Math.min(...xValues);
    const xMax = Math.max(...xValues);
    const yMin = Math.min(...yValues);
    const yMax = Math.max(...yValues);

    const xRange = xMax - xMin;
    const yRange = yMax - yMin;

    // Function to determine appropriate interval and adjust min/max
    const calculateAxisConfig = (min, max, range) => {
      let interval, adjustedMin, adjustedMax, tickCount;

      if (range < 1) {
        // For very small ranges, use a smaller interval
        interval = Math.pow(10, Math.floor(Math.log10(range)) - 1);
        tickCount = Math.ceil(range / interval) + 1;
        adjustedMin = Math.floor(min / interval) * interval;
        adjustedMax = adjustedMin + (tickCount - 1) * interval;
      } else {
        interval = Math.pow(10, Math.floor(Math.log10(range / 5)));
        tickCount = Math.ceil(range / interval) + 1;
        adjustedMin = Math.floor(min / interval) * interval;
        adjustedMax = Math.ceil(max / interval) * interval;
      }

      return { min: adjustedMin, max: adjustedMax, interval, tickCount };
    };

    const xConfig = calculateAxisConfig(xMin, xMax, xRange);
    const yConfig = calculateAxisConfig(yMin, yMax, yRange);

    return {
      x: xConfig,
      y: yConfig,
    };
  }, [data.games]);

  const getPositionPercentage = (value, axis) => {
    const { min, max } = axisConfig[axis];
    return ((value * 100 - min) / (max - min)) * 100;
  };

  const renderAxisLabels = (axis) => {
    const { min, max, interval } = axisConfig[axis];
    const labels = [];

    if (axis === "x") {
      // Show 5 labels for x-axis: min, max, and 3 in between
      const xValues = [
        min,
        min + (max - min) * 0.25,
        min + (max - min) * 0.5,
        min + (max - min) * 0.75,
        max,
      ];
      xValues.forEach((value) => {
        const position = getPositionPercentage(value / 100, axis);
        labels.push(
          <span
            key={`${axis}-label-${value}`}
            className="chart-label vertical"
            style={{ left: `calc(${Math.max(position, 2)}% - 25px)` }}
          >
            {`${value > 0 && value < 1 ? value.toFixed(2) : value.toFixed(0)}%`}
          </span>
        );
      });
    } else {
      // For y-axis, show only min, center, and max
      const yValues = [min, (min + max) / 2, max];
      yValues.forEach((value) => {
        const position = getPositionPercentage(value / 100, axis);
        labels.push(
          <span
            key={`${axis}-label-${value}`}
            className="chart-label horizontal"
            style={{ bottom: `calc(${position}% - 10px)` }}
          >
            {`${value > 0 && value < 1 ? value.toFixed(2) : value.toFixed(0)}%`}
          </span>
        );
        labels.push(
          <div
            key={`${axis}-line-${value}`}
            className="chart-line horizontal"
            style={{ bottom: `${position}%` }}
          />
        );
      });
    }

    return labels;
  };

  return (
    <div className="market-landscape">
      <div className="market-chart">
        {renderAxisLabels("y")}
        {renderAxisLabels("x")}

        <div className="chart-axis x-axis" />
        <div className="chart-axis y-axis" />

        <div className="axis-label x-axis-label">
          {data?.trend_score_description}
        </div>

        <div className="axis-label y-axis-label">
          {data?.position_score_description}
        </div>

        {data.games?.map(({ game_id, position_score, trend_score }) => {
          const game = games[game_id];
          const left = `${getPositionPercentage(trend_score, "x")}%`;
          const bottom = `${getPositionPercentage(position_score, "y")}%`;
          return (
            <Tooltip key={game_id} title={game?.title || "Loading..."}>
              <div className="market-game-wrapper" style={{ left, bottom }}>
                {game ? (
                  <div
                    className="market-game"
                    onClick={() => onClickedGame(game)}
                  >
                    <GameIcon game={game} fallbackToCover={true} />
                  </div>
                ) : (
                  <CircularProgress size={20} />
                )}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

const DEFAULT_OBJECT = {};

const GameIcon = ({ game = DEFAULT_OBJECT, fallbackToCover, onClick }) => {
  const url = useMemo(() => {
    return getGameIconUrl(game, fallbackToCover);
  }, [game, fallbackToCover]);

  const className = "icon " + game?.source;

  return url ? (
    <div
      style={{ backgroundImage: `url(${url})` }}
      className={className}
      onClick={!!onClick ? () => onClick(game) : undefined}
    />
  ) : null;
};

const TrendsListing = ({
  title,
  onClickedMore,
  onClickedTopic,
  borders,
  data,
  info,
  bars = false,
}) => {
  let className = "trends-listing-content";
  if (bars) className += " bars";

  return (
    <>
      {borders > 0 && <div className="borders" />}
      <div className="trends-listing">
        <div className="top-bar">
          <div className="title-wrapper">
            <TableTooltip title={info}>
              <span className="title">{title}</span>
            </TableTooltip>
          </div>
          {onClickedMore && (
            <span className="link" onClick={onClickedMore}>
              More <ChevronRightOutlined />
            </span>
          )}
        </div>
        <div className={className}>
          {data ? (
            data.map((topic) => (
              <TopicDetail
                key={topic.topic_id}
                topic={topic}
                bars={bars}
                onClick={() => onClickedTopic(topic)}
              />
            ))
          ) : (
            <div className="w-100 text-align-center">
              <CircularProgress size={40} />{" "}
            </div>
          )}
        </div>
      </div>
      {borders > 1 && <div className="borders" />}
    </>
  );
};

const TopicDetail = ({ topic, bars, onClick }) => {
  const [chosenImage, setChosenImage] = useState();
  const image = topic.images?.[0];
  const imageGameId = image?.game_id || topic.games[0]?.game_id;
  const { call } = useContext(APIContext);

  let title = topic.title.split(",")[0];

  useEffect(() => {
    if (imageGameId) {
      call(getGameInformation, { id: imageGameId }).then((response) => {
        if (response.ok) {
          let screenshots = response.body.screenshots || [];
          let chosenImage =
            screenshots?.find(({ id }) => image?.image_id === id) ||
            screenshots[0];
          setChosenImage(chosenImage);
        }
      });
    }
  }, [imageGameId, image]);

  return (
    <Tooltip
      arrow
      placement="bottom"
      title={topic.score_text}
      PopperProps={{
        disablePortal: true,
        className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
      }}
    >
      <div
        key={topic.topic_id}
        className="trends-listing-item"
        onClick={onClick}
      >
        {!bars &&
          (chosenImage ? (
            <div
              className="image"
              style={{
                backgroundImage: `url("${convertProxyUrl(chosenImage)}")`,
              }}
            />
          ) : (
            <CircularProgress size={50} />
          ))}
        <span>{title}</span>
        {bars && (
          <LinearProgress
            variant="determinate"
            value={topic.normalized_score * 100.0}
          />
        )}
      </div>
    </Tooltip>
  );
};

const TrendsBox = ({ title, info, children, hr = true, className }) => {
  let finalClassName = "trends-box";
  if (!hr) className += " no-hr";
  if (className) finalClassName += " " + className;

  return (
    <div className={finalClassName}>
      <div className="trends-box-title">
        {info ? (
          <TableTooltip title={info}>
            <span className="gradient-text3">{title}</span>
          </TableTooltip>
        ) : (
          <span className="gradient-text3">{title}</span>
        )}
      </div>
      <div
        className={"trends-box-content"+(hr ? " borders" : "")}
        style={{ "--item-count": children?.length || 1, "--borders": hr ? "5px" : "0px" }}
      >
        {children}
      </div>
    </div>
  );
};

const requests = [
  //0
  {
    ep: getTrendTopics,
    title: "Quickly Rising in the Charts",
    info: "Shows game categories experiencing rapid growth in popularity and rankings. These categories represent emerging trends that could be tomorrow's market leaders.",
    index: 0,
    subIndex: 0,
    data: {
      type: "charts",
      data: {
        sorting: "growth_rate",
        category: "game",
        n: 3,
      },
    },
  },
  {
    ep: getTrendTopics,
    title: "Established in the Charts",
    info: "Displays game categories that have proven staying power and consistent performance. These represent reliable, stable market segments with sustained player interest",
    index: 0,
    subIndex: 1,
    data: {
      type: "charts",
      data: {
        sorting: "absolute_growth",
        category: "game",
        n: 3,
      },
    },
  },
  {
    ep: getTrendTopics,
    title: "Increasing New Releases",
    info: "Highlights game categories seeing a surge in new game releases from developers. These represent areas where creators are actively investing resources, suggesting anticipated market opportunities",
    index: 0,
    subIndex: 2,
    data: {
      type: "latest",
      data: {
        sorting: "mom",
        category: "game",
        n: 3,
      },
    },
  },

  //1
  {
    ep: getGamesLandscape,
    title: "Market Landscape",
    info: "A visual representation plotting games' chart position (Y-axis) against their trend direction (X-axis), where higher percentage means better performance. Essential for understanding both current market leaders and upcoming challengers.",
    index: 1,
    subIndex: 0,
    data: {
      data: {
        n: 20,
      },
    },
  },

  //2
  {
    ep: getTopicsLandscape,
    title: "Top Game Topics",
    info: "Provides comprehensive performance metrics for the most significant game categories in the current market, including their growth rates, and market penetration. This data helps identify which game types are dominating the market and showing the strongest overall performance metrics.",
    index: 2,
    subIndex: 0,
    data: {
      data: {
        n: INITIAL_TABLE_ROWS,
        category: "game",
      },
    },
  },
  //3
  {
    ep: getTrendTopics,
    title: "Game Mechanics",
    info: "Reveals the fastest-growing gameplay systems and interaction patterns, ranked by growth rate. Identifies which core gameplay mechanics are experiencing the sharpest rise in player adoption.",
    index: 3,
    subIndex: 0,
    data: {
      type: "charts",
      data: {
        sorting: "growth_rate",
        category: "mechanics",
        n: 4,
      },
    },
  },
  {
    ep: getTrendTopics,
    title: "Game Themes",
    info: "Lists thematic elements and settings that are showing the highest growth velocity in the market. Spotlights which narrative and contextual themes are rapidly gaining player interest.",
    index: 3,
    subIndex: 1,
    data: {
      type: "charts",
      data: {
        sorting: "growth_rate",
        category: "theme",
        n: 4,
      },
    },
  },
  {
    ep: getTrendTopics,
    title: "Art Styles",
    info: "Shows which visual styles and graphics approaches are catching fire right now. Helps spot which looks and aesthetics players are getting most excited about.",
    index: 3,
    subIndex: 2,
    data: {
      type: "charts",
      data: {
        sorting: "growth_rate",
        category: "art-style",
        n: 4,
      },
    },
  },
  {
    ep: getTrendTopics,
    title: "Subgenres",
    info: "Identifies game subcategories demonstrating the most rapid market growth. Pinpoints emerging niches that are quickly expanding their player base.",
    index: 3,
    subIndex: 3,
    data: {
      type: "charts",
      data: {
        sorting: "growth_rate",
        category: "subgenre",
        n: 4,
      },
    },
  },
];

export default Trends2;
