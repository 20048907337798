import React, {useState, useEffect, useMemo, useLayoutEffect} from 'react';

export const ExpandableGrid = ({data, renderDetail, selectedIndex, onSelectedIndexChange, ...props}) => {
  const [selectedId, setSelectedId] = useState(getSelectedId(selectedIndex));
  const [resizeCount, setResizeCount] = useState(0);

  function getSelectedId(index) {
    if(index !== undefined) {
      return `grid_cell_${index}`;
    } else {
      return '';
    }
  }

  useLayoutEffect(() => {
    setResizeCount(prevState => prevState+1)
  },[data]);

  useEffect(() => {
    if(onSelectedIndexChange) {
      let index = selectedId ? parseInt(selectedId.split('grid_cell_')[1]) : undefined;
      if(index === undefined || !isNaN(index)) {
        onSelectedIndexChange(index);
      }
    }
  },[selectedId]);

  useEffect(() => {
    let id = getSelectedId(selectedIndex);
    setSelectedId(id);
  },[selectedIndex]);

  useEffect(() => {
    const handleResize = () => {
      setResizeCount(prevState => prevState+1);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedId, data]);

  const detailInsertionIndex = useMemo(() => {
    if(!selectedId) return;
    const thisIdNumber = parseInt(selectedId.split('grid_cell_')[1]);
    let ol = document.getElementById('grid-list')?.childNodes;
    if(!ol) return null;
    const startingIndex = thisIdNumber + 1;

    let filteredOl = [];
    ol.forEach(item => {
      if(item.className === 'grid-cell') {
        filteredOl.push(item);
      }
    });

    const lengthOfList = filteredOl.length;

    for (let i = startingIndex; i < filteredOl.length; i++) {
      if (filteredOl[i].className === 'grid-cell') {
        if (filteredOl[i].offsetTop !== filteredOl[thisIdNumber].offsetTop) {
          return i;
        }
      }
    }
    return lengthOfList;
  }, [selectedId, resizeCount]);

  const closeExpandedDetail = () => {
    setSelectedId('');
  };

  const generateGrid = () => {

    let result = [];

    let selectedItemIndex = parseInt(selectedId?.replace('grid_cell_', ''));

    data.forEach((d, index) => {
      const thisUniqueKey = `grid_cell_${index}`;
      result.push(
        <li
          key={thisUniqueKey}
          className='grid-cell'
          id={thisUniqueKey}
        >
          {props.renderItem(d, index)}
      </li>);

      if ((detailInsertionIndex - 1) === index) {
        result.push(<div className='expanded-detail' key={`inserted-${detailInsertionIndex}`}>
          {renderDetail(data[selectedItemIndex], selectedItemIndex)}
        </div>);
      }
    });

    return result;
  };

  const rows = generateGrid();

  return (
    <div className='expandable-grid'>
      <div className='grid-wrapper'>
        <ol className='grid-list' id="grid-list">
          {props.childrenStart}
          {rows}
        </ol>
      </div>
    </div>
  );
}

ExpandableGrid.defaultProps = {
  data: [],
}

export default ExpandableGrid
