import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ShowIf from "components/common/ShowIf";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const Collapse = props => {
  let rootClassName = "collapse";
  if (props.className) rootClassName += " " + props.className;
  return (
    <div className={rootClassName}>
      <Title
        title={props.title}
        titleElement={props.titleElement}
        collapsed={props.collapsed}
        onClick={props.onClick}
      />
      <ShowIf condition={!props.collapsed}>{props.children}</ShowIf>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.string,
  titleElement: PropTypes.element,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func
};

Collapse.defaultProps = {
  collapsed: true
};

export const UncontrolledCollapse = props => {

  let initialValue =
    props.initialValue !== undefined ? props.initialValue : true;
  let [collapsed, setCollapsed] = useState(initialValue);

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  if(props.disable) {
    return props.children
  }

  return (
    <Collapse {...props} collapsed={collapsed} onClick={toggleCollapsed}>
      {props.children}
    </Collapse>
  );
};

const Title = ({title = '', titleElement = null, collapsed, onClick}) => {
  let className = 'small-title collapse-title top clickable ';
  className += collapsed ? 'collapsed' : 'not-collapsed';
  return (
    <span className={className} onClick={onClick}>
      {collapsed ?
        (<ExpandMore className="font-size-lg mt-2 mr-2" style={{display: 'flex', float: 'left'}}/>) :
        (<ExpandLess className="font-size-lg mt-2 mr-2" style={{display: 'flex', float: 'left'}}/>)
      }
      {' '}
      {titleElement || title}
    </span>
  );
};

export default Collapse;
