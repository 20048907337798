import { useEffect } from 'react';
import { useLocation } from "react-router";

const BLACKLIST = ['/game-concept'];

const ScrollToTop = ({ scrollRef, children }) => {
  const location = useLocation();
  const locationData = location?.state?.data;

  useEffect(() => {
    if (scrollRef.current && !BLACKLIST.includes(location.pathname)) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [location.pathname, scrollRef, locationData]);

  return children || null;
};

export default ScrollToTop;
