import React, {useContext, useMemo, useState} from 'react';
import {ListItemIcon, ListItemText, MenuItem, Menu, Badge, Tooltip} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import _ from "lodash";
import SocketContext from "context/SocketContext";
import {
  ArrowDownward,
  ArrowUpward,
  CommentOutlined,
  Delete,
  MoreVert,
  TryOutlined
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import {GDDGamesActionCountry} from "pages/GDD3/GDDGames";
import {GDDGameElementActions} from "pages/GDD3/GDDGameElement";
import {MENUS} from "pages/GDD3/GDDSideMenu/GameElementsMenu";
import {GDDSummaryActions} from "pages/GDD3/GDDGameSummary";
import CacheContext from "context/CacheContext";
import {GDDLudoScoreActions} from "./GDDLudoScore";

const GDDSectionActions = ({component, section, value, changeOrder, deleteSection, gdd, changeGdd, projectId}) => {

  const {track} = useContext(SocketContext);
  const {preview, openMenu} = useContext(GDDContext);
  const {cache} = useContext(CacheContext);
  const {gddComponents} = cache;

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  function openChat() {
    openMenu({component, option: MENUS.chat.id, section});
  }

  function onActionsClick(event) {
    track('gdd.section.clicked-context', {section});
    //event.preventDefault();
    //event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onClickDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    deleteSection();
    setMenuAnchorEl(null);
  }

  const comments = value?.comments;

  const numberComments = useMemo(() => {
    let count = 0;
    (comments || []).forEach(comment => {
      count += 1 + (comment.replies || []).length;
    });
    return count;
  }, [comments]);

  const index = useMemo(() => {
    let index = _.findIndex(gdd?.sections || [], ['name', section]);
    if (index < 0) index = _.findIndex(gdd?.sections || [], ['id', section]);
    return index;
  }, [gdd, section])

  const allowUp = index > 1;
  const allowDown = index >= 1 && index < (gdd?.sections || []).length - 1;

  return (
    <div className="gdd-section-actions">
      <ShowIf condition={preview}>
        <div
          className="action"
        >
          <CommentOutlined className="font-size-xl hvr-grow comment-button"/>
          <ShowIf condition={numberComments > 0}>
            <Badge
              badgeContent={numberComments}
              color="secondary"
              style={{position: "absolute"}}
              key={numberComments}
            />
          </ShowIf>
        </div>
      </ShowIf>
      <GDDSummaryActions
        component={component}
        section={section}
      />
      <GDDGameElementActions
        value={value}
        component={component}
        section={section}
        changeGdd={changeGdd}
      />
      <ShowIf condition={!preview}>
        <GDDGamesActionCountry
          value={value}
          component={component}
          section={section}
          changeGdd={changeGdd}
        />
      </ShowIf>
      <ShowIf condition={!!changeOrder && allowDown}>
        <div className="action hide-preview" onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          changeOrder(false)
        }}>
          <ArrowDownward className="font-size-xxl hvr-grow"/>
        </div>
      </ShowIf>
      <ShowIf condition={!!changeOrder && allowUp}>
        <div className="action hide-preview" onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          changeOrder(true)
        }}>
          <ArrowUpward className="font-size-xxl hvr-grow"/>
        </div>
      </ShowIf>
      <ShowIf condition={!preview}>
        <ShowIf condition={!!deleteSection}>
          <div
            className="action hide-preview ml-2"
            onClick={onActionsClick}
          >
            <MoreVert className="font-size-xl hvr-grow"/>
          </div>
        </ShowIf>
        <ShowIf condition={!!menuAnchorEl}>
          <Menu
            anchorEl={menuAnchorEl}
            keepMounted
            open={!!menuAnchorEl}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem onClick={onClickDelete}>
              <ListItemIcon>
                <Delete/>
              </ListItemIcon>
              <ListItemText primary="Delete section"/>
            </MenuItem>
          </Menu>
        </ShowIf>
      </ShowIf>
      <ShowIf
        condition={!preview && !!gddComponents[component?.section]?.chat}>
        <div className="action ai-chat-icon hide-preview mr-3" onClick={openChat}>
          <div className="hvr-grow icon-wrapper">
            <TryOutlined className="font-size-xxl"/>
            <span>Ask Ludo</span>
          </div>
        </div>
      </ShowIf>
      <GDDLudoScoreActions
        component={component}
        section={section}
        value={value}
        changeGdd={changeGdd}
        projectId={projectId}
      />
    </div>
  )
}

export default GDDSectionActions;
