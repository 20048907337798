import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import moment from "moment";
import _ from "lodash";
import { CircularProgress, Grid, Switch, Tooltip } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import SubscriptionFlow from "scenes/SubscriptionPage/SubscriptionFlow";
import PlanCard from "scenes/SubscriptionPage/PlanCard";
import { SUBSCRIPTION_STATUS } from "scenes/SubscriptionPage";
import CacheContext from "context/CacheContext";
import MyButton from "components/Controls/MyButton";
import ellipse0 from "assets/images/icons/ellipse-0.svg";
import ellipse1 from "assets/images/icons/ellipse-1.svg";
import ellipse2 from "assets/images/icons/ellipse-2.svg";

const getSubscription = "getSubscription";
const unsubscribe = "unsubscribe";
const retryPayment = "retryPayment";

const highlightedName = "Indie";

const SPECIAL = {
  Discovery: { label: "Pay per Use", className: "white" },
  Indie: { label: "Most Popular", className: "secondary" },
  Studio: { label: "Best Value", className: "white" },
};

export const FREE_PLAN_ID = "free";

export const SEARCH_RESULTS_MESSAGE =
  "Upgrade your subscription to get hundreds of results and experience Ludo's full capabilities!";
export const FEATURE_UNAVAILABLE_MESSAGE =
  "Upgrade your subscription to access to all features and experience Ludo's full capabilities!";

const ICONS = [ellipse0, ellipse1, ellipse2];

const Plans = ({ onSuccess, redirectOnSubscription }) => {
  const history = useHistory();
  const { cache } = useContext(CacheContext);
  const { call, loading, loggedIn } = useContext(APIContext);
  const { auth, setAuth } = useContext(AuthContext);

  const { subscription } = auth;
  const { plans } = cache;

  const [monthly, setMonthly] = useState(
    subscription?.plan?.interval === "month"
  );
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [loadingSubscription, setLoadingSubscription] = useState(true);

  let plan = (cache.plans || []).find((plan) => !!plan.percent_off);
  const hasDiscount = !!plan?.percent_off;

  const subscriptionStatus =
    subscription?.status || SUBSCRIPTION_STATUS.unsubscribed;

  useEffect(() => {
    if (
      redirectOnSubscription &&
      (subscriptionStatus === SUBSCRIPTION_STATUS.active ||
        subscriptionStatus === SUBSCRIPTION_STATUS.trialing)
    )
      history.push("/");
  }, [redirectOnSubscription, subscriptionStatus]);

  useEffect(() => {
    if (loggedIn) {
      call(getSubscription).then((response) => {
        if (response.ok) {
          setAuth({ subscription: response.body }, true, false);
        }
        setLoadingSubscription(false);
      });
    }
  }, [loggedIn]);

  const freePlan = (plans || []).find((plan) => plan.amount === 0);
  const isLoading = loadingSubscription || plans?.length === 0;

  const oneTimePlans = useMemo(() => {
    return (plans || [])
      .filter((plan) => plan.interval === "one_time")
      .map((plan) => {
        return {
          ...plan,
          amount: (plan.amount || 0) / 100.0,
          icon: ICONS[0],
        };
      });
  }, [plans]);

  const periodPlans = useMemo(() => {
    let modifiedPlans = (plans || [])
      .filter((plan) => plan.amount > 0)
      .map((plan) => {
        return {
          ...plan,
          subscribed: subscription.plan.name === plan.name,
          highlight: plan.name === highlightedName,
          amount: (plan.amount || 0) / 100.0,
        };
      });
    let sorted = _.sortBy(
      modifiedPlans.filter(
        (plan) => plan.interval === (monthly ? "month" : "year")
      ),
      ["amount"]
    );

    return [...sorted].map((plan, index) => {
      plan.icon = ICONS[index + 1];
      return plan;
    });
  }, [plans, monthly, subscription]);

  async function clickedUnsubscribe() {
    let response = await call(unsubscribe, undefined, {
      successMessage:
        "Your subscription has been scheduled for unsubscription at the end of the current billing period.",
    });
    if (response.ok) {
      let subscription = response.body;
      setAuth({ subscription }, true, false);
    }
  }

  let subscriptionStatusString = undefined;
  const interval = subscription?.plan?.interval;
  const trialEnd = moment.unix(subscription?.trial_end).format("MMMM Do YYYY");
  const planName = `${interval ? `${interval}ly ` : ""}${
    subscription?.plan?.name
  }`;

  let renewalInfo = "";
  if (!!subscription.cancel_at) {
    renewalInfo = ` It is scheduled to end on ${moment
      .unix(subscription.cancel_at)
      .format("MMMM Do YYYY")} and will not be renewed.`;
  } else if (!!subscription.current_period_end) {
    renewalInfo = ` It is scheduled to renew on ${moment
      .unix(subscription.current_period_end)
      .format("MMMM Do YYYY")}.`;
  }

  async function onRetry() {
    let response = await call(retryPayment, undefined, {
      successMessage: "Your payment has been successful!",
    });
    if (response.ok) {
      let subscription = response.body;
      setAuth({ subscription }, true, false);
    }
  }

  switch (subscriptionStatus) {
    case SUBSCRIPTION_STATUS.active:
      subscriptionStatusString = (
        <span className="subscription-status">
          Your <strong>{planName}</strong>&nbsp;subscription is active!
          {renewalInfo}
        </span>
      );
      break;
    case SUBSCRIPTION_STATUS.trialing:
      subscriptionStatusString = (
        <span>
          Your&nbsp;<strong>{planName}</strong>&nbsp;trial is active until&nbsp;
          <strong>{trialEnd}</strong>.{renewalInfo}
        </span>
      );
      break;
    case SUBSCRIPTION_STATUS.past_due:
      subscriptionStatusString = (
        <>
          <span>
            Your&nbsp;<strong>{planName}</strong>&nbsp;subscription is past due.
            Please update your payment details.
          </span>
          <div className="retry">
            <MyButton
              loading={loading[unsubscribe]}
              className="red mx-2"
              onClick={clickedUnsubscribe}
            >
              Unsubscribe
            </MyButton>
            <MyButton
              loading={loading[retryPayment]}
              className="secondary mx-2"
              onClick={onRetry}
            >
              Retry Payment
            </MyButton>
          </div>
        </>
      );
      break;
    case SUBSCRIPTION_STATUS.unsubscribed:
      subscriptionStatusString = (
        <span>
          You are on the <strong>{freePlan?.name}</strong> plan.
        </span>
      );
      break;
  }

  if (subscriptionStatus === SUBSCRIPTION_STATUS.past_due) {
    subscriptionStatusString =
      "Your subscription status is past due. Please update your payment details to continue using Ludo.";
  } else if (cache.trialExpired) {
    subscriptionStatusString =
      "You are out of credits and you don't currently have a Ludo subscription. Choose an option below to continue using Ludo.";
  } else if (isFreePlan(subscription.plan)) {
    subscriptionStatusString = "You are currently using the Ludo free plan.";
  }

  return (
    <div className="subscriptions">
      {!!selectedPlan ? (
        <SubscriptionFlow
          plan={selectedPlan}
          onSuccess={onSuccess}
          goBack={() => setSelectedPlan(undefined)}
        />
      ) : isLoading ? (
        <div className="text-align-center mt-4 pt-4">
          <CircularProgress size={55} />
        </div>
      ) : (
        <>
          <Grid container className="text-center mt-4">
            <Grid item md={12}>
              {subscriptionStatusString}
            </Grid>
          </Grid>
          <div className="all-plans">
            {oneTimePlans.length > 0 && (
              <div className="plans-wrapper">
                <div className="monthly-switch">
                  <span>&nbsp;</span>
                </div>
                <div className="just-cards">
                  {oneTimePlans.map((plan, index) => {
                    return (
                      <PlanCard
                        key={plan.id || index}
                        plan={plan}
                        onSelectPlan={setSelectedPlan}
                        onSuccess={onSuccess}
                        special={SPECIAL[plan.name]}
                        clickAnywhere={true}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className="plans-wrapper">
              <div className="monthly-switch">
                <hr/>
                <span>Monthly</span>
                <IOSSwitch
                  checked={monthly === false}
                  name="monthly"
                  defaultChecked
                  inputProps={{"aria-label": "secondary checkbox"}}
                  onChange={() => setMonthly(!monthly)}
                />
                <span>Annual</span>
                <hr/>
              </div>
              <div className="just-cards">
                {periodPlans.map((plan, index) => {
                  return (
                    <PlanCard
                      key={plan.id || index}
                      plan={plan}
                      onSelectPlan={setSelectedPlan}
                      onSuccess={onSuccess}
                      special={SPECIAL[plan.name]}
                      clickAnywhere={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <Grid
            container
            className="text-center mt-4 mx-auto"
            style={{ display: "flex", flexDirection: "column", gap: "15px", maxWidth: "700px" }}
          >
            <span>1 credit = 1 generation</span>
            <span>
              Example: When you click the <b>Generate</b> button in the Game
              Ideator, Ludo creates <b>3 Game Ideas</b> at once. This counts as
              a <b>single generation action</b> and costs only <b>1 credit</b>.
            </span>
          </Grid>
        </>
      )}
    </div>
  );
};

export function isFreePlan(plan = {}, subscription) {
  return subscription ? (
    plan.id === FREE_PLAN_ID ||
    subscription?.status !== SUBSCRIPTION_STATUS.active
  ) : plan.id === FREE_PLAN_ID;
}

export function isTeamsPlan(subscription = {}) {
  return subscription.plan?.metadata?.team === "true";
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#5780F7" : "#5780F7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#5780F7",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default Plans;
