import React, {useState} from 'react';
import clsx from 'clsx';

import { connect } from 'react-redux';

import {
  SidebarMenu,
} from '../../layout-components';

const Sidebar = (props) => {

  const [simulateHover, setSimulateHover] = useState(false);

  const {
    sidebarStyle,
    sidebarShadow,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    sidebarToggle,
    onToggleMenu
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx("app-sidebar", sidebarStyle, {
          'app-sidebar--shadow': sidebarShadow,
          'simulate-hover': simulateHover
        })}>
        <div className="app-sidebar--content">
          <SidebarMenu key={sidebarToggle} onToggleMenu={onToggleMenu} setSimulateHover={setSimulateHover} />
        </div>
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

export default connect(mapStateToProps)(Sidebar);
