import React from 'react';
import {IconButton, Tooltip} from "@material-ui/core";
import {HelpOutline} from "@mui/icons-material";

const HelpIcon = ({text, className}) => {
  return (
    <Tooltip
      title={text}
      aria-label={text}
      PopperProps={{disablePortal: true, className: "MuiTooltip-popper secondary"}}
    >
      <IconButton aria-label={text} disableRipple={true} className={className}>
        <HelpOutline
          className="font-size-lg"
        />
      </IconButton>
    </Tooltip>
  );
};

export default HelpIcon;
