import React, {useContext, useMemo, useState} from 'react';
import APIContext from "context/APIContext";
import {
  Typography,
  CircularProgress,
  Grid, IconButton, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import {Form, Formik} from "formik";
import FormikChipSelect from "components/Controls/FormikChipSelect";
import {FormikCheckboxField, FormikSelectField} from "formik-material-fields";
import ShowIf from "components/common/ShowIf";
import {GeneratingButton} from "components/Controls/MyButton";
import PageTitle from "components/layout-components/PageTitle";
import * as Yup from 'yup';
import './style.scss';
import CacheContext from "context/CacheContext";
import FormikPersist from 'components/utils/FormikPersist';
import usePersistedState from "hooks/usePersistedState";
import {TextFavoriteButton} from "components/common/FavoriteButton";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {FilterPanel, FiltersButton} from "pages/Search";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
} from "components/Controls/UniversalInput";
import SocketContext from "context/SocketContext";
import PerformanceUtils from "helpers/PerformanceUtils";
import AuthContext from "context/AuthContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {FileCopyOutlined, Sync} from "@mui/icons-material";
import LoadingTip, {LOADING_TIPS_SECTIONS} from "components/utils/LoadingTip";
import {prepareGeneratedImage} from "pages/ImageGenerator";
import AddToGDD from "pages/GDD3/AddToGDD";
import {GeneratedImage} from "../../components/common/GeneratedGameCard";

const generateGameElementsUniversal = 'generateGameElementsUniversal';
const cancelGeneration = 'cancelGeneration';
const generateImagesForGenerations = 'generateImagesForGenerations';

const DEFAULT_ARRAY = [];

var currentImageGenerationId = undefined;

const TYPES = [
  'mechanics', 'howtoplay', 'characters', 'story', 'universe', 'levels', 'items', 'features', 'controls', 'location'
]

const FeatureGenerator = () => {

  const {auth} = useContext(AuthContext);
  const {track} = useContext(SocketContext);
  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {
    genres = DEFAULT_ARRAY,
    platforms = DEFAULT_ARRAY,
    generationStyles = DEFAULT_ARRAY,
    generationTypes = DEFAULT_ARRAY,
    gddComponents
  } = cache;

  const [loading, setLoading] = useState(false);
  const [persistedData, setPersistedData] = useState();
  const [currentBatchId, setCurrentBatchId] = useState();
  const [results, setResults] = usePersistedState('FeatureGenerator.result', []);
  const [collapsed, setCollapsed] = usePersistedState("FeatureGenerator.collapsed", true, true);
  const [imageOptions, setImageOptions] = useState({});

  const generate = async (values) => {

    let {search, genres, platform, generated_type, generateImages, art_style} = values;
    let data = {
      request_id: PerformanceUtils.generateId(),
      ...convertUniversalInput(search),
      filters: {
        genres,
        platform,
      }
    };
    track('game-elements.' + generated_type, data)
    setLoading(true);
    setResults([]);
    setCurrentBatchId(data.request_id);

    let imageOptions = generateImages ? {
      genres,
      platform,
      art_style,
      type: generated_type,
    } : undefined

    if (currentImageGenerationId) onCancel(currentImageGenerationId);
    let response = await call(generateGameElementsUniversal, {type: generated_type, data});
    if (response.ok) {
      setResults(response.body);
      if (imageOptions && response.body.length > 0) {
        setImageOptions(imageOptions);
        doGenerateImages(response.body, imageOptions, true).catch(console.log)
      }
    }
    setLoading(false);
  };

  async function doGenerateImages(components, {type, art_style, genres, platform}, noCredits = false) {

    if (!generationTypes.includes(type)) return;

    let data = {
      request_id: PerformanceUtils.generateId(noCredits ? '00000000' : undefined),
      components: components.map(component => {
        return {
          ...component,
          type: component.type || type
        }
      }),
      art_style,
      filters: {
        genres,
        platform
      }
    }

    currentImageGenerationId = data.request_id;
    let response = await call(generateImagesForGenerations, {data});
    currentImageGenerationId = undefined

    if (response.ok && response.body.length > 0) {
      let changedComponents = components.map((component, index) => {
        let image = prepareGeneratedImage(response.body[index]);
        component.image = image.originalImage;
        return component;
      });

      setResults(prevState => {
        return prevState.map(component => {
          return changedComponents.find(({id}) => id === component.id) || component;
        })
      });
    }
  }

  async function onNewImage(component) {
    setResults(prevState => {
      return prevState.map(result => {
        if (result.id === component.id)
          delete result.image;
        return result;
      })
    })
    await doGenerateImages([component], imageOptions, false);
  }

  function onCancel(generationId) {
    if (generationId) {
      call(cancelGeneration, {generationId}, {hideErrorMessage: true});
    }
  }

  function getTitle(item) {
    if (item) {
      return gddComponents[item.type].description;
    }
  }

  const initialValues = {
    search: [],
    genres: auth.user.genres || [],
    platform: auth.user.platform || "Mobile",
    elementType: TYPES[0],
    generated_type: gddComponents.mechanics.section,
    generateImages: true,
    art_style: generationStyles[0]
  }

  return (
    <div className="w-100 game-elements">
      <PageTitle
        titleHeading="Game Elements"
        titleDescription="Generate game elements for your game idea."
      >
        {/*<MechanicsTutorial/>*/}
      </PageTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={generate}
        validationSchema={ValidationSchema}
        validateOnChange={true}
      >
        {formik => (
          <>
            <div className="form-wrapper pb-3">
              <Form>
                <FormikPersist name="FeatureGenerator3" onLoad={(data) => setPersistedData(data)}/>
                <ShowIf condition={!!persistedData}>
                  <ChangeDataOnLocation
                    onAction={generate}
                    initialValues={initialValues}
                    shouldOverride={data => !!data.edit}
                    fields={["search", "genres"]}
                  />
                </ShowIf>
                <div className="d-flex flex-column">
                  <Grid
                    container
                  >
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid item sm={12} md={12} className="input-fields-wrapper">
                        <div className="d-flex input-fields">
                          <FormikSelectField
                            className="mode-field"
                            name="generated_type"
                            label="Game Element"
                            options={gddComponents
                              .filter(component => !component.hide && component.gameElements)
                              .map(component => {
                                return {
                                  value: component.section,
                                  label: (
                                    <div className="d-flex flex-row align-items-center">
                                      <span className="font-weight-bold ml-2">
                                  {component.label}
                                </span>
                                    </div>)
                                }
                              })}
                            fullWidth
                          />
                          <UniversalInput
                            name="search"
                            label="Leave blank, or type keywords, phrases, or game titles"
                            formik={formik}
                            onSetData={(data) => {
                              formik.setFieldValue("search", data);
                            }}
                            value={formik.values.search}
                          />
                        </div>
                        <div className="d-flex align-self-center">
                          <GeneratingButton
                            id="elements.generate"
                            loading={loading}
                            onCancel={() => {
                              onCancel(currentBatchId);
                              setCurrentBatchId(undefined);
                            }}
                            loadProgressSecs={10}
                            style={{margin: 0}}
                            trackOptions={{
                              ...formik.values,
                              search: convertUniversalInput(formik.values.search),
                            }}
                          >
                            Start New Generation
                          </GeneratingButton>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="mt-3">
                        <FiltersButton
                          collapsed={collapsed}
                          setCollapsed={setCollapsed}
                          page="game-elements"/>
                      </Grid>
                      <ShowIf condition={!collapsed}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormikChipSelect
                            name="genres"
                            title="Genres"
                            values={genres}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormikSelectField
                            className="mt-4"
                            name="platform"
                            label="Platform"
                            style={{top: "2px", position: "relative"}}
                            options={platforms.map(platform => {
                              return {value: platform, label: platform}
                            })}
                            fullWidth
                          />
                        </Grid>
                        <ShowIf condition={formik.values.generateImages}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormikSelectField
                              name="art_style"
                              label="Art Style"
                              options={generationStyles.map(value => {
                                return {
                                  value,
                                  label: (
                                    <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {value}
                                </span>
                                    </div>)
                                }
                              })}
                              onChange={event => setImageOptions(prevState => {
                                return {
                                  ...prevState,
                                  art_style: event.target.value
                                }
                              })}
                              fullWidth
                            />
                          </Grid>
                        </ShowIf>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormikCheckboxField
                            name="generateImages"
                            className={"p-0 mr-0 checkbox " + (formik.values.generateImages ? "checked " : "")}
                            trueValue={true}
                            falseValue={false}
                            color="secondary"
                            onChange={value => {
                              if (!value) {
                                formik.setFieldValue('art_style', null);
                              } else {
                                formik.setFieldValue('art_style', generationStyles[0]);
                              }
                            }}
                            label={
                              <span>Generate Images</span>
                            }
                          />
                        </Grid>
                      </ShowIf>
                      <ShowIf condition={collapsed}>
                        <Grid item sm={12} md={12}>
                          <FilterPanel onExpand={() => setCollapsed(false)}/>
                        </Grid>
                      </ShowIf>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            </div>
            <div className="generated-game-list">
              <LoadingTip
                style={{marginLeft: "30px", marginTop: "30px", marginBottom: "30px"}}
                section={LOADING_TIPS_SECTIONS.gameElements}
                visible={loading || results?.length === 0}
                key={loading}
              />
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <TextResult
                    title={getTitle((results || [])[0])}
                    loading={loading}
                    data={results}
                    onNewImage={onNewImage}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
};

export const TextResult = ({
                             title,
                             data = DEFAULT_ARRAY,
                             loading = false,
                             onReload,
                             columnsCountBreakPoints,
                             onClick,
                             onHover,
                             onNewImage
                           }) => {

  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;

  const defaultBreakpoints = detailsPanel?.id ? {600: 1, 1000: Math.min(2, data.length)} : {
    350: 1,
    600: 2,
    1000: Math.min(3, data.length)
  };

  return (
    <div className="mechanics" style={{maxWidth: "1300px"}}>
      <Typography className="mb-2 mt-2 mechanics-title text-primary">
        <ShowIf condition={!!title}>
          <span className="title">{title}</span>
        </ShowIf>
        <ShowIf condition={!!onReload && !!title}>
          <IconButton
            onClick={onReload}
            disabled={loading || !onReload}
            className="reload-button"
          >
            <CircularProgress size={15} hidden={!loading}/>
            <ShowIf condition={!loading}>
              <Sync
                className="font-size-lg pointer text-secondary"
              />
            </ShowIf>
          </IconButton>
        </ShowIf>
      </Typography>
      <ShowIf condition={data.length > 0}>
        <div className="mechanics-wrapper">
          <ResponsiveMasonry
            className="w-100"
            columnsCountBreakPoints={columnsCountBreakPoints || defaultBreakpoints}
          >
            <Masonry gutter="5px">
              {data.map((d, index) => (
                <SingleTextResult
                  data={d}
                  key={index}
                  onClick={onClick ? () => onClick(d) : undefined}
                  onHover={onHover}
                  onNewImage={onNewImage}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </ShowIf>
    </div>
  );
};

export const SingleTextResult = ({data, onClick, className = "", hideActions = false, onHover, onNewImage}) => {

  const {track} = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const {loading} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {gddComponents} = cache;

  function onActionsClick(event) {
    track('game-element.clicked-actions', {element: data});
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onActionsClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);
  }

  const typeData = gddComponents[data.type];

  const initials = useMemo(() => {
    let label = typeData?.label || "";
    return label.split(" ").map(str => str.charAt(0).toUpperCase()).join("");
  }, [data.type, typeData]);

  return (
    <div className={"mechanic " + className} onClick={onClick} onMouseEnter={onHover ? () => onHover(data) : undefined}>
      <GeneratedImage
        loading={loading[generateImagesForGenerations]}
        image={data.image}
        onNewImage={onNewImage ? () => onNewImage(data) : undefined}
        imageProps={{hideActions: true}}
        background={true}
      />
      {data.text}
      {initials &&
        <Tooltip title={typeData.label}>
          <div className="type-image">
            <span>{initials}</span>
          </div>
        </Tooltip>}
      <ShowIf condition={!hideActions}>
        <div className="element-actions">
          <TextFavoriteButton
            data={data}
            type={data.type}
            className="blue ml-2"
          />
          <IconButton aria-label="more actions" className="text-secondary more" onClick={onActionsClick}>
            <MoreVertIcon style={{height: "22px"}}/>
          </IconButton>
        </div>
      </ShowIf>
      <ShowIf condition={!!menuAnchorEl}>
        <ElementContextMenu
          element={data}
          onClose={onActionsClose}
          anchor={menuAnchorEl}
        />
      </ShowIf>
    </div>
  );
}

const ElementContextMenu = ({element, onClose, anchor}) => {

  const {track} = useContext(SocketContext);
  const [addToGdd, setAddToGdd] = useState(false);

  function clickedAddToGdd(event) {
    track('context.element.add-to-gdd');
    setAddToGdd(true);
  }

  return (
    <>
      {addToGdd ? <AddToGDD
        element={element}
        onClose={event => {
          setAddToGdd(false);
          onClose(event);
        }}
      /> : null}
      <Menu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={onClose}
      >
        <MenuItem onClick={clickedAddToGdd}>
          <div
            style={{display: "contents"}}
          >
            <ListItemIcon>
              <FileCopyOutlined/>
            </ListItemIcon>
            <ListItemText primary="Add to Game Concept"/>
          </div>
        </MenuItem>
      </Menu>
    </>
  )
};

const ValidationSchema = Yup
  .object({
    genres: Yup.array(),
  });
export default FeatureGenerator;
