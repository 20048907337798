import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
import { Badge, Chip, MenuItem, Select } from "@material-ui/core";
import { ProjectsModal } from "pages/Projects";
import logoSquare from "assets/images/logo-square.png";
import CacheContext from "context/CacheContext";
import ShowIf from "components/common/ShowIf";
import { ShareBox } from "components/Sharing";
import MyButton from "components/Controls/MyButton";
import { BillingModal } from "pages/Profile/Profile";
import { SUBSCRIPTION_STATUS } from "scenes/SubscriptionPage";
import AuthContext from "context/AuthContext";
import {
  AssessmentOutlined,
  BurstModeOutlined,
  ChatBubbleOutline,
  EmojiEventsOutlined,
  FavoriteBorderOutlined,
  FileCopyOutlined,
  HomeOutlined, InsightsOutlined,
  SearchOutlined,
  TrendingUpOutlined,
  VideoLibraryOutlined,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { Hint, MISSING_GENRES_MAP } from "../../../scenes/Headquarters";
import usePersistedState from "../../../hooks/usePersistedState";
import APIContext from "../../../context/APIContext";

const updateUserInfo = "updateUserInfo";
const getTrendOptions = "getTrendOptions";

const DEFAULT_ARRAY = [];

const SidebarMenu = ({ onToggleMenu, setSimulateHover }) => {
  const { cache, setCacheValue } = useContext(CacheContext);
  const { auth } = useContext(AuthContext);
  const { subscription } = auth;
  const { projectsOpen, allFavorites = DEFAULT_ARRAY, trialExpired } = cache;
  const [openBilling, setOpenBilling] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const openProjectsModal = projectsOpen;

  const subscriptionStatus =
    subscription?.status || SUBSCRIPTION_STATUS.unsubscribed;
  const isTrialing = subscriptionStatus === SUBSCRIPTION_STATUS.trialing;
  const showUpgradeButton = isTrialing || trialExpired;

  function onOpenVideoGeneratorMessage() {
    let message = (
      <div
        className="video-generator-placeholder"
        style={{ maxWidth: "400px" }}
      >
        <p>
          We're currently upgrading the Video Generator to bring you an even
          more powerful and seamless experience.
        </p>
        <p>
          Stay tuned for the launch of the new version, packed with features
          that will take your creativity to the next level.
        </p>
        <p>
          Thank you for your patience and support as we work on making our tools
          better for you. Keep an eye on our updates – something awesome is on
          the way!
        </p>
      </div>
    );
    let key = "video" + "" + moment().unix();
    enqueueSnackbar(message, {
      key,
      variant: "info",
      autoHideDuration: 5000,
      onClick: () => closeSnackbar(key),
    });
  }

  return (
    <div className="h-100">
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <ul>
            <div className="logo-wrapper">
              <div className="app-sidebar-logo">
                <NavLink
                  to="/home"
                  title="Ludo"
                  className="app-sidebar-logo minimized"
                >
                  <div className="app-sidebar-logo--icon2 d-flex">
                    <img
                      alt="Ludo"
                      width="60px"
                      height="60px"
                      src={logoSquare}
                    />
                    <span className="text-secondary font-weight-bold font-size-xxxxxxxl ml-3 align-self-center ludo-text">
                      LUDO
                    </span>
                  </div>
                </NavLink>
              </div>
            </div>
          </ul>
          {showUpgradeButton && (
            <ul>
              <BillingModal
                open={openBilling}
                onClose={() => setOpenBilling(false)}
              />
              <li>
                <MyButton
                  color="secondary"
                  className="upgrade-button align-self-center mb-2"
                  onClick={() => {
                    setOpenBilling(true);
                    onToggleMenu();
                  }}
                >
                  Subscriptions & Billing
                </MyButton>
              </li>
            </ul>
          )}
          <ul>
            <li>
              <NavLink end onClick={onToggleMenu} to="/home">
                <Icon name="Home" iconElement={<HomeOutlined />} />
                <span className="sidebar-item-label">Start Here</span>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-header">
            <span>Game Concept</span>
            <hr />
          </div>
          <ShareBox />
          <ProjectsModal
            open={openProjectsModal}
            onClose={() => setCacheValue("projectsOpen", false)}
          />
          <ul>
            <li>
              <NavLink onClick={onToggleMenu} to="/game-concept">
                <Icon name="Game Concept" iconElement={<FileCopyOutlined />} />
                <span className="sidebar-item-label">Game Concept</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onToggleMenu} to="/assistant">
                <Icon
                  name="Chat Assistant"
                  iconElement={<ChatBubbleOutline />}
                />
                <span className="sidebar-item-label">Chat Assistant</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onToggleMenu} to="/favorites">
                <Icon
                  name="Favorites"
                  iconElement={<FavoriteBorderOutlined />}
                  badge={allFavorites.length}
                />
                <span className="sidebar-item-label d-flex">Favorites</span>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-header">
            <span>Ideation</span>
            <hr />
          </div>
          <ul>
            <li>
              <NavLink onClick={onToggleMenu} to="/game-ideator">
                <Icon
                  name="Game Ideator"
                  iconElement={
                    <WbIncandescentOutlined className="flip-vertical" />
                  }
                />
                <span className="sidebar-item-label">Game Ideator</span>
              </NavLink>
            </li>
            {/*<li>
              <NavLink
                onClick={onToggleMenu}
                to="/game-elements">
                <Icon name="Game Elements" iconElement={<BuildOutlined/>}/>
                <span className="sidebar-item-label caps">
                  Game Elements
                </span>
              </NavLink>
            </li>*/}
            <li>
              <NavLink onClick={onToggleMenu} to="/image-generator">
                <Icon
                  name="Image Generator"
                  iconElement={<BurstModeOutlined />}
                />
                <span className="sidebar-item-label">Image Generator</span>
              </NavLink>
            </li>
            {/*<li>
              <a
                onClick={onOpenVideoGeneratorMessage}
              >
                <Icon name="Video Generator" iconElement={<VideoLibraryOutlined/>}/>
                <span className="sidebar-item-label">
                  Video Generator
                  <Chip
                    style={{height: "20px"}}
                    className="ml-2 font-size-xs bg-gradient"
                    label="BETA"
                  />
                </span>
              </a>
            </li>*/}
            {/*<li>
              <a
                onClick={onOpenVideoGeneratorMessage}
              >
                <Icon name="Video Generator" iconElement={<VideoLibraryOutlined/>}/>
                <span className="sidebar-item-label">
                  Video Generator Plus
                  <Chip
                    style={{height: "20px"}}
                    className="ml-2 font-size-xs bg-gradient"
                    label="BETA"
                  />
                </span>
              </a>
            </li>*/}
          </ul>
          <div className="sidebar-header">
            <span>Market Intelligence</span>
            <hr />
          </div>
          <ul>
            <li>
              <NavLink onClick={onToggleMenu} to="/trends">
                <Icon
                  name="Daily Trends"
                  iconElement={<InsightsOutlined />}
                />
                <span className="sidebar-item-label">Market Trends</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onToggleMenu} to="/market-analysis">
                <Icon
                  name="Market Analysis"
                  iconElement={<AssessmentOutlined />}
                />
                <span className="sidebar-item-label">Market Analysis</span>
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-header">
            <span>Research</span>
            <hr />
          </div>
          <ul>
            <li>
              <NavLink onClick={onToggleMenu} to="/top-charts">
                <Icon
                  name="Top Charts Blender"
                  iconElement={<EmojiEventsOutlined />}
                />
                <span className="sidebar-item-label">Top Charts Blender</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onToggleMenu} to="/search">
                <Icon name="Search" iconElement={<SearchOutlined />} />
                <span className="sidebar-item-label">Search</span>
              </NavLink>
            </li>
          </ul>
          <ul className="settings-wrapper">
            <li>
              <Settings
                onOpen={() => setSimulateHover(true)}
                onClose={() => setSimulateHover(false)}
              />
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const Settings = ({ onOpen, onClose }) => {
  return (
    <div className="settings">
      <span className="title">
        Global Settings
        <Hint
          hint="These will be your new defaults for all Ludo tools"
          iconClassName="help"
        />
      </span>
      <div className="inputs">
        <GenreSelection onOpen={onOpen} onClose={onClose} />
        <PlatformSelection onOpen={onOpen} onClose={onClose} />
      </div>
    </div>
  );
};

export const GenreSelection = ({ onOpen, onClose }) => {
  const { call } = useContext(APIContext);
  const [allGenres, setAllGenres] = usePersistedState(
    "Settings.allGenres",
    undefined
  );
  const { auth, setAuth } = useContext(AuthContext);

  useEffect(() => {
    if (!allGenres) {
      call(getTrendOptions, { type: "charts" }).then((response) => {
        if (response.ok) {
          setAllGenres(response.body.genres);
        }
      });
    }
  }, [allGenres]);

  const genre = useMemo(() => {
    let genre = "All";
    if (!!auth.user.genres) {
      let genres = auth.user.genres;
      genre = genres[0] || "All";
      genre = MISSING_GENRES_MAP[genre] || genre;
    }
    return genre;
  }, [auth.user.genres]);

  async function onSelectGenre(genre) {
    let response = await call(updateUserInfo, { data: { genres: [genre] } });
    if (response.ok) {
      setAuth({ user: response.body }, true, false);
    }
  }

  return (
    <div className="genre-field">
      <Select
        name="genre"
        value={genre}
        defaultValue={genre}
        onClose={onClose}
        onOpen={onOpen}
        onChange={(event) => {
          onSelectGenre(event.target.value);
        }}
      >
        {(allGenres || []).map((genre) => (
          <MenuItem key={genre} value={genre}>
            <span>{genre === "All" ? "All Genres" : genre}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const PlatformSelection = ({ chips = false, onOpen, onClose }) => {
  const { call } = useContext(APIContext);
  const { auth, setAuth } = useContext(AuthContext);

  const platform = useMemo(() => {
    return auth.user.platform || "Mobile";
  }, [auth.user.platform]);

  async function onSelectPlatform(platform) {
    let response = await call(updateUserInfo, { data: { platform } });
    if (response.ok) {
      setAuth({ user: response.body }, true, false);
    }
  }

  return chips ? (
    <div className="platform-field">
      <Chip
        label="Mobile"
        className={platform === "Mobile" ? "active" : ""}
        onClick={() => onSelectPlatform("Mobile")}
      />
      <Chip
        label="Desktop"
        className={platform === "Desktop" ? "active" : ""}
        onClick={() => onSelectPlatform("Desktop")}
      />
    </div>
  ) : (
    <div className="platform-field">
      <Select
        name="platform"
        label="Platform"
        value={platform}
        defaultValue={platform}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(event) => {
          onSelectPlatform(event.target.value);
        }}
      >
        {["Mobile", "Desktop"].map((platform) => (
          <MenuItem key={platform} value={platform}>
            <span>{platform}</span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

const Icon = ({ iconElement, style, badge }) => {
  return (
    <span className="sidebar-icon" style={style}>
      {iconElement}
      <ShowIf condition={!!badge}>
        <Badge
          badgeContent={badge}
          color="secondary"
          className="animate__animated animate__heartBeat ml-5"
          key={badge}
        />
      </ShowIf>
    </span>
  );
};

export default withRouter(SidebarMenu);
