import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import Gallery from "react-photo-gallery";
import { ImageFavoriteButton } from "components/common/FavoriteButton";
import config from "config/config.json";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { ShareContextMenu } from "components/Sharing";
import DetailsPanelContext from "context/DetailsPanelContext";
import APIContext from "context/APIContext";
import { useHistory, useLocation } from "react-router";
import SocketContext from "context/SocketContext";
import CacheContext from "context/CacheContext";
import { SEARCH_RESULTS_MESSAGE } from "scenes/SubscriptionPage/Plans";
import _ from "lodash";
import {
  Download,
  FileCopyOutlined,
  ModeEditOutlined,
  SearchOutlined,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import { toBase64 } from "components/Controls/FileUpload";
import AddToGDD from "pages/GDD3/AddToGDD";
import ImageViewer from "./ImageViewer";
import ImageEditor from "../../pages/ImageGenerator/ImageEditor";

const getGamesInformation = "getGamesInformation";

const DEFAULT_ARRAY = [];

export function prepareImage(image) {
  let url = convertProxyUrl(image) || "";
  return {
    ...image,
    width: image.width || 300,
    height: image.height || 300,
    src: url,
    originalImage: { ...image },
  };
}

const ImageGallery = ({
  images = DEFAULT_ARRAY,
  height = 250,
  onImageClick,
  onImageClickFunc,
  minImages = 0,
  hideActions = false,
  imageControls,
  customImageClassname,
  imageStyle,
  enforceSize = false,
  blurred,
  blurredAfter,
  onHover,
  showNSFW,
  imageWrapper,
  contextMenuOverrides,
  dontExpand,
}) => {
  const modifiedImages = useMemo(() => {
    let imgs = images.map((image) => prepareImage({ ...image }));

    let existing = imgs.find((img) => !!img?.width);

    let width = existing?.width || 300;
    let height = existing?.height || 300;

    while (imgs.length < minImages) {
      imgs.push({
        className: "fake",
        fake: true,
        originalImage: { id: imgs.length, width, height },
        width,
        height,
        src: "",
      });
    }

    return _.uniqBy(imgs, "originalImage.id");
  }, [images, minImages]);

  function imageWrapperFunction(photo, width, height, component) {
    if (imageWrapper && !photo.fake)
      return imageWrapper(photo.originalImage, width, height, component);
    return component;
  }

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) =>
      imageWrapperFunction(
        photo,
        photo.width,
        photo.height,
        <MyImage
          key={photo.id || photo.url}
          image={photo}
          onImageClick={onImageClick}
          onImageClickFunc={onImageClickFunc}
          hideActions={hideActions}
          imageControls={imageControls}
          customImageClassname={customImageClassname}
          imageStyle={imageStyle}
          enforceSize={enforceSize}
          blurred={blurred || (blurredAfter && index >= blurredAfter)}
          onHover={onHover}
          showNSFW={showNSFW}
          contextMenuOverrides={contextMenuOverrides}
          dontExpand={dontExpand}
        />
      ),
    [modifiedImages, imageWrapperFunction]
  );

  return (
    <div className="gallery-wrapper">
      {images.length > 0 && (
        <Gallery
          key={images.length}
          photos={modifiedImages}
          targetRowHeight={height}
          renderImage={imageRenderer}
        />
      )}
    </div>
  );
};

const ImageContextMenu = ({ image, onClose, anchor, overrides = {} }) => {
  const history = useHistory();
  const { track } = useContext(SocketContext);
  const [addToGdd, setAddToGdd] = useState(false);

  async function searchSimilar(event) {
    track("context.image.search-similar", { game_id: image?.game_id });
    let data = {
      image: image.originalImage || image,
    };
    if (overrides.searchSimilar) {
      return overrides.searchSimilar(data);
    }
    let url = "/search";
    history.push(url, { data });
  }

  function clickedAddToGdd(event) {
    track("context.image.add-to-gdd");
    setAddToGdd(true);
  }

  return (
    <>
      {addToGdd ? (
        <AddToGDD
          image={image}
          onClose={(event) => {
            setAddToGdd(false);
            onClose(event);
          }}
        />
      ) : null}
      <Menu anchorEl={anchor} keepMounted open={!!anchor} onClose={onClose}>
        <MenuItem onClick={clickedAddToGdd}>
          <ListItemIcon>
            <FileCopyOutlined />
          </ListItemIcon>
          <ListItemText primary="Add to Game Concept" />
        </MenuItem>
        <MenuItem onClick={onClose}>
          <span style={{ display: "contents" }} onClick={searchSimilar}>
            <ListItemIcon>
              <SearchOutlined />
            </ListItemIcon>
            <ListItemText
              className="d-inline-block"
              primary={`Search similar`}
            />
          </span>
        </MenuItem>
        <MenuItem>
          <span
            onClick={() => {
              track("context.image.download");
              downloadImage(image);
            }}
            style={{ display: "contents" }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText className="d-inline-block" primary="Download" />
          </span>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <ShareContextMenu data={{ image }} />
        </MenuItem>
      </Menu>
    </>
  );
};

export const MyImage = ({
  image,
  onImageClick,
  onImageClickFunc,
  customImageClassname,
  imageStyle = {},
  imageControls,
  hideActions,
  convertUrl = false,
  tooltipOpen,
  enforceSize,
  blurred,
  onHover,
  showNSFW = false,
  contextMenuOverrides,
  dontExpand = false,
  children,
}) => {
  const nsfw = image.originalImage.is_safe === false;

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const { setCacheValue } = useContext(CacheContext);
  const { showImage } = useContext(DetailsPanelContext);
  const { track } = useContext(SocketContext);
  const [blur, setBlur] = useState(nsfw && !showNSFW);
  const [showImageViewer, setShowImageViewer] = useState(false);

  let src = convertUrl ? convertProxyUrl(image.originalImage, true) : image.src;

  async function onClick() {
    track("game-image.clicked-image", { image: image.originalImage });

    if (blurred) {
      setCacheValue("lockScreen", SEARCH_RESULTS_MESSAGE);
      return;
    }

    if (onImageClickFunc) {
      onImageClickFunc(image.originalImage);
    } else if (onImageClick && !image.originalImage.embedded) {
      showImage(image.originalImage);
    } else if (!dontExpand) {
      setShowImageViewer(true);
    }
  }

  function onActionsClick(event) {
    track("game-image.clicked-actions", { image: image.originalImage });
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onActionsClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);
  }

  let imageClassName = "rounded";
  if (onImageClick || !!onImageClickFunc) imageClassName += " pointer";

  let className = "my-image";

  if (customImageClassname)
    className += " " + customImageClassname(image.originalImage);
  if (blurred || blur) className += " blurred";
  if (image.className) className += ` ${image.className}`;

  if (enforceSize) {
    imageStyle = {
      ...imageStyle,
      minWidth:
        (image.originalImage.width || image.originalImage.height) + "px",
      maxWidth:
        (image.originalImage.width || image.originalImage.height) + "px",
      minHeight:
        (image.originalImage.height || image.originalImage.width) + "px",
      maxHeight:
        (image.originalImage.height || image.originalImage.width) + "px",
    };
  }

  return (
    <>
      {showImageViewer && (
        <ImageViewer src={src} onClose={() => setShowImageViewer(false)} />
      )}
      <div
        className={className}
        id={image.originalImage?.id}
        style={imageStyle}
        onMouseEnter={onHover ? () => onHover(image.originalImage) : undefined}
        onMouseLeave={onHover ? () => onHover(undefined) : undefined}
      >
        {imageControls && !!image.src
          ? imageControls(image.originalImage)
          : null}
        {blurred ? <div className="blur-top" onClick={onClick} /> : null}
        <img
          width={image.width}
          height={image.height}
          alt={image.title}
          src={src}
          className={imageClassName}
          style={{
            maxWidth: "100%",
            minWidth: enforceSize ? "100%" : undefined,
            minHeight: enforceSize ? "100%" : undefined,
            maxHeight: enforceSize ? "100%" : undefined,
            visibility: image.src ? "visible" : "hidden",
            height: imageStyle?.height,
          }}
          onClick={onClick}
        />
        {blur && (
          <div className="nsfw-warning" onClick={() => setBlur(false)}>
            <div className="nsfw-content">
              <span>Potential NSFW content detected.</span>
              <span>Click to reveal.</span>
            </div>
          </div>
        )}
        {!!image.originalImage?.url && (!hideActions || !!imageControls) && (
          <>
            <div className="gradient-top clickable" onClick={onClick} />
            {!hideActions && (
              <>
                <div className="image-actions">
                  <ImageFavoriteButton
                    image={image.originalImage}
                    className="white"
                    tooltipOpen={tooltipOpen}
                    placement="left"
                  />
                  <GenerateSimilarImageButton
                    image={image.originalImage}
                    overrides={contextMenuOverrides}
                  />
                  <IconButton
                    aria-label="more actions"
                    className="text-white"
                    onClick={onActionsClick}
                  >
                    <MoreVertIcon style={{ height: "22px" }} />
                  </IconButton>
                </div>
                {!!menuAnchorEl && (
                  <ImageContextMenu
                    image={image?.originalImage}
                    onClose={onActionsClose}
                    anchor={menuAnchorEl}
                    overrides={contextMenuOverrides}
                  />
                )}
              </>
            )}
          </>
        )}
        {children}
      </div>
    </>
  );
};

const GenerateSimilarImageButton = ({ image, overrides }) => {
  const { track } = useContext(SocketContext);
  const [editImage, setEditImage] = useState(false);

  const label = `Edit ${image.icon ? "icon" : "image"}`;

  async function onClick(event) {
    track("game-image.edit-image", { image });
    setEditImage(true);
  }

  if (overrides?.generateSimilar === null) {
    return null;
  }

  return (
    <div className="favorite-button-wrapper">
      {editImage && (
        <ImageEditor
          url={image?.url}
          initialImage={image}
          onClose={() => setEditImage(false)}
          formValues={{
            image_type: image.icon ? "icon" : "screenshot",
          }}
          onResults={
            overrides?.onGenerateSimilarResults
              ? (results) => {
                  overrides.onGenerateSimilarResults(results);
                  setEditImage(false);
                }
              : undefined
          }
        />
      )}
      <Tooltip
        title={label}
        PopperProps={{
          disablePortal: true,
          className: "MuiTooltip-popper secondary",
        }}
        placement="left"
      >
        <span>
          <IconButton
            aria-label={label}
            className="text-white"
            onClick={onClick}
          >
            <ModeEditOutlined style={{ height: "22px" }} />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export const IconGallery = ({
  icons = DEFAULT_ARRAY,
  width = 95,
  spacing = 3,
  defaultDetails = false,
  showDetailsControl = true,
  blurred,
  blurredAfter,
  onImageClickFunc,
  onHover,
  showNSFW,
}) => {
  const { call } = useContext(APIContext);
  const { setCacheValue } = useContext(CacheContext);
  const [details, setDetails] = useState(defaultDetails);
  const [games, setGames] = useState({});
  const { showGame, showDeveloper } = useContext(DetailsPanelContext);

  const justify = showDetailsControl ? "left" : "center";

  useEffect(() => {
    const gameIds = icons
      .map(({ game_id }) => game_id)
      .filter((id) => !!id && !games[id]);
    if (gameIds.length > 0) {
      call(getGamesInformation, { data: { ids: gameIds } }).then((response) => {
        let isNewSearch = icons.length < Object.values(games).length;
        let newGames = isNewSearch ? {} : { ...games };
        if (response.ok) {
          response.body.forEach((game) => {
            newGames[game._id] = game;
          });
        }

        gameIds.forEach((id) => {
          newGames[id] = newGames[id] || {};
        });

        setGames(newGames);
      });
    }
  }, [icons, games]);

  function openBlurredMessage() {
    setCacheValue("lockScreen", SEARCH_RESULTS_MESSAGE);
  }

  function onClickTitle(image, shouldBlur) {
    if (shouldBlur) return openBlurredMessage();
    const game = games[image.game_id];
    if (game) showGame(game);
  }

  function onClickDeveloper(image, shouldBlur) {
    if (shouldBlur) return openBlurredMessage();
    const game = games[image.game_id];
    if (game) {
      const { developers, source } = game;
      showDeveloper(developers[0], source);
    }
  }

  return (
    <div className="icon-gallery">
      {showDetailsControl && icons.length > 0 && (
        <div className="mode-icon" onClick={() => setDetails(!details)}>
          {!details && (
            <>
              <span>Show Detailed View</span>
              <ViewList className="font-size-lg ml-2" />
            </>
          )}
          {!!details && (
            <>
              <span>Show Simple View</span>
              <ViewModule className="font-size-lg ml-2" />
            </>
          )}
        </div>
      )}
      <Grid container spacing={spacing} justifyContent={justify}>
        {icons.map((image, index) => {
          const title = (games[image.game_id] || {}).title;
          const developer = ((games[image.game_id] || {}).developers || [])[0];
          const tooltip = `${title} (${developer})`;
          const shouldBlur = blurred || (blurredAfter && index >= blurredAfter);

          return (
            <Grid item key={image.id || image.url} md="auto">
              <div className="icon-wrapper">
                <Tooltip
                  key={details}
                  title={tooltip}
                  arrow
                  open={details || !title ? false : undefined}
                  placement="left"
                  PopperProps={{
                    className:
                      "MuiTooltip-popper MuiTooltip-popperArrow secondary",
                  }}
                >
                  <div>
                    <MyImage
                      key={image?.id || image?.url}
                      onImageClick={true}
                      image={{
                        src: image?.url,
                        title: "",
                        width,
                        originalImage: image,
                      }}
                      tooltipOpen={details ? undefined : false}
                      convertUrl={true}
                      blurred={shouldBlur}
                      onImageClickFunc={onImageClickFunc}
                      onHover={onHover}
                      showNSFW={showNSFW}
                    />
                  </div>
                </Tooltip>
                {!!details && (
                  <div className={"details " + (shouldBlur ? "blurred" : "")}>
                    <CircularProgress
                      size={15}
                      hidden={!!games[image.game_id]}
                    />
                    {!!games[image.game_id] && (
                      <>
                        <span
                          className="title"
                          onClick={() => onClickTitle(image, shouldBlur)}
                        >
                          {shouldBlur ? "" : title}
                        </span>
                        <span
                          className="developer"
                          onClick={() => onClickDeveloper(image, shouldBlur)}
                        >
                          {shouldBlur ? "" : developer}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ImageGallery;

export function convertProxyUrl(image = {}, force = false) {
  if (
    (!!image.hosted_filename && force) ||
    (!!image.hosted_filename && (image?.url || "").includes("google"))
  )
    return `${config.API_PROTOCOL}://${
      config.API_HOST
    }/api/images/original/${encodeURIComponent(image.hosted_filename)}`;
  return image.url;
}

const FakeTransitionComponent = ({ children }) => children;
export const ImageEnlarge = ({ image, placement, anchor, ...props }) => {
  const vertical = image.width < image.height;

  return (
    <Tooltip
      placement={placement}
      className="large-tooltip"
      enterNextDelay={3}
      enterDelay={0}
      leaveDelay={0}
      enterTouchDelay={0}
      leaveTouchDelay={0}
      TransitionComponent={FakeTransitionComponent}
      PopperProps={{
        anchorEl: anchor,
        className: "image-tooltip",
      }}
      title={
        <img
          src={convertProxyUrl(image)}
          alt={image.title}
          className={vertical ? "vertical" : "horizontal"}
        />
      }
    >
      {props.children}
    </Tooltip>
  );
};

export function cleanImage(image) {
  return { ...image, src: undefined };
}

export async function downloadImage(image) {
  // Create the filename
  const defaultName =
    image.game_id ||
    image.hosted_filename ||
    image.id ||
    image.game_id ||
    "image";
  const type = image.type || "gen";
  const hints = Array.isArray(image.hints) ? image.hints : [];
  const hintString = hints.join(" ").replace(/\W+/g, "-").slice(0, 50);
  let name = `${type}-${hintString || defaultName}`;

  const urlToBase64 = async () => {
    let url = convertProxyUrl(image, true);
    const response = await fetch(url);
    const blob = await response.blob();

    // Determine the file extension based on the blob type
    let extension;
    switch (blob.type) {
      case "image/jpeg":
        extension = ".jpg";
        break;
      case "image/png":
        extension = ".png";
        break;
      case "image/gif":
        extension = ".gif";
        break;
      case "image/webp":
        extension = ".webp";
        break;
      default:
        extension = ".jpg"; // Default to .jpg if type is unknown
    }

    // Add the file extension to the name
    name += extension;

    let file = new File([blob], name, { type: blob.type });
    return toBase64(file, false);
  };

  let link = document.createElement("a");
  link.download = name;
  link.href = await urlToBase64();
  link.click();
}
