import {CircularProgress} from "@material-ui/core";
import qs from 'qs';
import React, {useContext, useEffect, useState} from "react";
import APIContext from "context/APIContext";
import {useHistory, useLocation} from "react-router";
import CacheContext from "context/CacheContext";
import ShowIf from "components/common/ShowIf";
import {mergeTeam} from "pages/Team";
import AuthContext from "context/AuthContext";
import moment from "moment";
import {useSnackbar} from "notistack";
import {LockIcon} from "components/Controls/MyButton";
import {BillingModal} from "pages/Profile/Profile";
import SocketContext from "context/SocketContext";
import {isTeamsPlan} from "scenes/SubscriptionPage/Plans";
import slackIcon from 'assets/images/icons/slack.svg';

const shareToSlack = 'shareToSlack';

export const ShareToSlackButton = ({data, onClose, onClick}) => {
  const {track} = useContext(SocketContext);
  const {auth} = useContext(AuthContext);
  const {call, loading} = useContext(APIContext);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const {subscription} = auth;
  const [openBilling, setOpenBilling] = useState(false);


  let canUseTeam = isTeamsPlan(subscription);

  async function localOnClick() {
    track('share-to-slack.click');
    if (canUseTeam) {

      if (onClick) return onClick();

      if (!loading[shareToSlack]) {
        let team = (auth.user.teams || [])[0];
        if (!team) return showInfoMessage('Sharing can only be used if you are a member of a team', enqueueSnackbar, closeSnackbar);
        let response = await sendToSlack(call, loading, {...data, team});
        if (onClose && response.ok)
          onClose();
      }
    } else {
      setOpenBilling(true);
    }
  }

  const isLoading = !!loading[shareToSlack]

  return (
    <>
      <BillingModal
        open={openBilling}
        onClose={() => setOpenBilling(false)}
      />
      <div className="action-chip hvr-grow" onClick={localOnClick}>
        <span>Slack</span>
        <ShowIf condition={!isLoading}>
          <center>
          <img alt="Slack" src={slackIcon} width="35"
               className="d-block"
          />
          </center>
        </ShowIf>
        <ShowIf condition={isLoading}>
          <CircularProgress size={37} className="d-block align-self-center"/>
        </ShowIf>
        <ShowIf condition={!canUseTeam}>
          <LockIcon className="text-secondary" style={{fontSize: "30px"}}/>
        </ShowIf>
      </div>
    </>
  )
}

export const CompleteSlackAuth = () => {
  const {call} = useContext(APIContext);
  const {cache, setCacheValue} = useContext(CacheContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {

    const {team, code} = qs.parse(location.search, {ignoreQueryPrefix: true});

    call('completeSlackAuth', {team, code}, {successMessage: 'Slack was integrated successfully!'}).then(response => {
      if (response.ok) {
        mergeTeam(cache.teams, response.body, setCacheValue);
        history.push('/');
      }
    });

  }, [location.search, cache.teams])

  return (
    <div>
      <div className="text-align-center" style={{marginTop: "400px"}}>
        <CircularProgress size={55}/>
      </div>
    </div>
  );
}

export async function sendToSlack(call, loading, payload) {
  if (!loading[shareToSlack]) {
    return call(shareToSlack, payload, {successMessage: 'Content shared successfully!'});
  }
}

export function showInfoMessage(message, enqueueSnackbar, closeSnackbar) {
  let key = message + "" + moment().unix();
  enqueueSnackbar(message, {
    key,
    variant: "info",
    autoHideDuration: 5000,
    onClick: () => closeSnackbar(key)
  });
}
