import React from 'react';
import ShowIf from "components/common/ShowIf";
import {Alert} from "@material-ui/lab";

const ErrorBadge = ({error}) => {
  return (
    <ShowIf condition={!!error}>
      <div className="w-100 d-flex">
        <Alert severity="error" className="mt-3 w-100" style={{height: "fit-content"}}>{error}</Alert>
      </div>
    </ShowIf>
  );
}

export default ErrorBadge;
