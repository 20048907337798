import React, {useContext, useState} from 'react';
import APIContext from 'context/APIContext';
import {Formik, Form} from "formik";
import {Grid, Typography, InputAdornment} from '@material-ui/core';
import {Alert} from '@material-ui/lab'
import {FormikTextField} from 'formik-material-fields';
import {NavLink} from "react-router-dom";
import MyButton from "components/Controls/MyButton";
import ShowIf from "components/common/ShowIf";
import * as Yup from "yup";
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import logoLarge from "assets/images/logo-white.png";
import ErrorBadge from "components/common/ErrorBadge";

const EP = "forgotPassword";

const SUCCESS_MESSAGE = "Success! You should receive an email in the next few minutes with a password reset link.";

const ForgotPassword = () => {

  const {call, loading} = useContext(APIContext);
  const [afterSubmitMessage, setAfterSubmitMessage] = useState();
  const [error, setError] = useState();

  async function onSubmit(values) {
    let {email} = values;
    let response = await call(EP, {email: {value: email}}, {setError});
    if (response.ok) {
      setAfterSubmitMessage(SUCCESS_MESSAGE);
    }
  }

  const isLoading = loading[EP];

  return (
    <div className="app-wrapper bg-gradient min-vh-100 forgot-password">
      <div className="app-main min-vh-100 top-bg-content">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content pb-5 px-2">
                <div className="auth-form-wrapper">
                  <div className="w-100 pr-0 pr-lg-5">
                    <div className="mt-3">
                    <span className="text-center">
                      <h1 className="display-4 mb-4 font-weight-bold font-size-xxxxl">
                        <img
                          width="450"
                          alt="Ludo"
                          className="d-block m-auto logo"
                          src={logoLarge}
                        />
                        Forgot Password
                      </h1>
                      <Typography className="py-2 mb-2 d-block">
                        Please input your email address below and we will send you an email with the instructions for password recovery.
                      </Typography>
                      </span>
                      <MyForm onSubmit={onSubmit} loading={isLoading} afterSubmitMessage={afterSubmitMessage}
                              error={error}/>
                      <div className="text-center pt-4">
                        Don't have an account?{' '}
                        <NavLink to="/register" className="text-white font-weight-bold">
                          Create an Account
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MyForm = ({onSubmit, loading, afterSubmitMessage, error}) => (
  <Formik
    initialValues={{email: ""}}
    onSubmit={onSubmit}
    validationSchema={ValidationSchema}
  >
    {({isValid, dirty}) => (
      <Form>
        <div className="mb-3">
          <label className="font-weight-bold mb-2">
            Email address
          </label>
          <FormikTextField
            name="email"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="yourname@yourmail.com"
            type="email"
            disabled={loading || !!afterSubmitMessage}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineTwoToneIcon/>
                </InputAdornment>
              )
            }}
          />
        </div>
        <ShowIf condition={!!afterSubmitMessage}>
          <Alert severity="success">
            {afterSubmitMessage}
          </Alert>
        </ShowIf>
        <ShowIf condition={!afterSubmitMessage}>
          <ErrorBadge error={error}/>
          <MyButton
            style={{width: "100%"}}
            className="blue"
            loading={loading}
          >
            Recover Password
          </MyButton>
        </ShowIf>
      </Form>
    )}
  </Formik>
);

export default ForgotPassword;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email.')
    .required('No email provided'),
});
