import {withStyles} from "@material-ui/core/styles";
import {Tab, Tabs} from "@material-ui/core";
import React from "react";

export const WhiteFormTabs = withStyles({
  root: {
    borderBottom: '1px solid white',
  },
  indicator: {
    backgroundColor: 'white',
    minHeight: "2px!important",
    height: "2px!important"
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const FormTabs = withStyles({
  root: {
    borderBottom: '1px solid #3F66A9',
  },
  indicator: {
    backgroundColor: '#3F66A9',
    minHeight: "2px!important",
    height: "2px!important"
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const FormTab = withStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
