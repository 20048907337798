import React, {useContext, useEffect, useState, useMemo} from "react";
import APIContext from "context/APIContext";
import ShowIf from "components/common/ShowIf";
import {CircularProgress, Collapse, Grid, IconButton, Tooltip} from "@material-ui/core";
import CreditCardForm from "components/common/CreditCardForm";
import Cards from "react-credit-cards";
import 'react-credit-cards/lib/styles.scss';
import ExpandIcon from "components/common/ExpandIcon";
import {CheckCircleOutline, CreditCard, DeleteOutline, ReceiptLongOutlined} from "@mui/icons-material";
import InvoicingDetails from "./SubscriptionFlow/InvoicingDetails";
import {Confirm3DSecureRedirect} from "./SubscriptionFlow";

const getCreditCards = 'getCreditCards';
const makeCardDefault = 'makeCardDefault';
const removeCreditCard = 'removeCreditCard';
const confirmSetupIntent = "confirmSetupIntent";

const CreditCards = ({onSelectedCard, onClose, whiteForm, message, detailsForm = false}) => {

  const {call, loading} = useContext(APIContext);

  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [cards, setCards] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [processingCard, setProcessingCard] = useState(false);
  const [loadingCard, setLoadingCard] = useState();
  const [ThreeDSecureRedirect, setThreeDSecureRedirect] = useState();

  function onCreditCardSuccess(cards) {
    cards = cards.map(card => {
      return {
        ...card,
        exp_month: card.exp_month < 10 ? "0" + card.exp_month : card.exp_month
      }
    });
    setCards(cards);
    setShowForm(cards.length === 0);
  }

  useEffect(() => {
    call(getCreditCards).then(response => {
      if (response.ok) onCreditCardSuccess(response.body);
    })
  }, []);

  async function on3DSComplete(intentId) {
    call(confirmSetupIntent, { data: { value: intentId } }).then(
      async (response) => {
        if (response.ok) {
          setThreeDSecureRedirect();
          onCreditCardSuccess(response.body);
          if (onSelectedCard) await onSelectedCard(response.body);
          setProcessingCard(false)
        } else {
          if (response.status === 406) {
            setThreeDSecureRedirect(response.body.error_payload);
          }
        }
      }
    );
  }

  async function setDefaultCreditCard(card) {
    let data = {data: {value: card.id}};
    setLoadingCard(card.id);
    let response = await call(makeCardDefault, data);
    setLoadingCard();
    if (response.ok) {
      onCreditCardSuccess(response.body);
      return true;
    } else {
      if (response.status === 406) {
        setThreeDSecureRedirect(response.body.error_payload);
      }
      return false;
    }
  }

  async function onClick(card) {
    if (!processingCard) {
      setProcessingCard(true);
      let result = await setDefaultCreditCard(card);
      if (result) {
        if (onSelectedCard) await onSelectedCard(card);
        setProcessingCard(false)
      }
    }
  }

  async function onSucceededAddingCard(cards) {
    onCreditCardSuccess(cards);
    if (onSelectedCard) await onSelectedCard();
    setShowForm(false);
  }

  async function onDelete(card) {
    let data = {data: {value: card.id}};
    setLoadingCard(card.id);
    let response = await call(removeCreditCard, data);
    if (response.ok) onCreditCardSuccess(response.body);
    setLoadingCard();
  }

  const defaultCard = useMemo(() => {
    return cards.find(card => card.default);
  }, [cards]);

  return (
    <div className="credit-cards-wrapper">
      {ThreeDSecureRedirect && <Confirm3DSecureRedirect
        url={ThreeDSecureRedirect}
        onSetupIntent={(sId) => on3DSComplete(sId)}
      />}
      <ShowIf condition={loading[getCreditCards]}>
        <div className="text-align-center text-white">
          <CircularProgress size={55} className="text-white mt-5"/>
        </div>
      </ShowIf>
      <ShowIf condition={!loading[getCreditCards]}>
        <>
          <ShowIf condition={cards.length > 0}>
            <div className="credit-cards-list">
              <ShowIf condition={!!message}>
                <span className="top-span">{message}</span>
              </ShowIf>
              <Grid container>
                {cards.map(card => (
                  <Grid
                    key={card.id}
                    item xs={12}
                    className={`card-grid mx-auto mt-2 position-relative ${card.id === defaultCard?.id ? 'selected' : ''}`}
                    onClick={!!onSelectedCard ? () => onClick(card) : undefined}
                  >
                    <ShowIf condition={loadingCard === card.id}>
                      <div className="card-loading-wrapper">
                        <CircularProgress color="inherit" size={50}/>
                      </div>
                    </ShowIf>
                    <Cards
                      expiry={`${card.exp_month}/${card.exp_year}`}
                      name={" "}
                      preview={true}
                      cvc="   "
                      number={`************${card.last4}`}
                      issuer={card.brand}
                      className={!!onSelectedCard ? " pointer" : ""}
                    >
                      to
                    </Cards>
                    <div className="actions">
                      <Tooltip
                        title="Remove"
                        arrow
                        placement="top"
                        PopperProps={{
                          disablePortal: true,
                          className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"
                        }}
                      >
                        <IconButton component="span" className="delete-icon" onClick={() => onDelete(card)}>
                          <DeleteOutline/>
                        </IconButton>
                      </Tooltip>
                      <ShowIf condition={card.id !== defaultCard?.id}>
                        <Tooltip
                          title="Make Default"
                          arrow
                          placement="top"
                          PopperProps={{
                            disablePortal: true,
                            className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"
                          }}
                        >
                          <IconButton component="span" className="default-icon"
                                      onClick={() => setDefaultCreditCard(card)}>
                            <CheckCircleOutline/>
                          </IconButton>
                        </Tooltip>
                      </ShowIf>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </ShowIf>
          {detailsForm && <div className="add-credit-card-wrapper">
            <div className="collapse-control pl-1 w-100 clickable d-flex"
                 onClick={() => setShowDetailsForm(!showDetailsForm)}>
                <span className="flex-grow-1">
                  <ReceiptLongOutlined className="font-size-lg mr-2"/>
                  <span className="font-weight-bold">Edit Billing Information</span>
                </span>
              <ExpandIcon expanded={showDetailsForm} setExpanded={() => setShowDetailsForm(!showDetailsForm)}/>
            </div>
            <Collapse in={showDetailsForm} timeout="auto" unmountOnExit>
              <div className="pt-5">
                <InvoicingDetails
                  buttonText="Save Billing Information"
                  onSuccess={() => setShowDetailsForm(false)}
                />
              </div>
            </Collapse>
          </div>}
          <div className="add-credit-card-wrapper">
            <div className="collapse-control pl-1 w-100 clickable d-flex"
                 onClick={() => setShowForm(!showForm)}>
                <span className="flex-grow-1">
                  <CreditCard className="font-size-lg mr-2"/>
                  <span className="font-weight-bold">Add Credit Card</span>
                </span>
              <ExpandIcon expanded={showForm} setExpanded={() => setShowForm(!showForm)}/>
            </div>
            <Collapse in={showForm} timeout="auto" unmountOnExit>
              <div className="pt-5">
                <CreditCardForm
                  whiteForm={whiteForm}
                  onSuccess={onSucceededAddingCard}
                  onCancel={onClose ? onClose : cards.length > 0 ? () => setShowForm(false) : undefined}
                />
              </div>
            </Collapse>
          </div>
        </>
      </ShowIf>
    </div>
  )
};

export default CreditCards;
