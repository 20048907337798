import {useCallback, useEffect, useState} from "react";
import config from './config/config.json';
import io from 'socket.io-client';
import PerformanceUtils from "helpers/PerformanceUtils";
import {useHistory} from "react-router";
import moment from 'moment';

const lastTracked = {};

export const STATUS = {
  disconnected: 'disconnected',
  connected: 'connected'
}

let socket = null

function useSocket(cache, setCache) {

  const history = useHistory();

  const [auth, setSocketAuth] = useState();
  const [trackListeners, setTrackListeners] = useState({});
  const [status, setStatus] = useState(STATUS.disconnected);
  const [instance, setInstance] = useState(null);

  function closeSocket(socket) {
    if (!!socket) {
      log('useSocket.closeSocket');
      socket.close();
      socket = null;
    }
  }

  useEffect(() => {
    log('useSocket.status', status);
  }, [status]);

  const token = auth?.token;

  useEffect(() => {
    if (token) {
      if (socket) closeSocket(socket);
      openSocket(token)
    }
  }, [token]);

  const addTrackListener = useCallback((key, listener) => {
    log("Adding Track Listener", key);
    setTrackListeners(prevState => {
      return {...prevState, [key]: listener}
    })
  }, []);

  function openSocket(token) {
    let url = config.API_HOST;
    log('useSocket.openSocket', url);

    let instance = io(url, {
      transports: ['websocket'],
      query: 'token=' + token
    });

    let connected = false;

    instance.on('connect_error', (err) => {
      log("connect_error", err);
      setStatus(STATUS.disconnected);
    });
    instance.on('connect_timeout', (err) => {
      log("connect_timeout", err);
      setStatus(STATUS.disconnected);
    });
    instance.on('disconnect', (err) => {
      log("disconnect", err);
      setStatus(STATUS.disconnected);
      connected = false;
      unsubscribeEvents(instance);
    });
    instance.on('connect', () => {
      log("connect");
      if (!connected) subscribeEvents(instance);
      connected = true;
      setStatus(STATUS.connected);
    });
    socket = instance;
    setInstance(instance);
  }

  function unsubscribeEvents(socket) {
    socket.removeListener('video-generation');
    socket.removeListener('chat-gdd');
    socket.removeListener('credits');
    socket.removeListener('team');
    socket.removeListener('favorite');
    socket.removeListener('favorite.delete');
    socket.removeListener('gdd-update-section');
    socket.removeListener('gdd-update-part');
    socket.removeListener('gdd-update');
    socket.removeListener('project');
  }

  function subscribeEvents(socket) {
    if (socket !== undefined) {
      socket.on('video-generation', data => {
        log('useSocket.event.video-generation', data);
        onVideoGeneration(data, setCache);
      });
      socket.on('chat-gdd', data => {
        log('useSocket.event.chat-gdd', data);
        onChatGdd(data, setCache);
      });
      socket.on('chat-assistant', data => {
        log('useSocket.event.chat-assistant', data);
        onChatAssistant(data, setCache);
      });
      socket.on('credits', data => {
        log('useSocket.event.credits', data);
        onCredits(data, setCache);
      });
      socket.on('team', data => {
        log('useSocket.event.team', data);
        onTeam(data, setCache);
      });
      socket.on('favorite', data => {
        log('useSocket.event.favorite', data);
        onFavorite(data, setCache);
      });
      socket.on('favorite.delete', data => {
        log('useSocket.event.favorite.delete', data);
        onFavoriteDelete(data, setCache);
      });
      socket.on('gdd-update-section', data => {
        log('useSocket.event.gdd-update-section', data);
        onGddSection(data, setCache);
      });
      socket.on('gdd-update-part', data => {
        log('useSocket.event.gdd-update-part', data);
        onGddPart(data, setCache);
      });
      socket.on('gdd-update', data => {
        log('useSocket.event.gdd-update', data);
        onGdd(data, setCache);
      });
      socket.on('project', data => {
        log('useSocket.event.project', data);
        setCache(prevState => {
          return {
            ...prevState,
            projects: PerformanceUtils.editOrAdd(data, prevState.projects, '_id'),
          }
        });
      });
    }
  }

  const {trialExpired, credits} = cache;
  const {current, max, reset_date, extra = 0} = credits;

  const track = useCallback((action, options = {}) => {
    if (lastTracked.action !== action || lastTracked.date !== moment().unix()) {
      options = {...options, trial_expired: trialExpired};
      if (trialExpired || max > 0) {
        options.credits = current;
        options.credits_max = max;
        options.credits_used = current >= (max + extra);
        options.credits_reset_date = reset_date;
      }
      options.page = history.location.pathname;
      options.userAgent = window.navigator.userAgent;
      lastTracked.action = action;
      lastTracked.date = moment().unix();
      try {
        if (options.files) {
          options.files = options.files.map(file => file.name);
        }
        if (options.search?.files) {
          options.search.files = options.search.files.map(file => file.name);
        }
      } catch (err) {
        console.log(err)
      }
      emit('track-ui', {action, options});
      Object.values(trackListeners).forEach(listener => listener({action, options}));
    }
  }, [socket, trialExpired, current, max, history.location.pathname, trackListeners])

  const emit = useCallback((key, data) => {
    if (!!socket) {
      log("emitting", socket, key, data);
      socket.emit(key, data);
    } else {
      setTimeout(() => {
        emit(key, data);
      }, 100);
    }
  }, [socket]);

  return {status, emit, track, addTrackListener, SOCKET_STATUS: STATUS, setSocketAuth, instance};
}

function onFavorite(value, setCache) {
  return setCache(prevState => {
    return {
      ...prevState,
      allFavorites: PerformanceUtils.editOrAdd(value, prevState.allFavorites, '_id')
    }
  });
}

function onFavoriteDelete(value, setCache) {
  return setCache(prevState => {
    return {
      ...prevState,
      allFavorites: PerformanceUtils.removeFromArray(value, prevState.allFavorites, '_id')
    }
  });
}

function onGddSection(data, setCache) {
  let {id, key, section} = data;
  setCache(prevState => {
    let newState = {...prevState};
    let project = (prevState.projects || []).find(project => project._id === id);
    if (project) {
      newState.projects = newState.projects.map(currentProject => {
        if (project._id === currentProject._id)
          return {
            ...project,
            gdd2: {
              ...project.gdd2,
              sections: (project.gdd2?.sections || []).map(currentSection => {
                if (currentSection.id === key || currentSection.name === key) {
                  return section;
                }
                return currentSection;
              })
            }
          };
        return currentProject;
      });
    }
    return newState;
  })
}

function onGddPart(data, setCache) {
  let {id, key, value} = data;
  setCache(prevState => {
    let newState = {...prevState};
    let project = (prevState.projects || []).find(project => project._id === id);
    if (project) {
      newState.projects = newState.projects.map(currentProject => {
        if (project._id === currentProject._id)
          return {
            ...project,
            gdd2: {
              ...project.gdd2,
              [data.key]: value,
            }
          };
        return currentProject;
      });
    }
    return newState;
  })
}

function onGdd(data, setCache) {
  let {id, gdd} = data;
  setCache(prevState => {
    let newState = {...prevState};
    let project = (prevState.projects || []).find(project => project._id === id);
    if (project) {
      newState.projects = newState.projects.map(currentProject => {
        if (project._id === currentProject._id) {
          return {
            ...project,
            gdd2: gdd
          };
        }
        return currentProject;
      });
    }
    return newState;
  })
}

export function onTeam(team, setCache) {
  setCache(prevState => {

    let teams = (prevState.teams || []).map(t => {
      if (t._id === team._id) return team;
      return t;
    });

    return {
      ...prevState,
      teams
    }
  });
}

export function onCredits(data, setCache) {
  setCache(prevState => {
    return {
      ...prevState,
      credits: data,
    }
  });
}

export function onVideoGeneration(data, setCache) {
  if (data?._id) {
    setCache(prevState => {

      let prevVideos = (prevState.videos || []);
      let exists = prevVideos.find(video => video._id === data._id)

      let videos = exists ? prevVideos.map(video => {
        if (video._id === data._id) return data;
        return video;
      }) : [data, ...prevVideos];

      return {
        ...prevState,
        videos,
      }
    });
  }
}

export function onChatGdd(data, setCache) {
  setCache(prevState => {
    let isNew = data.index === 0;
    let result = isNew ? [] : [...(prevState.streamingMessage || [])];

    result.push(data);

    return {
      ...prevState,
      streamingMessage: result,
    }
  });
}

export function onChatAssistant(data, setCache) {
  setCache(prevState => {
    let isNew = data.index === 0;
    let result = isNew ? [] : [...(prevState.assistantStreamingMessage || [])];

    result.push(data);

    return {
      ...prevState,
      assistantStreamingMessage: result,
    }
  });
}

function log(...d) {
  if (config.ENVIRONMENT === "development")
    console.log(...d);
}

export default useSocket;
