import { IconButton, Grid, CircularProgress, Chip } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ShowIf from "components/common/ShowIf";
import "./style.scss";
import { convertProxyUrl } from "components/common/ImageGallery";
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import GameCard from "components/common/GameCard";
import { getGameScoreData } from "pages/Trends/TrendGames";
import DetailsPanelContext from "context/DetailsPanelContext";
import {
  AssessmentOutlined,
  ChevronLeft,
  ChevronRight,
  WbIncandescentOutlined,
} from "@mui/icons-material";
import { useHistory } from "react-router";
import SocketContext from "context/SocketContext";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];
const getGamesInformation = "getGamesInformation";
const getGamesTrends = "getGamesTrends";

const TrendTopicDetailsPanel = () => {
  const { call } = useContext(APIContext);
  const [games, setGames] = useState([]);
  const [gameInfos, setGameInfos] = useState({});
  const { cache } = useContext(CacheContext);
  const { detailsPanel } = cache;
  const { showTrendTopic } = useContext(DetailsPanelContext);

  const {
    topic = DEFAULT_OBJECT,
    topics = DEFAULT_ARRAY,
    genre,
  } = detailsPanel;

  useEffect(() => {
    let gameIds = topic.games.map((game) => game.game_id || game);
    call(getGamesInformation, { data: { ids: gameIds } }).then((response) => {
      if (response.ok) {
        setGames(response.body);
      }
    });
    call(getGamesTrends, {
      data: { ids: gameIds },
      location: topic.location,
    }).then((response) => {
      if (response.ok && response.body.length > 0) {
        const result = {};
        response.body.forEach((data) => {
          result[data.game_id] = data.infos;
        });
        setGameInfos(result);
      }
    });
  }, [topic.games, topic.location]);

  const topicIndex = topics
    .map(({ topic_id }) => topic_id)
    .indexOf(topic.topic_id);

  const showPrevious = topics.length > 0 && topicIndex > 0;
  const showNext = topics.length > 0 && topicIndex < topics.length - 1;

  function onPrevious() {
    showTopic(topics[topicIndex - 1]);
  }

  function onNext() {
    showTopic(topics[topicIndex + 1]);
  }

  function showTopic(topic) {
    showTrendTopic(topic, genre, topics);
  }

  return (
    <div className="trend-topic-details-panel">
      <ShowIf condition={games.length > 0}>
        <TopicContent
          key={topic?.topic_id}
          topic={topic}
          games={games}
          onPrevious={showPrevious ? onPrevious : undefined}
          onNext={showNext > 0 ? onNext : undefined}
          gameInfos={gameInfos}
        />
      </ShowIf>
      <ShowIf condition={games.length === 0}>
        <div className="text-align-center m-4 loading">
          <CircularProgress size={55} />
        </div>
      </ShowIf>
    </div>
  );
};

const TopicContent = ({ topic, games, gameInfos, onPrevious, onNext }) => (
  <div className="topic-details content p0">
    <Header
      topic={topic}
      games={games}
      onPrevious={onPrevious}
      onNext={onNext}
    />
    <div className="info">
      <div className="info-content">
        <span className="white-title text-align-center d-block my-4 py-3">
          Games Included this Topic
        </span>
        <Games games={games} topic={topic} gameInfos={gameInfos} />
      </div>
    </div>
  </div>
);

const Actions = ({ topic }) => {
  const history = useHistory();
  const { track } = useContext(SocketContext);

  function onClickCompare() {
    track("trends.compare-to-market", { topic });
    let url = "/market-analysis";

    let dataTopic = JSON.parse(JSON.stringify(topic));
    history.push(url, {
      data: { topic: dataTopic, sources: topic.sources },
    });
  }

  function onClickGenerate() {
    track("trends.generate-ideas", { topic });
    let url = "/game-ideator";
    history.push(url, {
      data: { topic, sources: topic.sources },
    });
  }

  return (
    <div className="topic-actions">
      <span onClick={onClickCompare}>
        <AssessmentOutlined className="font-size-lg" />
        Compare to Market
      </span>{" "}
      |{" "}
      <span onClick={onClickGenerate}>
        <WbIncandescentOutlined className="flip-vertical font-size-lg" />{" "}
        Generate Game Ideas
      </span>
    </div>
  );
};

const Header = ({ topic, games = DEFAULT_ARRAY, onPrevious, onNext }) => {
  const image = topic.images?.[0];
  const imageGameId = image?.game_id || games[0]?._id;
  const imageGame = games.find((g) => g._id === imageGameId) || games[0];

  const screenshot =
    imageGame?.screenshots?.find(({ id }) => image?.image_id === id) ||
    imageGame?.screenshots?.[0];

  let style = {};
  let headerClassName = "header";
  if (screenshot?.url) {
    headerClassName += " cover-header";
    style = {
      background: `linear-gradient(rgba(63, 102, 170, 0.75), rgba(63, 102, 170, 0.75)), url(${convertProxyUrl(
        screenshot
      )})`,
    };
  }

  return (
    <>
      <div className={headerClassName} style={style}>
        <div className="topic-title text-align-center w-100">
          <div className="previous">
            <ShowIf condition={!!onPrevious}>
              <IconButton onClick={onPrevious}>
                <ChevronLeft className="font-size-xxxxxxl text-white" />
              </IconButton>
            </ShowIf>
          </div>
          <div className="center-column">
            <span className="topic-header text-align-center w-100">
              Topic
            </span>
            <span className="topic-keyword text-align-center w-100">
              {topic.title}
            </span>
          </div>

          <div className="next">
            <ShowIf condition={!!onNext}>
              <IconButton onClick={onNext}>
                <ChevronRight className="font-size-xxxxxxl text-white" />
              </IconButton>
            </ShowIf>
          </div>
        </div>
      </div>
      <Keywords topic={topic} />
    </>
  );
};

const Keywords = ({ topic }) => (
  <div className="keywords text-align-center">
    {topic.summary && <span className="summary">{topic.summary}</span>}
    <div className="chips">
      {topic.keywords.map((keyword, index) => (
        <Chip label={keyword} key={keyword} />
      ))}
    </div>
    <Actions topic={topic} />
  </div>
);

const Games = ({ games = DEFAULT_ARRAY, topic = {}, gameInfos = {} }) => {
  return (
    <div className="games">
      <ShowIf condition={games.length > 0}>
        <Grid container justifyContent="flex-start" spacing={3}>
          {games.map((game, index) => {
            let gameInfo = gameInfos[game._id];
            let altText = getGameScoreData({ game_info: gameInfo, game });
            return (
              <Grid item key={game._id} xs={6}>
                <GameCard
                  location={topic.location}
                  game={game}
                  genre={topic.genre}
                  altText={altText}
                  lazyLoad={false}
                />
              </Grid>
            );
          })}
        </Grid>
      </ShowIf>
      <ShowIf condition={games.length === 0}>
        <div className="text-align-center m-4">
          <CircularProgress size={55} />
        </div>
      </ShowIf>
    </div>
  );
};

export default TrendTopicDetailsPanel;
