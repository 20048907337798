import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const LinearProgressWithLabel = props => {
  let variant = !!props.valueBuffer ? "buffer" : "determinate";
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1} className="progress-content">
        <LinearProgress variant={variant} {...props} />
      </Box>
      <Box className="text-content">
        <Typography variant="body2" className="font-weight-bold text-white">
          {props.text || `${Math.round(
          props.value,
        )}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
