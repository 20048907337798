import React, { useContext, useMemo, useState, useEffect } from "react";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import LinearProgressWithLabel from "components/common/LinearProgressWithLabel";
import ShowIf from "components/common/ShowIf";
import CacheContext from "context/CacheContext";
import { BillingModal } from "pages/Profile/Profile";
import SocketContext from "context/SocketContext";
import { CloseOutlined, Lock } from "@mui/icons-material";

const MyButton = ({
  id,
  trackOptions,
  loading,
  disabled,
  style,
  className = "",
  confirmText,
  shouldLock,
  lockMessage,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { cache, setCache } = useContext(CacheContext);
  const { track } = useContext(SocketContext);
  const { trialExpired } = cache;

  const locked = useMemo(() => {
    return shouldLock && trialExpired;
  }, [shouldLock, trialExpired]);

  function onClick(event) {
    if (id) track("clicked-button", { id, ...(trackOptions || {}) });

    if (locked) {
      setCache((prevState) => {
        return {
          ...prevState,
          lockScreen: lockMessage,
        };
      });
      return;
    }

    if (confirmText) {
      setOpen(true);
      event.stopPropagation();
      event.preventDefault();
    } else if (props.onClick) {
      props.onClick(event);
    }
  }

  function onConfirmDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
    props.onClick(event);
  }

  function onCloseDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
  }

  return (
    <Button
      size="medium"
      variant="contained"
      type="submit"
      disabled={loading ? loading : disabled}
      {...props}
      style={{ width: "250px", ...style }}
      className={`my-button font-size-md ${className}`}
      onClick={onClick}
    >
      {confirmText ? (
        <ConfirmDialog
          text={confirmText}
          open={open}
          onConfirm={onConfirmDialog}
          onCancel={onCloseDialog}
        />
      ) : null}
      {loading
        ? props.loadingElement || (
            <>
              &nbsp;
              <CircularProgress size={14} />
              &nbsp;
            </>
          )
        : props.children}
      {locked ? <LockIcon /> : null}
    </Button>
  );
};

export const LockIcon = ({ style, className = "" }) => {
  let fullClassName = "lock-icon " + className;
  return (
    <div className={fullClassName} style={style}>
      <Lock />
    </div>
  );
};

export const LockModal = ({ open, message, countdown, onClose }) => {
  const [openBilling, setOpenBilling] = useState(false);
  const { track } = useContext(SocketContext);
  useEffect(() => {
    if (typeof message === "string") track("lockmodal.open", { message });
  }, [message]);

  function onClickUpgrade(event) {
    event.preventDefault();
    event.stopPropagation();
    setOpenBilling(true);
  }

  function onClickClose(event) {
    event.preventDefault();
    event.stopPropagation();
    onClose();
    track("lockmodal.close");
  }

  function onCloseBilling() {
    setOpenBilling(false);
    onClose();
  }

  return (
    <>
      <BillingModal open={openBilling} onClose={onCloseBilling} />
      <Dialog
        open={open}
        onClose={onClickClose}
        className="lock-modal ludo-modal"
      >
        <span className="top-right">
          <IconButton onClick={onClickClose}>
            <CloseOutlined className="font-size-xxl pointer text-secondary" />
          </IconButton>
        </span>
        <div className="content-wrapper p-5 text-align-center">
          <DialogTitle>Supercharge Your Game Development</DialogTitle>
          <DialogContent className="text-center">
            <DialogContentText
              id="alert-dialog-description"
              className="text-primary"
              style={{ maxWidth: "800px" }}
            >
              <span>
                Upgrade to Ludo.ai Premium for just $20/month! Access exclusive
                features, extra credits, and advanced tools to streamline your
                game development. Elevate your projects and boost efficiency!
              </span>
            </DialogContentText>
            <DialogActions className="justify-content-center mt-4">
              <MyButton
                id="upgrade-modal.upgrade"
                color="secondary"
                onClick={onClickUpgrade}
              >
                Upgrade Now
              </MyButton>
            </DialogActions>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export const ConfirmDialog = ({
  text,
  open,
  onCancel,
  onConfirm,
  title = "Confirmation Needed!",
  cancelLabel = "Cancel",
  confirmLabel = "Proceed",
  textElement,
  forwardRef,
  reverse = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className="confirm-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      ref={forwardRef}
    >
      <span className="top-right">
        <IconButton onClick={onCancel}>
          <CloseOutlined className="font-size-xxl pointer text-secondary" />
        </IconButton>
      </span>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {textElement || text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          className={
            "d-flex " + (reverse ? "row-reverse" : "")
          }
          style={{gap: "15px"}}
        >
          <MyButton
            size="small"
            className={reverse ? "secondary" : "tertiary-light"}
            color={reverse ? "primary" : "secondary"}
            onClick={onConfirm}
            autoFocus
          >
            {confirmLabel}
          </MyButton>
          <MyButton
            size="small"
            className={!reverse ? "secondary" : "tertiary-light"}
            color={!reverse ? "primary" : "secondary"}
            onClick={onCancel}
          >
            {cancelLabel}
          </MyButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const DEFAULT_OBJECT = {};

export const GeneratingButton = ({
  label = "Generate!",
  loading,
  onCancel,
  className = "",
  progress = DEFAULT_OBJECT,
  loadingText = "Generating...",
  loadProgressSecs = 0,
  ...props
}) => {
  const [currentProgress, setCurrentProgress] = useState();

  useEffect(() => {
    let interval;

    if (loading && loadProgressSecs) {
      let increment = 10;
      let current = 0;
      let timeout = (loadProgressSecs / 10) * 1000;
      interval = setInterval(() => {
        if (current < 90) {
          current += increment;
          setCurrentProgress({
            value: current,
            buffer: current === 90 ? 90 : undefined,
          });
        }
      }, timeout);
    }

    return () => {
      setCurrentProgress();
      clearInterval(interval);
    };
  }, [loading, loadProgressSecs]);

  return (
    <MyButton
      {...props}
      className={
        "button-linear-progress" + (loading ? " loading" : "") + " " + className
      }
      disabled={props.disabled || false}
      //style={{backgroundColor: "none"}}
      color={props.color}
      onClick={(event) => {
        if (loading) {
          event.stopPropagation();
          event.preventDefault();
          if (onCancel) {
            onCancel();
          }
        } else if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <ShowIf condition={loading}>
        <LinearProgressWithLabel
          value={currentProgress?.value || progress.value || 0}
          valueBuffer={
            currentProgress?.buffer || progress.buffer || progress.value || 0
          }
          text={
            <div className="d-flex flex-row">
              <ShowIf condition={!!onCancel}>
                <CloseOutlined
                  className="font-size-lg position-absolute"
                  style={{ left: "10px", top: "12px" }}
                />
              </ShowIf>
              {loadingText}
            </div>
          }
        />
        <CircularProgress size={15} />
      </ShowIf>
      <ShowIf condition={!loading}>{props.children || label}</ShowIf>
    </MyButton>
  );
};

export default MyButton;
