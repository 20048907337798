import React, {useContext, useEffect, useMemo, useState} from "react";
import {Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip} from "@material-ui/core";
import ShowIf from "components/common/ShowIf";
import ErrorBoundary from "components/utils/ErrorBoundary";
import appStoreImage from "assets/images/sources/app_store.png";
import steamImage from "assets/images/sources/steam.svg";
import itchImageBorder from "assets/images/sources/itch-border.svg";
import playstoreImage from "assets/images/sources/playstore.png";
import {GameFavoriteButton} from "components/common/FavoriteButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {convertProxyUrl} from "components/common/ImageGallery";
import {Link} from "react-router-dom";
import {ShareContextMenu} from "components/Sharing";
import './style.scss';
import DetailsPanelContext from "context/DetailsPanelContext";
import LudoLazyLoad from "components/common/LudoLazyLoad";
import SocketContext from "context/SocketContext";
import CacheContext from "context/CacheContext";
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {SEARCH_RESULTS_MESSAGE} from "scenes/SubscriptionPage/Plans";
import {FileCopyOutlined, InfoOutlined} from "@mui/icons-material";
import AddToGDD from "pages/GDD3/AddToGDD";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

const GameCard = ({
                    game = DEFAULT_OBJECT,
                    className = "",
                    genre,
                    location,
                    altText,
                    onClick,
                    actions,
                    lazyLoad = true,
                    scrollContainer = ".app-content--inner__wrapper",
                    useGif = true,
                    infoIcon = false,
                    locked = false
                  }) => {

  const {track} = useContext(SocketContext);
  const {showGame, showDeveloper} = useContext(DetailsPanelContext);

  let {title = "", developers = []} = game;

  if (title.length > 56) title = title.substr(0, 56) + "...";

  className = "game-card animate__animated animate__fadeIn " + className;

  let developer = (developers || [])[0];

  function onClickWrapper() {
    track('game-card.clicked-game', {id: game._id});
    if (!!onClick) return onClick(game);
    showGame(game, genre, location);
  }

  function onClickDeveloper() {
    track('game-card.clicked-developer', {id: game._id});
    showDeveloper(game.developers[0], game.source);
  }

  const {source, urls = DEFAULT_ARRAY} = game;
  let data = SOURCES[source] || DEFAULT_OBJECT;

  if (locked) return <LockedGameCard
    game={game}
    lazyLoad={lazyLoad}
    className={className}
    scrollContainer={scrollContainer}
    altText={altText}
  />

  return (
    <Grid item>
      <LudoLazyLoad enable={lazyLoad} offset={0} height={230} scrollContainer={scrollContainer} once>
        <ErrorBoundary fallback="An error has occurred">
          <div className={className}>
            <CoverImage game={game} onClick={onClickWrapper} useGif={useGif}/>
            {actions ? actions(game) :
              <GameActions game={game} infoIcon={infoIcon} onOpenGame={() => showGame(game, genre, location)}/>}
            <div className="card-content">
              <GameIcon game={game}/>
              <Tooltip
                arrow
                placement="top"
                title={data.label || "Game Source"}
                PopperProps={{disablePortal: true, className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
              >
                <a href={urls[0]} target="_blank" className="source-icon">
                  <img src={data.icon} alt={data.label} width="45"/>
                </a>
              </Tooltip>
            </div>
            <div className="card-info">
              <span className="name text-truncate link" onClick={onClickWrapper}>{title}</span>
              <ShowIf condition={!!developer}>
              <span className="developer text-truncate link" onClick={onClickDeveloper}>
                {developer}
              </span>
              </ShowIf>
              <ShowIf condition={!!altText}>
              <span className="alt-text text-truncate">
                {altText}
              </span>
              </ShowIf>
            </div>
          </div>
        </ErrorBoundary>
      </LudoLazyLoad>
    </Grid>
  )
};

export function cleanGame(game) {
  delete game.description_annotations;
  delete game.description;
  return game;
}

export const RELEASE_DATES = [
  {label: 'All time', value: 0},
  {label: 'Last year', value: 365},
  {label: 'Last 6 months', value: 180},
  {label: 'Last 2 months', value: 60},
  {label: 'Last month', value: 30},
];

export const SOURCES = {
  appstore: {
    icon: appStoreImage,
    label: "Apple App Store",
    id: 'appstore',
  },
  playstore: {
    icon: playstoreImage,
    label: "Google Play",
    id: 'playstore',
  },
  steam: {
    icon: steamImage,
    label: "Steam",
    id: 'steam',
  },
  itch: {
    icon: itchImageBorder,
    label: "itch.io",
    id: 'itch',
  }
};

const LockedGameCard = ({game, lazyLoad, className, scrollContainer, altText}) => {

  const {setCacheValue} = useContext(CacheContext);

  function onClick() {
    setCacheValue('lockScreen', SEARCH_RESULTS_MESSAGE);
  }

  return (
    <Grid item>
      <LudoLazyLoad enable={lazyLoad} offset={0} height={230} scrollContainer={scrollContainer} once>
        <ErrorBoundary fallback="An error has occurred">
          <div className={className + " locked"} onClick={onClick}>
            <CoverImage game={game} useGif={false}/>
            <div className="card-content">
              <GameIcon game={game}/>
            </div>
            <div className="card-info">
              <span className="name text-truncate link">&nbsp;</span>
              <span className="developer text-truncate link">
                &nbsp;
              </span>
              <ShowIf condition={!!altText}>
                <span className="alt-text text-truncate link">
                  &nbsp;
                </span>
              </ShowIf>
            </div>
          </div>
        </ErrorBoundary>
      </LudoLazyLoad>
    </Grid>
  )

}

export const GameActions = ({game, infoIcon, onOpenGame}) => {

  const {track} = useContext(SocketContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  function onActionsClick(event) {
    track('game-card.open-context', {game});
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  function onActionsClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);
  }

  return (
    <>
      <div className="game-actions">
        <ShowIf condition={!infoIcon}>
          <GameFavoriteButton game={game} className="white"/>
        </ShowIf>
        <ShowIf condition={!!infoIcon}>
          <Tooltip
            title="Show game details"
            PopperProps={{disablePortal: true, className: "MuiTooltip-popper secondary"}}
            placement="top"
          >
            <span>
          <IconButton aria-label="info" className="text-white" onClick={onOpenGame}>
            <InfoOutlined/>
          </IconButton>
              </span>
          </Tooltip>
        </ShowIf>
        <IconButton aria-label="more actions" className="text-white" onClick={onActionsClick}>
          <MoreVertIcon style={{height: "22px"}}/>
        </IconButton>
      </div>
      <ShowIf condition={!!menuAnchorEl}>
        <GameContextMenu
          game={game}
          onClose={onActionsClose}
          anchor={menuAnchorEl}
        />
      </ShowIf>
    </>
  )
};

export const GameIcon = ({game = DEFAULT_OBJECT, onClick, fallbackToCover = false}) => {

  const url = useMemo(() => {
    return getGameIconUrl(game, fallbackToCover);
  }, [game, fallbackToCover]);

  const className = "icon " + game?.source;

  return (url ? <div className={className} onClick={!!onClick ? () => onClick(game) : undefined}>
      <img alt={game.title} src={url}/>
    </div> : null);
}

export function getGameIconUrl(game, fallbackToCover = false) {
  if (!game) return;
  let icon = (game.icons || []).find(({targetSize}) => targetSize === 100);
  if (!icon) icon = (game.icons || [])[0] || game.icon;
  if (!icon && fallbackToCover) {
    icon = game.cover_image || game.header;
    if (!icon) icon = (game.screenshots || [])[0];
  }
  return convertProxyUrl(icon, false);
}

export const CoverImage = ({game, onClick, useGif = true, children}) => {

  function isGif(image = {}) {
    return image.type === "gif" || (image.url || "").includes('.gif');
  }

  let image;

  if (useGif && isGif(game.header))
    image = game.header;

  if (useGif && !image && game.videos && game.videos.length > 0)
    image = game.videos.find(isGif);

  if (!image && game.screenshots && game.screenshots.length > 0)
    image = useGif ? game.screenshots[0] : game.screenshots.find(image => !isGif(image));

  if (!image)
    image = game.header && game.header.url ? game.header : game.icon;

  let forceProxy = useGif ? undefined : true;
  let proxyUrl = convertProxyUrl(image, forceProxy);

  return (
    <div className="cover-image clickable" onClick={onClick} style={{backgroundImage: `url(${proxyUrl})`}}>
      <div className="gradient-top"/>
      {children}
    </div>
  );
};

export const SourceIcon = ({game}) => {
  const {source, urls = []} = game;
  let data = SOURCES[source] || DEFAULT_OBJECT;
  return (
    <ShowIf condition={!!data}>
      <Tooltip
        title={data.label || "Game Source"}
        arrow
        placement="top"
        PopperProps={{disablePortal: true, className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
        aria-label={data.label}>
        <IconButton>
          <a href={urls[0]} target="_blank" rel="noreferrer" className="ml-1">
            <img src={data.icon} alt={data.label} width="40"/>
          </a>
        </IconButton>
      </Tooltip>
    </ShowIf>
  )
};

export const GameContextMenu = ({game, onClose, anchor, showGenerate = true, disablePortal = false}) => {

  const {track} = useContext(SocketContext);
  const [addToGdd, setAddToGdd] = useState(false);

  function trackWrapper(event, action) {
    track(action, {id: game._id});
  }

  function clickedAddToGdd(event) {
    trackWrapper(event, 'context.game.add-to-gdd');
    setAddToGdd(true);
  }

  return (
    <>
      {addToGdd ? <AddToGDD
        game={game}
        onClose={event => {
          setAddToGdd(false);
          onClose(event);
        }}
      /> : null}
      <Menu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={onClose}
        disablePortal={disablePortal}
        className="action-menu"
      >
        <MenuItem onClick={clickedAddToGdd}>
          <div
            style={{display: "contents"}}
          >
            <ListItemIcon>
              <FileCopyOutlined/>
            </ListItemIcon>
            <ListItemText primary="Add to Game Concept"/>
          </div>
        </MenuItem>
        {showGenerate ?
          <MenuItem onClick={onClose}>
            <Link
              to={{
                pathname: "/game-ideator",
                state: {data: {game}}
              }}
              style={{display: "contents"}}
              onClick={(event) => trackWrapper(event, 'context.game.generate')}
            >
              <ListItemIcon>
                <WbIncandescentOutlinedIcon className="flip-vertical"/>
              </ListItemIcon>
              <ListItemText primary="Generate based on this game"/>
            </Link>
          </MenuItem> : null}
        <MenuItem onClick={onClose}>
          <Link
            to={{
              pathname: "/search",
              state: {data: {game}}
            }}
            style={{display: "contents"}}
            onClick={(event) => trackWrapper(event, 'context.game.search-similar', true)}
          >
            <ListItemIcon>
              <SearchOutlinedIcon/>
            </ListItemIcon>
            <ListItemText primary="Search similar"/>
          </Link>
        </MenuItem>
        <MenuItem onClick={onClose}>
          <ShareContextMenu
            data={{game}}
            trackId="context.game.share"
            trackOptions={{id: game._id}}
          />
        </MenuItem>
      </Menu>
    </>
  )
};

export default GameCard;
