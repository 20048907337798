import React, {useState, useEffect, useRef, useContext} from "react";
import { Editable } from "pages/GDD3/Helpers";
import GDDContext from "../../context/GDDContext";

const GDDText = ({ active, loading, scrollTo, changeGdd, section, value }) => {
  const { preview } = useContext(GDDContext);
  const [selected, setSelected] = useState();

  const ref = useRef();
  useEffect(() => {
    if (active && scrollTo && ref.current) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 150);
    }
    if (!active) setSelected();
  }, [active, scrollTo]);

  const actionsActive = loading ? false : active;

  let className = "section text";
  if (actionsActive) className += " active";
  if (!value?.text || !value.title) className += " empty";

  function changeText(text, index) {
    changeGdd(section, { ...(value || {}), [index]: text }, true);
  }

  let titleClassName = "menu-wrapper editable section-subtitle";
  if (selected === "title") titleClassName += " selected";
  let textClassName = "menu-wrapper editable";
  if (selected === "text") textClassName += " selected";

  return (
    <div className={className} ref={ref}>
      <div className="left-content">
        <div className={titleClassName}>
          {preview ? (
            <span className="section-subtitle">{value?.title}</span>
          ) : <Editable
            className="text-align-left w-100"
            value={value?.title}
            setValue={(value = "") => changeText(value, "title")}
            placeholder="Section title"
            onFocus={() => setSelected("title")}
            onBlur={() => setSelected()}
          />}
        </div>
      </div>
      <div className="right-content">
        <div className={textClassName}>
          <Editable
            className="text-align-left w-100"
            value={value?.text}
            setValue={(value = "") => changeText(value, "text")}
            placeholder="Section text"
            onFocus={() => setSelected("text")}
            onBlur={() => setSelected()}
          />
        </div>
      </div>
    </div>
  );
};

export default GDDText;
