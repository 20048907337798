import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Button } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import ShowIf from "components/common/ShowIf";
import _ from "lodash";
import { CloudUploadOutlined, DeleteOutline } from "@mui/icons-material";

const DEFAULT_ARRAY = [];

const FileUpload = ({
  onFilesUpdated,
  title = "Image Upload",
  accept = "image/jpeg, image/png",
  maxFiles,
  hideDropZone = false,
  onRemovedFile,
  initialFiles = DEFAULT_ARRAY,
  hideFiles = false,
  uploadText = "Drag & Drop File",
  preview
}) => {
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept,
    maxFiles,
  });

  const [files, setFiles] = useState(initialFiles);
  const [rejected, setRejected] = useState([]);

  function removeFile(file) {
    if (onRemovedFile) onRemovedFile(file);
    setRejected([...rejected, file.path]);
  }

  useEffect(() => {
    setFiles(
      acceptedFiles
        .filter((file) => !rejected.includes(file.path))
        .map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
    );
  }, [acceptedFiles, rejected]);

  useEffect(() => {
    onFilesUpdated(files);
  }, [files]);

  return (
    <div className="dropzone-wrapper mt-4">
      {title && <Typography className="mb-2 title">{title}</Typography>}
      <ShowIf condition={!hideDropZone}>
        <div className="dropzone">
          <div {...getRootProps({ className: "dropzone-upload-wrapper" })}>
            <input {...getInputProps()} />
            <div className="dropzone-inner-wrapper p-1">
              {isDragAccept && (
                <div>
                  <div className="d-50 btn-icon mb-2 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                    <CheckIcon className="d-30" />
                  </div>
                  <div className="font-size-sm text-success">
                    All files will be uploaded!
                  </div>
                </div>
              )}
              {isDragReject && (
                <div>
                  <div className="d-50 btn-icon mb-2 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                    <CloudUploadOutlined className="d-30" />
                  </div>
                  <div className="font-size-sm text-danger">
                    Some files will be rejected!
                  </div>
                </div>
              )}
              {!isDragActive && (
                <div className="icon-wrapper">
                  <div className="btn-icon mb-2 hover-scale-lg text-white">
                    <CloudUploadOutlined />
                  </div>
                  <div className="font-size-sm instructions">
                    <span>{uploadText}</span>
                    <span className="font-italic">or</span>
                    <span className="browse">Browse</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ShowIf>
      <ShowIf condition={!hideFiles}>
        <ThumbnailPreview
          files={_.uniqBy([...files, ...initialFiles], "path")}
          removeFile={removeFile}
        />
      </ShowIf>
      {preview}
    </div>
  );
};

export const ThumbnailPreview = ({
  files,
  removeFile,
  squareSize,
  squareFit,
  controls = true,
  extraControls,
}) => {
  const DEFAULT_SIZE = 130;

  const renderThumbnail = (file) => {
    const thumbnailClass = squareSize ? "square-thumbnail" : "";
    const style = squareSize
      ? { width: `${squareSize}px`, height: `${squareSize}px` }
      : {};
    const isVideo = file.type.startsWith("video/");
    const objectStyle = squareFit ? { objectFit: squareFit } : undefined;
    return (
      <div className={thumbnailClass} style={style} key={file.path}>
        {isVideo ? (
          <video
            src={file.preview}
            height={squareSize || DEFAULT_SIZE}
            style={objectStyle}
            controls={controls}
          />
        ) : (
          <>
            {file?.mask && <img src={file.mask} className="mask" />}
            <img
              src={file?.cropped?.url || file.image?.url || file.preview}
              height={squareSize || DEFAULT_SIZE}
              style={objectStyle}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="thumbnail-preview m-1 d-flex flex-row">
      {files.map((file) => (
        <div
          className="font-size-sm px-1 m-0 p-0 text-primary d-flex justify-content-between align-items-center h-10 position-relative"
          key={file.path || file.size || file.lastModifiedDate}
        >
          {file.preview && renderThumbnail(file)}
          <div className="thumbnail-controls">
            {removeFile && (
              <Button
                variant="text"
                className="btn-transition-none p-0"
                style={{ color: "white" }}
                onClick={() => removeFile(file)}
              >
                <DeleteOutline />
              </Button>
            )}
            {extraControls}
          </div>
        </div>
      ))}
    </div>
  );
};

export async function toBase64(file, shouldClean = true) {
  function clean(base64) {
    let split = base64.split(",");
    return split[1] || split(0);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (shouldClean) reader.onload = () => resolve(clean(reader.result));
    else reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default FileUpload;
