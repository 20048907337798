import React, {useMemo} from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import moment from 'moment';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;

  const year = useMemo(() => {
    return moment().format('YYYY');
  },[]);

  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
        </div>
        <div className="app-footer--second">
          <span>Copyright</span> ©
          {year} {' '}
          {' '}
          <a
            href="https://ludo.ai"
            target="_blank"
            title="ludo.ai"
            rel="noopener noreferrer">
            Ludo.ai
          </a>
          <span>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
