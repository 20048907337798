import {useCallback, useMemo} from "react";
import _ from 'lodash';
import PerformanceUtils from "helpers/PerformanceUtils";
import usePersistedState from "hooks/usePersistedState";

export const DETAILS_PANEL_TYPES = {
  game_topics: 'game_topics',
  game: 'game',
  trend_topic: 'trend_topic',
  image: 'image',
  developer: 'developer',
  ideator: 'ideator',
  competitive_analysis: 'competitive_analysis',
  chat: 'chat',
}

const DEFAULT_OBJECT = {};
const MAX_HISTORY_SIZE = 20;

function useDetailsPanel(cache, setCache, setCacheValue, track) {

  const [detailsPanelHistory, setDetailsPanelHistory] = usePersistedState('detailsPanelHistory', []);

  const {detailsPanel = DEFAULT_OBJECT} = cache;

  const historyIndex = useMemo(() => {
    return _.findIndex(detailsPanelHistory || [], element => element.id === detailsPanel.id);
  }, [detailsPanelHistory, detailsPanel]);

  const hasPrevious = useMemo(() => {
    return historyIndex > 0;
  }, [historyIndex]);

  const hasNext = useMemo(() => {
    return historyIndex < ((detailsPanelHistory || []).length - 1);
  }, [historyIndex, detailsPanelHistory]);

  const showGameTopics = useCallback(async (game, topics, genre, location, chart) => {
    track('details-panel.game_topics', {id: game._id});
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.game_topics,
      game,
      genre,
      location,
      topics,
      content_id: game._id,
      chart
    }
    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const showGame = useCallback(async (game, genre, location, chart) => {
    track('details-panel.game', {id: game._id});
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.game,
      game,
      genre,
      location,
      content_id: game._id,
      chart
    }
    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const showTrendTopic = useCallback(async (topic, genre, topics) => {
    track('details-panel.trend-topic', {topic});
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.trend_topic,
      topic,
      genre,
      topics,
      content_id: topic.topic_id
    };

    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const showIdeator = useCallback(async () => {
    track('details-panel.ideator', {});
    let data = {id: PerformanceUtils.generateId(), visible: true, mode: DETAILS_PANEL_TYPES.ideator};
    setCacheValue('detailsPanel', data);
  }, []);

  const showChat = useCallback(async () => {
    track('details-panel.chat', {});
    let data = {id: PerformanceUtils.generateId(), visible: true, mode: DETAILS_PANEL_TYPES.chat};
    setCacheValue('detailsPanel', data);
  }, []);

  const showImage = useCallback(async (image) => {
    track('details-panel.image', {image});
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.image,
      image,
      content_id: image?.url
    };
    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const showDeveloper = useCallback(async (developer_name, developer_store) => {
    track('details-panel.developer', {developer_name, developer_store});
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.developer,
      developer_name,
      developer_store,
      content_id: developer_name + developer_store
    };
    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const showCompetitiveAnalysis = useCallback(async (payload) => {
    track('details-panel.competitive-analysis', payload);
    let data = {
      id: PerformanceUtils.generateId(),
      visible: true,
      mode: DETAILS_PANEL_TYPES.competitive_analysis,
      ...payload
    };
    setCacheValue('detailsPanel', data);
    addToHistory(data);
  }, [detailsPanelHistory, historyIndex]);

  const addToHistory = useCallback((data) => {
    let history = detailsPanelHistory;

    if (history.length > 0 && data.content_id === history[history.length - 1].content_id) {
      history[history.length - 1] = data;
    } else {
      if (historyIndex >= 0 && historyIndex < (detailsPanelHistory || []).length - 1) {
        history = history.slice(0, historyIndex + 1);
      }
      history = [...history, data].slice(-1 * MAX_HISTORY_SIZE);
    }
    setDetailsPanelHistory(history);
    //triggerResize();
  }, [detailsPanelHistory, historyIndex]);

  const navigatePrevious = useCallback(() => {
    if (hasPrevious) {
      track('details-panel.previous');
      let data = detailsPanelHistory[historyIndex - 1];
      setCacheValue('detailsPanel', data);
    }
  }, [detailsPanelHistory, historyIndex, hasPrevious]);

  const navigateNext = useCallback(() => {
    if (hasNext) {
      track('details-panel.next');
      let data = detailsPanelHistory[historyIndex + 1];
      setCacheValue('detailsPanel', data);
    }
  }, [detailsPanelHistory, historyIndex, hasNext]);

  const hidePanel = useCallback(() => {
    track('details-panel.hide');
    setCacheValue('detailsPanel', {visible: false})
    //triggerResize();
  }, [])


  return {
    showGameTopics,
    showGame,
    showTrendTopic,
    showImage,
    showDeveloper,
    showChat,
    showCompetitiveAnalysis,
    showIdeator,
    hidePanel,
    navigatePrevious,
    navigateNext,
    hasPrevious,
    hasNext
  }
}

export default useDetailsPanel;
