import React, {useEffect, useState} from "react";

const fabric = window.fabric;

export const Canvas = ({
                  width, height, onExport, brushSize = 10, className, onUndo
                }) => {
  const [canvasState, setCanvasState] = useState({});
  const [canvasHistory, setCanvasHistory] = useState([]);

  useEffect(() => {
    if (onUndo) {
      onUndo(undo);
    }
  }, [onUndo, brushSize, canvasState, canvasHistory]);

  useEffect(() => {
    setupCanvas(width, height);
  }, [width, height])

  useEffect(() => {
    if (canvasState.canvas) {
      canvasState.canvas.freeDrawingBrush.width = brushSize;
    }
  }, [brushSize, canvasState])

  useEffect(() => {

    const canvas = canvasState.canvas;

    const handleExport = () => {
      let canvas = canvasState.canvas;
      if (canvas) {
        const isCanvasErased = canvas.getObjects().some(obj => obj.eraser);
        if (!isCanvasErased) {
          return onExport(undefined);
        }

        const pngDataUrl = canvas.toDataURL({
          format: 'png',
          enableRetinaScaling: false,
          top: 1,
          left: 1,
          width: canvas.getWidth() - 1,
          height: canvas.getHeight() - 1
        });
        onExport(pngDataUrl, false);

        // Save the current canvas state
        setCanvasHistory(prevHistory => [...prevHistory, canvas.toJSON()]);
      }
    };

    if (canvas) {
      handleExport();
      canvas.on('mouse:up', handleExport);
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:up', handleExport);
      }
    };
  }, [canvasState.canvas]);

  const undo = () => {
    if (canvasHistory.length > 1) {
      const previousState = canvasHistory[canvasHistory.length - 2];
      canvasState.canvas.loadFromJSON(previousState, () => {
        canvasState.canvas.renderAll();
        setCanvasHistory(prevHistory => prevHistory.slice(0, -1));
        setCanvasState(prevState => ({...prevState, historyLength: canvasHistory.length - 1}));
      });
    } else if (canvasHistory.length === 1) {
      // Reset to initial state
      canvasState.canvas.loadFromJSON(canvasHistory[0], () => {
        canvasState.canvas.renderAll();
      });
    }
  };

  async function setupCanvas(width, height) {
    let mainCanvas = new fabric.Canvas('mask-canvas', {
      height,
      width,
      renderOnAddRemove: true,
    });

    let rectOptions = {
      left: 0,
      top: 0,
      width: width,
      height: height,
      selectable: false,
      evented: false,
    }
    mainCanvas.add(new fabric.Rect({...rectOptions, fill: "white", erasable: false}));
    mainCanvas.add(new fabric.Rect({...rectOptions, fill: "black", erasable: true}));

    mainCanvas.isDrawingMode = true;
    mainCanvas.freeDrawingBrush = new fabric.EraserBrush(mainCanvas);
    mainCanvas.freeDrawingBrush.width = brushSize;
    mainCanvas.renderAll();

    // Save the initial state to the history
    setCanvasHistory([mainCanvas.toJSON()]);

    setCanvasState({canvas: mainCanvas, width, height});
  }

  return (
    <div className={className} style={{width: `${width}px`, height: `${height}px`}}>
      <canvas id="mask-canvas" tabIndex="1"/>
    </div>
  );
}
