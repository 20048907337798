import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {getGameScoreData} from "pages/Trends/TrendGames";
import APIContext from "context/APIContext";
import CacheContext from "context/CacheContext";
import ShowIf from "components/common/ShowIf";
import {Grid} from "@material-ui/core";
import GameCard from "components/common/GameCard";
import './style.scss';

const getGamesTrends = 'getGamesTrends';
const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];

const GameGrid = ({
                    games = DEFAULT_ARRAY,
                    altText = DEFAULT_ARRAY,
                    gameWidth = 260,
                    spacing = 3,
                    children,
                    gameProps = DEFAULT_OBJECT,
                    includeStats = true,
                    allGenres = false,
                    lockAfterIndex
                  }) => {

  const {cache} = useContext(CacheContext);
  const {trialExpired} = cache;

  const ref = useRef();
  const [trends, setTrends] = useState({});
  const {call} = useContext(APIContext);

  let hasSelected = false;
  let selectedGame = undefined;
  let className = "game-grid";
  const itemClassName = "py-0 keep-together game-grid-item";
  if (hasSelected) className += " has-selected";

  useEffect(() => {
    if (includeStats) {
      let ids = games.filter(g => !!g).map(({_id}) => _id);
      call(getGamesTrends, {data: {ids}, location: gameProps?.location || 'United States'}).then(response => {
        if (response.ok) {
          let result = {};
          response.body.forEach(trend => {
            result[trend.game_id] = trend.infos;
          })
          setTrends(result);
        }
      });
    }
  }, [games, includeStats, gameProps?.location]);

  const finalAltText = useMemo(() => {
    if (altText.length > 0) return altText;
    return games.map(game => getGameScoreData({
      game_info: trends[game._id],
      game,
    }, undefined, undefined, allGenres))
  }, [altText, allGenres, games, trends]);

  return (
    <div className={className} ref={ref}>
      <Grid container justifyContent="flex-start" spacing={spacing}>
        {games.map((game, index) => {
            return (
              <ShowIf condition={!!game?._id} key={game?._id+index}>
                <Grid
                  item
                  className={itemClassName + (selectedGame === index ? " selected" : "")}
                  key={game?._id}
                  sm="auto"
                  xs="auto"
                >
                  <GameCard
                    game={game}
                    altText={finalAltText[index]}
                    {...gameProps}
                    locked={trialExpired && lockAfterIndex ? index >= lockAfterIndex : false}
                  />
                </Grid>
              </ShowIf>
            );
          }
        )}
        {children}
      </Grid>
    </div>
  )
};

export default GameGrid;
