import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import * as Sentry from "@sentry/react";
import config from 'config/config.json';
import {Integrations} from "@sentry/tracing";
import FontFaceObserver from 'fontfaceobserver';


Sentry.init({
  environment: config.ENVIRONMENT,
  dsn: "https://d2a650c98ae7408680e8639998e3db24@o505219.ingest.sentry.io/5594568",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

//new FontFaceObserver('Bison').load().then(() => {
  ReactDOM.render(<AppWrapper/>, document.getElementById('root'));
//});
