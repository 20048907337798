import React, {useContext, useEffect, useMemo, useState, useCallback} from 'react';
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import LudoLazyLoad from "components/common/LudoLazyLoad";
import _ from 'lodash';
import {
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar, MenuItem, Select, InputLabel, IconButton, Tooltip, FormControl, Chip
} from "@material-ui/core";
import ShowIf, {ShowVisibleIf} from "components/common/ShowIf";
import ChartsContext from "context/ChartsContext";
import {GameContextMenu, GameIcon, SOURCES} from "components/common/GameCard";
import './style.scss';
import usePersistedState from "hooks/usePersistedState";
import DetailsPanelContext from "context/DetailsPanelContext";
import SocketContext from "context/SocketContext";
import {getRankAndSlope} from "pages/Trends/TrendGames";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CacheContext from "context/CacheContext";
import {DETAILS_PANEL_TYPES} from "useDetailsPanel";
import {useBus} from "react-bus";
import moment from "moment";
import {WbIncandescentOutlined} from "@mui/icons-material";
import {SlopeIcon} from "components/common/GameDetailsPanel";
import AuthContext from "../../context/AuthContext";
import {useHistory, useLocation} from "react-router";

const getTopCharts = 'getTopCharts';
const getGamesInformation = 'getGamesInformation';
const getGamesTrends = 'getGamesTrends';
const getGameTopics = 'getGameTopics';

const DEFAULT_SOURCE = 'appstore';
const DEFAULT_GENRE = 'All';
const DEFAULT_COUNTRY = 'United States';
const LIMIT = 200;

const DEFAULT_OBJECT = {};
const DEFAULT_GAME_PROPS = {
  lazyLoad: true
};

const TopCharts = ({fullVersion = true, gameProps = DEFAULT_GAME_PROPS, defaultLocation}) => {

  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const location = useLocation();

  const {track} = useContext(SocketContext);
  const {auth} = useContext(AuthContext);
  const {call, loading} = useContext(APIContext);
  const {charts, setCharts} = useContext(ChartsContext);

  const defaultSource = auth.user.platform === "Mobile" ? 'appstore' : (auth.user.platform === 'Desktop' ? 'steam' : DEFAULT_SOURCE);
  const defaultGenre = auth.user.genres[0] || DEFAULT_GENRE;

  const [source, setSource] = usePersistedState('topCharts.source'+defaultSource, defaultSource, true);
  const [genre, setGenre] = usePersistedState('topCharts.genre'+defaultGenre, defaultGenre, true);
  const [country, setCountry] = usePersistedState('topCharts.country' + (defaultLocation || ""), defaultLocation || DEFAULT_COUNTRY, true);
  const [chart, setChart] = useState();

  useEffect(() => {
    if (!!location.state?.data) {
      let data = location.state.data;
      if(data.source) setSource(data.source);
      if(data.genre) setGenre(data.genre);
      if(data.country) setCountry(data.country);
      history.replace({...history.location, state: {}});
    }
  }, [location]);

  useEffect(() => {
    if (charts.length === 0) {
      call(getTopCharts, {filters: {}}).then(response => {
        if (response.ok) {
          setCharts(response.body);
        }
      })
    }
  }, [charts, fullVersion]);

  const isLoading = loading[getTopCharts];

  useEffect(() => {
    if (!fullVersion && !chart && !!source && (charts[source] || []).length > 0) {
      setChart(charts[source][0].chart);
    }
  }, [chart, fullVersion, charts, source]);

  const filteredCountries = useMemo(() => {
    return _.uniq((charts[source] || []).map(chart => chart.country)).filter(c => !!c);
  }, [source, charts]);

  const filteredGenres = useMemo(() => {

    return _(charts[source] || [])
      .map(chart => {
        let label = chart.genre !== "All" ? chart.genre : "AA_Overall";
        return {
          value: chart.genre,
          label
        }
      })
      .filter(g => !!g && !!g.value && g.label)
      .uniqBy('value')
      .sortBy(['label'])
      .map(val => {
        return {
          ...val, label: val.label.replace('AA_', '')
        }
      })
      .value();

  }, [source, charts]);

  const filteredCharts = useMemo(() => {
    return _.uniqBy((charts[source] || []).map(chart => {
      return {
        value: chart.chart,
        label: chart.chart
      }
    }).filter(g => !!g && !!g.value && g.label), 'value');
  }, [source, charts]);

  const selectedCountry = useMemo(() => {
    return filteredCountries.includes(country) ? country : undefined;
  }, [country, filteredCountries]);

  const selectedGenre = useMemo(() => {
    return filteredGenres.map(({value}) => value).includes(genre) ? genre : "All";
  }, [genre, filteredGenres]);

  useEffect(() => {
    track('top-charts.load', {genre: selectedGenre, country: selectedCountry, source});
  }, [selectedGenre, selectedCountry, source])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  if (isMobile) fullVersion = false;

  const size = fullVersion ? {xs: 12, s: 4, lg: 3} : {xs: 12, lg: 12};

  useEffect(() => {
    if (!isMobile && !!fullVersion) {
      setChart();
    }
  }, [isMobile, fullVersion]);

  const orderedChartIds = useMemo(() => {
    return [SOURCES.appstore.id, SOURCES.playstore.id, SOURCES.steam.id, SOURCES.itch.id].filter(id => Object.keys(charts).includes(id));
  }, [charts]);

  return (
    <div className="top-charts">
      <ShowVisibleIf condition={fullVersion} className="title-hide">
        <PageTitle
          titleHeading="Top Charts Blender"
          titleDescription="Blend games from the top charts to create new ideas."
        />
      </ShowVisibleIf>
      <div className="form-wrapper pb-4">
        <ShowIf condition={isLoading}>
          <div className="text-align-center m-4">
            <CircularProgress size={55}/>
          </div>
        </ShowIf>
        <ShowIf condition={!isLoading}>
          <Grid container spacing={3} className="mb-0">
            <ShowIf condition={!isLoading && Object.keys(charts).length > 0}>
              <Grid item xs={size.xs} lg={size.lg}>
                <FormControl className="w-100">
                  <InputLabel id="chip-select-label">Store</InputLabel>
                  <Select
                    name="source"
                    label="Store"
                    value={source}
                    className="w-100 store-input"
                    defaultValue={source}
                    onChange={(event) => {
                      setSource(event.target.value);
                      setChart();
                    }}
                  >
                    {orderedChartIds.map(chartSource => (
                      <MenuItem
                        key={chartSource}
                        value={chartSource}>
                        <>
                          <Avatar
                            variant="square"
                            className="mr-2 d-inline-block position-relative"
                            src={SOURCES[chartSource].icon}
                            style={{
                              width: "20px",
                              height: "20px",
                              overflow: "visible",
                              position: "relative",
                              top: "-2px"
                            }}
                          />
                          <span>{SOURCES[chartSource].label}</span>
                        </>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </ShowIf>
            <ShowIf condition={!isLoading && !!source && filteredCountries.length > 0}>
              <Grid item xs={size.xs} lg={size.lg}>
                <FormControl className="w-100">
                  <InputLabel id="chip-select-label">Country</InputLabel>
                  <Select
                    name="country"
                    label="Country"
                    value={country}
                    className="w-100"
                    defaultValue={country}
                    onChange={(event) => {
                      setCountry(event.target.value)
                    }}
                  >
                    {filteredCountries.map(currentCountry => (
                      <MenuItem
                        key={currentCountry}
                        value={currentCountry}
                      >
                        <span>{currentCountry}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </ShowIf>
            <ShowIf condition={!isLoading && !!source && !!country && filteredGenres.length > 0}>
              <Grid item xs={size.xs} lg={size.lg}>
                <FormControl className="w-100">
                  <InputLabel id="chip-select-label">Genre</InputLabel>
                  <Select
                    name="genre"
                    label="Genre"
                    value={genre}
                    className="w-100"
                    defaultValue={genre}
                    onChange={(event) => {
                      setGenre(event.target.value)
                    }}
                  >
                    {filteredGenres.map(({value, label}) => (
                      <MenuItem
                        key={value}
                        value={value}
                      >
                        <span>{label}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </ShowIf>
            <ShowIf
              condition={!fullVersion && !isLoading && !!source && !!country && genre && filteredCharts.length > 0}>
              <Grid item xs={size.xs} lg={size.lg}>
                <FormControl className="w-100">
                  <InputLabel id="chip-select-label">Chart</InputLabel>
                  <Select
                    key={chart + fullVersion}
                    name="chart"
                    label="Chart"
                    value={chart}
                    className="w-100"
                    defaultValue={chart}
                    onChange={(event) => {
                      setChart(event.target.value)
                    }}
                  >
                    {filteredCharts.map(({value, label}) => (
                      <MenuItem
                        key={value}
                        value={value}
                      >
                        <span>{label}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </ShowIf>
          </Grid>
        </ShowIf>
      </div>
      {(fullVersion || !!chart) && <TopChartsContent
        key={country+source+genre}
        charts={charts}
        source={source}
        country={selectedCountry}
        genre={selectedGenre}
        gameProps={gameProps}
        chart={chart}
        showDate={true}
      />}
    </div>
  )
};

export const TopChartsContent = ({charts, genre, country, source, gameProps, chart, showDate = false}) => {

  const {call} = useContext(APIContext);
  const [games, setGames] = useState({});
  const [trends, setTrends] = useState({});
  const [topics, setTopics] = useState({});

  let filteredCharts = useMemo(() => {
    let result = [];
    if (source) {
      result = charts[source] || [];
      if (country && !!result.find((chart) => chart.country === country))
        result = result.filter(chart => chart.country === country);
      if (genre && !!result.find((chart) => chart.genre === genre))
        result = result.filter(chart => chart.genre === genre);
      if (chart && !!result.find((ch) => ch.chart === chart))
        result = result.filter(ch => ch.chart === chart);
    }
    return _.uniqBy(_.sortBy(result, ['chart']), 'chart').slice(0, 3);
  }, [country, source, charts, genre, chart]);

  useEffect(() => {
    if (!gameProps?.lazyLoad) {
      let gameIds = _.uniq(_.flatten(filteredCharts.map(({game_ids}) => game_ids)));
      doSearch(gameIds);
    }
  }, [filteredCharts, gameProps?.lazyLoad]);

  const doSearch = useCallback(async ids => {
    let [response, responseTrends, responseTopics] = await Promise.all([
      call(getGamesInformation, {data: {ids}}),
      call(getGamesTrends, {data: {ids}, genre, location: country}),
      call(getGameTopics, {data: {ids, location: country, source}}),
    ]);
    if (responseTrends.ok) {
      setTrends(prevState => {
        let newTrends = {...prevState};
        responseTrends.body.forEach(trend => {
          if (trend.game_id) newTrends[trend.game_id] = trend.infos[0];
        });
        return newTrends;
      });
    }
    if (responseTopics.ok) {
      setTopics(prevState => {
        let newTopics = {...prevState};
        responseTopics.body.forEach(data => {
          if (data.game_id) {
            newTopics[data.game_id] = data.topics;
          }
        });
        return newTopics;
      });
    }
    if (response.ok) {
      setGames(prevState => {
        let newGames = {...prevState};
        response.body.forEach(g => {
          newGames[g._id] = g;
        });
        return newGames;
      });
    }
  }, [genre, country]);

  const xs = (12 / (filteredCharts || []).length);

  const onLoad = useCallback(async (ids) => {
    if (gameProps.lazyLoad) {
      doSearch(ids)
    }
  }, [gameProps.lazyLoad]);

  let date = filteredCharts[0]?.date;
  let dateString = moment.unix(date).fromNow();

  return (
    <ShowIf condition={filteredCharts.length > 0}>
      <ShowIf condition={!!showDate && !!date}>
        <div className="updated-bar">
          <span className="d-block">Last Updated: {dateString}</span>
        </div>
      </ShowIf>
      <div className="top-charts-content text-primary pt-4">
        <Grid container>
          {filteredCharts.map((chart) => (
            <Grid item xs={xs} key={chart._id}>
              <Chart
                chart={chart}
                games={games}
                trends={trends}
                genre={genre}
                location={country}
                onLoad={onLoad}
                gameProps={gameProps}
                topics={topics}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </ShowIf>
  );
}

const BATCH_SIZE = 20;

export const Chart = ({chart = DEFAULT_OBJECT, genre, games, trends, location, onLoad, gameProps, topics}) => {

  const {showGame} = useContext(DetailsPanelContext);
  const {cache} = useContext(CacheContext);
  const {trialExpired, detailsPanel} = cache;

  const onSelectedGame = useCallback(async (game, chart) => {
    if (!!gameProps?.onClick) return gameProps.onClick(game);
    if (game) showGame(game, genre, location, chart);
  }, [gameProps.onClick, genre, location, chart]);

  const batches = useMemo(() => {
    let result = [];
    let ids = chart.game_ids.slice(0, LIMIT);
    for (let i = 0, j = 0; i < ids.length; i++) {
      if (i > 0 && i % BATCH_SIZE === 0) j++;
      result[j] = result[j] || [];
      result[j].push(ids[i]);
    }
    return result;
  }, [chart.game_ids])

  return (
    <div className="chart">
      <Typography className="chart-title text-align-left text-capitalize font-size-md font-weight-bold">
        {chart.chart}
      </Typography>
      <div className="card-box p-2 m-1">
        <List>
          {batches.map((ids, index) => (
            <LudoLazyLoad enable={gameProps?.lazyLoad && index > 0} offset={0} height={2000}
                          scrollContainer=".app-content--inner__wrapper"
                          once key={"lazy" + index}>
              <ChartGameBatch
                key={"batch" + index}
                chart={chart.chart}
                games={games}
                trends={trends}
                ids={ids}
                index={(index * BATCH_SIZE) + 1}
                onSelectedGame={onSelectedGame}
                onLoad={onLoad}
                detailsPanelMode={detailsPanel?.mode}
                trialExpired={trialExpired}
                topics={topics}
              />
            </LudoLazyLoad>
          ))}
        </List>
      </div>
    </div>
  )
}

const ChartGameBatch = ({
                          ids,
                          chart,
                          index,
                          games,
                          trends,
                          onSelectedGame,
                          onLoad,
                          trialExpired,
                          detailsPanelMode,
                          topics
                        }) => {

  useEffect(() => {
    onLoad(ids);
  }, [ids]);

  return ids.map(((id, gameIndex) => <ChartGame
    id={id}
    key={id + index + gameIndex}
    chart={chart}
    game={games[id]}
    trend={trends[id]}
    index={index + gameIndex}
    onSelectedGame={onSelectedGame}
    trialExpired={trialExpired}
    detailsPanelMode={detailsPanelMode}
    topics={topics[id]}
  />));
}

export const ChartGame = React.memo(({
                                id,
                                index,
                                game,
                                trend,
                                onSelectedGame,
                                chart,
                                trialExpired,
                                detailsPanelMode,
                                topics,
  includeActions = true,
  showChartPosition = false
                              }) => {

  const bus = useBus();
  const {track} = useContext(SocketContext);
  const {showDeveloper, showIdeator} = useContext(DetailsPanelContext);
  const {showTrendTopic} = useContext(DetailsPanelContext);

  const {slope, rank} = useMemo(() => getRankAndSlope(trend, chart), [trend, chart]);

  function onSelectedDeveloper(game) {
    if (game) showDeveloper(game.developers[0], game.source);
  }

  function addToIdeator(event) {
    trackWrapper('add-to-ideator');
    event.preventDefault();
    event.stopPropagation();

    let timeout = 500;

    if (detailsPanelMode === DETAILS_PANEL_TYPES.ideator)
      timeout = 0;

    showIdeator();
    setTimeout(() => {
      bus.emit('ideatorAddGame', game);
    }, timeout);
  }

  function trackWrapper(action) {
    track(`top-chart-card.${action}`, {game});
  }

  function onClickGameTopic() {
    trackWrapper(`clicked-game-topics`);
    showTrendTopic(topics[0], undefined, topics)
  }

  let topicName = useMemo(() => {
    let result = (topics || [])[0]?.title;
    if(result && result.length > 16) {
      result = result.substring(0, 16) + '...';
    }
    return result;
  },[topics]);

  return <div key={id + index} className="chart-game">
    <ListItem>
        {includeActions && !!game && <ActionMenu game={game} key={"action" + id + index} trackWrapper={trackWrapper} trialExpired={trialExpired}
                    addToIdeator={addToIdeator}/>}
      {index !== undefined && <span className="position">{index}&nbsp;</span>}
      <ListItemAvatar className="clickable" onClick={() => onSelectedGame(game, chart)}>
        <>
          {game && <GameIcon game={game} fallbackToCover={true}/>}
          {!game && <CircularProgress size={20}/>}
        </>
      </ListItemAvatar>
      {!!game && <ListItemText
        primary={
          <span
            className="title truncate-text font-size-md link"
            onClick={() => onSelectedGame(game, chart)}>
            {game?.title}
          </span>
        }
        secondary={
          <>
            <span className="sub-title">
              <span
                className="dev truncate-text font-size-sm link"
                onClick={() => onSelectedDeveloper(game)}>
            {(game?.developers || [])[0]}
              </span>
            </span>
            <span className="sub-title">
              {showChartPosition && rank && <span className="trend font-size-sm">#{rank}</span>}
              {slope !== null && <span className="trend font-size-sm">
                <SlopeIcon slope={slope} className="font-size-sm mr-1"/>
                <span>{slope}</span>
              </span>}
              {showChartPosition && rank && trend?.genre && <span className="trend font-size-sm ml-1">{trend.genre}</span>}
              {topicName && <div className="game-topic" onClick={onClickGameTopic}>
                <Chip label={topicName} className="font-size-sm mr-1"/>
                {topics?.length > 1 && <span className="trend font-size-sm">+{topics.length - 1}</span>}
              </div>}
            </span>
          </>
        }
      />}
    </ListItem>
  </div>
});

const ActionMenu = ({game, addToIdeator, trackWrapper}) => {

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  function onActionsClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(null);
  }

  function onActionsClick(event) {
    trackWrapper('open-context');
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }

  return (
    <div className="actions">
      <span className="gamepad-wrapper">
          <Tooltip
            title="Add to Ideator"
            PopperProps={{disablePortal: true, className: "MuiTooltip-popper secondary"}}
            placement="top"
          >
          <IconButton aria-label="ideator" className="icon-button text-secondary" onClick={addToIdeator}>
            <WbIncandescentOutlined className="flip-vertical"/>
          </IconButton>
          </Tooltip>
        </span>
      <IconButton aria-label="more actions" className="icon-button text-secondary" onClick={onActionsClick}
                  key="icon-button">
        <MoreVertIcon/>
      </IconButton>

      <GameContextMenu
        game={game}
        onClose={onActionsClose}
        anchor={menuAnchorEl}
        showGenerate={false}
        disablePortal={true}
      />
    </div>
  )
}

export default TopCharts;
