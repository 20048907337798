import React, {useContext, useState} from "react";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import {FormikTextField} from "formik-material-fields";
import AccountBoxOutlined from "@mui/icons-material/AccountBoxOutlined";
import MailOutlineTwoToneIcon from "@mui/icons-material/MailOutlineTwoTone";
import MyButton from "components/Controls/MyButton";
import CountryData from "helpers/CountryData";
import {Autocomplete} from "@material-ui/lab";
import ShowIf from "components/common/ShowIf";
import * as Yup from "yup";
import ErrorBadge from "components/common/ErrorBadge";
import {ErrorBoundary} from "@sentry/react";

const updateUserInfo = 'updateUserInfo';
const InvoicingDetails = ({onSuccess, buttonText = "Save & Proceed"}) => {

  const {call, loading} = useContext(APIContext);
  const {auth, setAuth} = useContext(AuthContext);
  const {user} = auth;

  const [error, setError] = useState();

  const TAX_TYPES = ["ae_tr", "au_abn", "br_cnpj", "br_cpf", "ca_bn", "ca_qst", "ch_vat", "cl_tin", "es_cif", "eu_vat", "hk_br", "id_npwp", "in_gst", "jp_cn", "jp_rn", "kr_brn", "li_uid", "mx_rfc", "my_frp", "my_itn", "my_sst", "no_vat", "nz_gst", "ru_inn", "ru_kpp", "sa_vat", "sg_gst", "sg_uen", "th_vat", "tw_vat", "us_ein", "za_vat"];

  async function onSubmit(values) {
    let data = {
      name: values.name,
      address: values.address,
      tax: values.tax
    };
    let response = await call(updateUserInfo, {data}, {setError});
    if (response.ok) {
      setAuth({user: response.body}, true, false);
      if (onSuccess) onSuccess();
    }
  }

  let isLoading = loading[updateUserInfo];

  return (
    <div className="user-details strong-colors-form">
      <Grid container>
        <Grid item xs={12} className="mx-auto">
          <Formik
            initialValues={{
              email: user.email,
              name: user.name || (user.first_name || "" + user.last_name || ""),
              tax: user.tax || {
                type: "none"
              },
              address: user.address || {}
            }}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
          >
            {(formik) => (
              <Form>
                <FormControl variant="outlined" size="small" fullWidth>
                  <label className="font-weight-bold mb-2">
                    Invoicing Name
                  </label>
                  <FormikTextField
                    name="name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={isLoading}
                    placeholder=""
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBoxOutlined/>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" size="small" fullWidth>
                  <label className="font-weight-bold mb-2">
                    Email address
                  </label>
                  <FormikTextField
                    name="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="yourname@yourmail.com"
                    type="email"
                    disabled={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineTwoToneIcon/>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" size="small" fullWidth>
                  <label className="font-weight-bold mb-3">
                    Tax Identification Number
                  </label>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel>
                          Type
                        </InputLabel>
                        <Select
                          name="tax.type"
                          label="Type"
                          disabled={isLoading}
                          onChange={event => {
                            formik.setFieldValue('tax.type', event.target.value);
                            if (event.target.value === "none")
                              formik.setFieldValue('tax.value', "")
                          }}
                          value={formik.values.tax.type}
                        >
                          <MenuItem value="none">None</MenuItem>
                          {Object.values(TAX_TYPES).map(tax => (
                            <MenuItem value={tax} key={tax}>
                              {tax.toUpperCase().replace('_', ' ')}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <FormikTextField
                        name="tax.value"
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Number"
                        placeholder="If applicable"
                        disabled={isLoading ||
                          formik.values.tax.type === "none" ||
                          !formik.values.tax.type
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <AddressFields loading={isLoading} formik={formik} variant="outlined"/>
                <ErrorBadge error={error}/>
                <div className="text-center">
                  <MyButton
                    id="subscription.invoicing-details-save"
                    color="secondary"
                    style={{width: "100%"}}
                    loading={isLoading}
                  >
                    {buttonText}
                  </MyButton>
                </div>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  )
};

export const AddressFields = ({loading, formik, title = "Address", variant, labels = false}) => {

  let currentCountry = formik.values.address?.country || "";

  const countryValue = {
    value: currentCountry,
    label: CountryData[currentCountry] || ""
  };

  return (
    <ErrorBoundary fallback="An error has occurred">
      <ShowIf condition={!!title}>
        <label className="font-weight-bold mb-4 d-block">
          {title}
        </label>
      </ShowIf>
      {labels && <label className="font-weight-bold mb-1 caps">
        Line 1
      </label>}
      <FormikTextField
        onChange={formik.handleChange}
        name="address.line1"
        variant={variant}
        label={!labels && "Line 1"}
        size="small"
        fullWidth
        disabled={loading}
      />
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          {labels && <label className="font-weight-bold mb-1 caps">
            Line 2
          </label>}
          <FormikTextField
            name="address.line2"
            variant={variant}
            label={!labels && "Line 2"}
            size="small"
            disabled={loading}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {labels && <label className="font-weight-bold mb-1 caps">
            Postal Code
          </label>}
          <FormikTextField
            name="address.postal_code"
            variant={variant}
            label={!labels && "Postal Code"}
            size="small"
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          {labels && <label className="font-weight-bold mb-1 caps">
            City
          </label>}
          <FormikTextField
            name="address.city"
            variant={variant}
            label={!labels && "City"}
            size="small"
            disabled={loading}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl error={formik.errors.address?.state} fullWidth>
            {labels && <label className="font-weight-bold mb-1 caps">
              State
            </label>}
            <FormikTextField
              form={{}}
              name="address.state"
              variant={variant}
              size="small"
              label={!labels && "State"}
              disabled={loading}
              error={false}
            />
            <ShowIf condition={formik.errors.address?.state}>
              <FormHelperText>{formik.errors.address?.state}</FormHelperText>
            </ShowIf>
          </FormControl>
        </Grid>
      </Grid>
      <FormControl error={formik.errors.address?.country} fullWidth>
        {labels && <label className="font-weight-bold mb-1 caps">
          Country
        </label>}
        <Autocomplete
          name="address.country"
          options={Object.keys(CountryData).map(key => {
            return {value: key, label: CountryData[key]}
          })}
          onChange={(event, value) => {
            if (value)
              formik.setFieldValue('address.country', value.value);
            else
              formik.setFieldValue('address.country', "");
          }}
          defaultValue={countryValue}
          value={countryValue}
          getOptionLabel={(option) => {
            return (option.label || "")
          }}
          fullWidth
          disabled={loading}
          renderInput={params => (
            <Field
              component={TextField}
              {...params}
              value={(formik.values.address || {}).country}
              variant={variant}
              size="small"
              label={!labels && "Country"}
            />
          )}
          freeSolo={true}
          getOptionSelected={(option, value) => {
            if (value && value.value) {
              return option.value === value.value;
            }
            return false;
          }}
        />
        <ShowIf condition={formik.errors.address?.country}>
          <FormHelperText>{formik.errors.address?.country}</FormHelperText>
        </ShowIf>
      </FormControl>
    </ErrorBoundary>
  );
};

export default InvoicingDetails;

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No name provided'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('No email provided'),
  address: Yup.object().shape({
    country: Yup.string().required('No country provided'),
    state: Yup.string().required('No state provided'),
  })
});
