import React from 'react';
import logoError from "assets/images/ludo-error.png";
import './style.scss';
import discordIcon from 'assets/images/icons/discord-white.svg';

const LudoErrorPage = () => {
  return (
    <div className="app-wrapper bg-gradient min-vh-100 ludo-error-page">
      <div className="app-main min-vh-100">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div
              className="content-wrapper flex-grow-1 w-100 d-flex align-items-center place-content-center flex-column p-5">
              <img
                height="280"
                alt="Ludo"
                className="d-inline-block ml-2 mr-2"
                src={logoError}
                style={{position: "relative", top: "-3px"}}
              />
              <span className="large-text">Time for a little reboot</span>
              <span>
                This means more awesomeness is around the corner.
              </span>
              <span>
                Please come back soon not to miss it!
              </span>
              <span className="my-4">
                &
              </span>
              <span>
                Join our Discord server in the meantime
              </span>
              <a href="https://discord.gg/FmTPyugsrR"
                 rel="noopener nofollow"
                 target="_blank">
                <img src={discordIcon} alt="Discord" width="40px" className="mt-3"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LudoErrorPage;
