import React, { useState, useCallback } from 'react';

const ImageViewer = ({ src, alt, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleBackgroundClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }, [onClose]);

  return (
    <div className="image-viewer-overlay" onClick={handleBackgroundClick}>
      <div className="image-viewer-header">
        <button className="image-viewer-close-button" onClick={onClose}>&times;</button>
      </div>
      <div className="image-viewer-wrapper">
        {isLoading && <div className="image-viewer-loading">Loading...</div>}
        <img
          className="image-viewer-image"
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>
    </div>
  );
};

export default ImageViewer;
