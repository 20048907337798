import React, {useContext, useEffect, useRef, useState} from 'react';
import SocketContext from "context/SocketContext";
import PageTitle from "components/layout-components/PageTitle";
import './style.scss';
import {Grid} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import ShowIf from "components/common/ShowIf";
import MyButton from "components/Controls/MyButton";
import {useHistory} from "react-router";
import {UncontrolledCollapse} from "components/common/Collapse";
import ReactMarkdown from 'react-markdown'

import AchievementDoneIcon from 'assets/images/icons/mission_done.png';
import AchievementDisabledIcon from 'assets/images/icons/mission_disabled.png';
import config from "config/config.json";
import AuthContext from "context/AuthContext";
import APIContext from "context/APIContext";
import {ArrowBackIosNewOutlined, CheckCircle} from "@mui/icons-material";

const updateUserInfo = 'updateUserInfo';

export function getAchievementStepId(achievement, step) {
  return `${achievement.id}.${step.id}`;
}

const Achievements = ({achievements, onClose, completed, total, progress}) => {

  const {track} = useContext(SocketContext);
  const {setAuth} = useContext(AuthContext);
  const {call} = useContext(APIContext);
  const history = useHistory();
  const [titleKey, setTitleKey] = useState();

  const scrollRef = useRef();

  useEffect(() => {
    track(`achievements.open`);
    setTitleKey(1);
  }, []);

  function isLastCompleted(achievement, step) {
    return completed[completed.length - 1] === getAchievementStepId(achievement, step);
  }

  function isStepCompleted(achievement, step) {
    return completed.includes(getAchievementStepId(achievement, step));
  }

  function isAchievementCompleted(achievement) {
    let stepIds = achievement.steps.map(step => getAchievementStepId(achievement, step));
    let found = stepIds.map(id => completed.includes(id)).filter(val => !!val);
    return found.length === stepIds.length;
  }

  function goToLink(link) {
    history.push(link);
    onClose();
  }

  async function resetAchievements() {
    let response = await call(updateUserInfo, {data: {missions_completed: []}});
    if (response.ok) {
      setAuth({user: {...response.body}}, true, false);
    }
  }

  return (
    <div className="achievement-modal-content scrollable" ref={scrollRef}>
      <PageTitle
        key={titleKey}
        titleHeading="Achievements"
        scrollRef={scrollRef}
      />
      <div className="top-bar position-relative">
        <div className="back clickable hvr-backward" onClick={onClose}>
          <ArrowBackIosNewOutlined className="font-size-lg"/>
          <span className="ml-2">To Ludo</span>
        </div>
        <ShowIf condition={config.ENVIRONMENT === "development"}>
          <MyButton onClick={resetAchievements} color="secondary" size="small"
                    style={{position: "absolute", left: "150px", top: "8px", width: "150px"}}>
            Reset (DEV ONLY)
          </MyButton>
        </ShowIf>
      </div>
      <div className="achievements">
        <div className="big-bar">
          <div className="bar-info">
            <ShowIf condition={completed.length < total}>
              <span className="title">More achievements await to be unlocked!</span>
            </ShowIf>
            <ShowIf condition={completed.length >= total}>
              <span className="title">You unlocked all achievements!</span>
            </ShowIf>
            <span className="description">{completed.length}/{total} Achievements Unlocked</span>
          </div>
          <div className="bar-wrapper">
            <LinearProgress
              variant="determinate"
              value={progress}
            />
          </div>
        </div>
        <Grid container spacing={1}>
          {achievements.map(achievement => {
            let completed = isAchievementCompleted(achievement);
            return (
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div className="achievement" key={achievement.id}>
                  <div className="name">
                    <span>{achievement.name}</span>
                    <div className="emblem">
                      <ShowIf condition={completed}>
                        <img src={AchievementDoneIcon} alt="Achievement Unlocked!" width="50"/>
                      </ShowIf>
                      <ShowIf condition={!completed}>
                        <img src={AchievementDisabledIcon} alt="Achievement not yet Unlocked" width="50"/>
                      </ShowIf>
                    </div>
                  </div>
                  <div className="steps">
                    {achievement.steps.map(step => {
                      let completed = isStepCompleted(achievement, step);
                      let className = "step";
                      let isLast = isLastCompleted(achievement, step);
                      if (isLast) className += " last-completed";
                      return (
                        <div className={className} key={step.id}>
                          <div className="collapse-wrapper">
                            <UncontrolledCollapse title={step.name}>
                              <div className="description">
                                <ReactMarkdown children={step.description}/>
                              </div>
                            </UncontrolledCollapse>
                            <ShowIf condition={isLast}>
                              <span className="last-completed-text">Last Completed</span>
                            </ShowIf>
                          </div>
                          <ShowIf condition={completed}>
                            <CheckCircle className="font-size-xl check-mark"/>
                          </ShowIf>
                          <ShowIf condition={!completed && step.link}>
                            <MyButton
                              id="achievement.go-button"
                              trackOptions={{achievementId: achievement.id, stepId: step.id, link: step.link}}
                              size="small"
                              className="go-button"
                              style={{width: "50px"}}
                              onClick={() => goToLink(step.link)}
                            >Go</MyButton>
                          </ShowIf>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default Achievements;
