import React, {useContext, useMemo} from 'react';
import Tutorial from "components/Tutorials/Tutorial";
import APIContext from "context/APIContext";
import AuthContext from "context/AuthContext";
import _ from 'lodash';

const updateUserInfo = 'updateUserInfo';

export const IdeatorTutorial = () => {
  return <SavedStateTutorial key="image" param="ideator" steps={IDEATOR_STEPS}/>
};

export const ImageTutorial = () => {
  return <SavedStateTutorial key="image" param="image" steps={IMAGE_STEPS}/>
};

export const TrendsTutorial = () => {
  return <SavedStateTutorial key="image" param="trends" steps={TRENDS_STEPS}/>
};

const SavedStateTutorial = ({param, steps}) => {

  const {call} = useContext(APIContext);
  const {auth, setAuth} = useContext(AuthContext);

  const value = useMemo(() => {
    let {tutorials_closed = []} = auth.user;
    return !tutorials_closed.find(item => item === param)
  }, [auth, param]);

  async function updateUser(open) {
    if (open === value) return;

    let tutorials_closed = auth.user.tutorials_closed || [];
    if (!!open)
      tutorials_closed = tutorials_closed.filter(item => item !== param);
    else
      tutorials_closed.push(param);

    tutorials_closed = _.uniq(tutorials_closed);

    let response = await call(updateUserInfo, {data: {tutorials_closed}});
    if (response.ok) {
      setAuth({user: {...response.body}}, true, false);
    }
  }

  return <Tutorial defaultExpanded={value} steps={steps} onChangeExpanded={updateUser}/>
};


export const YOUTUBE_VIDEOS = {
  IDEATOR_1: {
    video: 'https://www.youtube.com/embed/huKOSWsfH7w',
    text: 'Game Ideator'
  },
  IMAGE_1: {
    video: 'https://www.youtube.com/embed/PR7UmxviIKM',
    text: 'Image Search'
  },
  TRENDS_1: {
    video: 'https://www.youtube.com/embed/Tb1Vkv3sxYA',
    text: 'Daily Trends Part 1'
  },
  TRENDS_2: {
    video: 'https://www.youtube.com/embed/7BaZpg8oJjQ',
    text: 'Daily Trends Part 2'
  },
}

const IMAGE_STEPS = [
  YOUTUBE_VIDEOS.IMAGE_1
];

const IDEATOR_STEPS = [
  YOUTUBE_VIDEOS.IDEATOR_1
];

const TRENDS_STEPS = [
  YOUTUBE_VIDEOS.TRENDS_1,
  YOUTUBE_VIDEOS.TRENDS_2
];
