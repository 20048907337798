import React, { useContext } from "react";
import {ArrowDownwardOutlined, FormatListBulletedOutlined} from "@mui/icons-material";
import CacheContext from "../../context/CacheContext";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

const TableOfContents = ({ gdd, changeActiveComponent }) => {
  const { cache } = useContext(CacheContext);
  const { gddComponents } = cache;

  let numberLoading = gdd.sections?.filter(({ loading }) => loading).length;
  numberLoading += (gdd.icon?.loading || gdd.header?.loading) ? 1: 0

  const forceOpen = numberLoading > 0

  let className = "toc-content";
  if (forceOpen) className += " force-open";

  return (
    gdd?.sections?.length > 0 && (
      <div className="toc">
        <div className={className}>
          <FormatListBulletedOutlined className="collapsed-icon" />
          <span className="small-title">Outline</span>
          <ul className="toc-items">
            {gdd.sections?.map((section, index) => {
              const matchingComponent = gddComponents[section.name];
              let title = "";
              let loading = section.loading;
              if (index === 0) {
                title = "Description";
                loading = loading || gdd.icon?.loading || gdd.header?.loading;
              }
              else if (section?.value?.title) title = section?.value?.title;
              else title = matchingComponent?.label;
              return (
                <li onClick={() => changeActiveComponent({...matchingComponent, id: section.id, scrollTo: moment().unix()})}>
                  <span>{title}</span>
                  {loading && <CircularProgress size={15} />}
                </li>
              );
            })}
          </ul>
        </div>
        {numberLoading > 0 && <div className="toc-indicator">
          {numberLoading} sections loading <CircularProgress size={17} />
        </div>}
      </div>
    )
  );
};

export default TableOfContents;
