import PropTypes from 'prop-types';
import React from 'react';

const ShowIf = ({condition, children}) => (condition ? children : null);

export const ShowVisibleIf = ({
                                condition,
                                className = '',
                                style,
                                children
                              }) => (
  <div className={(condition ? '' : 'hide ') + className} style={style}>
    {children}
  </div>
);

ShowIf.propTypes = {
  condition: PropTypes.bool.isRequired
};

ShowIf.defaultProps = {
  condition: false
};

export default ShowIf;
